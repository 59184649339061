<template>
  <div class="call">
    <div class="nav">
      <div class="calleeNumber">
        坐席编号：
        <el-input
          v-model="condition.clientNumber"
          placeholder="请输入坐席编号"
          clearable
        ></el-input>
      </div>
      <div class="callerNumber">
        客户编号：
        <el-input
          v-model="condition.customerCode"
          placeholder="请输入客户编号或手机号"
          clearable
        ></el-input>
      </div>
      <div class="callTime">
        开始时间：
        <el-date-picker
          class="startTime"
          v-model="condition.callEndAtStart"
          type="datetime"
          placeholder="起始时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
          :clearable="false"
        ></el-date-picker>
        -
        <el-date-picker
          class="endTime"
          v-model="condition.callEndAtEnd"
          type="datetime"
          placeholder="终止时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </div>
      <div class="callSecondsTime">
        通话时长：
        <el-input
          class="call-startTime"
          v-model="condition.callSecondsLongerThan"
          placeholder="请输入开始时间"
          @input="handleInput"
        ></el-input>
        -
        <el-input
          class="call-endTime"
          v-model="condition.callSecondsShorterThan"
          placeholder="请输入结束时间"
          @input="handleInputThan"
        ></el-input>
      </div>
      <div class="callStatus" style="float: right">
        通话状态：
        <el-select
          v-model="condition.callStatus"
          placeholder="请选择通话状态"
          clearable
        >
          <el-option
            v-for="item in callStatusList"
            :key="item.callStatus"
            :value="item.callStatus"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
      <div
        class="callerNumber"
        v-if="roleMenu == 'SUPER_MANAGER'"
        style="float: right"
      >
        用户名称：
        <el-select
          v-model="condition.userName"
          filterable
          :clearable="interfaceType"
          remote
          :loading="loadingName"
          :remote-method="NameRemoteMethod"
          placeholder="请选择用户名称"
          @change="loginNameChange"
          @focus="NameRemoteFocus"
        >
          <el-option
            v-for="item in userNameList"
            :key="item.id"
            :value="item.loginName"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
      <div class="taskStatus" style="float: right">
        任务名称：
        <el-select
          v-model="condition.taskId"
          filterable
          remote
          :loading="loadingTask"
          :remote-method="taskRemoteMethod"
          @focus="taskRemoteFocus"
          clearable
          placeholder="请选择任务名称"
        >
          <el-option
            v-for="item in taskNameList"
            :key="item.id"
            :value="item.id"
            :label="item.taskName"
          ></el-option>
        </el-select>
      </div>
      <div class="projectStatus" style="float: right">
        项目名称：
        <el-select
          v-model="condition.projectId"
          filterable
          remote
          :loading="loading"
          :remote-method="remoteMethod"
          @focus="projectIdRemoteFocus"
          clearable
          placeholder="请选择项目名称"
          @change="projectIdChange"
        >
          <el-option
            v-for="item in projectNameList"
            :key="item.id"
            :value="item.id"
            :label="item.projectName"
          ></el-option>
        </el-select>
      </div>
      <div class="projectStatus" style="float: right">
        客户分类：
        <el-select v-model="flagsNumber" placeholder="请选择客户类别" clearable>
          <el-option
            v-for="item in flagsFilter"
            :key="item.id"
            :value="item.id"
            :label="item.label"
          >
            <span><img :src="item.src" width="18px" /></span>
            <span>{{ item.label }}</span>
          </el-option>
        </el-select>
      </div>
      <div class="projectStatus">
        批次编号：
        <el-input
          v-model="condition.batchNumber"
          placeholder="请输入批次编号"
          clearable
        ></el-input>
      </div>
      <div class="operate">
        <el-button type="primary" @click="inquire()">查询</el-button>
        <el-button type="success" @click="reset()">重置</el-button>
        <el-button
          type="primary"
          @click="exportRecord()"
          v-if="roleMenu == 'SUPER_MANAGER' && !interfaceType"
          :disabled="exportRecordDisabled"
          >导出录音</el-button
        >
        <el-button
          type="primary"
          v-if="roleMenu == 'SUPER_MANAGER' && interfaceType"
          disabled
          >导出录音</el-button
        >
        <el-button
          type="primary"
          @click="exportPhone()"
          v-if="roleMenu == 'SUPER_MANAGER'"
          :disabled="exportRecordDisabled"
          >导出手机号</el-button
        >
        <el-switch
          v-model="interfaceType"
          active-text="搜索引擎查询"
          inactive-text="数据库查询"
        >
        </el-switch>
      </div>
    </div>
    <!-- 通话记录表 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingTable"
    >
      <el-table-column
        prop="batchNumber"
        label="批次编号"
        width="90"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="customerCode"
        label="客户编号"
        :width="roleMenu == 'SUPER_MANAGER' ? 140 : 130"
        align="left"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.customerCode }}</span>
          <el-button
            type="text"
            size="small"
            @click="seeCustomerCode(scope.row)"
            v-if="roleMenu == 'SUPER_MANAGER'"
            style="float: right; margin-top: 5px"
            >查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="clientNumber"
        label="坐席编号"
        width="130"
        align="left"
      ></el-table-column>
      <!-- <el-table-column prop="ringSeconds" label="响铃时长" width align="center"></el-table-column> -->
      <el-table-column
        prop="callSeconds"
        label="通话时长"
        width
        align="center"
      ></el-table-column>
      <el-table-column
        prop="distributeSeconds"
        label="转接时长"
        width
        align="center"
      ></el-table-column>
      <el-table-column
        prop="connected"
        label="是否接通"
        width
        align="center"
      ></el-table-column>
      <el-table-column
        prop="distributed"
        label="是否转接"
        width
        align="center"
      ></el-table-column>
      <el-table-column
        prop="fee"
        label="费用"
        width="110"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="callStartAt"
        label="开始时间"
        width="190"
        align="center"
      ></el-table-column>
      <el-table-column prop label="通话状态" width align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.callStatus }}</span>
          <i
            v-show="scope.row.callStatus == '通话中'"
            class="el-icon-loading"
          ></i>
        </template>
      </el-table-column>
      <el-table-column
        prop="routeName"
        label="线路名称"
        width="130"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="客户分类" width="130" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="historicalChanges(scope.row)"
            >{{ scope.row.flagLable }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop
        label="操作"
        :width="roleMenu === 'SUPER_MANAGER' ? '270' : '150'"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="playRecord(scope.row)"
            :disabled="scope.row.playVoiceDisabled"
            >播放录音</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="downloadVoice(scope.row)"
            :disabled="scope.row.playVoiceDisabled"
            >下载录音</el-button
          >
          <el-button
            type="text"
            size="small"
            v-if="roleMenu === 'SUPER_MANAGER'"
            @click="dialogueTranslate(scope.row)"
            :disabled="scope.row.playVoiceDisabled"
            >对话翻译</el-button
          >
          <el-button
            slot="reference"
            type="text"
            size="small"
            v-if="roleMenu === 'SUPER_MANAGER'"
            @click="classificationClick(scope.row)"
            :disabled="scope.row.flag ? false : true"
            >分类调整</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 分类调整 -->
    <el-dialog
      title="分类调整"
      :visible.sync="classificationShow"
      width="500px"
      @close="classificationClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">分类调整</span>
      </template>
      <div class="popover-btn">
        <el-radio-group v-model="classificationFlag">
          <el-radio v-for="item in flagsFilter" :key="item.id" :label="item.id">
            <img :src="item.src" width="18px" />{{ item.label }}
          </el-radio>
        </el-radio-group>
      </div>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="modifyFlag">确定</el-button>
        <el-button @click="classificationShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 播放录音 -->
    <el-dialog
      class="customDialog"
      :visible.sync="playRecordShow"
      width="300px"
      @close="closeDialog"
    >
      <audio
        ref="audio"
        :src="currentSrc"
        autoplay="autoplay"
        controlsList="nodownload"
        controls="controls"
      ></audio>
    </el-dialog>
    <!-- 查看客户编号 -->
    <el-dialog
      title="查看客户编号"
      :visible.sync="seeCustomerCodeShow"
      width="400px"
      @close="seeCustomerCodeClose"
      class="seeCustomerCode-class"
    >
      <template slot="title">
        <i
          class="el-icon-data-analysis dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">查看客户编号</span>
      </template>
      <el-form label-position="right">
        <el-form-item label="客户号码：" label-width="110px">
          <el-input
            placeholder="请输入内容"
            v-model="customerCodeNumber"
            id="input"
            style="width: 100%"
          >
            <template slot="append">
              <i class="el-icon-document icon"></i>
            </template>
          </el-input>
          <el-tooltip placement="bottom">
            <div slot="content">复制</div>
            <a
              href="javascript:void(0)"
              v-clipboard:copy="customerCodeNumber"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              class="copy"
            ></a>
          </el-tooltip>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 查看历史变更 -->
    <el-dialog
      width="1360px"
      title="历史变更"
      :visible.sync="changeVisible"
      class="historical"
      @close="closeHistoricalDialog"
    >
      <template slot="title">
        <i
          class="el-icon-data-analysis dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">历史变更</span>
      </template>
      <div class="changeClass tableChange">
        <el-table
          :data="tableDataHistor"
          stripe
          style="width: 100%"
          border
          size="mini"
          highlight-current-row
          @current-change="handleCurrentChange"
          ref="singleTable"
          v-loading="historLoading"
        >
          <el-table-column
            type="index"
            prop
            label="编号"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="clientNumber"
            label="坐席"
            min-width="100"
            align="left"
          ></el-table-column>
          <el-table-column
            prop="gmtCreated"
            label="时间"
            min-width="150"
            align="left"
          ></el-table-column>
        </el-table>
      </div>
      <div class="changeClass formChange" v-show="historicalShow">
        <el-form label-position="right" class="projectQuestion-form">
          <el-form-item label="客户分类：" label-width="150px" class="flagImg">
            <el-radio-group v-model="disabledFlagList">
              <el-radio
                v-for="item in flagsFilter"
                :key="item.id"
                :label="item.id"
                disabled
              >
                <img :src="item.src" width="18px" />{{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-for="item in sipQuestionList"
            :key="item.id"
            :label="item.question"
            label-width="150px"
          >
            <el-radio-group
              v-if="item.questionType == 'RADIO'"
              v-model="options[item.id]"
            >
              <el-radio
                v-for="muen in item.optionList"
                :key="muen.id"
                :label="muen.id"
                disabled
                >{{ muen.option }}</el-radio
              >
            </el-radio-group>

            <el-input
              v-if="item.questionType == 'INPUT'"
              v-model="options[item.id]"
              :placeholder="'请输入' + item.question"
              style="width: 30%"
              disabled
            ></el-input>

            <el-input
              v-if="item.questionType == 'DISTRICT_SELECTOR'"
              v-model="options[item.id]"
              :placeholder="'请输入' + item.question"
              class="question_input"
              disabled
            ></el-input>

            <el-input
              v-if="item.questionType == 'CAR_BRAND_SELECTOR'"
              v-model="options[item.id]"
              :placeholder="'请输入' + item.question"
              class="question_input"
              disabled
            ></el-input>

            <el-input
              v-if="item.questionType == 'TEXT'"
              v-model="options[item.id]"
              :rows="8"
              type="textarea"
              resize="none"
              ref="remark"
              :placeholder="'请输入' + item.question"
              disabled
            ></el-input>

            <el-checkbox-group
              v-if="item.questionType == 'CHECKBOX'"
              v-model="optionsCheckList"
            >
              <el-checkbox
                v-for="muen in item.optionList"
                :key="muen.id"
                :label="muen.id"
                disabled
                >{{ muen.option }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button @click="historicalColse">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 录音格式 -->
    <el-dialog
      title="录音格式"
      :visible.sync="recordShow"
      width="500px"
      @close="closeRecord"
    >
      <template slot="title">
        <i class="el-icon-download dialog-title-my" style="color: #1296db"></i>
        <span class="dialog-title-my my-title">录音格式</span>
      </template>
      <el-form :inline="true" label-width="100px">
        <el-form-item label="录音格式:">
          <el-select
            v-model="voiceFileNameFormat"
            placeholder="请选择录音格式"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="item in voiceFileNameFormatList"
              :key="item.id"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="voiceFileNameChange"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 下载提示 -->
    <el-dialog
      :visible.sync="downloadTips"
      width="300px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeDownload"
      class="download-tips"
    >
      <div class="download-div">
        <i class="el-icon-loading loadingClass"></i>
        <span class="download-text">文件正在下载中...</span>
      </div>
    </el-dialog>
    <!-- 对话翻译 -->
    <el-dialog
      :title="dialogueTranslateTitle"
      :visible.sync="dialogueTranslateShow"
      width="700px"
      @close="dialogueTranslateClose"
      class="dialogue_dialog_style"
    >
      <template slot="title">
        <i
          class="el-icon-data-analysis dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">{{
          dialogueTranslateTitle
        }}</span>
      </template>
      <!-- 对话框 -->
      <div class="dialogue_style">
        <ul class="dialogue_style_ul">
          <li
            v-for="(item, index) in dialogueList"
            :key="index"
            class="dialogue_style_li"
          >
            <div
              v-if="item.ChannelId === item.customerChannelId"
              class="dialogue_style_customer"
            >
              <div class="dialogue_style_customer_avatar">
                <el-avatar size="small" icon="el-icon-s-custom"></el-avatar>
              </div>
              <div class="dialogue_style_customer_content">
                {{ item.Text }}
              </div>
            </div>
            <div v-else class="dialogue_style_service">
              <div class="dialogue_style_service_content">
                {{ item.Text }}
              </div>
              <div class="dialogue_style_service_avatar">
                <el-avatar size="small" icon="el-icon-service"></el-avatar>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 标签 -->
      <!-- <div class="dialogue_labels">
        <div class="dialogue_labels_tags">
          <el-tag
            v-for="tag in dialogueTagList"
            :key="tag"
            closable
            :disable-transitions="false"
            @close="handleTagClose(tag)"
            class="tag_style"
          >
            {{tag}}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="newTagName"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          >
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">新建标签</el-button>
        </div>
      </div> -->
    </el-dialog>
    <!-- 对话翻译 -->
    <dialogue-translation
      ref="dialogueTranslation"
      :title="dialogueTranslateTitle"
      type="translation"
      :dialogueRound="dialogueRound"
      :currentSrc="currentSrc"
      :dialogueData="dialogueList"
    >
    </dialogue-translation>
  </div>
</template>

<script>
import dialogueTranslation from "../pages/dialogueTranslation";
export default {
  name: "call",
  components: {
    dialogueTranslation,
  },
  data() {
    return {
      dialogueRound: false,
      dialogueTranslateTitle: "",
      interfaceType: false,
      callUuid: "",
      dialogueTranslateShow: false,
      dialogueList: [],
      dialogueTagList: [],
      newTagName: "",
      inputVisible: false,
      flagModifyId: "",
      flagModifyLoginName: "",
      classificationFlag: "",
      classificationShow: false,
      recordShow: false,
      downloadTips: false,
      downloadTimer: null,
      downloadId: null,
      exportRecordDisabled: true,
      loadingTable: false,
      customerCodeNumber: "",
      seeCustomerCodeShow: false,
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
      },
      flagsNumber: "",
      flagsFilter: [
        {
          src: require("@/assets/img/flag_1.png"),
          label: "成单",
          id: "1",
        },
        {
          src: require("@/assets/img/flag_2.png"),
          label: "回拨",
          id: "2",
        },
        {
          src: require("@/assets/img/flag_3.png"),
          label: "非目标客群",
          id: "3",
        },
        {
          src: require("@/assets/img/flag_4.png"),
          label: "无意向",
          id: "4",
        },
        {
          src: require("@/assets/img/flag_5.png"),
          label: "异议处理",
          id: "5",
        },
      ],
      loading: false,
      loadingTask: false,
      loadingName: false,
      callId: "",
      tableData: null,
      currentPage: null,
      total: null,
      callStatus_show: false,
      cancelShow: false,
      playRecordShow: false,
      currentSrc: null, //录音url
      playFlag: true, //播放状态
      projectNameList: [],
      taskNameList: [],
      userNameList: [],
      condition: {
        taskId: "",
        projectId: "",
        customerCode: "",
        clientNumber: "",
        callSecondsLongerThan: "",
        callSecondsShorterThan: "",
        callEndAtStart: "",
        callEndAtEnd: "",
        userName: "",
        callStatus: [],
        batchNumber: "",
        checked: false,
      },
      callStatusList: [
        {
          callStatus: "CONNECTED",
          label: "接通",
        },
        {
          callStatus: "NO_ANSWER",
          label: "无人接听",
        },
        {
          callStatus: "USER_BUSY",
          label: "占线",
        },
        {
          callStatus: "EXCEPTION",
          label: "无法接通",
        },
      ],
      pageSize: 10,
      changeVisible: false,
      historicalShow: false,
      tableDataHistor: [],
      historLoading: false,
      optionsCheckList: [],
      sipQuestionList: [],
      options: {},
      disabledFlagList: "",
      voiceFileNameFormat: "format1",
      voiceFileNameFormatList: [
        {
          id: 1,
          value: "format1",
          label: "日期_坐席编号_客户编号",
        },
        {
          id: 2,
          value: "format3",
          label: "日期^客户编号",
        },
        {
          id: 3,
          value: "format2",
          label: "日期_坐席编号_手机号码",
        },
        {
          id: 4,
          value: "format4",
          label: "日期_手机号",
        },
        {
          id: 5,
          value: "format6",
          label: "手机号",
        },
      ],
      unifiedLoading: null,
      urlServer: "",
    };
  },
  props: ["roleMenu", "server"],
  mounted() {
    // this.inquireProject({"pageNo":1,"pageSize":20});
    // this.inquireTask({"pageNo":1,"pageSize":20});
    document.title = "惠销平台管理系统-通话记录";
    this.urlServer = "https://" + this.server + this.$constants.basePath;
    this.initTime();
    this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true }, "start");
  },
  methods: {
    // 初始化时间
    initTime() {
      let strDate = new Date();
      strDate.setHours(0);
      strDate.setMinutes(0);
      strDate.setSeconds(0);
      strDate.setMilliseconds(0);
      strDate = this.$common.transformTime(strDate.valueOf());
      this.condition.callEndAtStart = strDate;
    },
    // 点击对话翻译
    dialogueTranslate(val) {
      this.callUuid = val.callerUuid;
      let data = {
        userLoginName: val.userLoginName,
        uuid: val.callerUuid,
      };
      // let path = "https://backend.huixiao9.huiyu.org.cn";
      this.unifiedLoading = this.$loading();
      this.$http("/sip/call/translationInfo", null, "get", this.urlServer, data)
        .then((res) => {
          this.unifiedLoading.close();
          if (res.result === 200) {
            this.currentSrc = `${this.urlServer}/sip/playVoice?callId=${val.id}&loginName=${val.userLoginName}`;
            this.dialogueList = [];
            this.dialogueTranslateTitle = "对话翻译-" + val.customerCode;
            if (val.clientNumber) {
              this.dialogueTranslateTitle += "-" + val.clientNumber;
            }
            this.dialogueRound = res.data.dialogueRound;
            if (res.data.dialogueRound) {
              // if (!res.data || res.data.status !== "SUCCESS") {
              //   this.$message.warning("对话未翻译");
              //   return
              // }
              // this.dialogueTranslateShow = true;
              // this.$refs.dialogueTranslation.speechTestClick();
              // let list = JSON.parse(res.data.list.content);
              let list = res.data.list;
              // list.forEach((item) => {
              //   item.customerChannelId = res.data.list.customerChannelId;
              // });
              this.dialogueList = list;
              // if (res.data.list.labels) {
              //   this.dialogueTagList = res.data.labels.split(",");
              // }
            } else {
              console.log("2222,false");
              let list = JSON.parse(res.data.list.content);
              console.log(list);
              list.forEach((item) => {
                item.customerChannelId = res.data.list.customerChannelId;
              });
              this.dialogueList = list;
              // this.dialogueList = res.data.list;
            }
            console.log(this.dialogueRound);

            this.$refs.dialogueTranslation.speechTestClick();
          }
        })
        .catch((err) => {
          this.unifiedLoading.close();
        });
    },
    // 关闭对话翻译
    dialogueTranslateClose() {
      this.dialogueList = [];
      this.dialogueTagList = [];
    },
    // 删除标签
    handleTagClose(tag) {
      let tagList = [...this.dialogueTagList];
      tagList.splice(tagList.indexOf(tag), 1);
      let data = {
        uuid: this.callUuid,
        labels: tagList.join(","),
      };
      this.updateTranslationLabel(data);
    },
    // 点击新建标签
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    // 回车or失去焦点确认新建标签
    handleInputConfirm() {
      let newTagName = this.newTagName.trim();
      if (newTagName) {
        let tagList = [...this.dialogueTagList];
        tagList.push(newTagName);
        let data = {
          uuid: this.callUuid,
          labels: tagList.join(","),
        };
        this.updateTranslationLabel(data);
      }
      this.inputVisible = false;
    },
    // 更新标签
    updateTranslationLabel(data) {
      let path = "https://huixiao9.backend.huiyu.org.cn";
      this.$service(
        "/sip/call/updateTranslationLabel",
        data,
        "post",
        path
      ).then((res) => {
        if (res.result === 200) {
          this.dialogueTagList = data.labels.split(",");
          this.newTagName = "";
          this.$message.success("更新成功");
        }
      });
    },
    // 点击分类调整
    classificationClick(val) {
      this.classificationShow = true;
      this.classificationFlag = val.flagLabel;
      this.flagModifyId = val.id;
      this.flagModifyLoginName = val.userLoginName;
      this.callUuid = val.callerUuid;
    },
    // 确定分类调整
    modifyFlag() {
      let data = {
        callId: this.flagModifyId,
        loginName: this.flagModifyLoginName,
        flag: Number(this.classificationFlag),
        callerUuid: this.callUuid,
      };
      this.$service(
        "/sip/question/updateFlagInfo",
        data,
        "post",
        this.urlServer
      ).then((res) => {
        if (res.result === 200) {
          this.classificationShow = false;
          this.$message({ message: "修改成功", type: "success" });
          let params = {
            pageSize: this.pageSize,
            pageNo: this.currentPage,
            customerCode: this.condition.customerCode,
            clientNumber: this.condition.clientNumber,
            callSecondsLongerThan: this.condition.callSecondsLongerThan,
            callSecondsShorterThan: this.condition.callSecondsShorterThan,
            callStartAtStart: this.condition.callEndAtStart,
            callStartAtEnd: this.condition.callEndAtEnd,
            callStatus: this.condition.callStatus,
            projectId: this.condition.projectId,
            taskId: this.condition.taskId,
            loginName: this.condition.userName,
            finished: true,
            batchNumber: this.condition.batchNumber,
            flag: this.flagsNumber,
          };
          this.inquireCallList(params);
        }
      });
    },
    // 关闭分类调整
    classificationClose() {
      this.classificationFlag = "";
      this.classificationFlag = "";
      this.flagModifyId = "";
    },
    //点击--导出录音
    exportRecord() {
      this.recordShow = true;
    },
    //关闭录音格式
    closeRecord() {
      this.voiceFileNameFormat = "format1";
    },
    //选择录音格式
    voiceFileNameChange() {
      this.recordShow = false;
      this.exportRecording(); // 导出录音
    },
    //刷新下载状态
    downloadStatus() {
      if (this.downloadTimer) {
        clearInterval(this.downloadTimer);
        this.downloadTimer = null;
      }
      this.downloadTimer = setInterval(() => {
        this.$http(
          "/sip/downloadFile/checkDownloadStatus",
          null,
          "get",
          this.urlServer,
          { id: this.downloadId }
        ).then((res) => {
          if (res.result == 200) {
            let status = res.data;
            if (status === "DOWNLOADABLE") {
              this.$http(
                "/sip/downloadFile/getRemoteDownloadUrl",
                null,
                "get",
                this.urlServer,
                { id: this.downloadId }
              ).then((res) => {
                clearInterval(this.downloadTimer);
                this.downloadTimer = null;
                this.downloadTips = false;
                if (res.result == 200) {
                  let href = res.data;
                  window.location.href = href;
                }
              });
            } else if (status === "EXCEPTION") {
              clearInterval(this.downloadTimer);
              this.downloadTimer = null;
              this.downloadTips = false;
              this.$message.error("下载失败");
            }
          } else {
            this.downloadTips = false;
            clearInterval(this.downloadTimer);
            this.downloadTimer = null;
          }
        });
      }, 2000);
    },
    //关闭下载提示
    closeDownload() {
      if (this.downloadTimer) {
        clearInterval(this.downloadTimer);
        this.downloadTimer = null;
      }
    },
    // 点击导出录音
    exportPhone() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
        customerCode: this.condition.customerCode,
        clientNumber: this.condition.clientNumber,
        callSecondsLongerThan: this.condition.callSecondsLongerThan,
        callSecondsShorterThan: this.condition.callSecondsShorterThan,
        callStartAtStart: this.condition.callEndAtStart,
        callStartAtEnd: this.condition.callEndAtEnd,
        callStatus: this.condition.callStatus,
        projectId: this.condition.projectId,
        taskId: this.condition.taskId,
        loginName: this.condition.userName,
        finished: true,
        batchNumber: this.condition.batchNumber,
        flag: this.flagsNumber,
        downloadVoice: true,
        voiceFileNameFormat: this.voiceFileNameFormat,
      };
      this.exportRecordDisabled = true;
      this.$http("/sip/call/phone/export", params, "post", this.urlServer).then(
        (res) => {
          this.exportRecordDisabled = false;
          if (res.result === 200) {
            this.downloadTips = true;
            this.downloadId = res.data.id;
            this.downloadStatus();
          }
        }
      );
    },
    // 导出录音
    exportRecording() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
        customerCode: this.condition.customerCode,
        clientNumber: this.condition.clientNumber,
        callSecondsLongerThan: this.condition.callSecondsLongerThan,
        callSecondsShorterThan: this.condition.callSecondsShorterThan,
        callStartAtStart: this.condition.callEndAtStart,
        callStartAtEnd: this.condition.callEndAtEnd,
        callStatus: this.condition.callStatus,
        projectId: this.condition.projectId,
        taskId: this.condition.taskId,
        loginName: this.condition.userName,
        finished: true,
        batchNumber: this.condition.batchNumber,
        flag: this.flagsNumber,
        downloadVoice: true,
        voiceFileNameFormat: this.voiceFileNameFormat,
      };
      this.$http("/sip/call/list", params, "post", this.urlServer).then(
        (res) => {
          this.exportRecordDisabled = false;
          if (res.result === 200) {
            this.downloadTips = true;
            this.downloadId = res.data.id;
            this.downloadStatus();
          }
        }
      );
    },
    // 查看成单记录
    historicalChanges(val) {
      this.changeVisible = true;
      let params = {
        callId: val.id,
        pageNo: 1,
        pageSize: 6,
        clientNumber: val.clientNumber,
        loginName: val.userLoginName,
        callerUuid: val.callerUuid,
      };
      this.historLoading = true;
      this.$http(
        "/sip/question/answerHistory",
        null,
        "get",
        this.urlServer,
        params
      ).then((res) => {
        this.historLoading = false;
        if (res.result == 200) {
          let list = res.data;
          list.map((item) => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            if (item.sipQuestionList && item.sipQuestionList.length > 0) {
              item.sipQuestionList.map((menu) => {
                menu.question = menu.question + "：";
              });
            }
          });
          this.tableDataHistor = list;
          this.setCurrent(this.tableDataHistor[0]);
        }
      });
    },
    historicalColse() {
      //点击关闭查看历史变更
      this.changeVisible = false;
    },
    setCurrent(row) {
      //选中的行
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      //选择历史
      let disabledFlagList = [];
      if (val) {
        this.options = {};
        this.historicalShow = true;
        this.disabledFlagList = "";
        this.optionsCheckList = [];
        let flags = JSON.parse(val.flags);
        if (flags) {
          for (let key in flags) {
            disabledFlagList.push(key);
          }
        }
        disabledFlagList = disabledFlagList.join(",");
        this.disabledFlagList = disabledFlagList.substring(0, 1);
        let sipQuestionList = val.sipQuestionList;
        if (sipQuestionList && sipQuestionList.length > 0) {
          sipQuestionList.map((item) => {
            if (item.optionList) {
              item.optionList.map((menu) => {
                menu.id = menu.id.toString();
              });
            }
          });
        }
        let answer = JSON.parse(val.answer);
        let optionsCheck = "";
        this.sipQuestionList = sipQuestionList;
        for (let i in answer) {
          optionsCheck += answer[i] + ",";
          for (let j in sipQuestionList) {
            if (i == sipQuestionList[j].id) {
              this.options[i] = answer[i];
            }
          }
        }
        this.optionsCheckList = optionsCheck.split(",");
      }
    },
    closeHistoricalDialog() {
      //关闭历史弹窗
      this.historicalShow = false;
      this.historLoading = false;
      this.tableDataHistor = [];
    },
    // 复制成功
    onCopy(e) {
      this.$message({ message: "复制成功", type: "success" });
    },
    // 复制失败
    onError(e) {
      this.$message({ message: "复制失败", type: "error" });
    },
    seeCustomerCode(val) {
      //查看客户编号
      this.seeCustomerCodeShow = true;
      let params = {
        callId: val.id,
        customerCode: val.customerCode,
        loginName: val.userLoginName,
      };
      this.$http(
        "/sip/call/queryMobile",
        null,
        "get",
        this.urlServer,
        params
      ).then((res) => {
        if (res.result == 200) {
          this.customerCodeNumber = res.data;
        }
      });
    },
    seeCustomerCodeClose() {
      //关闭查看客户编号
      this.customerCodeNumber = "";
    },
    handleInput() {
      //限制input输入
      let callSecondsLongerThan = this.condition.callSecondsLongerThan;
      callSecondsLongerThan = callSecondsLongerThan.replace(/[^\d]/g, ""); // 清除“数字”以外的字符
      if (
        callSecondsLongerThan.indexOf(".") < 0 &&
        callSecondsLongerThan != ""
      ) {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        callSecondsLongerThan = parseInt(callSecondsLongerThan);
      }
      this.condition.callSecondsLongerThan = callSecondsLongerThan;
    },
    handleInputThan() {
      //限制input输入
      let callSecondsShorterThan = this.condition.callSecondsShorterThan;
      callSecondsShorterThan = callSecondsShorterThan.replace(/[^\d]/g, ""); // 清除“数字”以外的字符
      if (
        callSecondsShorterThan.indexOf(".") < 0 &&
        callSecondsShorterThan != ""
      ) {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        callSecondsShorterThan = parseInt(callSecondsShorterThan);
      }
      this.condition.callSecondsShorterThan = callSecondsShorterThan;
    },
    // 用户名称获取焦点
    NameRemoteFocus() {
      this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true });
    },
    NameRemoteMethod(userName) {
      //搜索用户名称
      this.loadingName = true;
      setTimeout(() => {
        this.loadingName = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          userName: userName,
          enabled: true,
        };
        this.inquireLoginName(params);
      }, 200);
    },
    remoteMethod(projectName) {
      //搜索项目
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          loginName: this.condition.userName,
          projectName: projectName,
        };
        this.inquireProject(params);
      }, 200);
    },
    // 任务搜索获取焦点
    taskRemoteFocus() {
      let params = {
        pageSize: 1000,
        pageNo: 1,
        loginName: this.condition.userName,
        projectId: this.condition.projectId,
      };
      this.inquireTask(params);
    },
    taskRemoteMethod(taskName) {
      //搜索任务
      this.loadingTask = true;
      setTimeout(() => {
        this.loadingTask = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          loginName: this.condition.userName,
          taskName: taskName,
          projectId: this.condition.projectId,
        };
        this.inquireTask(params);
      }, 200);
    },
    // 项目搜索获取焦点
    projectIdRemoteFocus() {
      let params = {
        pageSize: 20,
        pageNo: 1,
        loginName: this.condition.userName,
      };
      this.inquireProject(params);
    },
    projectIdChange(projectId) {
      //选择项目
      this.condition.taskId = "";
    },
    currentPage_data(index) {
      //当前页
      this.currentPage = index;
      let params = {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
        customerCode: this.condition.customerCode,
        clientNumber: this.condition.clientNumber,
        callSecondsLongerThan: this.condition.callSecondsLongerThan,
        callSecondsShorterThan: this.condition.callSecondsShorterThan,
        callStartAtStart: this.condition.callEndAtStart,
        callStartAtEnd: this.condition.callEndAtEnd,
        callStatus: this.condition.callStatus,
        projectId: this.condition.projectId,
        taskId: this.condition.taskId,
        loginName: this.condition.userName,
        finished: true,
        batchNumber: this.condition.batchNumber,
        flag: this.flagsNumber,
      };
      this.inquireCallList(params);
    },
    sizeChange(index) {
      //显示条数--分页
      this.currentPage = 1;
      this.pageSize = index;
      let params = {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
        customerCode: this.condition.customerCode,
        clientNumber: this.condition.clientNumber,
        callSecondsLongerThan: this.condition.callSecondsLongerThan,
        callSecondsShorterThan: this.condition.callSecondsShorterThan,
        callStartAtStart: this.condition.callEndAtStart,
        callStartAtEnd: this.condition.callEndAtEnd,
        callStatus: this.condition.callStatus,
        projectId: this.condition.projectId,
        taskId: this.condition.taskId,
        loginName: this.condition.userName,
        finished: true,
        batchNumber: this.condition.batchNumber,
        flag: this.flagsNumber,
      };
      this.inquireCallList(params);
    },
    cancel(val) {
      //点击强拆
      if (val) {
        this.callId = val.id;
      }
      this.$confirm("是否继续强拆?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            callId: this.callId,
          };
          this.$http("/sip/forceClearing", data).then((res) => {
            if (res.result == 200) {
              this.$message({ message: "强拆成功", type: "success" });
              let currentPage = this.currentPage;
              this.inquire(currentPage);
            }
          });
        })
        .catch(() => {});
    },
    listen(val) {
      //监听
      console.log(val);
    },
    playRecord(val) {
      //播放录音
      this.playRecordShow = true;
      if (val) {
        this.callId = val.id;
      }
      let callId = this.callId;
      this.currentSrc = `${this.urlServer}/sip/playVoice?callId=${callId}&loginName=${val.userLoginName}`;
      let lastRunTime = Date.now(),
        currentTime = Date.now(),
        protectTime = 100;
      if (currentTime - lastRunTime < protectTime) {
        return; //两次执行太过频繁，直接退出
      }
      if (this.playFlag == false) {
        this.$refs.audio.play();
        this.playFlag = true;
      }
    },
    downloadVoice(val) {
      //下载录音
      let href =
        this.urlServer +
        "/sip/downloadVoice?callId=" +
        val.id +
        "&loginName=" +
        val.userLoginName +
        "&voiceFileNameFormat=format1";
      window.location.href = href;
    },
    closeDialog() {
      //播放录音关闭
      this.$refs.audio.pause();
      this.playFlag = false;
    },
    inquire() {
      //点击查询
      this.currentPage = 1;
      let params = {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
        customerCode: this.condition.customerCode,
        clientNumber: this.condition.clientNumber,
        callSecondsLongerThan: this.condition.callSecondsLongerThan,
        callSecondsShorterThan: this.condition.callSecondsShorterThan,
        callStartAtStart: this.condition.callEndAtStart,
        callStartAtEnd: this.condition.callEndAtEnd,
        callStatus: this.condition.callStatus,
        projectId: this.condition.projectId,
        taskId: this.condition.taskId,
        loginName: this.condition.userName,
        finished: true,
        batchNumber: this.condition.batchNumber,
        flag: this.flagsNumber,
      };
      this.inquireCallList(params);
    },
    inquireCallList(params) {
      //查询通话记录列表
      this.loadingTable = true;
      let url = "";
      if (!this.interfaceType) {
        url = "/sip/call/list";
      } else {
        url = "/sip/call/listFromEs";
      }
      this.$http(url, params, "post", this.urlServer).then((res) => {
        setTimeout(() => {
          this.loadingTable = false;
        }, 200);
        if (res.result == 200) {
          let list = res.data.list;
          this.total = res.data.count;
          list.map((item) => {
            //数据转换
            item.callEndAt = this.$common.transformTime(item.callEndAt);
            item.callStartAt = this.$common.transformTime(item.callStartAt);
            item.callAnsweredAt = this.$common.transformTime(
              item.callAnsweredAt
            );
            item.callReceivedAt = this.$common.transformTime(
              item.callReceivedAt
            );
            if (item.callSeconds == -1) {
              item.playVoiceDisabled = true;
              item.callSeconds = "-";
            } else {
              item.playVoiceDisabled = false;
              item.callSeconds = `${item.callSeconds} 秒`;
            }
            if (item.ringSeconds == -1) {
              item.ringSeconds = "-";
            } else {
              item.ringSeconds = `${item.ringSeconds} 秒`;
            }
            if (item.distributeSeconds == -1) {
              item.distributeSeconds = "-";
            } else {
              item.distributeSeconds = `${item.distributeSeconds} 秒`;
            }
            item.forceClearingDisabled = true;
            item.monitorDisabled = true;
            switch (item.callStatus) {
              case "CONNECTED":
                item.callStatus = "接通";
                break;
              case "NO_ANSWER":
                item.callStatus = "无人接听";
                break;
              case "USER_BUSY":
                item.callStatus = "占线";
                break;
              case "EXCEPTION":
                item.callStatus = "无法接通";
                break;
              // case "RINGING":
              //   item.callStatus = "响铃中";
              //   break;
              // case "RECOVERY_ON_TIMER_EXPIRE":
              //   item.callStatus = "拨打超限";
              //   break;
              // case "UNALLOCATED_NUMBER":
              //   item.callStatus = "未分配号码";
              //   break;
              // case "USER_BUSY":
              //   item.callStatus = "用户拒接";
              //   break;
              // case "NO_ANSWER":
              //   item.callStatus = "无人接听";
              //   break;
              // case "ORIGINATOR_CANCEL":
              //   item.callStatus = "拨打取消";
              //   break;
              // case "TALKING":
              //   item.callStatus = "通话中";
              //   item.forceClearingDisabled = false
              //   item.monitorDisabled = false
              //   break;
              // case "NORMAL_CLEARING":
              //   item.callStatus = "拨打完成";
              //   item.playVoiceDisabled = false;
              //   break;
              // case "SYSTEM_SHUTDOWN":
              //   item.callStatus = "系统挂断";
              //   break;
              // case "NO_USER_RESPONSE":
              //   item.callStatus = "线路未响应";
              //   break;
              // case "NO_ROUTE_DESTINATION":
              //   item.callStatus = "线路未响应";
              //   break;
              // case "SUBSCRIBER_ABSENT":
              //   item.callStatus = "线路未响应";
              //   break;
              // case "MEDIA_TIMEOUT":
              //   item.callStatus = "超时";
              //   break;
              // case "USER_NOT_REGISTERED":
              //   item.callStatus = "坐席未注册";
              //   break;
              // case "WAITING_OUTCALL":
              //   item.callStatus = "等待拨打";
              //   break;
              // case "CALLING":
              //   item.callStatus = "拨打中";
              //   break;
              // case "TIMEOUT_CLEARING":
              //   item.callStatus = "转接超时";
              //   break;
              // case "PARK":
              //   item.callStatus = "等待转接";
              //   break;
              // case "NORMAL_TEMPORARY_FAILURE":
              //   item.callStatus = "异常";
              //   break;
              // case "BLACKLIST":
              //   item.callStatus = "黑名单";
              //   break;
              // case "EXCEPTION":
              //   item.callStatus = "异常";
              //   break;
              // case "OUT_OF_FEE":
              //   item.callStatus = "欠费";
              //   break;
            }
            switch (item.distributed) {
              case true:
                item.distributed = "是";
                break;
              case false:
                item.distributed = "否";
                break;
            }
            switch (item.connected) {
              case true:
                item.connected = "是";
                break;
              case false:
                item.connected = "否";
                break;
            }
            switch (item.flag) {
              case 1:
                item.flagLable = "成单";
                break;
              case 2:
                item.flagLable = "回拨";
                break;
              case 3:
                item.flagLable = "非目标客群";
                break;
              case 4:
                item.flagLable = "无意向";
                break;
            }
            item.flagLabel = item.flag.toString();
          });
          this.tableData = list;
          if (this.tableData && this.tableData.length > 0) {
            this.exportRecordDisabled = false;
          } else {
            this.exportRecordDisabled = true;
          }
        }
      });
    },
    reset() {
      //重置
      this.condition = {
        taskId: "",
        projectId: "",
        customerCode: "",
        clientNumber: "",
        callSecondsLongerThan: "",
        callSecondsShorterThan: "",
        callEndAtStart: "",
        callEndAtEnd: "",
        callStatus: "",
        userName: "",
        batchNumber: "",
      };
      this.flagsNumber = "";
      this.initTime();
      this.inquireLoginName(
        { pageNo: 1, pageSize: 20, enabled: true },
        "start"
      );
    },
    inquireLoginName(params, key) {
      //查询用户名称
      this.userNameList = [];
      this.$http("/user/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newuserNameList = res.data.list;
          newuserNameList.map((item) => {
            item.label = item.userName;
            this.userNameList.push(item);
          });
          if (key === "start" && this.roleMenu === "SUPER_MANAGER") {
            if (!this.interfaceType) {
              this.condition.userName = this.userNameList[0].loginName;
              this.urlServer =
                "https://" +
                this.userNameList[0].server +
                this.$constants.basePath;
            }
            this.inquire();
          }
        }
      });
    },
    loginNameChange(userName) {
      //改变用户名称
      this.condition.projectId = "";
      this.condition.taskId = "";
      this.userNameList.forEach((item) => {
        if (userName === item.loginName) {
          this.urlServer = "https://" + item.server + this.$constants.basePath;
        }
      });
    },
    inquireProject(params) {
      //查询项目名称
      this.projectNameList = [];
      this.$http("/sip/project/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newProjectNameList = res.data.list;
          newProjectNameList.map((item) => {
            this.projectNameList.push(item);
          });
        }
      });
    },
    inquireTask(params) {
      //查询任务名称
      this.taskNameList = [];
      this.$http("/sip/task/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newTaskNameList = res.data.list;
          newTaskNameList.map((item) => {
            this.taskNameList.push(item);
          });
        }
      });
    },
  },
};
</script>

<style scoped="scoped">
.dialogue_dialog_style >>> .el-dialog__body {
  padding: 10px 20px 30px;
}
.dialogue_style {
  width: 100%;
  height: 500px;
  overflow: auto;
  background: #f2f6fc;
}
.dialogue_style_li {
  margin: 15px 0;
  padding: 0 10px;
}
.dialogue_style_li .dialogue_style_service {
  display: flex;
  justify-content: flex-end;
}
.dialogue_style_li .dialogue_style_service .dialogue_style_service_avatar {
  margin-left: 10px;
}
.dialogue_style_li .dialogue_style_service .dialogue_style_service_content {
  max-width: 350px;
  padding: 8px 8px;
  background: #90ee62;
  border-radius: 5px;
  color: #303133;
}
.dialogue_style_li .dialogue_style_customer {
  display: flex;
  justify-content: flex-start;
}
.dialogue_style_li .dialogue_style_customer .dialogue_style_customer_avatar {
  margin-right: 10px;
}
.dialogue_style_li .dialogue_style_customer .dialogue_style_customer_content {
  max-width: 350px;
  padding: 8px 8px;
  background: #ffffff;
  border-radius: 5px;
  color: #303133;
}
.dialogue_labels {
  width: 100%;
}
.dialogue_labels .dialogue_labels_tags {
  width: 100%;
  margin: 20px 0 0 0;
}
.dialogue_labels .dialogue_labels_tags .tag_style {
  margin-right: 10px;
  margin-bottom: 10px;
}
.dialogue_labels .dialogue_labels_tags .button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.dialogue_labels .dialogue_labels_tags .input-new-tag {
  width: 90px;
}
.popover-btn {
  text-align: center;
}
.copy {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 55px;
  height: 40px;
}
.icon {
  font-size: 20px;
}
.seeCustomerCode-class >>> .el-dialog__title {
  font-size: 16px;
}
.seeCustomerCode-class >>> .el-dialog__body {
  padding: 10px 20px 5px 20px;
}
.seeCustomerCode-class >>> .el-dialog__body .el-form-item__label {
  font-size: 17px;
}
.seeCustomerCode-class >>> .el-dialog__body .el-form-item__content {
  font-size: 17px;
}
.call {
  position: relative;
  margin-bottom: 50px;
}
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.nav {
  width: 1200px;
  margin-bottom: 20px;
}
.callerNumber,
.calleeNumber,
.callSecondsTime,
.callTime,
.callStatus,
.projectStatus,
.taskStatus {
  width: 600px;
  margin-bottom: 20px;
}
.nav .el-input,
.nav .el-select {
  width: 430px;
}
.callTime,
.calleeNumber {
  float: right;
  text-align: left;
}
.callSecondsTime .call-startTime {
  width: 200px !important;
  margin-right: 6px;
}
.callSecondsTime .call-endTime {
  width: 200px !important;
  margin-left: 7px;
}
.callTime .startTime {
  width: 200px !important;
  margin-right: 6px;
}
.callTime .endTime {
  width: 200px !important;
  margin-left: 7px;
}
.customDialog >>> .el-dialog {
  border-radius: 50px;
  background: transparent;
}
.customDialog >>> .el-dialog__header {
  padding: 0px;
}
.customDialog >>> .el-dialog__body {
  padding: 0px;
  font-size: 0px;
}
.historical >>> .el-dialog__body {
  position: relative;
}
.dialog-footer {
  clear: both;
}
.changeClass {
  display: inline-block;
  width: 48%;
}
.tableChange {
  float: left;
  margin-bottom: 20px;
}
.formChange {
  float: right;
}
.el-radio-group >>> .el-radio__label {
  padding-left: 5px;
}
</style>
