<template>
  <div class="smsTemplate">
    <div class="search-m">
      <div class="search-m-1">
        名称:
        <el-input
          v-model="smsName"
          placeholder="请输入名称"
          clearable
        ></el-input>
      </div>
      <div class="search-m-1">
        子名称:
        <el-input
          v-model="smsSubName"
          placeholder="请输入子名称"
          clearable
        ></el-input>
      </div>
      <div class="search-m-1">
        <el-checkbox v-model="enabled">状态过滤</el-checkbox>
        <el-tooltip
          content="勾选会过滤掉禁用的模板"
          placement="right"
          effect="light"
        >
          <i class="el-icon-question" style="color: #909399"></i>
        </el-tooltip>
      </div>
    </div>
    <div class="search-botton">
      <el-button type="primary" @click="inquire">查 询</el-button>
      <el-button
        type="primary"
        @click="modifyClick('add')"
        v-if="roleMenu === 'SUPER_MANAGER'"
        >新增</el-button
      >
    </div>
    <el-tabs
      type="border-card"
      v-model="activeTemplateName"
      @tab-click="handleClick"
    >
      <el-tab-pane label="人工坐席" name="normal">
        <!-- 人工坐席列表 -->
        <el-table
          :data="callTemplateTableData"
          stripe
          style="width: 100%"
          border=""
          v-loading="seatsLoading"
        >
          <el-table-column
            type="index"
            prop
            label="编号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            width=""
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="subName"
            label="子名称"
            width=""
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="originateTimeout"
            label="呼叫超时(秒)"
            min-width="200"
            align="left"
          >
            <template slot-scope="scope">
              {{ scope.row.strategyTemplateClientTalking.originateTimeout }}
            </template>
          </el-table-column>
          <el-table-column
            prop="answerInterval"
            label="坐席整理时间(秒)"
            min-width="200"
            align="left"
          >
            <template slot-scope="scope">
              {{ scope.row.strategyTemplateClientTalking.answerInterval }}
            </template>
          </el-table-column>
          <el-table-column
            prop="stopCallOnFull"
            label="坐席全满暂停外呼"
            min-width="200"
            align="left"
          >
            <template slot-scope="scope">
              <span
                v-if="scope.row.strategyTemplateClientTalking.stopCallOnFull"
                >是</span
              >
              <span
                v-if="!scope.row.strategyTemplateClientTalking.stopCallOnFull"
                >否</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="pauseSecondsOnFull"
            label="坐席全满停止外呼(秒)"
            min-width="200"
            align="left"
          >
            <template slot-scope="scope">
              {{ scope.row.strategyTemplateClientTalking.pauseSecondsOnFull }}
            </template>
          </el-table-column>
          <el-table-column
            prop
            label="操作"
            width="90"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="modifyClick('modify', scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="callTemplateTablePageSizeChange"
          @current-change="callTemplateChangePage"
          :current-page.sync="callTemplateTablePageNo"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="callTemplateTableTotal"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>

      <el-tab-pane label="人工开场白" name="prologue">
        <!-- 人工开场白列表 -->
        <el-table
          :data="prologueTemplateTableData"
          stripe
          style="width: 100%"
          border=""
          v-loading="prologueLoading"
        >
          <el-table-column
            type="index"
            prop
            label="编号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            width=""
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="subName"
            label="子名称"
            width=""
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="prologueTitle"
            label="开场白名称"
            width
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="playPrologue"
            label="开场白启用"
            width="100"
            align="left"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.playPrologue">是</span>
              <span v-else>否</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="originateTimeout"
            label="呼叫超时(秒)"
            width="110"
            align="left"
          >
            <template slot-scope="scope">
              {{ scope.row.strategyTemplateClientTalking.originateTimeout }}
            </template>
          </el-table-column>
          <el-table-column
            prop="answerInterval"
            label="坐席整理时间(秒)"
            width="140"
            align="left"
          >
            <template slot-scope="scope">
              {{ scope.row.strategyTemplateClientTalking.answerInterval }}
            </template>
          </el-table-column>
          <el-table-column
            prop="stopCallOnFull"
            label="坐席全满暂停外呼"
            width="150"
            align="left"
          >
            <template slot-scope="scope">
              <span
                v-if="scope.row.strategyTemplateClientTalking.stopCallOnFull"
                >是</span
              >
              <span
                v-if="!scope.row.strategyTemplateClientTalking.stopCallOnFull"
                >否</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="pauseSecondsOnFull"
            label="坐席全满停止外呼(秒)"
            width="160"
            align="left"
          >
            <template slot-scope="scope">
              {{ scope.row.strategyTemplateClientTalking.pauseSecondsOnFull }}
            </template>
          </el-table-column>
          <el-table-column
            prop
            label="操作"
            width="90"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="modifyClick('modify', scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="prologueTemplateTablePageSizeChange"
          @current-change="prologueTemplateChangePage"
          :current-page.sync="prologueTemplateTablePageNo"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="prologueTemplateTableTotal"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>

      <el-tab-pane label="挂机短信" name="short">
        <!-- 短信模板列表 -->
        <el-table
          :data="smsTemplateTableData"
          stripe
          style="width: 100%"
          border=""
          v-loading="loading"
        >
          <el-table-column
            type="index"
            prop
            label="编号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            width=""
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="subName"
            label="子名称"
            width=""
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="prologueTitle"
            label="开场白名称"
            width="120"
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="playPrologue"
            label="开场白启用"
            width="100"
            align="left"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.playPrologue">是</span>
              <span v-else>否</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="content"
            label="内容"
            min-width="200"
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="prefix"
            label="前缀"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="smsClickCallbackType"
            label="点击回调"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.smsClickCallbackType === 'ADD_WECHAT'"
                >添加微信</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="longUrl"
            label="长链"
            min-width="200"
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop
            label="操作"
            width="90"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="modifyClick('modify', scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="smsTemplateTablePageSizeChange"
          @current-change="smsTemplateChangePage"
          :current-page.sync="smsTemplateTablePageNo"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="smsTemplateTableTotal"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>

      <el-tab-pane label="挂机微信" name="wechat">
        <!-- 微信模板列表 -->
        <el-table
          :data="wechatTemplateTableData"
          stripe
          style="width: 100%"
          border=""
          v-loading="wechatLoading"
          ref="wechatTemplateTable"
        >
          <el-table-column
            type="index"
            prop
            label="编号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            width=""
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="subName"
            label="子名称"
            width=""
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="prologueTitle"
            label="开场白名称"
            width="120"
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="playPrologue"
            label="开场白启用"
            width="100"
            align="left"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.playPrologue">是</span>
              <span v-else>否</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="triggerWechatServer"
            label="服务器"
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="triggerWechatClientGroupName"
            label="分组"
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop
            label="操作"
            width="90"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="modifyClick('modify', scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="wechatTemplateTablePageSizeChange"
          @current-change="wechatTemplateChangePage"
          :current-page.sync="wechatTemplateTablePageNo"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="wechatTemplateTableTotal"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>

      <el-tab-pane label="AI机器人" name="robot">
        <!-- 微信模板列表 -->
        <el-table
          :data="robotTemplateTableData"
          stripe
          style="width: 100%"
          border=""
          v-loading="robotLoading"
          ref="wechatTemplateTable"
        >
          <el-table-column
            type="index"
            prop
            label="编号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            width=""
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="subName"
            label="子名称"
            width=""
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="dialogueName"
            label="机器人话术"
            width=""
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop
            label="操作"
            width="90"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="modifyClick('modify', scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="robotTemplateTablePageSizeChange"
          @current-change="robotTemplateChangePage"
          :current-page.sync="robotTemplateTablePageNo"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="robotTemplateTableTotal"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>

    <!-- 编辑短信模板 -->
    <el-dialog
      :title="modalTltle"
      :visible.sync="smsInputShow"
      width="950px"
      top="8vh"
      @close="smsInputClose"
    >
      <el-form label-position="left" label-width="120px" :inline="true">
        <el-form-item label="名称：" required>
          <el-input
            v-model="smsInputName"
            placeholder="请输入名称"
            style="width: 300px"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="子名称：" required>
          <el-input
            v-model="smsInputSubName"
            placeholder="请输入子名称"
            style="width: 300px"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="触发动作：" required v-if="isTriggerShow">
          <el-radio-group v-model="triggerAction" @change="radioTriggerChange">
            <!-- <el-radio label="FLAG_CHANGE">标记成单
              <el-tooltip content="通话结束后如果通话被标记为成单则执行" placement="top-start" effect="light">
                <i class="el-icon-question" style="color: #909399"></i>
              </el-tooltip>
            </el-radio> -->
            <el-radio label="CALL_MILLIS_LIMIT">通话时长达标(秒)</el-radio>
          </el-radio-group>
          <el-input
            v-model="callDuration"
            placeholder="请输入通话达标时长"
            style="width: 200px; margin: 0 0 0 10px"
            clearable
            v-if="callDurationShow"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-tabs v-model="activeName" @tab-click="activeNameChange">
        <el-tab-pane
          label="人工坐席模板"
          name="normal"
          :disabled="activeTemplateName !== 'normal'"
        >
          <el-form
            label-width="180px"
            label-position="left"
            :inline="true"
            class="call_template_style"
          >
            <el-form-item label="呼叫超时(秒)：" required>
              <el-select
                v-model="originateTimeout"
                placeholder="请选择超时时间"
              >
                <el-option
                  v-for="item in originateTimeoutList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="坐席整理时间(秒)：" required>
              <el-input
                v-model="answerInterval"
                placeholder="请输入整理时间"
              ></el-input>
            </el-form-item>
            <el-form-item label="坐席全满暂停外呼：" required>
              <el-select
                v-model="newStopCallOnFull"
                placeholder="请选择状态"
                @change="strategyChange"
              >
                <el-option
                  v-for="item in strategyList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="坐席全满停止外呼(秒)：" required>
              <el-input
                v-model="pauseSecondsOnFull"
                :disabled="pauseSeconds_disabled"
                placeholder="请输入停止外呼秒数"
              ></el-input>
            </el-form-item>
            <el-form-item label="微信加粉：" required style="width: 700px">
              <template slot="label">
                微信加粉启用
                <el-tooltip
                  content="开启后，坐席页面将看到加微信按钮"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <el-switch
                v-model="showAddWechatButton"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
              <el-button
                type="text"
                v-if="showAddWechatButton"
                @click="groupSettings(clientWechatServer, 'wechat')"
              >
                分组设置 {{ wechatGroupIdName }} ({{ clientWechatServer }})
              </el-button>
            </el-form-item>
            <el-form-item label="成单加粉：" required style="width: 700px">
              <template slot="label">
                成单加粉
                <el-tooltip
                  content="坐席标记为成单后， 自动进行加粉操作"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <el-switch
                v-model="addWechatIfMarkFlag"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
              <el-button
                type="text"
                v-if="addWechatIfMarkFlag"
                @click="groupSettings(flagWechatServer, 'automatic')"
              >
                分组设置 {{ automaticGroupIdName }} ({{ flagWechatServer }})
              </el-button>
            </el-form-item>
            <!-- <el-form-item label="启用短信：" required style="width: 700px">
              <el-switch
                v-model="smsEnabled"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </el-form-item> -->
            <el-form-item label="未成单加粉：" required style="width: 700px">
              <template slot="label">
                未成单加粉
                <el-tooltip
                  content="坐席标记为非成单并且通话话时长大于n秒后， 自动进行加粉操作"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <el-switch
                v-model="addWechatIfMarkUnFlag"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
              <el-button
                type="text"
                v-if="addWechatIfMarkUnFlag"
                @click="groupSettings(unFlagWechatServer, 'unAutomatic')"
              >
                分组设置 {{ unFlagWechatClientGroupIdName }} ({{
                  unFlagWechatServer
                }})
              </el-button>
            </el-form-item>
            <!-- <el-form-item label="二次加粉:" required style="width: 700px">
              <template slot="label">
                二次加粉
                <el-tooltip
                  content="提醒客户通过好友申请， 同时更改好友申请类型为 二次加粉"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <el-switch
                v-model="notifyAddFans"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
              <span v-if="notifyAddFans">
                通话时长：<el-input
                  v-model="notifyAddFansTimes"
                  placeholder="请输入通话时长"
                ></el-input>
              </span>
            </el-form-item> -->
            <el-form-item label="启用短信：" required style="width: 700px">
              <el-switch
                v-model="smsEnabled"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </el-form-item>
            <el-form-item
              label="默认短信长链分组："
              required
              v-show="smsEnabled"
            >
              <el-select
                v-model="smsLongUrlGroup"
                placeholder="请选择短信长链分组"
              >
                <el-option
                  v-for="item in smsLongUrlGroupList"
                  :key="item.id"
                  :label="item.groupName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="附加短信长链分组：" v-show="smsEnabled">
              <el-select
                v-model="extraLongUrlGroup"
                placeholder="请选择短信长链分组"
                multiple
              >
                <el-option
                  v-for="item in smsLongUrlGroupList"
                  :key="item.id"
                  :label="item.groupName"
                  :value="item.id"
                  :disabled="item.id === smsLongUrlGroup"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane
          label="人工开场白模板"
          name="prologue"
          :disabled="activeTemplateName !== 'prologue'"
        >
          <el-form
            label-width="180px"
            label-position="left"
            :inline="true"
            class="call_template_style"
          >
            <el-form-item label="呼叫超时(秒)：" required>
              <el-select
                v-model="originateTimeout"
                placeholder="请选择超时时间"
              >
                <el-option
                  v-for="item in originateTimeoutList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="坐席整理时间(秒)：" required>
              <el-input
                v-model="answerInterval"
                placeholder="请输入整理时间"
              ></el-input>
            </el-form-item>
            <el-form-item label="坐席全满暂停外呼：" required>
              <el-select
                v-model="newStopCallOnFull"
                placeholder="请选择状态"
                @change="strategyChange"
              >
                <el-option
                  v-for="item in strategyList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="坐席全满停止外呼(秒)：" required>
              <el-input
                v-model="pauseSecondsOnFull"
                :disabled="pauseSeconds_disabled"
                placeholder="请输入停止外呼秒数"
              ></el-input>
            </el-form-item>
            <el-form-item label="微信加粉：" required style="width: 700px">
              <template slot="label">
                微信加粉启用
                <el-tooltip
                  content="开启后，坐席页面将看到加微信按钮"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <el-switch
                v-model="showAddWechatButton"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
              <el-button
                type="text"
                v-if="showAddWechatButton"
                @click="groupSettings(clientWechatServer, 'wechat')"
              >
                分组设置 {{ wechatGroupIdName }} ({{ clientWechatServer }})
              </el-button>
            </el-form-item>
            <el-form-item label="成单加粉：" required style="width: 700px">
              <template slot="label">
                成单加粉
                <el-tooltip
                  content="坐席标记为成单后， 自动进行加粉操作"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <el-switch
                v-model="addWechatIfMarkFlag"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
              <el-button
                type="text"
                v-if="addWechatIfMarkFlag"
                @click="groupSettings(flagWechatServer, 'automatic')"
              >
                分组设置 {{ automaticGroupIdName }} ({{ flagWechatServer }})
              </el-button>
            </el-form-item>
            <el-form-item label="未成单加粉：" required style="width: 700px">
              <template slot="label">
                未成单加粉
                <el-tooltip
                  content="坐席标记为非成单并且通话话时长大于n秒后， 自动进行加粉操作"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <el-switch
                v-model="addWechatIfMarkUnFlag"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
              <el-button
                type="text"
                v-if="addWechatIfMarkUnFlag"
                @click="groupSettings(unFlagWechatServer, 'unAutomatic')"
              >
                分组设置 {{ unFlagWechatClientGroupIdName }} ({{
                  unFlagWechatServer
                }})
              </el-button>
            </el-form-item>
            <!-- <el-form-item label="二次加粉:" required style="width: 700px">
              <template slot="label">
                二次加粉
                <el-tooltip
                  content="提醒客户通过好友申请， 同时更改好友申请类型为 二次加粉"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <el-switch
                v-model="notifyAddFans"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
              <span v-if="notifyAddFans">
                通话时长：<el-input
                  v-model="notifyAddFansTimes"
                  placeholder="请输入通话时长"
                ></el-input>
              </span>
            </el-form-item> -->
            <el-form-item label="开场白启用：" required style="width: 700px">
              <el-switch
                v-model="playPrologue"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
              <el-button
                type="text"
                v-if="playPrologue"
                @click="prologueSettings(prologueId)"
              >
                开场白设置 {{ prologueName }}
              </el-button>
            </el-form-item>
            <el-form-item label="启用短信：" required style="width: 700px">
              <el-switch
                v-model="smsEnabled"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </el-form-item>
            <el-form-item
              label="默认短信长链分组："
              required
              v-show="smsEnabled"
            >
              <el-select
                v-model="smsLongUrlGroup"
                placeholder="请选择短信长链分组"
              >
                <el-option
                  v-for="item in smsLongUrlGroupList"
                  :key="item.id"
                  :label="item.groupName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="附加短信长链分组：" v-show="smsEnabled">
              <el-select
                v-model="extraLongUrlGroup"
                placeholder="请选择短信长链分组"
                multiple
              >
                <el-option
                  v-for="item in smsLongUrlGroupList"
                  :key="item.id"
                  :label="item.groupName"
                  :value="item.id"
                  :disabled="item.id === smsLongUrlGroup"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane
          label="挂机短信模板"
          name="smsTemplate"
          :disabled="activeTemplateName !== 'short'"
        >
          <el-form label-position="left" :disabled="smsDisabeld">
            <el-form-item label="短信平台路由：" required>
              <div style="width: 900px">
                <el-table
                  :data="platformTableData"
                  stripe
                  style="width: 100%"
                  border=""
                  class="m_table"
                >
                  <el-table-column prop label="禁用区域" align="center">
                    <template slot-scope="scope">
                      <el-cascader
                        v-model="scope.row.forbiddenArea"
                        placeholder="可以试试搜索"
                        :options="options"
                        :props="props"
                        collapse-tags
                        filterable
                        :show-all-levels="false"
                        size="small"
                        clearable
                        :disabled="isAddPlatform"
                      ></el-cascader>
                    </template>
                  </el-table-column>
                  <el-table-column prop label="运营商" align="center">
                    <template slot-scope="scope">
                      <el-select
                        v-model="scope.row.customerPhoneOperator"
                        placeholder="请选择运营商"
                        size="small"
                        multiple
                        collapse-tags
                        style="width: 100%"
                        :disabled="isAddPlatform"
                      >
                        <el-option
                          v-for="item in operatorsArr"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column prop label="平台名称" align="center">
                    <template slot-scope="scope">
                      <el-select
                        v-model="scope.row.platformLabel"
                        placeholder="请选择平台名称"
                        size="small"
                        style="width: 100%"
                        @change="platformChagne($event, scope.$index)"
                        :disabled="isAddPlatform"
                      >
                        <el-option
                          v-for="item in platformArr"
                          :key="item.id"
                          :label="item.name"
                          :value="item.code + '_' + item.accessKey"
                        ></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column prop label="顺序" width="100" align="center">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.order"
                        size="small"
                        style="width: 100%"
                        :disabled="isAddPlatform"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop label="操作" width="60" align="center">
                    <template slot-scope="scope">
                      <el-button
                        type="text"
                        size="small"
                        @click.native.prevent="
                          platformDelete(
                            scope.$index,
                            platformTableData,
                            scope.row.id
                          )
                        "
                        style="color: #ff0000b0"
                        :disabled="isAddPlatform"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <el-button
                  type="primary"
                  size="small"
                  @click="addPlatform()"
                  :disabled="isAddPlatform"
                  v-if="addBtnShow"
                  >新增</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  disabled
                  v-if="!addBtnShow"
                  >新增</el-button
                >
              </div>
            </el-form-item>
            <el-form-item label="短信签名：" label-width="120px" required>
              <el-select
                v-model="smsSign"
                placeholder="请选择短信签名"
                @change="smsSignChange"
                :disabled="isAddPlatform"
              >
                <el-option
                  v-for="item in smsSignList"
                  :key="item.signId"
                  :label="item.label"
                  :value="item.sign"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="短信内容：" label-width="120px" required>
              <template slot="label">
                短信内容
                <el-tooltip
                  content="短信内容不需要手动输入签名"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <sms-input
                :textareaSMS="textareaSMS"
                @change="textarea_sms"
                @longUrlChange="longUrlChange"
                @smsDataLabelChange="smsDataLabelChange"
                @prefixChange="prefixChange"
                @smsNum="getSmsNum"
                @callValueChange="callValueChange"
                @wechatServerChange="wechatServerChange"
                @wechatGroupIdChange="wechatGroupIdChange"
                @urlFormatChange="urlFormatChange"
                :isDisabled="isDisabled"
                :longUrlValue="longUrlValue"
                :prefixValue="prefixValue"
                :callValue="callValue"
                :serverWechat="serverWechat"
                :clientGroupId="clientGroupId"
                :urlFormat="urlFormat"
                :smsDataLabelValue="smsDataLabelValue"
                :parseUrlTitle="parseUrlTitle"
                :smsSign="smsSign"
                :isAddPlatform="isAddPlatform"
                style="width: 750px"
              ></sms-input>
            </el-form-item>
            <el-form-item label="开场白启用：" required style="width: 700px">
              <el-switch
                v-model="playPrologue"
                active-color="#13ce66"
                inactive-color="#ff4949"
                disabled
              ></el-switch>
              <el-button
                type="text"
                v-if="playPrologue"
                @click="prologueSettings(prologueId)"
              >
                开场白设置 {{ prologueName }}
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane
          label="挂机微信模板"
          name="wechatTemplate"
          :disabled="activeTemplateName !== 'wechat'"
        >
          <el-form label-position="left" label-width="130px">
            <el-form-item label="执行动作：" required>
              <el-switch
                v-model="addWechatOnHangup"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
              <div
                style="width: 300px; margin-bottom: 10px; display: inline-block"
                v-if="addWechatOnHangup"
              >
                <el-select
                  v-model="triggerWechatServer"
                  placeholder="请选择服务器"
                  @change="wechatClientGroupFocus(triggerWechatServer, '2')"
                >
                  <el-option
                    v-for="item in wechatServerList"
                    :key="item.server"
                    :label="item.server"
                    :value="item.server"
                  >
                  </el-option>
                </el-select>
              </div>
              <div
                style="width: 300px; margin-left: 45px"
                v-if="addWechatOnHangup"
              >
                <el-select
                  v-model="triggerWechatClientGroupId"
                  placeholder="请选择机器分组"
                >
                  <el-option
                    v-for="item in wechatClientGroupList"
                    :key="item.id"
                    :label="item.groupName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="二次加粉:" required style="width: 700px">
              <template slot="label">
                二次加粉
                <el-tooltip
                  content="提醒客户通过好友申请， 同时更改好友申请类型为 二次加粉"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <el-switch
                v-model="changeCustomerSourceType"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </el-form-item>
            <el-form-item label="开场白启用：" required style="width: 700px">
              <el-switch
                v-model="playPrologue"
                active-color="#13ce66"
                inactive-color="#ff4949"
                disabled
              ></el-switch>
              <el-button
                type="text"
                v-if="playPrologue"
                @click="prologueSettings(prologueId)"
              >
                开场白设置 {{ prologueName }}
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane
          label="AI机器人模板"
          name="robotTemplate"
          :disabled="activeTemplateName !== 'robot'"
        >
          <el-form label-position="left" label-width="120px">
            <el-form-item label="话术名称：" required>
              <div style="width: 500px">
                <el-select
                  v-model="dialogueId"
                  placeholder="请选择话术"
                  filterable
                >
                  <el-option
                    v-for="item in dialogueList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div class="button-center">
        <el-button type="primary" @click="smsInput_submit">提 交</el-button>
        <el-button @click="smsInputShow = false">取 消</el-button>
      </div>
      <!-- 分组设置 -->
      <el-dialog
        width="450px"
        :title="innerTitle"
        :visible.sync="innerVisible"
        append-to-body
      >
        <el-form>
          <el-form-item
            label="分组类型:"
            v-if="innerTitle === '微信加粉分组设置'"
          >
            <el-radio-group v-model="value2" @change="switchChange">
              <el-radio label="false">固定分组</el-radio>
              <el-radio label="true">动态分组</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="分组设置："
            required
            v-if="innerTitle === '微信加粉分组设置' && value2 === 'false'"
          >
            <div style="width: 500px; margin-bottom: 10px">
              <el-select
                v-model="clientWechatServer"
                placeholder="请选择服务器"
                @change="wechatClientGroupFocus(clientWechatServer, '1')"
              >
                <el-option
                  v-for="item in wechatServerList"
                  :key="item.server"
                  :label="item.server"
                  :value="item.server"
                >
                </el-option>
              </el-select>
              <!-- 通话时长加粉 -->
            </div>
            <div style="width: 500px; padding-left: 92px">
              <el-select
                v-model="clientWechatClientGroupId"
                @change="clientWechatClientGroupIdChange($event, 'wechat')"
                placeholder="请选择机器分组"
              >
                <el-option
                  v-for="item in wechatClientGroupList"
                  :key="item.id"
                  :label="item.groupName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item
            label="分组设置："
            required
            v-if="innerTitle === '自动加粉分组设置'"
          >
            <div style="width: 500px; margin-bottom: 10px">
              <el-select
                v-model="flagWechatServer"
                placeholder="请选择服务器"
                @change="wechatClientGroupFocus(flagWechatServer, '3')"
              >
                <el-option
                  v-for="item in wechatServerList"
                  :key="item.server"
                  :label="item.server"
                  :value="item.server"
                >
                </el-option>
              </el-select>
            </div>
            <div style="width: 500px; padding-left: 92px">
              <el-select
                v-model="flagWechatClientGroupId"
                @change="clientWechatClientGroupIdChange($event, 'automatic')"
                placeholder="请选择机器分组"
              >
                <el-option
                  v-for="item in wechatClientGroupList"
                  :key="item.id"
                  :label="item.groupName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <!-- 未成单加粉 -->
          <el-form-item
            label="分组类型:"
            v-if="innerTitle === '未成单加粉分组设置'"
          >
            <el-radio-group v-model="value3" @change="switchChange3">
              <el-radio label="false">固定分组</el-radio>
              <el-radio label="true">动态分组</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="分组设置："
            required
            v-if="innerTitle === '未成单加粉分组设置' && value3 === 'false'"
          >
            <div style="width: 500px; margin-bottom: 10px">
              <el-select
                v-model="unFlagWechatServer"
                placeholder="请选择服务器"
                @change="wechatClientGroupFocus(unFlagWechatServer, '4')"
              >
                <el-option
                  v-for="item in wechatServerList"
                  :key="item.server"
                  :label="item.server"
                  :value="item.server"
                >
                </el-option>
              </el-select>
            </div>
            <div style="width: 500px; padding-left: 92px">
              <el-select
                v-model="unFlagWechatClientGroupId"
                @change="clientWechatClientGroupIdChange($event, 'unAutomatic')"
                placeholder="请选择机器分组"
              >
                <el-option
                  v-for="item in wechatClientGroupList"
                  :key="item.id"
                  :label="item.groupName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item
            label="通话时长(秒):"
            required
            v-if="innerTitle === '未成单加粉分组设置'"
          >
            <div style="width: 500px; padding-left: 92px">
              <!-- <el-switch
                v-model="autoAddFansByTimeFlag"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch> -->
              <el-input
                class="input1"
                v-model="addWechatIfMarkUnFlagTime"
                placeholder="请输入时长(秒)"
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" align="center">
          <el-button type="primary" @click="innerVisible = false"
            >确定</el-button
          >
          <el-button @click="innerVisible = false">取消</el-button>
        </div>
      </el-dialog>
    </el-dialog>
    <!-- 开场白模板 -->
    <prologueTemplate
      ref="prologueTemplate"
      @select="selectionClick"
    ></prologueTemplate>
  </div>
</template>
<script>
import smsInput from "../pages/smsInput.vue";
import prologueTemplate from "../pages/prologueTemplate.vue";
export default {
  name: "smsTemplate",
  props: ["roleMenu"],
  components: {
    smsInput,
    prologueTemplate,
  },
  data() {
    return {
      serverSelect: false,
      addWechatIfMarkUnFlagTime: "",
      // autoAddFansByTimeFlag: "false",
      value2: "false",
      switchStatus: "NORMAL",
      value3: "false",
      switchStatus3: "NORMAL",
      //---------------
      dialogueId: "",
      dialogueList: [],
      robotTemplateTableTotal: 0,
      robotTemplateTablePageNo: 1,
      robotTemplateTablePageSize: 10,
      robotTemplateTableData: [],
      robotLoading: false,
      smsLongUrlGroup: "",
      extraLongUrlGroup: [],
      smsLongUrlGroupList: [],
      smsEnabled: false,
      playPrologue: true,
      prologueName: "",
      prologueId: "",
      wechatGroupIdName: "",
      automaticGroupIdName: "",
      unFlagWechatClientGroupIdName: "",
      innerTitle: "",
      innerVisible: false,
      strategyTemplateClientTalkingId: "",
      showAddWechatButton: false,
      flagWechatServer: "",
      unFlagWechatServer: "",
      flagWechatClientGroupId: "",
      unFlagWechatClientGroupId: "",
      addWechatIfMarkFlag: false,
      addWechatIfMarkUnFlag: false,
      // notifyAddFansTimes: "",
      // notifyAddFans: false,
      changeCustomerSourceType: false,
      addWechatOnHangup: true,
      clientWechatServer: "",
      clientWechatClientGroupId: "",
      pauseSeconds_disabled: false,
      originateTimeout: "",
      originateTimeoutList: [
        {
          value: "30",
          label: "30",
        },
        {
          value: "40",
          label: "40",
        },
      ],
      answerInterval: "",
      newStopCallOnFull: "",
      strategyList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      pauseSecondsOnFull: "",
      activeTemplateName: "normal",
      seatsLoading: false,
      callTemplateTablePageNo: 1,
      callTemplateTablePageSize: 10,
      callTemplateTableTotal: 0,
      callTemplateTableData: [],
      wechatLoading: false,
      wechatTemplateTableData: [],
      wechatTemplateTablePageNo: 1,
      wechatTemplateTablePageSize: 10,
      wechatTemplateTableTotal: 0,
      prologueLoading: false,
      prologueTemplateTablePageNo: 1,
      prologueTemplateTableTotal: 0,
      prologueTemplateTablePageSize: 10,
      prologueTemplateTableData: [],
      wechatServerList: [
        {
          server: "t1.innerchic.cn",
        },
        {
          server: "t9.innerchic.cn",
        },
        {
          server: "gateway.bilinl.com",
        },
      ],
      wechatClientGroupList: [],
      triggerWechatServer: "",
      triggerWechatClientGroupId: "",
      triggerAction: "CALL_MILLIS_LIMIT",
      callDuration: "",
      callDurationShow: true,
      activeName: "normal",
      smsSign: "",
      smsSignValue: "",
      smsSignList: [],
      smsDisabeld: false,
      enabled: true,
      loading: false,
      smsName: "",
      smsSubName: "",
      smsTemplateTablePageNo: 1,
      smsTemplateTablePageSize: 10,
      smsTemplateTableTotal: 10,
      smsTemplateTableData: [],
      modalTltle: "",
      smsInputShow: false,
      textareaSMS: "",
      isDisabled: false,
      longUrlValue: "",
      smsDataLabelValue: "",
      parseUrlTitle: "",
      prefixValue: "https://",
      callValue: "NONE",
      serverWechat: "t9.innerchic.cn",
      clientGroupId: "",
      urlFormat: false,
      smsNum: 0,
      smsInputName: "",
      smsInputSubName: "",
      smsInputEnabled: true,
      smsTemplateId: "",
      platformTableData: [],
      options: [],
      props: {
        multiple: true,
        emitPath: false,
        value: "code",
        label: "name",
        checkStrictly: true,
      },
      operatorsArr: [
        {
          label: "中国移动",
          value: "中国移动",
        },
        {
          label: "中国联通",
          value: "中国联通",
        },
        {
          label: "中国电信",
          value: "中国电信",
        },
        {
          label: "其它",
          value: "其它",
        },
      ],
      platformArr: [],
      addBtnShow: true,
    };
  },
  watch: {
    platformTableData(val) {
      if (this.platformTableData.length >= 1) {
        this.addBtnShow = false;
      } else {
        this.addBtnShow = true;
      }
    },
  },
  computed: {
    isTriggerShow() {
      if (
        this.activeName === "normal" ||
        this.activeName === "prologue" ||
        this.activeName === "robotTemplate"
      ) {
        return false;
      }
      return true;
    },
    isAddPlatform() {
      if (this.roleMenu !== "SUPER_MANAGER" && this.modalTltle === "编辑模板") {
        if (
          this.activeTemplateName === "wechat" ||
          this.activeTemplateName === "short"
        ) {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    this.seatsInquire(1);
    this.getProvinceAndCity();
    this.getPlatformList();
  },
  methods: {
    switchChange() {
      if (this.value2 == "true") {
        this.switchStatus = "CLIENT";
        this.clientWechatServer = "";
        this.clientWechatClientGroupId = "";
        this.wechatGroupIdName = "";
      } else {
        this.switchStatus = "NORMAL";
      }
    },
    switchChange3() {
      if (this.value3 == "true") {
        this.switchStatus3 = "CLIENT";
        this.unFlagWechatServer = "";
        this.unFlagWechatClientGroupId = "";
        this.unFlagWechatClientGroupIdName = "";
      } else {
        this.switchStatus3 = "NORMAL";
      }
    },
    // ycEnd----------------------------------------------------------------
    // 查询话术列表
    getDialogueList() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      this.dialogueList = [];
      this.$http("/dialogue/list", null, "get", null, data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.dialogueList = list;
        }
      });
    },
    // 查询ai机器人列表
    robotInquire(index) {
      if (index) {
        this.robotTemplateTablePageNo = index;
      }
      let data = {
        pageNo: this.robotTemplateTablePageNo,
        pageSize: this.robotTemplateTablePageSize,
        name: this.smsName,
        subName: this.smsSubName,
        templateType: "AI_ROBOT",
      };
      if (this.enabled) {
        data.enabled = this.enabled;
      }
      this.getMsgTemplateList(data, "robot");
    },
    // 机器人列表--翻页
    robotTemplateChangePage(index) {
      this.robotInquire(index);
    },
    // 机器人列表--页数
    robotTemplateTablePageSizeChange(index) {
      this.robotTemplateTablePageSize = index;
      this.robotInquire(1);
    },
    // 查询坐席长链
    getGroupItemList() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      this.$http("/longUrlGroup/groupList", null, "get", null, data)
        .then((res) => {
          if (res.result === 200) {
            let list = res.data.records;
            this.smsLongUrlGroupList = list;
          }
        })
        .catch((err) => {});
    },
    // 编辑模板选择tab
    activeNameChange(val) {
      this.parameterInfo();
      if (
        this.activeName === "smsTemplate" ||
        this.activeName === "wechatTemplate"
      ) {
        this.playPrologue = true;
      }
    },
    // 开场白设置
    prologueSettings(val) {
      this.$refs.prologueTemplate.init();
    },
    // 选中开场白
    selectionClick(val) {
      this.prologueId = val.id;
      this.prologueName = val.title;
    },
    // 点击分组设置
    groupSettings(val, key) {
      this.innerVisible = true;
      if (key === "wechat") {
        this.innerTitle = "微信加粉分组设置";
      }
      if (key === "automatic") {
        this.innerTitle = "自动加粉分组设置";
      }
      if (key === "unAutomatic") {
        this.innerTitle = "未成单加粉分组设置";
      }
      this.wechatClientGroupFocus(val);
    },
    // 机器分组获取--人工坐席
    clientWechatClientGroupIdChange(val, key) {
      console.log(val, key);
      this.wechatClientGroupList.forEach((item) => {
        if (val === item.id) {
          if (key === "wechat") {
            this.wechatGroupIdName = item.groupName;
          }
          if (key === "automatic") {
            this.automaticGroupIdName = item.groupName;
          }
          if (key === "unAutomatic") {
            this.unFlagWechatClientGroupIdName = item.groupName;
          }
        }
      });
    },
    //坐席全满暂停外呼改变
    strategyChange(val) {
      if (val == true) {
        this.pauseSeconds_disabled = false;
      } else {
        this.pauseSeconds_disabled = true;
      }
    },
    // 点击查询
    inquire() {
      if (this.activeTemplateName === "short") {
        this.smsInquire(1);
      }
      if (this.activeTemplateName === "wechat") {
        this.wechatInquire(1);
      }
      if (this.activeTemplateName === "normal") {
        this.seatsInquire(1);
      }
      if (this.activeTemplateName === "prologue") {
        this.prologueInquire(1);
      }
      if (this.activeTemplateName === "robot") {
        this.robotInquire(1);
      }
    },
    // 选择模板tab
    handleClick() {
      if (this.activeTemplateName === "short") {
        this.smsInquire(1);
      }
      if (this.activeTemplateName === "wechat") {
        this.wechatInquire(1);
      }
      if (this.activeTemplateName === "normal") {
        this.seatsInquire(1);
      }
      if (this.activeTemplateName === "prologue") {
        this.prologueInquire(1);
      }
      if (this.activeTemplateName === "robot") {
        this.robotInquire(1);
      }
    },
    // 触发动作选择
    radioTriggerChange(val) {
      // if (val === "FLAG_CHANGE") {
      //   this.callDurationShow = false;
      // }
      if (val === "CALL_MILLIS_LIMIT") {
        this.callDurationShow = true;
      }
    },
    // 服务器获取
    wechatClientGroupFocus(val, key) {
      if (val === "gateway.bilinl.com") {
        this.serverSelect = true;
      } else {
        this.serverSelect = false;
      }
      let data = {
        pageNo: 1,
        pageSize: 100,
        server: val,
      };
      if (key === "1") {
        this.clientWechatClientGroupId = "";
        this.wechatGroupIdName = "";
      }
      if (key === "2") {
        this.triggerWechatClientGroupId = "";
      }
      if (key === "3") {
        this.flagWechatClientGroupId = "";
        this.automaticGroupIdName = "";
      }
      if (key === "4") {
        this.unFlagWechatClientGroupId = "";
        this.unFlagWechatClientGroupIdName = "";
      }
      this.getWechatClientGroupList(data);
    },
    // 查询机器分组
    getWechatClientGroupList(data) {
      this.$service(
        "/wechat/wechatClientGroupList",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.wechatClientGroupList = list;
          this.wechatClientGroupList.forEach((item) => {
            if (this.clientWechatClientGroupId === item.id) {
              this.wechatGroupIdName = item.groupName;
            }
            if (this.flagWechatClientGroupId === item.id) {
              this.automaticGroupIdName = item.groupName;
            }
            if (this.unFlagWechatClientGroupId === item.id) {
              this.unFlagWechatClientGroupIdName = item.groupName;
            }
          });
        }
      });
    },
    // 平台选择
    platformChagne(e, index) {
      let platforms = e.split("_");
      this.$set(this.platformTableData[index], "platformLabel", e);
      this.$set(this.platformTableData[index], "platform", platforms[0]);
      this.$set(this.platformTableData[index], "accessKey", platforms[1]);
      this.smsSign = "";
      this.smsSignValue = "";
      let signData = {
        pageNo: 1,
        pageSize: 100,
        accessKey: platforms[1],
      };
      this.getSignList(signData);
      this.$forceUpdate();
    },
    // 点击删除
    platformDelete(index, rows, id) {
      rows.splice(index, 1);
    },
    // 点击新增短信路由平台
    addPlatform() {
      let index = this.platformTableData.length + 1;
      let obj = {
        forbiddenArea: [],
        customerPhoneOperator: [],
        platform: "",
        order: index,
        accessKey: "",
        platformLabel: "",
        id: null,
      };
      this.platformTableData.push(obj);
    },
    getProvinceAndCity() {
      //查询区域
      this.$http("/getProvinceAndCity", null).then((res) => {
        if (res.result == 200) {
          this.options = [];
          let data = res.data;
          for (let i in data) {
            let a = {};
            a.name = i;
            a.code = i;
            a.children = [];
            if (data[i] && data[i].length > 0) {
              for (let j of data[i]) {
                let b = {};
                b.name = i + " " + j;
                b.code = i + ":" + j;
                a.children.push(b);
              }
            }
            this.options.push(a);
          }
        }
      });
    },
    // 查询平台
    getPlatformList() {
      this.$http("/sms/smsAccountInfo/list", null, "get").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.platformArr = list;
        }
      });
    },
    // 点击短信模板查询
    smsInquire(index) {
      if (index) {
        this.smsTemplateTablePageNo = index;
      }
      let data = {
        pageNo: this.smsTemplateTablePageNo,
        pageSize: this.smsTemplateTablePageSize,
        name: this.smsName,
        subName: this.smsSubName,
        templateType: "PROLOGUE_SMS",
      };
      if (this.enabled) {
        data.enabled = this.enabled;
      }
      this.getMsgTemplateList(data, "short");
    },
    // 查询模板列表
    getMsgTemplateList(data, key) {
      if (key === "short") {
        this.loading = true;
      }
      if (key === "wechat") {
        this.wechatLoading = true;
      }
      if (key === "normal") {
        this.seatsLoading = true;
      }
      if (key === "prologue") {
        this.prologueLoading = true;
      }
      if (key === "robot") {
        this.robotLoading = true;
      }
      this.$http("/msgTemplate/list", null, "get", null, data)
        .then((res) => {
          if (key === "short") {
            this.loading = false;
          }
          if (key === "wechat") {
            this.wechatLoading = false;
          }
          if (key === "normal") {
            this.seatsLoading = false;
          }
          if (key === "prologue") {
            this.prologueLoading = false;
          }
          if (key === "robot") {
            this.robotLoading = false;
          }
          if (res.result === 200) {
            let list = res.data.records;
            let hangupSmsRouteInfoList = list.hangupSmsRouteInfoList;
            if (hangupSmsRouteInfoList) {
              hangupSmsRouteInfoList.forEach((item) => {
                if (item.customerPhoneOperator) {
                  item.customerPhoneOperator =
                    item.customerPhoneOperator.split(",");
                } else {
                  item.customerPhoneOperator = [];
                }
                if (item.forbiddenArea) {
                  item.forbiddenArea = item.forbiddenArea.split(",");
                } else {
                  item.forbiddenArea = [];
                }
                item.platformLabel = item.platform + "_" + item.accessKey;
              });
            }
            if (key === "short") {
              this.smsTemplateTableData = list;
              this.smsTemplateTableTotal = res.data.total;
            }
            if (key === "wechat") {
              this.wechatTemplateTableData = list;
              this.wechatTemplateTableTotal = res.data.total;
            }
            if (key === "normal") {
              list.forEach((item) => {
                if (!item.strategyTemplateClientTalking) {
                  item.strategyTemplateClientTalking = {
                    originateTimeout: "",
                    answerInterval: "",
                    stopCallOnFull: "",
                    pauseSecondsOnFull: "",
                  };
                }
              });
              this.callTemplateTableData = list;
              this.callTemplateTableTotal = res.data.total;
            }
            if (key === "prologue") {
              list.forEach((item) => {
                if (!item.strategyTemplateClientTalking) {
                  item.strategyTemplateClientTalking = {
                    originateTimeout: "",
                    answerInterval: "",
                    stopCallOnFull: "",
                    pauseSecondsOnFull: "",
                  };
                }
              });
              this.prologueTemplateTableData = list;
              this.prologueTemplateTableTotal = res.data.total;
            }
            if (key === "robot") {
              this.robotTemplateTableData = list;
              this.robotTemplateTableTotal = res.data.total;
            }
          }
        })
        .catch((err) => {
          if (key === "short") {
            this.loading = false;
          }
          if (key === "wechat") {
            this.wechatLoading = false;
          }
          if (key === "normal") {
            this.seatsLoading = false;
          }
          if (key === "prologue") {
            this.prologueLoading = false;
          }
          if (key === "robot") {
            this.robotLoading = false;
          }
        });
    },
    // 翻页--短信
    smsTemplateChangePage(index) {
      this.smsInquire(index);
    },
    // 每页条数--短信
    smsTemplateTablePageSizeChange(index) {
      this.smsTemplateTablePageSize = index;
      this.smsInquire(1);
    },
    // 点击查询微信模板列表
    wechatInquire(index) {
      if (index) {
        this.wechatTemplateTablePageNo = index;
      }
      let data = {
        pageNo: this.wechatTemplateTablePageNo,
        pageSize: this.wechatTemplateTablePageSize,
        name: this.smsName,
        subName: this.smsSubName,
        templateType: "PROLOGUE_WECHAT",
      };
      if (this.enabled) {
        data.enabled = this.enabled;
      }
      this.getMsgTemplateList(data, "wechat");
    },
    // 翻页--微信
    wechatTemplateChangePage(index) {
      this.wechatInquire(index);
    },
    // 每页条数--微信
    wechatTemplateTablePageSizeChange(index) {
      this.wechatTemplateTablePageSize = index;
      this.wechatInquire(1);
    },
    // 点击查询人工坐席列表
    seatsInquire(index) {
      if (index) {
        this.callTemplateTablePageNo = index;
      }
      let data = {
        pageNo: this.callTemplateTablePageNo,
        pageSize: this.callTemplateTablePageSize,
        name: this.smsName,
        subName: this.smsSubName,
        templateType: "NORMAL",
      };
      if (this.enabled) {
        data.enabled = this.enabled;
      }
      this.getMsgTemplateList(data, "normal");
    },
    // 翻页--人工坐席
    callTemplateChangePage(index) {
      this.seatsInquire(index);
    },
    // 每页条数--人工坐席
    callTemplateTablePageSizeChange(index) {
      this.callTemplateTablePageSize = index;
      this.seatsInquire(1);
    },
    // 点击查询人工开场白列表
    prologueInquire(index) {
      if (index) {
        this.prologueTemplateTablePageNo = index;
      }
      let data = {
        pageNo: this.prologueTemplateTablePageNo,
        pageSize: this.prologueTemplateTablePageSize,
        name: this.smsName,
        subName: this.smsSubName,
        templateType: "PROLOGUE",
      };
      if (this.enabled) {
        data.enabled = this.enabled;
      }
      this.getMsgTemplateList(data, "prologue");
    },
    // 翻页--人工开场白
    prologueTemplateChangePage(index) {
      this.prologueInquire(index);
    },
    // 每页条数--人工开场白
    prologueTemplateTablePageSizeChange(index) {
      this.prologueTemplateTablePageSize = index;
      this.prologueInquire(1);
    },
    // 请求短信编辑详情
    getMsgTemplateDetail(id) {
      this.$http("/msgTemplate/detail", null, "get", null, { id: id }).then(
        (res) => {
          if (res.result === 200) {
            let val = res.data;
            console.log(val);
            if (val) {
              this.smsTemplateId = val.id;
              this.smsInputName = val.name;
              this.smsInputSubName = val.subName;
              this.triggerAction = val.triggerAction;
              this.smsInputEnabled = val.enabled;
              this.playPrologue = val.playPrologue;

              this.prologueName = val.prologueTitle;
              this.prologueId = val.prologueId;
              if (val.templateType === "NORMAL") {
                this.activeName = "normal";
                if (val.strategyTemplateClientTalking) {
                  this.smsEnabled =
                    val.strategyTemplateClientTalking.showSendSmsButton;
                  this.$nextTick(() => {
                    this.strategyTemplateClientTalkingId =
                      val.strategyTemplateClientTalking.id;
                    this.originateTimeout =
                      val.strategyTemplateClientTalking.originateTimeout;
                    this.answerInterval =
                      val.strategyTemplateClientTalking.answerInterval;
                    this.newStopCallOnFull =
                      val.strategyTemplateClientTalking.stopCallOnFull;
                    this.pauseSecondsOnFull =
                      val.strategyTemplateClientTalking.pauseSecondsOnFull;
                    this.showAddWechatButton =
                      val.strategyTemplateClientTalking.showAddWechatButton;
                    this.addWechatIfMarkFlag =
                      val.strategyTemplateClientTalking.addWechatIfMarkFlag;
                    this.addWechatIfMarkUnFlag =
                      val.strategyTemplateClientTalking.addWechatIfMarkUnFlag;
                    // this.notifyAddFans =
                    //   val.strategyTemplateClientTalking.notifyAddFans;
                    if (
                      val.strategyTemplateClientTalking.clientWechatServer != 0
                    ) {
                      this.clientWechatServer =
                        val.strategyTemplateClientTalking.clientWechatServer;
                    }
                    if (
                      val.strategyTemplateClientTalking
                        .clientWechatClientGroupId != 0
                    ) {
                      this.clientWechatClientGroupId =
                        val.strategyTemplateClientTalking.clientWechatClientGroupId;
                    }
                    if (
                      val.strategyTemplateClientTalking.flagWechatServer != 0
                    ) {
                      this.flagWechatServer =
                        val.strategyTemplateClientTalking.flagWechatServer;
                    }
                    if (
                      val.strategyTemplateClientTalking.unFlagWechatServer != 0
                    ) {
                      this.unFlagWechatServer =
                        val.strategyTemplateClientTalking.unFlagWechatServer;
                    }
                    if (
                      val.strategyTemplateClientTalking
                        .flagWechatClientGroupId != 0
                    ) {
                      this.flagWechatClientGroupId =
                        val.strategyTemplateClientTalking.flagWechatClientGroupId;
                    }
                    // console.log('1111', val)
                    if (
                      val.strategyTemplateClientTalking
                        .unFlagWechatClientGroupId != 0
                    ) {
                      this.unFlagWechatClientGroupId =
                        val.strategyTemplateClientTalking.unFlagWechatClientGroupId;
                    }
                    this.smsLongUrlGroup = "";
                    if (val.strategyTemplateClientTalking.longUrlGroupId) {
                      this.smsLongUrlGroup =
                        val.strategyTemplateClientTalking.longUrlGroupId;
                    }
                    this.extraLongUrlGroup = [];
                    if (val.strategyTemplateClientTalking.extraLongUrlGroupId) {
                      let extraLongUrlGroup =
                        val.strategyTemplateClientTalking.extraLongUrlGroupId.split(
                          ","
                        );
                      this.extraLongUrlGroup = extraLongUrlGroup.map(Number);
                    }
                    this.smsSignValue =
                      val.strategyTemplateClientTalking.smsSign;
                    // yc新增
                    //  this.autoAddFansByTimeFlag =
                    //   val.strategyTemplateClientTalking.autoAddFansByTimeFlag;
                    this.addWechatIfMarkUnFlagTime =
                      val.strategyTemplateClientTalking
                        .addWechatIfMarkUnFlagTime / 1000;
                    // this.notifyAddFansTimes =
                    //   val.strategyTemplateClientTalking.notifyAddFansTimes /
                    //   1000;
                    if (
                      val.strategyTemplateClientTalking.addFansGroupType ===
                      "CLIENT"
                    ) {
                      this.switchStatus =
                        val.strategyTemplateClientTalking.addFansGroupType;
                      this.value2 = "true";
                      this.clientWechatClientGroupId = "";
                    } else if (
                      val.strategyTemplateClientTalking.addFansGroupType ===
                      "NORMAL"
                    ) {
                      this.switchStatus =
                        val.strategyTemplateClientTalking.addFansGroupType;
                      this.value2 = "false";
                    }
                    //
                    if (
                      val.strategyTemplateClientTalking
                        .unFlagAddFansGroupType === "CLIENT"
                    ) {
                      this.switchStatus3 =
                        val.strategyTemplateClientTalking.unFlagAddFansGroupType;
                      this.value3 = "true";
                      this.unFlagWechatClientGroupId = "";
                    } else if (
                      val.strategyTemplateClientTalking
                        .unFlagAddFansGroupType === "NORMAL"
                    ) {
                      this.switchStatus3 =
                        val.strategyTemplateClientTalking.unFlagAddFansGroupType;
                      this.value3 = "false";
                    }
                    // yc新增end
                  });
                }
              }
              if (val.templateType === "PROLOGUE") {
                this.activeName = "prologue";
                if (val.strategyTemplateClientTalking) {
                  this.smsEnabled =
                    val.strategyTemplateClientTalking.showSendSmsButton;
                  this.$nextTick(() => {
                    this.strategyTemplateClientTalkingId =
                      val.strategyTemplateClientTalking.id;
                    this.originateTimeout =
                      val.strategyTemplateClientTalking.originateTimeout;
                    this.answerInterval =
                      val.strategyTemplateClientTalking.answerInterval;
                    this.newStopCallOnFull =
                      val.strategyTemplateClientTalking.stopCallOnFull;
                    this.pauseSecondsOnFull =
                      val.strategyTemplateClientTalking.pauseSecondsOnFull;
                    this.showAddWechatButton =
                      val.strategyTemplateClientTalking.showAddWechatButton;
                    this.addWechatIfMarkFlag =
                      val.strategyTemplateClientTalking.addWechatIfMarkFlag;
                    this.addWechatIfMarkUnFlag =
                      val.strategyTemplateClientTalking.addWechatIfMarkUnFlag;
                    // this.notifyAddFans =
                    //   val.strategyTemplateClientTalking.notifyAddFans;
                    if (
                      val.strategyTemplateClientTalking.clientWechatServer != 0
                    ) {
                      this.clientWechatServer =
                        val.strategyTemplateClientTalking.clientWechatServer;
                    }
                    if (
                      val.strategyTemplateClientTalking
                        .clientWechatClientGroupId != 0
                    ) {
                      this.clientWechatClientGroupId =
                        val.strategyTemplateClientTalking.clientWechatClientGroupId;
                    }
                    if (
                      val.strategyTemplateClientTalking.flagWechatServer != 0
                    ) {
                      this.flagWechatServer =
                        val.strategyTemplateClientTalking.flagWechatServer;
                    }
                    if (
                      val.strategyTemplateClientTalking.unFlagWechatServer != 0
                    ) {
                      this.unFlagWechatServer =
                        val.strategyTemplateClientTalking.unFlagWechatServer;
                    }
                    if (
                      val.strategyTemplateClientTalking
                        .flagWechatClientGroupId != 0
                    ) {
                      this.flagWechatClientGroupId =
                        val.strategyTemplateClientTalking.flagWechatClientGroupId;
                    }
                    if (
                      val.strategyTemplateClientTalking
                        .unFlagWechatClientGroupId != 0
                    ) {
                      this.unFlagWechatClientGroupId =
                        val.strategyTemplateClientTalking.unFlagWechatClientGroupId;
                    }
                    this.smsLongUrlGroup = "";
                    if (val.strategyTemplateClientTalking.longUrlGroupId) {
                      this.smsLongUrlGroup =
                        val.strategyTemplateClientTalking.longUrlGroupId;
                    }
                    this.extraLongUrlGroup = [];
                    if (val.strategyTemplateClientTalking.extraLongUrlGroupId) {
                      let extraLongUrlGroup =
                        val.strategyTemplateClientTalking.extraLongUrlGroupId.split(
                          ","
                        );
                      this.extraLongUrlGroup = extraLongUrlGroup.map(Number);
                    }
                    this.smsSignValue =
                      val.strategyTemplateClientTalking.smsSign;
                    // yc新增
                    //  this.autoAddFansByTimeFlag =
                    //   val.strategyTemplateClientTalking.autoAddFansByTimeFlag;
                    this.addWechatIfMarkUnFlagTime =
                      val.strategyTemplateClientTalking
                        .addWechatIfMarkUnFlagTime / 1000;
                    // this.notifyAddFansTimes =
                    //   val.strategyTemplateClientTalking.notifyAddFansTimes /
                    //   1000;
                    if (
                      val.strategyTemplateClientTalking.addFansGroupType ===
                      "CLIENT"
                    ) {
                      this.switchStatus =
                        val.strategyTemplateClientTalking.addFansGroupType;
                      this.value2 = "true";
                      this.clientWechatClientGroupId = "";
                    } else if (
                      val.strategyTemplateClientTalking.addFansGroupType ===
                      "NORMAL"
                    ) {
                      this.switchStatus =
                        val.strategyTemplateClientTalking.addFansGroupType;
                      this.value2 = "false";
                    }
                    // ---
                    if (
                      val.strategyTemplateClientTalking
                        .unFlagAddFansGroupType === "CLIENT"
                    ) {
                      this.switchStatus3 =
                        val.strategyTemplateClientTalking.unFlagAddFansGroupType;
                      this.value3 = "true";
                      this.unFlagWechatClientGroupId = "";
                    } else if (
                      val.strategyTemplateClientTalking
                        .unFlagAddFansGroupType === "NORMAL"
                    ) {
                      this.switchStatus3 =
                        val.strategyTemplateClientTalking.unFlagAddFansGroupType;
                      this.value3 = "false";
                    }
                    // yc新增end
                  });
                }
              }
              if (val.templateType === "PROLOGUE_SMS") {
                if (this.triggerAction === "CALL_MILLIS_LIMIT") {
                  this.callDuration = val.triggerValue;
                  this.callDurationShow = true;
                }
                this.playPrologue = true;
                this.activeName = "smsTemplate";
                this.$nextTick(() => {
                  this.longUrlValue = val.longUrl;
                  let textareaSMS = val.content;
                  let signFirstIndex = textareaSMS.indexOf("【");
                  let signLastIndex = textareaSMS.indexOf("】");
                  if (signFirstIndex !== -1 && signLastIndex !== -1) {
                    let startText = textareaSMS.substring(0, signFirstIndex);
                    let endText = textareaSMS.substring(signLastIndex + 1);
                    textareaSMS = startText + endText;
                  }
                  this.textareaSMS = textareaSMS;
                  this.prefixValue = val.prefix;
                  this.callValue = val.smsClickCallbackType;
                  this.serverWechat = val.wechatServer;
                  this.clientGroupId = val.wechatClientGroupId;
                  if (val.smsSign) {
                    this.smsSign = "【" + val.smsSign + "】";
                  } else {
                    this.smsSign = "";
                  }
                  this.smsSignValue = val.smsSign;
                  this.smsDataLabelValue = val.sign;
                  let hangupSmsRouteInfoList = val.hangupSmsRouteInfoList;
                  if (hangupSmsRouteInfoList) {
                    hangupSmsRouteInfoList.forEach((item) => {
                      if (item.customerPhoneOperator) {
                        item.customerPhoneOperator =
                          item.customerPhoneOperator.split(",");
                      } else {
                        item.customerPhoneOperator = [];
                      }
                      if (item.forbiddenArea) {
                        item.forbiddenArea = item.forbiddenArea.split(",");
                      } else {
                        item.forbiddenArea = [];
                      }
                      item.platformLabel = item.platform + "_" + item.accessKey;
                    });
                    if (hangupSmsRouteInfoList.length > 0) {
                      let signData = {
                        pageNo: 1,
                        pageSize: 100,
                        accessKey: hangupSmsRouteInfoList[0].accessKey,
                      };
                      this.getSignList(signData);
                    }
                  }
                  this.platformTableData = hangupSmsRouteInfoList;
                });
                if (val.longUrl) {
                  let httpIndex = val.longUrl.indexOf("http://");
                  let httpsIndex = val.longUrl.indexOf("https://");
                  if (httpIndex === 0 || httpsIndex === 0) {
                    this.getParseUrlTitle(val.longUrl);
                  }
                }
              }
              if (val.templateType === "PROLOGUE_WECHAT") {
                if (this.triggerAction === "CALL_MILLIS_LIMIT") {
                  this.callDuration = val.triggerValue;
                  this.callDurationShow = true;
                }
                this.playPrologue = true;
                this.changeCustomerSourceType = val.changeCustomerSourceType;
                this.addWechatOnHangup = val.addWechatOnHangup;
                this.triggerWechatServer = val.triggerWechatServer;
                this.triggerWechatClientGroupId =
                  val.triggerWechatClientGroupId;
                this.activeName = "wechatTemplate";
              }
              if (val.templateType === "AI_ROBOT") {
                this.activeName = "robotTemplate";
                this.dialogueId = val.dialogueId;
                this.getDialogueList();
              }
              // if (this.triggerAction === "NONE") {

              // } else {

              //   // if (this.triggerAction === "FLAG_CHANGE") {
              //   //   this.callDuration = "";
              //   //   this.callDurationShow = false;
              //   // }
              //   if (val.triggerWechatClientGroupId) {

              //   } else {

              //   }
              // }
              this.wechatClientGroupFocus(undefined);
            }
          }
        }
      );
    },
    // 获取短信签名
    getSignList(data) {
      this.$http("/sms/signList", null, "get", null, data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          let signList = [];
          list.forEach((item) => {
            signList.push({
              sign: "【" + item.sign.trim() + "】",
              label: item.sign.trim(),
              signId: item.id,
            });
          });
          this.smsSignList = signList;
        }
      });
    },
    // 选择短信签名
    smsSignChange(val) {
      this.smsSignList.forEach((item) => {
        if (val === item.sign) {
          this.smsSignValue = item.label;
          this.smsDataLabelValue = item.label;
        }
      });
    },
    // 解析url标题
    getParseUrlTitle(url) {
      this.$http("/parseUrlTitle", null, "get", null, { url: url }).then(
        (res) => {
          if (res.result === 200) {
            let title = res.data.urlTitle;
            this.parseUrlTitle = title;
          }
        }
      );
    },
    // 点击编辑
    modifyClick(key, val) {
      this.smsInputShow = true;
      this.getGroupItemList();
      if (key === "add") {
        this.modalTltle = "新增模板";
        if (this.activeTemplateName === "normal") {
          this.activeName = "normal";
        }
        if (this.activeTemplateName === "prologue") {
          this.activeName = "prologue";
        }
        if (this.activeTemplateName === "short") {
          this.activeName = "smsTemplate";
        }
        if (this.activeTemplateName === "wechat") {
          this.activeName = "wechatTemplate";
        }
        if (this.activeTemplateName === "robot") {
          this.activeName = "robotTemplate";
          this.getDialogueList();
        }
      }
      if (key === "modify") {
        this.modalTltle = "编辑模板";
        this.textareaSMS = undefined;
        this.getMsgTemplateDetail(val.id);
      }
    },
    // 短信编辑提交---yc修改
    smsInput_submit() {
      let triggerValue = 1;
      let data = {
        name: this.smsInputName,
        subName: this.smsInputSubName,
        triggerAction: this.triggerAction,
        enabled: this.smsInputEnabled,
      };
      if (!data.name) {
        this.$message.warning("请输入名称");
        return;
      }
      if (!data.subName) {
        this.$message.warning("请输入子名称");
        return;
      }
      if (this.activeName === "normal") {
        data.triggerAction = "NONE";
        data.triggerValue = "";
        data.templateType = "NORMAL";
        data.strategyTemplateClientTalking = {
          originateTimeout: this.originateTimeout,
          answerInterval: this.answerInterval,
          stopCallOnFull: this.newStopCallOnFull,
          pauseSecondsOnFull: this.pauseSecondsOnFull,
          addWechatIfMarkFlag: this.addWechatIfMarkFlag,
          addWechatIfMarkUnFlag: this.addWechatIfMarkUnFlag,
          // notifyAddFans: this.notifyAddFans,
          flagWechatClientGroupId: this.flagWechatClientGroupId,
          unFlagWechatClientGroupId: this.unFlagWechatClientGroupId,
          showAddWechatButton: this.showAddWechatButton,
          clientWechatServer: this.clientWechatServer,
          clientWechatClientGroupId: this.clientWechatClientGroupId,
          showSendSmsButton: this.smsEnabled,
          longUrlGroupId: this.smsLongUrlGroup,
          extraLongUrlGroupId: this.extraLongUrlGroup.join(","),
          // yc新增
          addFansGroupType: this.switchStatus,
          unFlagAddFansGroupType: this.switchStatus3,
          // autoAddFansByTimeFlag: this.autoAddFansByTimeFlag,
          // notifyAddFansTimes: this.notifyAddFansTimes * 1000,
          unFlagWechatServer: this.unFlagWechatServer,
          addWechatIfMarkUnFlagTime: this.addWechatIfMarkUnFlagTime * 1000,
        };
        if (!data.strategyTemplateClientTalking.originateTimeout) {
          this.$message.warning("请选择呼叫超时");
          return;
        }
        if (!data.strategyTemplateClientTalking.answerInterval) {
          this.$message.warning("请输入坐席整理时间");
          return;
        }
        if (data.strategyTemplateClientTalking.stopCallOnFull === "") {
          this.$message.warning("请选择坐席全满暂停外呼");
          return;
        }
        if (
          !data.strategyTemplateClientTalking.stopCallOnFull &&
          !data.strategyTemplateClientTalking.pauseSecondsOnFull
        ) {
          this.$message.warning("请输入坐席全满停止外呼");
          return;
        }
        if (data.strategyTemplateClientTalking.showAddWechatButton) {
          if (this.value2 === "false") {
            //yc新增
            if (!data.strategyTemplateClientTalking.clientWechatServer) {
              this.$message.warning("请选择微信加粉服务器");
              return;
            }
            if (this.serverSelect) {
              data.strategyTemplateClientTalking.clientWechatClientGroupId = -2;
            }
            if (!data.strategyTemplateClientTalking.clientWechatClientGroupId) {
              this.$message.warning("请选择微信加粉机器分组");
              return;
            }
          }
        }
        if (data.strategyTemplateClientTalking.addWechatIfMarkFlag) {
          console.log(
            this.flagWechatServer,
            data.strategyTemplateClientTalking.flagWechatClientGroupId
          );
          if (!this.flagWechatServer) {
            this.$message.warning("请选择成单加粉服务器");
            return;
          }
          if (this.serverSelect) {
            data.strategyTemplateClientTalking.flagWechatClientGroupId = -2;
          }
          if (!data.strategyTemplateClientTalking.flagWechatClientGroupId) {
            this.$message.warning("请选择成单加粉机器分组");
            return;
          }
        }
        if (data.strategyTemplateClientTalking.addWechatIfMarkUnFlag) {
          // console.log(
          //   this.unFlagWechatServer,
          //   data.strategyTemplateClientTalking.unFlagWechatClientGroupId
          // );
          if (this.value3 === "false") {
            if (!this.unFlagWechatServer) {
              this.$message.warning("请选择未成单加粉服务器");
              return;
            }
            if (this.serverSelect) {
              data.strategyTemplateClientTalking.unFlagWechatClientGroupId = -2;
            }
            if (!data.strategyTemplateClientTalking.unFlagWechatClientGroupId) {
              this.$message.warning("请选择未成单加粉机器分组");
              return;
            }
          }
        }
        // if (data.strategyTemplateClientTalking.notifyAddFans) {
        //   if (!this.notifyAddFansTimes) {
        //     this.$message.warning("请输入通话时长");
        //     return;
        //   }
        // }
        if (this.smsEnabled) {
          if (!data.strategyTemplateClientTalking.longUrlGroupId) {
            this.$message.warning("请选择短信长链分组");
            return;
          }
        }
      }
      if (this.activeName === "prologue") {
        data.triggerAction = "NONE";
        data.triggerValue = "";
        data.templateType = "PROLOGUE";
        data.playPrologue = this.playPrologue;
        data.prologueId = this.prologueId;
        data.strategyTemplateClientTalking = {
          originateTimeout: this.originateTimeout,
          answerInterval: this.answerInterval,
          stopCallOnFull: this.newStopCallOnFull,
          pauseSecondsOnFull: this.pauseSecondsOnFull,
          addWechatIfMarkFlag: this.addWechatIfMarkFlag,
          addWechatIfMarkUnFlag: this.addWechatIfMarkUnFlag,
          // notifyAddFans: this.notifyAddFans,
          flagWechatClientGroupId: this.flagWechatClientGroupId,
          unFlagWechatClientGroupId: this.unFlagWechatClientGroupId,
          showAddWechatButton: this.showAddWechatButton,
          clientWechatServer: this.clientWechatServer,
          clientWechatClientGroupId: this.clientWechatClientGroupId,
          showSendSmsButton: this.smsEnabled,
          longUrlGroupId: this.smsLongUrlGroup,
          extraLongUrlGroupId: this.extraLongUrlGroup.join(","),
          // yc新增
          addFansGroupType: this.switchStatus,
          unFlagAddFansGroupType: this.switchStatus3,
          // autoAddFansByTimeFlag: this.autoAddFansByTimeFlag,
          // notifyAddFansTimes: this.notifyAddFansTimes * 1000,
          addWechatIfMarkUnFlagTime: this.addWechatIfMarkUnFlagTime * 1000,
        };
        if (!data.strategyTemplateClientTalking.originateTimeout) {
          this.$message.warning("请选择呼叫超时");
          return;
        }
        if (!data.strategyTemplateClientTalking.answerInterval) {
          this.$message.warning("请输入坐席整理时间");
          return;
        }
        if (data.strategyTemplateClientTalking.stopCallOnFull === "") {
          this.$message.warning("请选择坐席全满暂停外呼");
          return;
        }
        if (
          !data.strategyTemplateClientTalking.stopCallOnFull &&
          !data.strategyTemplateClientTalking.pauseSecondsOnFull
        ) {
          this.$message.warning("请输入坐席全满停止外呼");
          return;
        }
        if (data.strategyTemplateClientTalking.showAddWechatButton) {
          if (this.value2 === "false") {
            //yc新增
            if (!data.strategyTemplateClientTalking.clientWechatServer) {
              this.$message.warning("请选择微信加粉服务器");
              return;
            }
            if (this.serverSelect) {
              data.strategyTemplateClientTalking.clientWechatClientGroupId = -2;
            }
            if (!data.strategyTemplateClientTalking.clientWechatClientGroupId) {
              this.$message.warning("请选择微信加粉机器分组");
              return;
            }
          }
        }
        if (data.strategyTemplateClientTalking.addWechatIfMarkFlag) {
          if (!this.flagWechatServer) {
            this.$message.warning("请选择成单加粉服务器");
            return;
          }
          if (this.serverSelect) {
            data.strategyTemplateClientTalking.flagWechatClientGroupId = -2;
          }
          if (!data.strategyTemplateClientTalking.flagWechatClientGroupId) {
            this.$message.warning("请选择成单加粉机器分组");
            return;
          }
        }
        if (data.strategyTemplateClientTalking.addWechatIfMarkUnFlag) {
          if (this.value3 === "false") {
            if (!this.unFlagWechatServer) {
              this.$message.warning("请选择未成单加粉服务器");
              return;
            }
            if (this.serverSelect) {
              data.strategyTemplateClientTalking.unFlagWechatClientGroupId = -2;
            }
            if (!data.strategyTemplateClientTalking.unFlagWechatClientGroupId) {
              this.$message.warning("请选择未成单加粉机器分组");
              return;
            }
          }
        }
        // if (data.strategyTemplateClientTalking.notifyAddFans) {
        //   if (!this.notifyAddFansTimes) {
        //     this.$message.warning("请输入通话时长");
        //     return;
        //   }
        // }
        if (!data.prologueId && data.playPrologue) {
          this.$message.warning("请选择开场白");
          return;
        }
        if (this.smsEnabled) {
          if (!data.strategyTemplateClientTalking.longUrlGroupId) {
            this.$message.warning("请选择短信长链分组");
            return;
          }
        }
      }
      if (this.activeName === "robotTemplate") {
        data.templateType = "AI_ROBOT";
        data.triggerAction = "NONE";
        data.triggerValue = "";
        data.dialogueId = this.dialogueId;
        if (!data.dialogueId) {
          this.$message.warning("请选择话术");
          return;
        }
      }
      // if (this.triggerAction === "FLAG_CHANGE") {
      //   triggerValue = 1;
      //   data.triggerValue = triggerValue;
      // }
      if (data.triggerAction === "CALL_MILLIS_LIMIT") {
        if (this.callDuration) {
          triggerValue = Number(this.callDuration);
        } else {
          triggerValue = "";
        }
        data.triggerValue = triggerValue;
      }
      if (!data.triggerValue && data.triggerAction !== "NONE") {
        this.$message.warning("请输入通话达标时长");
        return;
      }
      if (this.activeName === "smsTemplate") {
        data.templateType = "PROLOGUE_SMS";
        data.longUrl = this.longUrlValue;
        data.sign = this.smsDataLabelValue;
        data.content = this.smsSign + this.textareaSMS;
        data.prefix = this.prefixValue;
        data.smsClickCallbackType = this.callValue;
        data.wechatServer = this.serverWechat;
        data.wechatClientGroupId = this.clientGroupId;
        data.hangupSmsRouteInfoItemList = [];
        data.smsSign = this.smsSignValue;
        data.playPrologue = this.playPrologue;
        data.prologueId = this.prologueId;
        if (this.platformTableData.length < 1) {
          this.$message.warning("请添加短信路由平台");
          return;
        }
        let isOrder = false; // 是否填写顺序
        let isPlatform = false; // 是否填写平台
        this.platformTableData.forEach((item) => {
          let platforms = item.platformLabel.split("_");
          item.platform = platforms[0];
          item.accessKey = platforms[1];
          if (!item.order) {
            isOrder = true;
          }
          if (!item.platform) {
            isPlatform = true;
          }
        });
        if (isPlatform) {
          this.$message({ message: "平台不能为空", type: "warning" });
          return;
        }
        if (isOrder) {
          this.$message({ message: "顺序不能为空", type: "warning" });
          return;
        }
        let platformTableData = this.platformTableData;
        platformTableData.forEach((item) => {
          let obj = {
            forbiddenArea: item.forbiddenArea.join(","),
            customerPhoneOperator: item.customerPhoneOperator.join(","),
            order: item.order,
            platform: item.platform,
            accessKey: item.accessKey,
            id: item.id,
          };
          data.hangupSmsRouteInfoItemList.push(obj);
        });
        if (!data.smsSign) {
          this.$message.warning("请选择短信签名");
          return;
        }
        if (!data.content) {
          this.$message.warning("请输入短信内容");
          return;
        }
        if (!data.prologueId && data.playPrologue) {
          this.$message.warning("请选择开场白");
          return;
        }
      }
      if (this.activeName === "wechatTemplate") {
        data.templateType = "PROLOGUE_WECHAT";
        data.triggerWechatServer = this.triggerWechatServer;
        data.triggerWechatClientGroupId = this.triggerWechatClientGroupId;
        data.playPrologue = this.playPrologue;
        data.changeCustomerSourceType = this.changeCustomerSourceType;
        data.addWechatOnHangup = this.addWechatOnHangup;
        data.prologueId = this.prologueId;
        if (data.addWechatOnHangup) {
          if (!data.triggerWechatServer) {
            this.$message.warning("请选择服务器");
            return;
          }
          if (!data.triggerWechatClientGroupId) {
            this.$message.warning("请选择分组");
            return;
          }
        }

        if (!data.prologueId && data.playPrologue) {
          this.$message.warning("请选择开场白");
          return;
        }
      }
      if (this.modalTltle === "新增模板") {
        this.addSmsTemplate(data);
      }
      if (this.modalTltle === "编辑模板") {
        data.id = this.smsTemplateId;
        if (this.activeName === "normal" || this.activeName === "prologue") {
          data.strategyTemplateClientTalking.id =
            this.strategyTemplateClientTalkingId;
        }
        this.updateSmsTemplate(data);
      }
    },
    // 关闭短信编辑
    smsInputClose() {
      this.smsLongUrlGroupList = [];
      this.smsLongUrlGroup = "";
      this.extraLongUrlGroup = [];
      (this.smsEnabled = false), (this.urlFormat = false);
      this.textareaSMS = "";
      this.isDisabled = false;
      this.longUrlValue = "";
      this.smsDataLabelValue = "";
      this.parseUrlTitle = "";
      this.prefixValue = "https://";
      this.callValue = "NONE";
      this.serverWechat = "t9.innerchic.cn";
      this.clientGroupId = "";
      this.smsNum = 0;
      this.smsInputName = "";
      this.smsInputSubName = "";
      this.smsTemplateId = "";
      this.smsInputEnabled = true;
      this.platformTableData = [];
      this.smsSign = "";
      this.smsSignValue = "";
      this.smsSignList = [];
      this.callDurationShow = true;
      this.callDuration = "";
      this.triggerAction = "CALL_MILLIS_LIMIT";
      this.triggerWechatServer = "";
      this.triggerWechatClientGroupId = "";
      this.activeName = "normal";
      this.showAddWechatButton = false;
      this.flagWechatServer = "";
      this.unFlagWechatServer = "";
      // this.autoAddFansByTimeFlag = false;
      this.autoAddFansTime = "";
      this.flagWechatClientGroupId = "";
      this.unFlagWechatClientGroupId = "";
      this.addWechatIfMarkFlag = false;
      this.addWechatIfMarkUnFlag = false;
      // this.notifyAddFans = false;
      this.clientWechatServer = "";
      this.clientWechatClientGroupId = "";
      this.strategyTemplateClientTalkingId = "";
      this.originateTimeout = "";
      this.answerInterval = "";
      this.newStopCallOnFull = "";
      this.pauseSecondsOnFull = "";
      this.wechatGroupIdName = "";
      this.automaticGroupIdName = "";
      this.unFlagWechatClientGroupIdName = "";
      this.prologueName = "";
      this.prologueId = "";
      this.playPrologue = true;
      this.changeCustomerSourceType = false;
      this.addWechatOnHangup = true;
      // this.notifyAddFansTimes = "";
    },
    // 切换任务类型参数初始化
    parameterInfo() {
      this.textareaSMS = "";
      this.callValue = "NONE";
      this.serverWechat = "";
      this.clientGroupId = "";
    },
    // 确定新增短信模板
    addSmsTemplate(data) {
      this.$service("/msgTemplate/addMsgTemplate", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("新增成功");
          if (this.activeTemplateName === "short") {
            this.smsInquire(1);
          }
          if (this.activeTemplateName === "wechat") {
            this.wechatInquire(1);
          }
          if (this.activeTemplateName === "normal") {
            this.seatsInquire(1);
          }
          if (this.activeTemplateName === "prologue") {
            this.prologueInquire(1);
          }
          if (this.activeTemplateName === "robot") {
            this.robotInquire(1);
          }
          this.smsInputShow = false;
          // yc新增
          this.value2 = "false";
        }
      });
    },
    // 确定更新短信模板
    updateSmsTemplate(data) {
      this.$service("/msgTemplate/updateMsgTemplate", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("编辑成功");
          if (this.activeTemplateName === "short") {
            this.smsInquire(1);
          }
          if (this.activeTemplateName === "wechat") {
            this.wechatInquire(1);
          }
          if (this.activeTemplateName === "normal") {
            this.seatsInquire(1);
          }
          if (this.activeTemplateName === "prologue") {
            this.prologueInquire(1);
          }
          if (this.activeTemplateName === "robot") {
            this.robotInquire(1);
          }
          this.smsInputShow = false;
          // yc新增
          this.value2 = "false";
        }
      });
    },
    // 获取短信内容
    textarea_sms(val) {
      this.textareaSMS = val;
    },
    // 获取长链内容
    longUrlChange(val) {
      this.longUrlValue = val;
    },
    // 获取数据标记内容
    smsDataLabelChange(val) {
      this.smsDataLabelValue = val;
    },
    // 获取前缀
    prefixChange(val) {
      this.prefixValue = val;
    },
    // 获取短信条数
    getSmsNum(num) {
      this.smsNum = num;
    },
    // 判断长链格式
    urlFormatChange(val) {
      this.urlFormat = val;
    },
    // 获取点击调用
    callValueChange(val) {
      this.callValue = val;
    },
    // 获取微信服务器
    wechatServerChange(val) {
      this.serverWechat = val;
    },
    // 获取微信机器
    wechatGroupIdChange(val) {
      this.clientGroupId = val;
    },
  },
};
</script>
<style scoped>
.input1 {
  width: 230px;
}
.search-m {
  margin-bottom: 20px;
}
.search-m .search-m-1 {
  display: inline-block;
  margin-right: 20px;
}
.search-m .search-m-1 .el-input {
  width: 250px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
.search-botton {
  margin-bottom: 20px;
}
.smsTemplate >>> .el-table__fixed-right {
  height: 100% !important;
}
.call_template_style .el-input,
.el-select {
  width: 250px;
}
</style>
