import { render, staticRenderFns } from "./Call.vue?vue&type=template&id=779522fa&scoped=true&"
import script from "./Call.vue?vue&type=script&lang=js&"
export * from "./Call.vue?vue&type=script&lang=js&"
import style0 from "./Call.vue?vue&type=style&index=0&id=779522fa&prod&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "779522fa",
  null
  
)

export default component.exports