//公用path
const versionNumber = new Date().getTime().toString().substr(-6); // 当前时间戳
const constants = {
  baseURL: "https://huixiao.backend.test.huiyu.org.cn/api",
  basePath: ".backend.test.huiyu.org.cn/api",
  versionNumber,
};
const path = window.location.href;
let server = "huixiao";
let url = path.split("/")[2];
let newArr = url.split(".");
let index = url.indexOf(".");
let domain = url.substr(index);
server = newArr[0];

if (process.env.VUE_APP_ENVIRONMENT === "dev") {
  constants.baseURL = "http://localhost:8080/backendApiPrefix";
  constants.basePath = "/backendApiPrefix";
} else if (process.env.VUE_APP_ENVIRONMENT === "test") {
  constants.baseURL = "https://huixiao.backend.test.huiyu.org.cn/api";
  constants.basePath = ".backend.test.huiyu.org.cn/api";
} else if (process.env.VUE_APP_ENVIRONMENT === "prod") {
  constants.baseURL = "https://" + server + ".backend" + domain + "/api";
  constants.basePath = ".backend.huiyu.org.cn/api";
}

export default constants;
