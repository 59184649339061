import { render, staticRenderFns } from "./qualityTestList.vue?vue&type=template&id=be2484ce&scoped=true&"
import script from "./qualityTestList.vue?vue&type=script&lang=js&"
export * from "./qualityTestList.vue?vue&type=script&lang=js&"
import style0 from "./qualityTestList.vue?vue&type=style&index=0&id=be2484ce&prod&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be2484ce",
  null
  
)

export default component.exports