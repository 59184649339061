<template>
  <div class="wechat">
    <div class="nav">
      <div class="callTime">
        开课时间：
        <el-date-picker
          v-model="condition.courseOpenTimeStartDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          unlink-panels
          :picker-options="courseOpenPickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
          style="margin: 0 16px 20px 16px"
        ></el-date-picker>
      </div>
      <div class="callTime">
        添加时间：
        <el-date-picker
          v-model="condition.gmtCreatedDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
          style="margin: 0 16px 20px 16px"
          :clearable="false"
        ></el-date-picker>
      </div>
    </div>
    <div class="nav">
      <div class="batchName">
        批次编号：
        <el-input
          v-model="condition.batchNumber"
          placeholder="请输入批次编号"
          clearable
        ></el-input>
      </div>
      <div class="batchName">
        客户编号：
        <!-- <i style="margin-left: 16px;"></i> -->
        <el-input
          v-model="condition.customerCodeOrPhone"
          placeholder="请输入客户编号或手机号"
          clearable
        ></el-input>
      </div>
      <div class="batchName">
        客户分类：
        <el-select
          v-model="condition.customerTag"
          clearable
          placeholder="请选择客户分类"
        >
          <el-option
            v-for="(item, index) in customerTagList"
            :key="index"
            :label="item.label + ' ( ' + item.labelRemark + ' )'"
            :value="item.label"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="nav">
      <div class="batchName">
        微信昵称：
        <el-input
          v-model="condition.nickName"
          placeholder="请输入微信昵称"
          clearable
        ></el-input>
      </div>
      <div class="batchName">
        机器名称：
        <el-input
          v-model="condition.clientNickName"
          placeholder="请输入机器名称"
          clearable
        ></el-input>
        <!-- <el-select
          v-model="condition.clientNickName"
          clearable
          @focus="clientNickNameFocus"
          placeholder="请选择机器"
          multiple
          collapse-tags
          filterable
        >
          <el-option
            v-for="item in wechatClientList"
            :key="item.id"
            :label="item.codeOrName"
            :value="item.clientCode"
          ></el-option>
        </el-select> -->
      </div>
      <div class="batchName">
        来源：
        <i style="margin-left: 33px"></i>
        <el-select
          v-model="condition.source"
          clearable
          placeholder="请选择来源"
        >
          <el-option
            v-for="item in sourceList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="nav">
      <div class="batchName">
        报名科目：
        <el-select
          v-model="condition.lessonName"
          clearable
          placeholder="请选择报名科目"
          multiple
          collapse-tags
          filterable
        >
          <el-option
            v-for="(item, index) in lessonNameList"
            :key="index"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
      </div>
      <div class="batchName">
        质检标签：
        <el-select
          v-model="condition.recordingLabelList"
          clearable
          placeholder="请选择质检标签"
          multiple
          collapse-tags
          filterable
        >
          <el-option
            v-for="(item, index) in recordTagList"
            :key="index"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
      </div>
      <div
        class="batchName"
        v-if="roleMenu == 'SUPER_MANAGER' || roleMenu == 'WECHAT_MANAGER'"
      >
        跟进运营：
        <el-input
          v-model="condition.operator"
          placeholder="请输入跟进运营"
          clearable
        ></el-input>
      </div>
    </div>
    <div class="nav">
      <div class="batchName">
        服务器：
        <i style="margin-left: 17px"></i>
        <el-select
          v-model="wechatClientGroupIdServer"
          placeholder="请选择服务器"
          @change="wechatClientGroupFocus(wechatClientGroupIdServer, 'query')"
          clearable
        >
          <el-option
            v-for="item in wechatServerList"
            :key="item.server"
            :label="item.server"
            :value="item.server"
          >
          </el-option>
        </el-select>
      </div>
      <div class="batchName">
        机器分组：
        <el-select
          v-model="addWechatClientGroupId"
          placeholder="请选择机器分组"
          clearable
          :disabled="!wechatClientGroupIdServer"
        >
          <el-option
            v-for="item in wechatClientGroupList"
            :key="item.id"
            :label="item.groupName"
            :value="item.groupName"
          >
          </el-option>
        </el-select>
      </div>
      <div class="batchName">
        产品名称：
        <!-- <el-input
          v-model="productName"
          placeholder="请输入产品名称"
          clearable
        ></el-input> -->
        <el-select
          v-model="productName"
          filterable
          remote
          placeholder="请输入关键字搜索"
          :remote-method="getProductNameList"
          clearable
        >
          <el-option
            v-for="item in productNameList"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="batchName" v-if="roleMenu === 'WECHAT'">
        <el-checkbox v-model="queryAllClient">查询历史数据</el-checkbox>
      </div>
    </div>
    <div class="nav">
      <div
        class="batchName"
        v-if="roleMenu == 'SUPER_MANAGER' || roleMenu == 'WECHAT_MANAGER'"
      >
        状态<el-tooltip class="item" effect="light" placement="top-start">
          <div slot="content">
            <div>
              登录失效:
              实际上已经登录失效了(在其他手机登录，封号，登录过期)，但是页面还没有刷新(点击任何地方都会立即退出登录)
            </div>
            <div>未登陆: 开机后，直接显示微信未登陆</div>
          </div>
          <i class="el-icon-question icon-color"></i> </el-tooltip
        >：
        <i style="margin-left: 18px"></i>
        <el-select
          v-model="condition.status"
          clearable
          placeholder="请选择状态"
          multiple
          collapse-tags
        >
          <el-option
            v-for="item in wechatStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="batchName">
        标签：
        <i style="margin-left: 50px"></i>
        <span style="font-size: 14px">
          {{ tagsLabel }}
        </span>
        <el-button
          type="text"
          @click="tag_retrieval"
          style="margin: 0 0px 20px 0px"
          >标签检索</el-button
        >
        <el-button type="text" @click="clearTagList" class="clear"
          >清除</el-button
        >
      </div>
    </div>
    <div class="operate">
      <el-button type="primary" @click="inquire(1)">查询</el-button>
      <el-button type="primary" @click="uploadFiles('file')"
        >上传文件</el-button
      >
      <el-button type="primary" @click="uploadFiles('data')"
        >数据更新</el-button
      >
      <el-button type="primary" @click="batchLabelSetShow = true"
        >批量设置标签</el-button
      >
      <el-button
        type="success"
        @click="downloadCustomerList"
        :disabled="derivedDis"
        >导出</el-button
      >
      <el-switch
        v-model="interfaceType"
        style="margin-left: 10px"
        active-text="搜索引擎查询"
        inactive-text="数据库查询"
      >
      </el-switch>
    </div>
    <!-- 微信粉丝列表 -->
    <el-table
      :data="wechatTableData"
      stripe
      style="width: 100%"
      border=""
      v-loading="loadingTable"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="productName"
        label="产品名称"
        width="140"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="batchNumber"
        label="批次编号"
        width="140"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="sourceLabel"
        label="来源"
        width="140"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="wechatGroupName"
        label="机器分组"
        width="140"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="wechatCustomerLabelList"
        label="转粉情况"
        width="120"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <!-- <el-tag
            v-for="tag in scope.row.wechatCustomerLabelList" 
            :key="tag.id"
            size="mini"
            class="tags_td"
          >{{tag.label}}</el-tag> -->
          <el-button
            v-if="scope.row.wechatCustomerLabel"
            type="text"
            @click="classifyClick(scope.row, 'wechat')"
            >{{ scope.row.wechatCustomerLabel }}</el-button
          >
          <el-button
            v-else
            type="text"
            @click="classifyClick(scope.row, 'wechat')"
            >-</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="mobileArea"
        label="手机归属地"
        width="120"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="lessonName"
        label="报名科目"
        width="120"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.lessonName"
            type="text"
            @click="calssOpenClick(scope.row)"
            >{{ scope.row.lessonName }}</el-button
          >
          <el-button v-else type="text" @click="calssOpenClick(scope.row)"
            >-</el-button
          >
          <!-- @click="classifyClick(scope.row, 'name')" -->
        </template>
      </el-table-column>
      <el-table-column
        prop="customerCode"
        label="客户编号"
        width="140"
        align="center"
      >
        <template slot-scope="scope">
          <div class="customerCode_class">
            <div>{{ scope.row.customerCode }}</div>
            <el-button
              type="text"
              size="small"
              v-if="roleMenu == 'SUPER_MANAGER' || roleMenu == 'WECHAT_MANAGER'"
              @click="queryMobile(scope.row)"
              >查看</el-button
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="nickName"
        label="微信昵称"
        width="100"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="passDate"
        label="通过时间"
        width="170"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="childAge"
        label="孩子年龄"
        width="85"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="clientCode"
        label="机器昵称"
        width="140"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.clientCode }}</span>
          <span v-if="scope.row.clientNickName">
            ( {{ scope.row.clientNickName }} )
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="roleMenu !== 'WECHAT'"
        prop="operator"
        label="跟进运营"
        width="100"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="status" label="状态" width="170" align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.statusType" size="small">{{
            scope.row.statuslabel
          }}</el-tag>
          <el-button
            type="text"
            v-if="scope.row.status === 'SUBMITTED'"
            @click="refreshStatus(scope.row)"
            >刷新</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="courseOpenTime"
        label="开课时间"
        width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="gmtCreated"
        label="添加时间"
        width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="customerTag"
        label="客户分类"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.customerTag"
            type="text"
            @click="classifyClick(scope.row, 'tag')"
            >{{ scope.row.customerTag }}</el-button
          >
          <el-button v-else type="text" @click="classifyClick(scope.row, 'tag')"
            >-</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="wechatCustomerRecordingLabelList"
        label="质检标签"
        width="120"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-tag
            v-for="tag in scope.row.wechatCustomerRecordingLabelList"
            :key="tag.id"
            size="mini"
            class="tags_td"
            >{{ tag.label }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop
        label="操作"
        width="400"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" v-if="scope.row.talking" disabled
            >查看问卷</el-button
          >
          <el-button type="text" size="small" v-if="scope.row.talking" disabled
            >播放录音</el-button
          >
          <el-button
            type="text"
            size="small"
            v-if="!scope.row.talking"
            @click="questionClick(scope.row)"
            :disabled="
              !(
                scope.row.callerUuid &&
                scope.row.source === 'CLIENT_TALKING_WECHAT'
              )
            "
            >查看问卷</el-button
          >
          <el-button
            type="text"
            size="small"
            v-if="!scope.row.talking"
            @click="playRecord(scope.row)"
            :disabled="!scope.row.callerUuid"
            >播放录音</el-button
          >
          <el-button
            type="text"
            size="small"
            v-if="roleMenu === 'SUPER_MANAGER'"
            @click="dialogueTranslate(scope.row)"
            :disabled="!scope.row.callerUuid"
            >对话翻译</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="calssOpenClick(scope.row)"
            :disabled="scope.row.talking"
            >开课时间设置</el-button
          >
          <!-- <el-button type="text" size="small" @click="tagsEdit(scope.row)" :disabled="scope.row.talking">修改标签</el-button> -->
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              其它操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-button
                type="text"
                size="small"
                v-clipboard:copy="scope.row.requestId"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                >复制requestId</el-button
              >
              <el-button
                type="text"
                size="small"
                v-clipboard:copy="scope.row.wechatId"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                :disabled="!scope.row.wechatId"
                >复制wechatId</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="classifyClick(scope.row, 'status')"
                v-if="
                  roleMenu === 'SUPER_MANAGER' || roleMenu === 'WECHAT_MANAGER'
                "
                >修改状态</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="wechatModify(scope.row)"
                :disabled="scope.row.talking"
                >修改信息</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="customerLog(scope.row)"
                :disabled="scope.row.talking"
                >客户跟进</el-button
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <my-pagination
      totalShow
      onePageNoShow
      pageSizeShow
      :tableList="wechatTableData"
      @inquireTotal="inquire(null, 'total')"
      @inquireOne="inquire(1)"
      @currentPageNo="currentPageNo"
      @currentPageSize="currentPageSize"
      class="el-pagination"
    ></my-pagination>
    <!-- 查看问卷 -->
    <el-dialog
      width="900px"
      title="查看问卷"
      :visible.sync="questionShow"
      class="historical"
      @close="questionClose"
    >
      <div class="changeClass formChange" v-show="historicalShow">
        <el-form label-position="right" label-width="150px">
          <el-form-item label="客户分类：" class="flagImg">
            <el-radio-group v-model="disabledFlagList">
              <el-radio
                v-for="item in flagsFilter"
                :key="item.id"
                :label="item.id"
                disabled
              >
                <img :src="item.src" width="18px" />
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-for="item in sipQuestionList"
            :key="item.id"
            :label="item.question"
          >
            <el-radio-group
              v-if="item.questionType == 'RADIO'"
              v-model="options[item.id]"
            >
              <el-radio
                v-for="muen in item.optionList"
                :key="muen.id"
                :label="muen.id"
                disabled
                >{{ muen.option }}</el-radio
              >
            </el-radio-group>
            <el-input
              v-if="item.questionType == 'INPUT'"
              v-model="options[item.id]"
              :placeholder="'请输入' + item.question"
              disabled
            ></el-input>
            <el-input
              v-if="item.questionType == 'DISTRICT_SELECTOR'"
              v-model="options[item.id]"
              :placeholder="'请输入' + item.question"
              class="question_input"
              disabled
            ></el-input>
            <el-input
              v-if="item.questionType == 'CAR_BRAND_SELECTOR'"
              v-model="options[item.id]"
              :placeholder="'请输入' + item.question"
              class="question_input"
              disabled
            ></el-input>
            <el-input
              v-if="item.questionType == 'TEXT'"
              v-model="options[item.id]"
              :rows="8"
              type="textarea"
              resize="none"
              ref="remark"
              :placeholder="'请输入' + item.question"
              disabled
            ></el-input>
            <el-checkbox-group
              v-if="item.questionType == 'CHECKBOX'"
              v-model="optionsCheckList"
            >
              <el-checkbox
                v-for="muen in item.optionList"
                :key="muen.id"
                :label="muen.id"
                disabled
                >{{ muen.option }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 上传文件 -->
    <el-dialog
      :title="uploadDataTitle"
      :visible.sync="uploadFilesShow"
      custom-class="el-modifyLabelShow"
      width="450px"
      @close="uploadFilesClose"
    >
      <template slot="title">
        <i
          class="el-icon-circle-plus-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">{{ uploadDataTitle }}</span>
      </template>
      <div style="width: 360px; margin: 0 auto">
        <el-upload
          class="upload-demo"
          drag
          :data="addUploadData"
          :action="addUrl"
          :on-success="successNumberUpload"
          :on-error="errorNumberUpload"
          ref="addUpload"
          :auto-upload="false"
          :file-list="fileList"
          :with-credentials="cookie"
          :limit="1"
          :on-exceed="handleExceed"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
        </el-upload>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <el-button type="primary" @click="addLabelFile_submit()"
            >提 交</el-button
          >
          <el-button @click="addLabelFile_cancel">取 消</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 播放录音 -->
    <el-dialog
      class="customDialog"
      title="质检录音"
      :visible.sync="playRecordShow"
      width="450px"
      @close="closePlayRecordShow"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">质检录音</span>
      </template>
      <el-form label-position="left" label-width="60px">
        <el-form-item label="录音：">
          <audio
            ref="audio"
            :src="currentSrc"
            autoplay="autoplay"
            controlslist="nodownload"
            controls="controls"
          ></audio>
        </el-form-item>
        <el-form-item label="标签：">
          <el-checkbox-group v-model="recordCheckList">
            <el-checkbox
              v-for="item in recordTagList"
              :key="item.id"
              :label="item.label"
              >{{ item.label }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            v-model="recordRemark"
            type="textarea"
            :rows="4"
            placeholder="请输入备注"
            clearable
            style="width: 340px"
          ></el-input>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <el-button key="back" @click="playRecordShow = false">取消</el-button>
          <el-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="recordQuality_submit"
            >确定</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!-- 编辑标签 -->
    <tag-operation
      ref="tagOperation"
      :customerId="wechatCustomerId"
      :customerData="wechatCustomerData"
      :callerUuid="callerUuid"
      @submit="updateWechatLabel"
      @cancel="updateWechatLabel_cancel"
    ></tag-operation>
    <!-- 修改 -->
    <el-dialog
      title="修改"
      :visible.sync="visibleWechatModfity"
      @close="closeWechatModfity"
      width="750px"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">修改</span>
      </template>
      <el-form :model="wechatModfityForm" label-position="left" :inline="true">
        <el-form-item label="客户编号：" label-width="100px">
          <el-input
            v-model="wechatModfityForm.customerCode"
            disabled
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="批次编号：" label-width="100px">
          <el-input
            v-model="wechatModfityForm.batchNumber"
            placeholder="请输入批次编号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="客户分类：" label-width="100px">
          <el-select
            v-model="wechatModfityForm.customerTag"
            clearable
            placeholder="请选择客户分类"
          >
            <el-option
              v-for="(item, index) in customerTagList"
              :key="index"
              :label="item.label + ' ( ' + item.labelRemark + ' )'"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报名科目：" label-width="100px">
          <el-select
            v-model="wechatModfityForm.lessonName"
            clearable
            placeholder="请选择报名科目"
            multiple
            collapse-tags
            filterable
          >
            <el-option
              v-for="(item, index) in lessonNameList"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推荐机构：" label-width="100px">
          <el-input
            v-model="wechatModfityForm.projectName"
            placeholder="请输入推荐机构"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="孩子年龄：" label-width="100px">
          <el-input
            v-model="wechatModfityForm.childAge"
            placeholder="请输入孩子年龄"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="孩子年级：" label-width="100px">
          <el-input
            v-model="wechatModfityForm.childGrade"
            placeholder="请输入孩子年级"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="家长姓氏：" label-width="100px">
          <el-input
            v-model="wechatModfityForm.parentName"
            placeholder="请输入家长姓氏"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="孩子姓名：" label-width="100px">
          <el-input
            v-model="wechatModfityForm.childName"
            placeholder="请输入孩子姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="机器昵称：" label-width="100px">
          <el-select
            v-model="wechatModfityForm.clientCode"
            clearable
            @change="clientNickNameChange"
            placeholder="请选择机器昵称"
            filterable
            disabled
          >
            <el-option
              v-for="item in wechatClientList"
              :key="item.id"
              :label="item.codeOrName"
              :value="item.clientCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跟进运营：" label-width="100px">
          <el-input
            v-model="wechatModfityForm.operator"
            placeholder="请输入跟进运营"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="微信昵称：" label-width="100px">
          <el-input
            v-model="wechatModfityForm.nickName"
            placeholder="请输入微信昵称"
            disabled
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="备注：" label-width="100px">
          <el-input
            v-model="wechatModfityForm.remark"
            type="textarea"
            :rows="4"
            placeholder="请输入备注"
            clearable
            style="width: 550px"
          ></el-input>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <el-button key="back" @click="visibleWechatModfity = false"
            >取消</el-button
          >
          <el-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="wechatModfityOk"
            >确定</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!-- 客户跟进 -->
    <el-drawer
      :visible.sync="drawerCustomerLog"
      :with-header="false"
      class="drawerCustomerLog_style"
    >
      <div class="drawerCustomerLog_title">
        <span style="font-weight: bold; line-height: 30px">客户跟进</span>
        <el-button
          type="primary"
          size="small"
          @click="addWechatCustomerLogClick"
          >新增</el-button
        >
      </div>
      <div class="drawer_time_line">
        <el-timeline>
          <el-timeline-item
            v-for="item in wechatCustomerLogList"
            :key="item.id"
            :timestamp="item.gmtCreatedTitle"
            placement="top"
          >
            <el-card>
              <h4>{{ item.content }}</h4>
              <p>{{ item.operator }} 提交于 {{ item.gmtCreated }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-drawer>
    <!-- 新增客户跟进 -->
    <el-dialog
      title="新增客户跟进"
      :visible.sync="addCustomerLogShow"
      custom-class="el-modifyLabelShow"
      width="450px"
      @close="addCustomerLogClose"
    >
      <template slot="title">
        <i
          class="el-icon-circle-plus-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">新增客户跟进</span>
      </template>
      <el-form :model="addCustomerLogForm" label-position="left">
        <el-form-item label label-width="10px">
          <el-input
            v-model="addCustomerLogForm.content"
            type="textarea"
            :rows="8"
            placeholder="请输入客户跟进内容"
            clearable
          ></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="addCustomerLog_submit()"
            >提 交</el-button
          >
          <el-button @click="addCustomerLogShow = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 标签检索 -->
    <tag-retrieval
      ref="tagRetrieval"
      :tagList="tagsLabelData"
      @submit="tagRetrieval_submit"
    >
    </tag-retrieval>
    <!-- 分类修改 -->
    <el-dialog
      :title="classifytitle"
      :visible.sync="classifyShow"
      custom-class="el-modifyLabelShow"
      width="450px"
      @close="classifyClose"
    >
      <el-form label-position="left">
        <el-form-item
          label="客户分类："
          label-width="100px"
          v-if="classifytitle === '客户分类'"
          required
        >
          <el-select
            v-model="wechatModfityForm.customerTag"
            clearable
            placeholder="请选择客户分类"
          >
            <el-option
              v-for="(item, index) in customerTagList"
              :key="index"
              :label="item.label + ' ( ' + item.labelRemark + ' )'"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报名科目："
          label-width="100px"
          v-if="classifytitle === '报名科目'"
        >
          <el-select
            v-model="wechatModfityForm.lessonName"
            clearable
            placeholder="请选择报名科目"
            multiple
            collapse-tags
            filterable
          >
            <el-option
              v-for="(item, index) in lessonNameList"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="转粉情况："
          label-width="100px"
          v-if="classifytitle === '修改标签'"
        >
          <el-select
            v-model="wechatModfityForm.wecahtLabel"
            clearable
            placeholder="请选择转粉情况"
            multiple
            collapse-tags
            filterable
          >
            <el-option
              v-for="(item, index) in wechatLabelList_location"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="状态："
          label-width="100px"
          v-if="classifytitle === '修改状态'"
        >
          <el-select
            v-model="statusModfity"
            clearable
            placeholder="请选择报状态"
            filterable
          >
            <el-option
              v-for="item in wechatStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="classify_submit()">提 交</el-button>
          <el-button @click="classifyShow = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 批量设置标签 -->
    <el-dialog
      title="批量设置标签"
      :visible.sync="batchLabelSetShow"
      custom-class="el-modifyLabelShow"
      width="450px"
      @close="batchLabelSetClose"
    >
      <el-input
        v-model="batchLabelMobiles"
        type="textarea"
        :rows="16"
        placeholder="请输入手机号"
        clearable
      ></el-input>
      <div style="color: #e6a23c">请输入手机号，每行一个，最多输入200个。</div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <el-button type="primary" @click="batchLabel_submit()"
            >提交</el-button
          >
          <el-button @click="batchLabelSetShow = false">取消</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 批量设置标签列表 -->
    <el-dialog
      title="批量设置标签列表"
      :visible.sync="batchLabelTableShow"
      custom-class="el-modifyLabelShow"
      width="750px"
      @close="batchLabelTableClose"
    >
      <el-tabs v-model="activeNameBatchLabel">
        <el-tab-pane label="命中" name="first">
          <!-- 列表 -->
          <el-table
            :data="
              batchLabelTableData.slice(
                (this.batchLabelPageNo - 1) * 10,
                (this.batchLabelPageNo - 1) * 10 + 10
              )
            "
            stripe
            style="width: 100%"
            border
          >
            <el-table-column
              type="index"
              prop
              label="编号"
              width="60"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="batchNumber"
              label="批次编号"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="sourceLabel"
              label="来源"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="customerCode"
              label="客户编号"
              align="center"
            ></el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="batchLabelCurrentPageNo"
            :current-page.sync="batchLabelPageNo"
            :page-size="10"
            :pager-count="5"
            layout="total, prev, pager, next, jumper"
            :total="batchLabelTableData.length"
            style="width: 100%"
          ></el-pagination>
          <div>
            <div style="display: inline-block">
              客户分类：
              <el-select
                v-model="wechatModfityForm.customerTag"
                clearable
                placeholder="请选择客户分类"
              >
                <el-option
                  v-for="(item, index) in customerTagList"
                  :key="index"
                  :label="item.label + ' ( ' + item.labelRemark + ' )'"
                  :value="item.label"
                ></el-option>
              </el-select>
            </div>
            <div style="display: inline-block; margin-left: 20px">
              报名科目：
              <el-select
                v-model="wechatModfityForm.lessonName"
                clearable
                placeholder="请选择报名科目"
                multiple
                collapse-tags
                filterable
              >
                <el-option
                  v-for="(item, index) in lessonNameList"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="未查询到" name="second">
          <el-input
            v-model="notFoundMobiles"
            type="textarea"
            :rows="16"
            placeholder="未查询到手机号"
            readonly
          ></el-input>
        </el-tab-pane>
      </el-tabs>
      <template slot="footer">
        <div style="display: flex; justify-content: center; width: 100%">
          <el-button type="primary" @click="batchLabelTable_submit()"
            >提交</el-button
          >
          <el-button @click="batchLabelTableShow = false">取消</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 查看客户编号 -->
    <el-dialog
      title="查看客户编号"
      :visible.sync="seeCustomerCodeShow"
      width="400px"
      @close="seeCustomerCodeClose"
      class="seeCustomerCode-class"
    >
      <template slot="title">
        <i
          class="el-icon-data-analysis dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">查看客户编号</span>
      </template>
      <el-form label-position="right">
        <el-form-item label="客户号码：" label-width="110px">
          <el-input
            placeholder="请输入内容"
            v-model="customerCodeNumber"
            id="input"
            style="width: 100%"
          >
            <template slot="append">
              <i class="el-icon-document icon"></i>
            </template>
          </el-input>
          <el-tooltip placement="bottom">
            <div slot="content">复制</div>
            <a
              href="javascript:void(0)"
              v-clipboard:copy="customerCodeNumber"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              class="copy"
            ></a>
          </el-tooltip>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 开课时间列表 -->
    <el-dialog
      title="开课时间列表"
      :visible.sync="classOpenShow"
      @close="classOpenClose"
      width="750px"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">开课时间列表</span>
      </template>
      <el-button type="primary" @click="addPartOrder">新增</el-button>
      <el-table
        :data="classOpenList"
        stripe
        style="width: 100%"
        border
        size="small"
      >
        <el-table-column
          prop="lessonName"
          label="科目"
          width
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="payTime"
          label="支付时间"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="courseOpenTime"
          label="开课时间"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column prop label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="calssOpenEdit(scope.row)"
              >更新</el-button
            >
            <el-button
              type="text"
              @click="cancelClassesClick(scope.row)"
              style="color: #f56c6c"
              :disabled="!scope.row.deletable"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 设置开课时间 -->
    <el-dialog
      title="设置开课时间"
      :visible.sync="classOpenModfityShow"
      @close="classOpenModfityClose"
      width="450px"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">{{ classOpenName }}</span>
      </template>
      <el-form label-position="right" label-width="110px">
        <el-form-item label="科目：" required>
          <el-select
            v-model="lessonNameClassOpen"
            placeholder="请选择报名科目"
            filterable
            v-if="classOpenName === '新增开课时间'"
            @change="selectChange"
          >
            <el-option
              v-for="(item, index) in lessonNameList1"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <span v-else>{{ lessonNameClassOpen }}</span>
        </el-form-item>
        <el-form-item label="支付时间：" required>
          <el-date-picker
            v-model="buyTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择支付时间"
            :disabled="!payTime && classOpenName === '修改开课时间'"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开课时间：" required v-if="classTime">
          <el-date-picker
            v-model="skuOpenCourseTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择开课时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="业绩上报：" v-if="uploadShow">
          <template slot="label">
            业绩上报
            <el-tooltip class="item" placement="top-start">
              <div slot="content">选中后, 将自动更新业绩看板成单数据</div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-checkbox v-model="modifyOrderCount"></el-checkbox>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <el-button key="back" @click="classOpenModfityShow = false"
            >取消</el-button
          >
          <el-button
            key="submit"
            type="primary"
            @click="classOpenModfity_submit"
            >确定</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!-- 上报次数 -->
    <el-dialog
      title="业绩"
      :visible.sync="newNumShow"
      @close="newNumClose"
      width="450px"
    >
      <div>
        {{ lessonNameClassOpen1 }}订单量变更:{{ oldNum }}——>{{ newNum }}
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <el-button key="back" @click="newNumShow = false">关闭</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 删除课程 -->
    <el-dialog
      title="确定要删除吗?"
      :visible.sync="delClassShow"
      @close="delClassClose"
      width="450px"
    >
      <div v-if="uploadShow">
        业绩上报
        <el-tooltip class="item" placement="top-start">
          <div slot="content">选中后, 将自动更新业绩看板成单数据</div>
          <i class="el-icon-question icon-color"></i> </el-tooltip
        >：
        <el-checkbox v-model="modifyOrderCount"></el-checkbox>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <el-button key="back" @click="delClassShow = false">取消</el-button>
          <el-button key="submit" type="primary" @click="delClass_submit"
            >确定</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!-- 对话翻译 -->
    <dialogue-translation
      ref="dialogueTranslation"
      :title="dialogueTranslateTitle"
      type="translation"
      :dialogueRound="dialogueRound"
      :currentSrc="currentSrc"
      :dialogueData="dialogueList"
      :videoType="videoType"
    >
    </dialogue-translation>
  </div>
</template>
<script>
import tagOperation from "../pages/tagOperation.vue";
import tagRetrieval from "../pages/tagRetrieval.vue";
import myPagination from "../pages/myPagination.vue";
import dialogueTranslation from "../pages/dialogueTranslation";

export default {
  name: "wechat",
  components: {
    tagOperation,
    tagRetrieval,
    myPagination,
    dialogueTranslation,
  },
  props: ["roleMenu"],
  data() {
    return {
      dialogueTranslateTitle: "",
      dialogueRound: false,
      currentSrc: null, //录音url
      dialogueList: [],
      callUuid: "",
      videoType: "videoType",
      //
      productNameList: [],
      productName: "", //产品名称查询
      modifyOrderCount: true,
      uploadShow: true,
      classTime: true,
      payTime: false,
      queryAllClient: false,
      wechatLabelList: [],
      wechatLabelList_location: [
        {
          id: 1,
          label: "已转粉",
        },
      ],
      interfaceType: false,
      classOpenName: "",
      classOpenShow: false,
      classOpenList: [],
      classOpenModfityShow: false,
      lessonNameClassOpen: "",
      buyTime: "",
      skuOpenCourseTime: "",
      callerUuid: "",
      statusModfity: "",
      seeCustomerCodeShow: false,
      customerCodeNumber: "",
      wechatServerList: [
        {
          server: "t1.innerchic.cn",
        },
        {
          server: "t9.innerchic.cn",
        },
      ],
      recordTagList: [],
      recordCheckList: [],
      recordRemark: "",
      sourceList: [
        {
          label: "挂机微信",
          value: "HANGUP_WECHAT",
        },
        {
          label: "坐席沟通",
          value: "CLIENT_TALKING_WECHAT",
        },
        {
          label: "挂机短信点击",
          value: "HANGUP_SMS_CLICK_WECHAT",
        },
        {
          label: "手动加粉",
          value: "MANUEL_WECHAT",
        },
        {
          label: "盲加家长粉",
          value: "FLAG_WECHAT",
        },
        {
          label: "AI机器人",
          value: "ROBOT_WECHAT",
        },
        {
          label: "YX加粉",
          value: "UN_FLAG_WECHAT",
        },
        {
          label: "二次加粉",
          value: "NOTIFY_WECHAT",
        },
        {
          label: "短信回调加粉",
          value: "SMS_CLICK_WECHAT",
        },
      ],
      customerCodeList: [],
      notFoundMobiles: "",
      activeNameBatchLabel: "first",
      batchLabelTableData: [],
      batchLabelPageNo: 1,
      batchLabelTableShow: false,
      batchLabelMobiles: "",
      batchLabelSetShow: false,
      classifytitle: "",
      classifyShow: false,
      uploadDataTitle: "",
      derivedDis: true,
      wechatClientList: [],
      tagsLabel: "",
      tagsLabelData: null,
      wechatCustomerLogList: [],
      addCustomerLogForm: {
        wechatCustomerId: "",
        content: "",
      },
      addCustomerLogShow: false,
      drawerCustomerLog: false,
      loading: false,
      visibleWechatModfity: false,
      wechatModfityForm: {
        id: "",
        customerCode: "",
        batchNumber: "",
        projectName: "",
        childAge: "",
        childGrade: "",
        parentName: "",
        childName: "",
        clientNickName: "",
        clientCode: "",
        operator: "",
        nickName: "",
        remark: "",
        lessonName: [],
        wecahtLabel: [],
        customerTag: "",
      },
      wechatCustomerData: [],
      wechatCustomerId: "",
      uploadFilesShow: false,
      addUploadData: {},
      addUrl: this.$constants.baseURL + "/wechat/uploadCustomerInfo",
      fileList: [],
      cookie: true,
      playRecordShow: false,
      playFlag: false,
      currentSrc: "",
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "近三个月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let sm = start.getMonth() - 2;
              start.setMonth(sm);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      courseOpenPickerOptions: {
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      condition: {
        source: "",
        operator: "",
        batchNumber: "",
        customerCodeOrPhone: "",
        status: ["PASSED"],
        nickName: "",
        // clientNickName: [],
        clientNickName: "",
        gmtCreatedDate: [],
        courseOpenTimeStartDate: [],
        customerTag: "",
        lessonName: [],
        recordingLabelList: [],
      },
      wechatClientGroupIdServer: "",
      addWechatClientGroupId: "",
      wechatClientGroupList: [],
      customerTagList: [
        {
          label: "w ( 没回复过 )",
          value: "w",
        },
        {
          label: "L ( 已沟通 )",
          value: "L",
        },
        {
          label: "P ( 用户支付 )",
          value: "P",
        },
        {
          label: "G ( 跟进中 )",
          value: "G",
        },
        {
          label: "S ( 被删 )",
          value: "S",
        },
      ],
      lessonNameList: [],
      lessonNameList1: [],
      wechatTableData: [],
      loadingTable: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      questionShow: false,
      historicalShow: false,
      options: {},
      disabledFlagList: "",
      sipQuestionList: [],
      optionsCheckList: [],
      flagsFilter: [
        {
          src: require("@/assets/img/flag_1.png"),
          label: "成单",
          id: "1",
        },
        {
          src: require("@/assets/img/flag_2.png"),
          label: "回拨",
          id: "2",
        },
        {
          src: require("@/assets/img/flag_3.png"),
          label: "非目标客群",
          id: "3",
        },
        {
          src: require("@/assets/img/flag_4.png"),
          label: "无意向",
          id: "4",
        },
      ],
      wechatStatusList: [
        // {
        //   value: "CREATING",
        //   label: "请求发送中"
        // },
        {
          value: "NOT_FOUND",
          label: "未查询到好友",
        },
        {
          value: "SUBMITTED",
          label: "已提交好友申请",
        },
        {
          value: "SUBMIT_FAILURE",
          label: "好友申请异常",
        },
        {
          value: "ADDEDFRIEND",
          label: "已经是好友",
        },
        {
          value: "PASSED",
          label: "通过好友申请",
        },
        // {
        //   value: "REJECTED",
        //   label: "拒绝好友申请"
        // },
        {
          value: "EXPIRED",
          label: "好友申请已过期",
        },
        {
          value: "RELOGIN",
          label: "登录失效",
        },
        {
          value: "CLIENT_OFFLINE",
          label: "手机离线",
        },
        {
          value: "NOTLOGGEDIN",
          label: "未登录",
        },
        {
          value: "NO_REQUEST",
          label: "未加微信",
        },
        {
          value: "CUSTOMER_ACCOUNT_IS_ILLEGAL",
          label: "对方账号异常",
        },
        {
          value: "IN_QUEUE",
          label: "排队中",
        },
        {
          value: "DISCARDED",
          label: "已丢弃",
        },
      ],
      unifiedLoading: null,
      server: "",
      orderId: "",
      newNum: "",
      oldNum: "",
      newNumShow: false,
      delClassShow: false,
      delId: undefined,
      delWechatCustomerId: undefined,
      lessonNameClassOpen1: "",
    };
  },
  computed: {
    // 禁用上一页
    disabledPrevBtn() {
      if (this.pageNo === 1) {
        return true;
      }
      return false;
    },
    // 禁用下一页
    disabledNextBtn() {
      if (this.wechatTableData.length < 10) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    document.title = "惠销平台管理系统-微信粉丝列表";
    this.theWeek();
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.condition.gmtCreatedDate = [start, end];
    this.inquire(1);
    this.getRecordTagList();
    this.getLessomTagList();
    this.getWechatTagList();
    this.getCustomerTagList();
    console.log(this.roleMenu);
  },
  methods: {
    // 科目下拉框改变
    selectChange(val) {
      let newArr = this.lessonNameList1.filter((el) => el.label === val);
      if (newArr[0].wechatLabelProperty) {
        this.classTime = newArr[0].wechatLabelProperty.needOpenCourseTime;
      } else {
        this.classTime = false;
      }
      if (newArr[0].wechatLabelProperty) {
        this.payTime = newArr[0].wechatLabelProperty.autoSync;
      } else {
        this.payTime = false;
      }
      if (newArr[0].wechatLabelProperty) {
        if (newArr[0].wechatLabelProperty.autoUploadOrder) {
          this.uploadShow = false;
          this.modifyOrderCount = false;
        } else {
          this.uploadShow = true;
          this.modifyOrderCount = true;
        }
      } else {
        this.uploadShow = true;
        this.modifyOrderCount = true;
      }
      this.lessonNameClassOpen1 = val;
    },
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
    // 点击新增开课设置
    addPartOrder() {
      this.classOpenName = "新增开课时间";
      let date = new Date();
      this.buyTime = this.$common.transformTime(date);
      this.classOpenModfityShow = true;
      this.getLessomTagList();
      this.lessonNameClassOpen = this.lessonNameList1[0].label;
      if (this.lessonNameList1[0].wechatLabelProperty.autoUploadOrder) {
        this.uploadShow = false;
        this.modifyOrderCount = false;
      } else {
        this.uploadShow = true;
        this.modifyOrderCount = true;
      }
      if (this.lessonNameList1[0].wechatLabelProperty.needOpenCourseTime) {
        this.classTime = true;
      } else {
        this.classTime = false;
      }
      this.lessonNameClassOpen1 = this.lessonNameClassOpen;
    },
    // 点击设置开课时间修改
    calssOpenClick(val) {
      this.classOpenShow = true;
      this.addPartOrder();
      this.wechatCustomerId = val.id;
      this.getClassOpenList(val.id);
      this.getLessomTagList();
    },
    // 关闭设置开课时间列表
    classOpenClose() {
      this.classOpenList = [];
      this.inquire();
    },
    // 点击删除开课时间
    cancelClassesClick(val) {
      this.delId = val.id;
      this.delWechatCustomerId = val.wechatCustomerId;
      this.delClassShow = true;
      this.lessonNameClassOpen1 = val.lessonName;
      let newArr = this.lessonNameList.filter(
        (el) => el.label === val.lessonName
      );
      if (newArr[0].wechatLabelProperty.autoUploadOrder) {
        this.uploadShow = false;
      } else {
        this.uploadShow = true;
      }
    },
    delClassClose() {},
    delClass_submit() {
      let params = {
        id: this.delId,
        wechatCustomerId: this.delWechatCustomerId,
        modifyOrderCount: this.modifyOrderCount,
      };
      this.$service("/thirdPart/cancelClasses", params).then((res) => {
        if (res.result == 200) {
          this.$message({ message: "删除成功", type: "success" });
          this.getClassOpenList(this.wechatCustomerId);
          this.delClassShow = false;
          this.newNum = res.data.newCount;
          this.oldNum = res.data.originalCount;
          this.newNumShow = true;
        }
      });
    },
    // 点击删除开课时间
    // cancelClassesClick(val) {
    //   this.$confirm(
    //     "删除【" + val.lessonName + "】开课时间, 是否继续?",
    //     "提示",
    //     {
    //       confirmButtonText: "确定",
    //       cancelButtonText: "取消",
    //       cancelButtonClass: "btn-custom-cancel",
    //       type: "warning",
    //     }
    //   )
    //     .then(() => {
    //       let params = {
    //         id: val.id,
    //         wechatCustomerId: val.wechatCustomerId,
    //       };
    //       this.$service("/thirdPart/cancelClasses", params).then((res) => {
    //         if (res.result == 200) {
    //           this.$message({ message: "删除成功", type: "success" });
    //           this.getClassOpenList(this.wechatCustomerId);
    //         }
    //       });
    //     })
    //     .catch(() => {});
    // },
    // 点击设置开课时间修改
    calssOpenEdit(val) {
      this.getLessomTagList();
      this.classOpenName = "修改开课时间";
      this.classOpenModfityShow = true;
      this.lessonNameClassOpen = val.lessonName;
      this.buyTime = val.payTime;
      this.skuOpenCourseTime = val.courseOpenTime;
      this.orderId = val.id;
      this.payTime = val.deletable;
      this.uploadShow = false;
      let newArr = this.lessonNameList.filter(
        (el) => el.label === val.lessonName
      );
      if (newArr[0].wechatLabelProperty) {
        this.classTime = newArr[0].wechatLabelProperty.needOpenCourseTime;
      } else {
        this.classTime = false;
      }
    },
    // 确定设置开课时间
    classOpenModfity_submit() {
      let data = {
        lessonName: this.lessonNameClassOpen,
        payTime: this.buyTime,
        courseOpenTime: this.skuOpenCourseTime,
      };
      if (!data.lessonName) {
        this.$message.warning("科目不能为空");
        return;
      }
      if (!data.payTime) {
        this.$message.warning("支付时间不能为空");
        return;
      }
      if (this.classTime) {
        if (!data.courseOpenTime) {
          this.$message.warning("开课时间不能为空");
          return;
        }
      }
      if (this.classOpenName === "新增开课时间") {
        data.wechatCustomerId = this.wechatCustomerId;
        data.modifyOrderCount = this.modifyOrderCount;
        this.$service("/thirdPart/addThirdPartOrder", data).then((res) => {
          if (res.result === 200) {
            this.classOpenModfityShow = false;
            this.$message.success("新增成功");
            this.getClassOpenList(this.wechatCustomerId);
            this.newNum = res.data.newCount;
            this.oldNum = res.data.originalCount;
            this.newNumShow = true;
          }
        });
      }
      if (this.classOpenName === "修改开课时间") {
        data.id = this.orderId;
        this.$service("/thirdPart/update", data).then((res) => {
          if (res.result === 200) {
            this.classOpenModfityShow = false;
            this.$message.success("更新成功");
            this.getClassOpenList(this.wechatCustomerId);
          }
        });
      }
    },
    // 关闭业绩变化
    newNumClose() {
      this.oldNum = "";
      this.newNum = "";
    },
    // 关闭设置开课时间
    classOpenModfityClose() {
      this.lessonNameClassOpen = "";
      this.buyTime = "";
      this.skuOpenCourseTime = "";
      this.classTime = true;
      this.payTime = false;
      // this.lessonNameList1 = [];
    },
    // 查询开课时间列表
    getClassOpenList(wechatCustomerId) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        wechatCustomerId: wechatCustomerId,
      };
      this.$http("/thirdPart/orderList", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            let list = res.data.records;
            let oldTime = new Date("2001-1-1 00:00:00").getTime();
            list.forEach((item) => {
              let payTime = new Date(item.payTime).getTime();
              let courseOpenTime = new Date(item.courseOpenTime).getTime();
              if (payTime < oldTime) {
                item.payTime = "";
              }
              if (courseOpenTime < oldTime) {
                item.courseOpenTime = "";
              }
            });
            this.classOpenList = list;
          }
        }
      );
    },
    // 点击刷新状态
    refreshStatus(val) {
      this.$http("/wechat/refreshStatus", null, "get", null, {
        requestId: val.requestId,
      }).then((res) => {
        if (res.result === 200) {
          this.$message.success("刷新成功");
          this.inquire();
        }
      });
    },
    // 查看客户编号
    queryMobile(val) {
      this.seeCustomerCodeShow = true;
      // if (val.server) {
      //   let data = {
      //     callerUuid: val.callerUuid,
      //     customerCode: val.customerCode,
      //     loginName: val.userLoginName,
      //   };
      //   let path = "https://" + val.server + this.$constants.basePath;
      //   this.$http(
      //     "/sip/call/queryMobileWithCallerUuid",
      //     null,
      //     "get",
      //     path,
      //     data
      //   ).then((res) => {
      //     if (res.result == 200) {
      //       this.customerCodeNumber = res.data;
      //     }
      //   });
      //   return;
      // }
      let data = {
        customerCode: val.customerCode,
      };
      this.$http(
        "/sip/call/queryMobileWithCustomerCode",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result == 200) {
          this.customerCodeNumber = res.data;
        }
      });
    },
    // 关闭查看客户编号
    seeCustomerCodeClose() {
      this.customerCodeNumber = "";
    },
    // 复制成功
    onCopy(e) {
      this.$message({ message: "复制成功", type: "success" });
    },
    // 复制失败
    onError(e) {
      this.$message({ message: "复制失败", type: "error" });
    },
    // 机器分组获取焦点
    wechatClientGroupFocus(val, sign) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        server: val,
      };
      if (sign === "query") {
        this.addWechatClientGroupId = "";
      }
      this.getWechatClientGroupList(data);
    },
    // 查询机器分组
    getWechatClientGroupList(data) {
      this.$service(
        "/wechat/wechatClientGroupList",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.wechatClientGroupList = list;
        }
      });
    },
    // 提交批量设置标签
    batchLabel_submit() {
      this.wechatMolbilesinquire();
    },
    theMonth() {
      let end = new Date(),
        start = new Date();
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      start.setMilliseconds(0);
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      end.setMilliseconds(59);
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      start = this.$common.transformTime(start.valueOf());
      end = this.$common.transformTime(end.valueOf());
      let date = [start, end];
      return date;
    },
    // 查询微信粉丝批量设置标签列表
    wechatMolbilesinquire() {
      let batchLabelMobiles = "";
      let batchLabelMobilesList = this.batchLabelMobiles.split("\n");
      batchLabelMobilesList = batchLabelMobilesList.filter((item) => item);
      if (batchLabelMobilesList.length > 200) {
        this.$message.warning("最多输入200个手机号");
        return;
      }
      batchLabelMobiles = batchLabelMobilesList.join(",");
      let date = this.theMonth();
      let data = {
        pageNo: this.batchLabelPageNo || 1,
        pageSize: 10000,
        mobiles: batchLabelMobiles,
        gmtCreatedStart: date[0],
        gmtCreatedEnd: date[1],
      };
      if (!data.mobiles) {
        this.$message.warning("请输入手机号");
        return;
      }
      this.getWechatMolbiles(data);
    },
    // 查询微信粉丝批量设置标签列表
    getWechatMolbiles(data) {
      this.$http("/wechat/customerList", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            this.batchLabelTableShow = true;
            // this.batchLabelSetShow = false;
            let list = res.data.records;
            let newList = [];
            list.forEach((item) => {
              switch (item.source) {
                case "CLIENT_TALKING_WECHAT":
                  item.sourceLabel = "坐席沟通";
                  break;
                case "HANGUP_WECHAT":
                  item.sourceLabel = "挂机微信";
                  break;
                case "HANGUP_SMS_CLICK_WECHAT":
                  item.sourceLabel = "挂机短信点击";
                  break;
                case "MANUEL_WECHAT":
                  item.sourceLabel = "手动加粉";
                  break;
                case "FLAG_WECHAT":
                  item.sourceLabel = "盲加家长粉";
                  break;
                case "UN_FLAG_WECHAT":
                  item.sourceLabel = "YX加粉";
                  break;
                case "ROBOT_WECHAT":
                  item.sourceLabel = "AI机器人";
                  break;
                case "NOTIFY_WECHAT":
                  item.sourceLabel = "二次加粉";
                  break;
                case "SMS_CLICK_WECHAT":
                  item.sourceLabel = "短信回调加粉";
                  break;
              }
              newList.push(item.wechatAccount);
              this.customerCodeList.push(item.customerCode);
            });
            this.batchLabelTableData = list;
            let batchLabelMobilesList = this.batchLabelMobiles.split("\n");
            batchLabelMobilesList = batchLabelMobilesList.filter(
              (item) => item
            );
            let notFoundMobilesList = [];
            notFoundMobilesList = newList
              .concat(batchLabelMobilesList)
              .filter((v, i, arr) => {
                return arr.indexOf(v) === arr.lastIndexOf(v);
              });
            this.notFoundMobiles = notFoundMobilesList.join("\n");
          }
        }
      );
    },
    // 微信粉丝批量设置标签列表--翻页
    batchLabelCurrentPageNo(index) {
      this.batchLabelPageNo = index;
    },
    // 提交批量设置标签列表
    batchLabelTable_submit() {
      let date = this.theMonth();
      let data = {
        customerCodeList: this.customerCodeList,
        customerTag: this.wechatModfityForm.customerTag,
        lessonName: "",
        gmtCreatedStart: date[0],
        gmtCreatedEnd: date[1],
      };
      if (this.wechatModfityForm.lessonName) {
        data.lessonName = this.wechatModfityForm.lessonName.join(",");
      }
      if (data.customerTag && data.lessonName) {
        this.$service("/wechat/batchUpdateLabel", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("操作成功");
            this.batchLabelTableShow = false;
            this.batchLabelSetShow = false;
            this.inquire();
          }
        });
      } else {
        this.$message.warning("请选择客户分类和报名科目");
      }
    },
    // 关闭批量设置标签列表
    batchLabelTableClose() {
      this.batchLabelPageNo = 1;
      this.activeNameBatchLabel = "first";
      this.wechatModfityForm.customerTag = "";
      this.wechatModfityForm.lessonName = [];
      this.notFoundMobiles = "";
      this.batchLabelTableData = [];
      this.customerCodeList = [];
    },
    // 关闭批量设置标签
    batchLabelSetClose() {
      this.batchLabelMobiles = "";
    },
    // 点击清除检索标签
    clearTagList() {
      this.tagsLabelData = null;
      this.tagsLabel = "";
      this.inquire(1);
    },
    // 修改--机器昵称选择
    clientNickNameChange(val) {
      this.wechatClientList.forEach((item) => {
        if (item.clientCode === val) {
          this.wechatModfityForm.clientNickName = item.clientName;
        }
      });
    },
    // 选择机器获取焦点
    clientNickNameFocus() {
      this.getWechatClientList();
    },
    // 查询机器列表
    getWechatClientList() {
      let data = {
        codeOrName: "",
      };
      this.wechatClientList = [];
      this.$http("/wechat/wechatClientList", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            let list = res.data;
            list.forEach((item) => {
              item.codeOrName = item.clientCode;
              if (item.clientName) {
                item.codeOrName =
                  item.clientCode + "( " + item.clientName + " )";
              }
            });
            this.wechatClientList = list;
          }
        }
      );
    },
    // 点击标签检索
    tag_retrieval() {
      this.$refs.tagRetrieval.init();
    },
    // 确定标签检索
    tagRetrieval_submit(val) {
      this.tagsLabel = val.tags;
      this.tagsLabelData = val.wechatLabelList;
      this.inquire(1);
    },
    // 点击新增客户跟进
    addWechatCustomerLogClick() {
      this.addCustomerLogShow = true;
      this.addCustomerLogForm.wechatCustomerId = this.wechatCustomerId;
    },
    // 确定新增客户跟进
    addCustomerLog_submit() {
      this.$service(
        "/wechat/addWechatCustomerLog",
        this.addCustomerLogForm
      ).then((res) => {
        if (res.result === 200) {
          this.$message.success("新增成功");
          this.addCustomerLogShow = false;
          this.getWechatCustomerLogList();
        }
      });
    },
    // 关闭新增客户跟进
    addCustomerLogClose() {
      this.addCustomerLogForm = {
        wechatCustomerId: "",
        content: "",
      };
    },
    // 点击客户跟进
    customerLog(val) {
      this.drawerCustomerLog = true;
      this.wechatCustomerId = val.id;
      this.getWechatCustomerLogList();
    },
    // 查询客户跟进列表
    getWechatCustomerLogList() {
      let data = {
        wechatCustomerId: this.wechatCustomerId,
      };
      this.wechatCustomerLogList = [];
      this.$http("/wechat/wechatCustomerLogList", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            let list = res.data;
            list.forEach((item) => {
              item.gmtCreated = this.$common.transformTime2(item.gmtCreated);
              item.gmtCreatedTitle = item.gmtCreated.substring(0, 10);
            });
            this.wechatCustomerLogList = list;
          }
        }
      );
    },
    // 点击修改
    wechatModify(val) {
      this.visibleWechatModfity = true;
      this.wechatModfityForm = {
        id: val.id,
        customerCode: val.customerCode,
        batchNumber: val.batchNumber,
        projectName: val.projectName,
        childAge: val.childAge,
        childGrade: val.childGrade,
        parentName: val.parentName,
        childName: val.childName,
        clientNickName: val.clientNickName,
        clientCode: val.clientCode,
        operator: val.operator,
        nickName: val.nickName,
        remark: val.remark,
        customerTag: val.customerTag,
        lessonName: "",
      };
      if (val.lessonName) {
        this.wechatModfityForm.lessonName = val.lessonName.split(",");
      }
      this.getWechatClientList();
    },
    // 确定修改
    wechatModfityOk() {
      this.loading = true;
      let data = {
        id: this.wechatModfityForm.id,
        customerCode: this.wechatModfityForm.customerCode,
        batchNumber: this.wechatModfityForm.batchNumber,
        projectName: this.wechatModfityForm.projectName,
        childAge: this.wechatModfityForm.childAge,
        childGrade: this.wechatModfityForm.childGrade,
        parentName: this.wechatModfityForm.parentName,
        childName: this.wechatModfityForm.childName,
        clientNickName: this.wechatModfityForm.clientNickName,
        operator: this.wechatModfityForm.operator,
        remark: this.wechatModfityForm.remark,
        customerTag: this.wechatModfityForm.customerTag,
        lessonName: this.wechatModfityForm.lessonName.join(","),
      };
      this.$service("/wechat/updateCustomerInfo", data).then((res) => {
        this.loading = false;
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.visibleWechatModfity = false;
          this.inquire();
        }
        ((err) => {
          this.l;
        }).catchoading = false;
      });
    },
    // 关闭修改
    closeWechatModfity() {
      this.wechatModfityForm = {
        id: "",
        customerCode: "",
        batchNumber: "",
        projectName: "",
        childAge: "",
        childGrade: "",
        parentName: "",
        childName: "",
        clientNickName: "",
        clientCode: "",
        operator: "",
        nickName: "",
        remark: "",
        customerTag: "",
        lessonName: [],
      };
    },
    // 点击客户分类或报名科目
    classifyClick(val, key) {
      this.wechatModfityForm.id = val.id;
      if (key === "tag") {
        this.classifytitle = "客户分类";
        this.wechatModfityForm.customerTag = val.customerTag;
      }
      if (key === "name") {
        this.classifytitle = "报名科目";
        this.callerUuid = val.callerUuid;
        this.wechatModfityForm.lessonName = "";
        if (val.lessonName) {
          this.wechatModfityForm.lessonName = val.lessonName.split(",");
        }
      }
      if (key === "wechat") {
        this.classifytitle = "修改标签";
        this.callerUuid = val.callerUuid;
        this.wechatModfityForm.wecahtLabel = "";
        if (val.wechatCustomerLabel) {
          this.wechatModfityForm.wecahtLabel =
            val.wechatCustomerLabel.split(",");
        }
      }
      if (key === "status") {
        this.classifytitle = "修改状态";
        this.statusModfity = val.status;
        this.callerUuid = val.callerUuid;
      }
      this.classifyShow = true;
    },
    // 提交户分类或报名科目
    classify_submit() {
      let data = {
        id: this.wechatModfityForm.id,
      };
      if (this.classifytitle === "客户分类") {
        data.customerTag = this.wechatModfityForm.customerTag;
        if (!data.customerTag) {
          this.$message.warning("请选择客户分类");
          return;
        }
        this.$service("/wechat/updateCustomerTag", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.classifyShow = false;
            this.inquire();
          }
        });
      }
      if (this.classifytitle === "报名科目") {
        data.lessonName = "";
        if (this.wechatModfityForm.lessonName) {
          data.lessonName = this.wechatModfityForm.lessonName.join(",");
        }
        data.callerUuid = this.callerUuid;
        // if (!data.lessonName) {
        //   this.$message.warning("请选择报名科目");
        //   return
        // }
        this.$service("/wechat/updateLessonName", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.classifyShow = false;
            this.inquire();
          }
        });
      }
      if (this.classifytitle === "修改状态") {
        let param = {
          callerUuid: this.callerUuid,
          newStatus: this.statusModfity,
        };
        this.$service("/wechat/updateCustomerStatus", param).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.classifyShow = false;
            this.inquire();
          }
        });
      }
      if (this.classifytitle === "修改标签") {
        let param = {
          wechatCustomerId: this.wechatModfityForm.id,
          labelList: this.wechatModfityForm.wecahtLabel,
          category: "COMMON",
          callerUuid: this.callerUuid,
        };
        this.$service("/wechat/updateLabel", param)
          .then((res) => {
            if (res.result === 200) {
              this.$message.success("修改成功");
              this.classifyShow = false;
              this.inquire();
            }
          })
          .catch((err) => {});
      }
    },
    // 关闭户分类或报名科目
    classifyClose() {
      this.wechatModfityForm.customerTag = "";
      this.wechatModfityForm.lessonName = [];
      this.wechatModfityForm.id = "";
    },
    // 点击标签编辑
    tagsEdit(val) {
      this.wechatCustomerId = val.id;
      this.callerUuid = val.callerUuid;
      this.wechatCustomerData = val.wechatCustomerLabelList;
      this.$refs.tagOperation.init();
    },
    // 确定修改标签
    updateWechatLabel() {
      this.inquire();
    },
    // 关闭修改标签
    updateWechatLabel_cancel() {
      this.wechatCustomerId = "";
      this.wechatCustomerData = [];
    },
    // 点击上传文件
    uploadFiles(type) {
      this.uploadFilesShow = true;
      if (type === "file") {
        this.uploadDataTitle = "上传文件";
        this.addUrl = this.$constants.baseURL + "/wechat/uploadCustomerInfo";
      }
      if (type === "data") {
        this.uploadDataTitle = "数据更新";
        this.addUrl =
          this.$constants.baseURL + "/wechat/uploadCustomerPassInfo";
      }
    },
    // 关闭上传文件
    uploadFilesClose() {
      this.$refs.addUpload.clearFiles();
    },
    //文件上传成功
    successNumberUpload(res, file, fileList) {
      this.$refs.addUpload.clearFiles();
      if (res.result == 200) {
        this.$message({ message: "文件上传成功！", type: "success" });
        this.addLabelFile_cancel();
        this.inquire(1);
      }
    },
    //文件上传失败
    errorNumberUpload(err, file, fileList) {
      let message = JSON.parse(err.message);
      this.$message.warning(message.message);
    },
    //文件上传提交
    addLabelFile_submit() {
      this.$refs.addUpload.submit();
    },
    //文件上传取消
    addLabelFile_cancel() {
      this.uploadFilesShow = false;
    },
    //限制上传文件数量提示
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // 获取录音质检标签
    getRecordTagList() {
      this.$http("/wechat/labelList", null, "get", null, {
        category: "RECORDING",
      }).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.recordTagList = list;
        }
      });
    },
    // 获取微信标签
    getWechatTagList() {
      this.$http("/wechat/labelList", null, "get", null, {
        category: "COMMON",
      }).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.wechatLabelList = list;
        }
      });
    },
    // 获取报名科目标签
    getLessomTagList() {
      this.$http("/wechat/labelList", null, "get", null, {
        category: "LESSON",
      }).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.lessonNameList = list;
          this.lessonNameList1 = [];
          for (var i = 0; i < list.length; i++) {
            if (list[i].wechatLabelProperty) {
              if (list[i].wechatLabelProperty.autoSync === false) {
                this.lessonNameList1.push(list[i]);
              }
            } else {
              this.lessonNameList1.push(list[i]);
            }
          }
        }
      });
    },
    // 获取客户分类标签
    getCustomerTagList() {
      this.$http("/wechat/labelList", null, "get", null, {
        category: "CUSTOMER_TAG",
      }).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.customerTagList = list;
        }
      });
    },
    // 点击播放录音
    playRecord(val) {
      this.playRecordShow = true;
      this.callerUuid = val.callerUuid;
      let path = "https://" + val.server + this.$constants.basePath;
      this.currentSrc = `${path}/sip/playVoice?callerUuid=${val.callerUuid}&loginName=${val.userLoginName}`;
      this.wechatCustomerId = val.id;
      if (val.wechatCustomerRecordingLabelList) {
        val.wechatCustomerRecordingLabelList.forEach((item) => {
          this.recordCheckList.push(item.label);
        });
      }
      this.recordRemark = val.remark;
      this.getRecordTagList();
      let lastRunTime = Date.now(),
        currentTime = Date.now(),
        protectTime = 100;
      if (currentTime - lastRunTime < protectTime) {
        return; //两次执行太过频繁，直接退出
      }
      if (this.playFlag == false) {
        // this.$refs.audio.currentTime = 0;
        this.$refs.audio.play();
        this.playFlag = true;
      }
    },
    // 质检录音确定
    recordQuality_submit() {
      let data = {
        wechatCustomerId: this.wechatCustomerId,
        labelList: this.recordCheckList,
        remark: this.recordRemark,
        category: "RECORDING",
        callerUuid: this.callerUuid,
      };
      this.$service("/wechat/updateLabel", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("操作成功");
          this.inquire();
          this.playRecordShow = false;
        }
      });
    },
    // 关闭播放录音
    closePlayRecordShow() {
      //播放录音关闭
      this.$refs.audio.pause();
      this.playFlag = false;
      this.recordCheckList = [];
      this.recordRemark = "";
      // this.recordTagList = [];
    },
    // 点击导出
    downloadCustomerList() {
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.condition.gmtCreatedDate) {
        gmtCreatedStart = this.condition.gmtCreatedDate[0];
        gmtCreatedEnd = this.condition.gmtCreatedDate[1];
      }
      let courseOpenTimeStart = "";
      let courseOpenTimeEnd = "";
      if (this.condition.courseOpenTimeStartDate) {
        courseOpenTimeStart = this.condition.courseOpenTimeStartDate[0];
        courseOpenTimeEnd = this.condition.courseOpenTimeStartDate[1];
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        operator: this.condition.operator,
        batchNumber: this.condition.batchNumber,
        customerCodeOrPhone: this.condition.customerCodeOrPhone,
        status: this.condition.status.join(","),
        source: this.condition.source,
        nickName: this.condition.nickName,
        clientCodes: "",
        labels: this.tagsLabel,
        recordingLabels: this.condition.recordingLabelList.join(","),
        customerTag: this.condition.customerTag,
        wechatServer: this.wechatClientGroupIdServer,
        wechatGroupName: this.addWechatClientGroupId,
        gmtCreatedStart: gmtCreatedStart,
        gmtCreatedEnd: gmtCreatedEnd,
        courseOpenTimeStart: courseOpenTimeStart,
        courseOpenTimeEnd: courseOpenTimeEnd,
        productName: this.productName,
      };
      if (this.condition.clientNickName) {
        // data.clientCodes = this.condition.clientNickName.join(",");
        data.clientCodes = this.condition.clientNickName;
      }
      if (this.condition.lessonName) {
        data.lessonName = this.condition.lessonName.join(",");
      }
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href =
        this.$constants.baseURL + "/wechat/downloadCustomerList?" + url;
      this.derivedDis = true;
      this.$common.promiseDownload(href).then((data) => {
        setTimeout(() => {
          this.derivedDis = data;
        }, 4000);
      });
    },
    // 点击查询
    inquire(index, isTotal) {
      if (index) {
        this.pageNo = index;
      }
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.condition.gmtCreatedDate) {
        gmtCreatedStart = this.condition.gmtCreatedDate[0];
        gmtCreatedEnd = this.condition.gmtCreatedDate[1];
      }
      let courseOpenTimeStart = "";
      let courseOpenTimeEnd = "";
      if (this.condition.courseOpenTimeStartDate) {
        courseOpenTimeStart = this.condition.courseOpenTimeStartDate[0];
        courseOpenTimeEnd = this.condition.courseOpenTimeStartDate[1];
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        operator: this.condition.operator,
        batchNumber: this.condition.batchNumber,
        customerCodeOrPhone: this.condition.customerCodeOrPhone,
        status: this.condition.status.join(","),
        source: this.condition.source,
        nickName: this.condition.nickName,
        clientCodes: "",
        labels: this.tagsLabel,
        recordingLabels: this.condition.recordingLabelList.join(","),
        customerTag: this.condition.customerTag,
        wechatServer: this.wechatClientGroupIdServer,
        wechatGroupName: this.addWechatClientGroupId,
        gmtCreatedStart: gmtCreatedStart,
        gmtCreatedEnd: gmtCreatedEnd,
        courseOpenTimeStart: courseOpenTimeStart,
        courseOpenTimeEnd: courseOpenTimeEnd,
        productName: this.productName,
      };
      if (this.queryAllClient) {
        data.queryAllClient = this.queryAllClient;
      }
      if (this.condition.clientNickName) {
        // data.clientCodes = this.condition.clientNickName.join(",");
        data.clientCodes = this.condition.clientNickName;
      }
      if (this.condition.lessonName) {
        data.lessonName = this.condition.lessonName.join(",");
      }
      if (isTotal) {
        data.queryTotalCount = true;
      }
      this.derivedDis = false;
      this.getWechatList(data, isTotal);
    },
    // 查询微信粉丝列表
    getWechatList(data, isTotal) {
      if (!isTotal) {
        this.loadingTable = true;
      }
      let url = "";
      if (!this.interfaceType) {
        url = "/wechat/customerList";
      } else {
        url = "/wechatCustomer/customerList";
      }
      this.$http(url, null, "get", null, data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            if (!list) {
              list = [];
            }
            if (isTotal) {
              this.$message.success("数据总共 " + res.data.total + " 条");
              return;
            }
            list.forEach((item) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              if (item.courseOpenTime === "1970-01-01 08:00:00") {
                item.courseOpenTime = "-";
              }
              if (item.passDate === 946656000000) {
                item.passDate = "-";
              } else {
                item.passDate = this.$common.transformTime(item.passDate);
              }
              let mobileProvince = "";
              let mobileCity = "";
              if (item.mobileCity) {
                mobileCity = item.mobileCity;
              }
              if (item.mobileProvince) {
                mobileProvince = item.mobileProvince;
              }
              item.mobileArea = mobileProvince + " " + mobileCity;
              item.wechatCustomerLabel = "";
              if (item.wechatCustomerLabelList) {
                let arr = [];
                item.wechatCustomerLabelList.forEach((menu) => {
                  arr.push(menu.label);
                });
                item.wechatCustomerLabel = arr.join(",");
              }
              switch (item.status) {
                case "CREATING":
                  item.statusType = "warning";
                  item.statuslabel = "请求发送中";
                  break;
                case "SUBMITTED":
                  item.statusType = "warning";
                  item.statuslabel = "已提交好友申请";
                  break;
                case "NOT_FOUND":
                  item.statusType = "danger";
                  item.statuslabel = "未查询到好友";
                  break;
                case "SUBMIT_FAILURE":
                  item.statusType = "danger";
                  item.statuslabel = "好友申请异常";
                  break;
                // case "REJECTED":
                //   item.statusType = "danger";
                //   item.statuslabel = "拒绝好友申请";
                //   break;
                case "EXPIRED":
                  item.statusType = "danger";
                  item.statuslabel = "好友申请已过期";
                  break;
                case "RELOGIN":
                  item.statusType = "danger";
                  item.statuslabel = "登录失效";
                  break;
                case "ADDEDFRIEND":
                  item.statusType = "success";
                  item.statuslabel = "已经是好友";
                  break;
                case "PASSED":
                  item.statusType = "success";
                  item.statuslabel = "通过好友申请";
                  break;
                case "CLIENT_OFFLINE":
                  item.statusType = "danger";
                  item.statuslabel = "手机离线";
                  break;
                case "NOTLOGGEDIN":
                  item.statusType = "danger";
                  item.statuslabel = "未登录";
                  break;
                case "NO_REQUEST":
                  item.statusType = "warning";
                  item.statuslabel = "未加微信";
                  break;
                // yc非法状态
                case "CUSTOMER_ACCOUNT_IS_ILLEGAL":
                  item.statusType = "warning";
                  item.statuslabel = "对方账号异常";
                  break;
                case "IN_QUEUE":
                  item.statusType = "warning";
                  item.statuslabel = "排队中";
                  break;
                case "DISCARDED":
                  item.statusType = "warning";
                  item.statuslabel = "已丢弃";
                  break;
                default:
                  item.statusType = "warning";
                  item.statuslabel = "未知";
                  break;
              }
              switch (item.source) {
                case "CLIENT_TALKING_WECHAT":
                  item.sourceLabel = "坐席沟通";
                  break;
                case "HANGUP_WECHAT":
                  item.sourceLabel = "挂机微信";
                  break;
                case "HANGUP_SMS_CLICK_WECHAT":
                  item.sourceLabel = "挂机短信点击";
                  break;
                case "MANUEL_WECHAT":
                  item.sourceLabel = "手动加粉";
                  break;
                case "FLAG_WECHAT":
                  item.sourceLabel = "盲加家长粉";
                  break;
                case "UN_FLAG_WECHAT":
                  item.sourceLabel = "YX加粉";
                  break;
                case "ROBOT_WECHAT":
                  item.sourceLabel = "AI机器人";
                  break;
                case "NOTIFY_WECHAT":
                  item.sourceLabel = "二次加粉";
                  break;
                case "SMS_CLICK_WECHAT":
                  item.sourceLabel = "短信回调加粉";
                  break;
              }
            });
            this.wechatTableData = list;
            if (list.length > 0) {
              this.derivedDis = false;
            } else {
              this.derivedDis = true;
            }
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 产品名称下拉框查询
    getProductNameList(val) {
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.condition.gmtCreatedDate) {
        gmtCreatedStart = this.condition.gmtCreatedDate[0];
        gmtCreatedEnd = this.condition.gmtCreatedDate[1];
      }
      let courseOpenTimeStart = "";
      let courseOpenTimeEnd = "";
      if (this.condition.courseOpenTimeStartDate) {
        courseOpenTimeStart = this.condition.courseOpenTimeStartDate[0];
        courseOpenTimeEnd = this.condition.courseOpenTimeStartDate[1];
      }
      let data = {
        operator: this.condition.operator,
        batchNumber: this.condition.batchNumber,
        customerCodeOrPhone: this.condition.customerCodeOrPhone,
        status: this.condition.status.join(","),
        source: this.condition.source,
        nickName: this.condition.nickName,
        clientCodes: "",
        labels: this.tagsLabel,
        recordingLabels: this.condition.recordingLabelList.join(","),
        customerTag: this.condition.customerTag,
        wechatServer: this.wechatClientGroupIdServer,
        wechatGroupName: this.addWechatClientGroupId,
        gmtCreatedStart: gmtCreatedStart,
        gmtCreatedEnd: gmtCreatedEnd,
        courseOpenTimeStart: courseOpenTimeStart,
        courseOpenTimeEnd: courseOpenTimeEnd,
        // productName: this.productName,
        productName: val,
      };
      if (this.queryAllClient) {
        data.queryAllClient = this.queryAllClient;
      }
      if (this.condition.clientNickName) {
        // data.clientCodes = this.condition.clientNickName.join(",");
        data.clientCodes = this.condition.clientNickName;
      }
      if (this.condition.lessonName) {
        data.lessonName = this.condition.lessonName.join(",");
      }
      // if (isTotal) {
      //   data.queryTotalCount = true;
      // }
      this.$http("/wechat/productNameList", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            this.productNameList = res.data.records;
            // let list = res.data.records;
            // list.forEach((item) => {
            //   this.productNameList.push({
            //     label: item,
            //     value: item,
            //   });
            // });
          }
        }
      );
    },
    // 翻页
    currentPageNo(pageNo) {
      this.inquire(pageNo);
    },
    currentPageSize(pageSize) {
      this.pageSize = Number(pageSize);
      this.inquire(1);
    },
    // 点击查看问卷
    questionClick(val) {
      this.options = {};
      this.historicalShow = true;
      this.disabledFlagList = "";
      this.optionsCheckList = [];
      this.server = val.server;
      let data = {
        userLoginName: val.userLoginName,
        callerUuid: val.callerUuid,
      };
      this.getSipCallDetail(data);
    },
    // 关闭查看问卷
    questionClose() {
      this.historicalShow = false;
    },
    // 查询通话记录
    getSipCallDetail(data) {
      this.unifiedLoading = this.$loading({ message: "数据加载中..." });
      let path = "https://" + this.server + this.$constants.basePath;
      this.$http("/sip/call/sipCallDetail", null, "get", path, data)
        .then((res) => {
          if (res.result === 200) {
            let obj = res.data;
            this.questionInquire(obj);
          }
        })
        .catch((err) => {
          this.unifiedLoading.close();
        });
    },
    // 查询问卷记录
    questionInquire(val) {
      let params = {
        callId: val.id,
        pageNo: 1,
        pageSize: 6,
        clientNumber: val.clientNumber,
        loginName: val.userLoginName,
        callerUuid: val.callerUuid,
      };
      this.$http("/sip/question/answerHistory", null, "get", null, params)
        .then((res) => {
          this.unifiedLoading.close();
          if (res.result == 200) {
            this.questionShow = true;
            let list = res.data;
            list.map((item) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              if (item.sipQuestionList && item.sipQuestionList.length > 0) {
                item.sipQuestionList.map((menu) => {
                  menu.question = menu.question + "：";
                });
              }
            });
            this.questionDataProcessing(list[0]);
          }
        })
        .catch((err) => {
          this.unifiedLoading.close();
        });
    },
    questionDataProcessing(val) {
      //
      let disabledFlagList = [];
      if (val) {
        this.options = {};
        this.historicalShow = true;
        this.disabledFlagList = "";
        this.optionsCheckList = [];
        let flags = JSON.parse(val.flags);
        if (flags) {
          for (let key in flags) {
            disabledFlagList.push(key);
          }
        }
        disabledFlagList = disabledFlagList.join(",");
        this.disabledFlagList = disabledFlagList.substring(0, 1);
        let sipQuestionList = val.sipQuestionList;
        if (sipQuestionList && sipQuestionList.length > 0) {
          sipQuestionList.map((item) => {
            if (item.optionList) {
              item.optionList.map((menu) => {
                menu.id = menu.id.toString();
              });
            }
          });
        }
        let answer = JSON.parse(val.answer);
        let optionsCheck = "";
        this.sipQuestionList = sipQuestionList;
        for (let i in answer) {
          optionsCheck += answer[i] + ",";
          for (let j in sipQuestionList) {
            if (i == sipQuestionList[j].id) {
              this.options[i] = answer[i];
            }
          }
        }
        this.optionsCheckList = optionsCheck.split(",");
      }
    },
    // 点击对话翻译
    dialogueTranslate(val) {
      this.callUuid = val.callerUuid;
      let data = {
        uuid: val.callerUuid,
      };
      this.unifiedLoading = this.$loading();
      this.$http(
        "/sip/call/customerCallTranslationInfo",
        null,
        "get",
        this.urlServer,
        data
      )
        .then((res) => {
          this.unifiedLoading.close();
          if (res.result === 200) {
            this.currentSrc = `${this.urlServer}/sip/playVoice?callId=${val.id}&loginName=${val.userLoginName}`;
            this.dialogueList = [];
            this.dialogueTranslateTitle = "对话翻译-" + val.customerCode;
            if (val.clientNumber) {
              this.dialogueTranslateTitle += "-" + val.clientNumber;
            }
            this.dialogueRound = res.data.dialogueRound;
            if (res.data.dialogueRound) {
              let list = res.data.list;
              this.dialogueList = list;
            } else {
              console.log("2222,false");
              let list = JSON.parse(res.data.list.content);
              console.log(list);
              list.forEach((item) => {
                item.customerChannelId = res.data.list.customerChannelId;
              });
              this.dialogueList = list;
            }
            console.log(this.dialogueRound);

            this.$refs.dialogueTranslation.speechTestClick();
          }
        })
        .catch((err) => {
          this.unifiedLoading.close();
        });
    },
  },
};
</script>
<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-dropdown {
  font-size: 12px;
  margin-left: 10px;
}
.el-dropdown-menu .el-button {
  display: block;
  width: 95px;
  border-radius: 0px;
}
.el-dropdown-menu .el-button + .el-button {
  margin: 0;
}
.el-dropdown-menu .el-button:hover {
  background: #ecf5ff;
}
.copy {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 55px;
  height: 40px;
}
.icon {
  font-size: 20px;
}
.seeCustomerCode-class >>> .el-dialog__title {
  font-size: 16px;
}
.seeCustomerCode-class >>> .el-dialog__body {
  padding: 10px 20px 5px 20px;
}
.seeCustomerCode-class >>> .el-dialog__body .el-form-item__label {
  font-size: 17px;
}
.seeCustomerCode-class >>> .el-dialog__body .el-form-item__content {
  font-size: 17px;
}
.customerCode_class {
  display: flex;
  justify-content: space-between;
}
.clear {
  margin-left: 10px;
  color: rgb(179, 174, 174);
}
.drawerCustomerLog_style >>> .el-drawer__body {
  height: 100%;
}
.drawerCustomerLog_title {
  display: flex;
  padding: 20px;
  justify-content: space-between;
}
.drawer_time_line {
  padding: 20px;
  overflow-x: auto;
  height: 88%;
}
.button-center {
  text-align: center;
}
.tags_td {
  margin-right: 5px;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
}
.nav .callTime .el-date-editor {
  width: 360px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin: 0 16px 20px 16px;
}
.nav .batchName .el-select {
  width: 260px;
  margin: 0 16px 20px 16px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.changeClass {
  display: inline-block;
  width: 100%;
}
.formChange .el-form-item {
  margin-bottom: 2px;
}
.formChange .el-input {
  width: 40%;
}
.formChange .el-textarea {
  width: 85%;
}
.flagImg .el-checkbox {
  margin-right: 20px;
}
.question_input {
  width: 100%;
}
</style>
