<template>
  <div class="project">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="普通任务" name="first">
        <div class="nav">
          <div class="projectStatus">
            <div v-if="roleMenu == 'SUPER_MANAGER'" style="margin-bottom: 20px">
              用户名称：
              <el-select
                v-model="loginName"
                filterable
                remote
                :loading="loadingLoginName"
                :remote-method="remoteMethodLoginName"
                @focus="NameRemoteFocus"
                placeholder="请选择用户名称"
                @change="loginNameChange"
                clearable
              >
                <el-option
                  v-for="item in loginNameList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.loginName"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="taskName-class" style="float: right">
            任务名称：
            <el-input
              v-model="inquireTaskName"
              placeholder="请输入任务名称"
              clearable
            ></el-input>
          </div>
          <div class="projectName-class" style="float: right">
            项目名称：
            <el-select
              v-model="condition.projectId"
              filterable
              remote
              :loading="loading"
              :remote-method="remoteMethod"
              @focus="projectIdFocus"
              clearable
              placeholder="请选择项目名称"
            >
              <el-option
                v-for="item in projectNameList"
                :key="item.id"
                :value="item.id"
                :label="item.projectName"
              ></el-option>
            </el-select>
          </div>
          <div class="projectChoice" style="float: right">
            状态：<i class="intervalClass"></i>
            <el-select
              v-model="condition.projectStatus"
              placeholder="请选择状态"
              clearable
            >
              <el-option
                v-for="item in projectStatusList"
                :key="item.status"
                :value="item.status"
                :label="item.label"
              ></el-option>
            </el-select>
          </div>
          <div class="batchNumber-class">
            批次编号：
            <el-input
              v-model="batchNumber"
              placeholder="请输入批次编号"
              clearable
            ></el-input>
          </div>
          <div class="recycled-class">
            重拨次数：<i style="margin-right: 20px"></i>
            <el-select
              v-model="condition.autoRecallCount"
              placeholder="请选择重拨次数"
              clearable
            >
              <el-option
                v-for="item in autoRecallCountList"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </div>
          <div class="projectStatus">
            任务类别：
            <el-select
              v-model="condition.categoryId"
              placeholder="请选择任务类别"
              clearable
            >
              <el-option
                v-for="item in projectTypeList"
                :key="item.id"
                :value="item.id"
                :label="item.category"
              ></el-option>
            </el-select>
          </div>
          <div
            :class="
              roleMenu == 'SUPER_MANAGER' ? 'callTimeClass' : 'callTime-class'
            "
          >
            拨打时间：
            <el-date-picker
              class="startTime"
              v-model="condition.firstCallAtStart"
              type="datetime"
              placeholder="起始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptionsCall"
            ></el-date-picker>
            -
            <el-date-picker
              class="endTime"
              v-model="condition.firstCallAtEnd"
              type="datetime"
              placeholder="终止时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptionsCall"
            ></el-date-picker>
          </div>
        </div>
        <el-button
          type="primary"
          class="button-mar"
          @click="inquire(1)"
          :disabled="inquireBtnDisabled"
          >查询</el-button
        >
        <el-button
          type="primary"
          @click="addProjectShow"
          v-if="taskOtherOperationShow('/sip/task/add')"
          >新增任务</el-button
        >
        <el-dropdown v-if="roleMenu == 'SUPER_MANAGER'">
          <el-button :disabled="dropdownDisabled">
            批量操作<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-button
              type="text"
              @click="startCall"
              :disabled="
                condition.projectStatus === 'EXCEPTION' ||
                condition.projectStatus === 'FINISHED' ||
                condition.projectStatus === 'CALLING' ||
                condition.projectStatus === 'QUEUING'
              "
              >开始拨打</el-button
            >
            <el-button
              type="text"
              @click="suspendCall"
              :disabled="condition.projectStatus !== 'CALLING'"
              >暂停拨打</el-button
            >
            <el-button
              type="text"
              @click="recreateCall"
              v-if="taskOtherOperationShow('/sip/task/recycleCreate')"
              >重新创建</el-button
            >
            <el-button type="text" @click="batchMergeTask"
              >多批次混合</el-button
            >
            <el-button type="text" @click="batchSetStrategy"
              >策略管理</el-button
            >
            <el-button type="text" @click="routeNameCall">修改线路</el-button>
            <el-button
              type="text"
              @click="pauseLineupCall"
              :disabled="condition.projectStatus !== 'QUEUING'"
              >取消排队</el-button
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-checkbox v-model="recycled" style="margin: 0 0 0 40px"
          >过滤重新创建</el-checkbox
        >
        <el-tooltip
          content="勾选会过滤掉已经重新创建的批次"
          placement="right"
          effect="light"
        >
          <i class="el-icon-question" style="color: #909399"></i>
        </el-tooltip>
        <el-checkbox v-model="filterClosed" style="margin: 0 0 0 40px"
          >过滤已关闭</el-checkbox
        >
        <el-tooltip
          content="勾选会过滤掉已经关闭的批次"
          placement="right"
          effect="light"
        >
          <i class="el-icon-question" style="color: #909399"></i>
        </el-tooltip>
        <el-checkbox v-model="filterMerged" style="margin: 0 0 0 40px"
          >过滤已合并</el-checkbox
        >
        <el-tooltip
          content="勾选会过滤掉已经合并的批次"
          placement="right"
          effect="light"
        >
          <i class="el-icon-question" style="color: #909399"></i>
        </el-tooltip>
        <!-- 任务表 -->
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          border
          v-loading="loadingTable"
          class="task-table"
          @selection-change="handleSelectionChange"
          :row-key="(row) => row.id"
        >
          <el-table-column
            type="selection"
            width="55"
            v-if="roleMenu == 'SUPER_MANAGER'"
          ></el-table-column>
          <el-table-column
            prop="batchNumber"
            label="批次编号"
            width="90"
            align="left"
          ></el-table-column>
          <el-table-column
            prop
            label="用户名称"
            width="150"
            align="left"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="user-class">{{ scope.row.userName }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="projectName"
            label="项目名称"
            width="150"
            align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="taskName"
            label="任务名称"
            width="400"
            align="left"
          >
            <template slot-scope="scope">
              <div class="taskName_td">
                <div>
                  {{ scope.row.taskName }}
                </div>
                <div style="height: 18px">
                  <el-tooltip
                    content="号码可见"
                    placement="top"
                    v-if="scope.row.mobileVisible"
                  >
                    <img
                      :src="require('@/assets/img/kejian.png')"
                      alt=""
                      width="18px"
                    />
                  </el-tooltip>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop label="队列名称" width="150" align="left">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="changeTeamName(scope.row)"
                :disabled="scope.row.teamIdDisabled"
                >{{ scope.row.sipTeamName }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop label="线路名称" width="150" align="left">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="changeLine(scope.row, 'line')"
                >{{ scope.row.sipRouteName }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop label="回拨线路" width="150" align="left">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="changeLine(scope.row, 'callbackLine')"
                >{{ scope.row.sipCallbackRouteName }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop label="状态" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.newStatus }}</span>
              <i
                v-show="
                  scope.row.newStatus == '拨打中' ||
                  scope.row.newStatus == '暂停中' ||
                  scope.row.newStatus == '创建中' ||
                  scope.row.newStatus == '排序中' ||
                  scope.row.newStatus == '清理中' ||
                  scope.row.newStatus == '迁移中' ||
                  scope.row.newStatus == '结束中' ||
                  scope.row.newStatus == '拆分中' ||
                  scope.row.newStatus == '删除中'
                "
                class="el-icon-loading"
              ></i>
              <el-popover
                placement="right"
                trigger="hover"
                v-if="scope.row.newStatus == '已合并'"
              >
                <el-form class="relationTaskList" label-width="90px">
                  <el-form-item>
                    <span slot="label">混合批次:</span>
                    {{ scope.row.relationTaskList.batchNumber }}
                  </el-form-item>
                  <el-form-item>
                    <span slot="label">子批次列表:</span>
                    <template
                      v-for="item in scope.row.relationTaskList
                        .childBatchNumberList"
                    >
                      <div v-if="item == scope.row.batchNumber">
                        <strong> {{ item }}* </strong>
                      </div>
                      <div v-else>
                        {{ item }}
                      </div>
                    </template>
                  </el-form-item>
                </el-form>
                <i class="el-icon-info" slot="reference"></i>
              </el-popover>
              <el-popover
                placement="right"
                width="500"
                @show="lineupClick(scope.row)"
                @hide="lineupKide(scope.row)"
              >
                <div>
                  <span class="text-pai">前置任务</span>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="前置任务打完后将拨打当前任务"
                    placement="top-start"
                  >
                    <i class="el-icon-question icon-color"></i>
                  </el-tooltip>
                  <el-button size="mini" @click="taskSortClick(scope.row)"
                    >任务排序</el-button
                  >
                </div>
                <!-- 排队列表 -->
                <el-table
                  :data="lineUpForm"
                  size="mini"
                  stripe
                  style="width: 100%"
                  border
                >
                  <el-table-column
                    type="index"
                    prop
                    label="序号"
                    width="50"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="batchNumber"
                    label="批次编号"
                    width="80"
                    align="left"
                    show-overflow-tooltip
                  ></el-table-column>
                  <el-table-column
                    prop="taskName"
                    label="任务名称"
                    align="left"
                    show-overflow-tooltip
                  ></el-table-column>
                  <el-table-column
                    prop="statusLable"
                    label="状态"
                    width="95"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="timeLable"
                    label="持续时间"
                    width="90"
                    align="center"
                  ></el-table-column>
                </el-table>
                <span
                  v-if="waitingAutoStart"
                  style="font-size: 12px; color: #409eff"
                >
                  <i
                    class="el-icon-warning"
                    style="font-size: 16px; color: #e6a23c"
                  ></i>
                  任务将在下个工作时间自动启动
                </span>
                <i
                  slot="reference"
                  v-show="scope.row.newStatus == '排队中'"
                  class="el-icon-info"
                ></i>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="progress"
            label="拨打进度"
            width="155"
            align="center"
          ></el-table-column>
          <el-table-column
            prop
            label="服务器分组"
            width="150"
            align="left"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-button
                v-if="!scope.row.distributeServerGroupName"
                type="text"
                size="small"
                @click="distributeTaskClick(scope.row)"
                :disabled="scope.row.distributeServerGroupIdDisabled"
                >-</el-button
              >
              <el-button
                v-if="scope.row.distributeServerGroupName"
                type="text"
                size="small"
                @click="distributeTaskClick(scope.row)"
                :disabled="scope.row.distributeServerGroupIdDisabled"
                >{{ scope.row.distributeServerGroupName }}
                <span v-if="scope.row.distributeTask">( 已开启 )</span>
                <span v-if="!scope.row.distributeTask">( 已关闭 )</span>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop label="接通数" width="90" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="viewConnected(scope.row)">{{
                scope.row.connectedCount
              }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop label="接通率" width="90" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.connectedCountRate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="成单数" width="70" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.successCount }}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="成单率" width="90" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.orderRate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="意向数" width="70" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.intentionCount }}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="意向率" width="90" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.intentionRate }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="greaterThan60sCountRate"
            label="超过1分钟率"
            width="110"
            align="center"
            v-if="roleMenu === 'SUPER_MANAGER'"
          ></el-table-column>
          <el-table-column
            prop="lessThan10sCountRate"
            label="秒挂率"
            width="90"
            align="center"
            v-if="roleMenu === 'SUPER_MANAGER'"
          ></el-table-column>
          <el-table-column
            prop="fee"
            label="费用"
            width="105"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="totalFeeUnitCount"
            label="通话分钟数"
            width="140"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="firstCallAt"
            label="首次拨打时间"
            width="170"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="lastCallAt"
            label="最后一次拨打时间"
            width="170"
            align="center"
          ></el-table-column>
          <el-table-column
            prop
            label="操作"
            :width="roleMenu === 'SUPER_MANAGER' ? 230 : 160"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="pauseCall(scope.row)"
                :disabled="scope.row.startCallDisabled"
                v-if="scope.row.startCallShow"
                >开始拨打</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="pauseTask(scope.row)"
                v-if="scope.row.pauseTaskShow"
                >暂停拨打</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="pauseLineup(scope.row)"
                v-if="scope.row.pauseLineupShow"
                >取消排队</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="reCreate(scope.row)"
                v-if="
                  scope.row.reCreateShow &&
                  taskOtherOperationShow('/sip/task/recycleCreate')
                "
                :disabled="scope.row.reCreateDisabled"
                >重新创建</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="showStrategy(scope.row)"
                :disabled="scope.row.showStrategyDisabled"
                >策略管理</el-button
              >
              <el-dropdown
                trigger="click"
                v-if="taskOtherOperationShow('show_task_other_operation')"
                :disabled="
                  scope.row.newStatus == '合并中' ||
                  scope.row.newStatus == '已合并' ||
                  scope.row.newStatus == '结束中' ||
                  scope.row.newStatus == '拆分中' ||
                  scope.row.newStatus == '删除中'
                "
              >
                <span class="el-dropdown-link">
                  其它操作<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-button
                    type="text"
                    size="small"
                    @click="supplementNumber(scope.row)"
                    :disabled="scope.row.supplementNumberShow"
                    v-if="taskOtherOperationShow('/sip/task/append')"
                    >补充号码</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="shuffleClick(scope.row)"
                    :disabled="scope.row.shuffleDisabeld"
                    v-if="taskOtherOperationShow('/sip/task/shuffleOrder')"
                    >乱序</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="autoRecallClick(scope.row)"
                    v-if="
                      taskOtherOperationShow('/sip/task/modifyAutoRecallInfo')
                    "
                    >自动重呼管理</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="batchNumberExport(scope.row)"
                    v-if="
                      taskOtherOperationShow('/sip/task/exportCustomerInfo')
                    "
                    >编号导出</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="mergeTask(scope.row)"
                    >多批次混合</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="modifyTaskName(scope.row)"
                    v-if="taskOtherOperationShow('/sip/task/modifyTaskName')"
                    >修改任务名称</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="closeTask(scope.row)"
                    :disabled="scope.row.closeTaskDisabled"
                    v-if="taskOtherOperationShow('/sip/task/closeTask')"
                    >关闭任务</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="deleteTask(scope.row)"
                    style="color: #ff0000b0"
                    v-if="taskOtherOperationShow('/sip/task/deleteTask')"
                    >删除任务</el-button
                  >
                  <div
                    style="
                      background-color: #dcdfe6;
                      height: 1px;
                      width: 100%;
                      margin: 0;
                    "
                    v-if="roleMenu == 'SUPER_MANAGER'"
                  ></div>
                  <el-button
                    type="text"
                    size="small"
                    @click="reloadClick(scope.row)"
                    :disabled="scope.row.reloadDisabeld"
                    v-if="
                      taskOtherOperationShow('/distribute/reloadSipCallToPool')
                    "
                    >任务状态同步</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="reStatisticsClick(scope.row)"
                    :disabled="scope.row.reloadDisabeld"
                    v-if="taskOtherOperationShow('/sip/task/modifyTaskStatus')"
                    >异常状态同步</el-button
                  >
                  <div
                    style="
                      background-color: #dcdfe6;
                      height: 1px;
                      width: 100%;
                      margin: 0;
                    "
                    v-if="roleMenu == 'SUPER_MANAGER'"
                  ></div>
                  <el-button
                    type="text"
                    size="small"
                    @click="modfityTaskStatusClick(scope.row)"
                    v-if="
                      taskOtherOperationShow(
                        '/distribute/reStatisticsExceptionInfo'
                      )
                    "
                    >修改任务状态</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="dataMigration(scope.row)"
                    v-if="taskOtherOperationShow('/sip/task/transSipCallTask')"
                    style="color: #ff0000b0"
                    >数据迁移</el-button
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentPage_data"
          :current-page.sync="currentPage"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40, 100]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="total"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="分布式任务" name="second">
        <distributed-task
          v-if="distributedTaskShow"
          :batchNumber="batchNumber"
        ></distributed-task>
      </el-tab-pane>
    </el-tabs>
    <!-- 新增 -->
    <el-dialog
      :visible.sync="addProject"
      custom-class="el-addProject"
      @close="closeDialog"
      width="800px"
      top="10vh"
      :close-on-click-modal="false"
      class="addProjectClass"
    >
      <template slot="title">
        <i
          class="el-icon-circle-plus-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">新增任务</span>
      </template>
      <el-form
        :model="form"
        :label-position="labelLeft"
        :inline="true"
        label-width="115px"
      >
        <el-form-item label="任务名称：" required>
          <el-input
            v-model="form.projectName"
            placeholder="请输入任务名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="用户名称："
          v-if="taskOtherOperationShow('/sip/task/add')"
        >
          <el-select
            v-model="userNameId"
            filterable
            remote
            :loading="loadingUserName"
            :remote-method="remoteMethodUserName"
            @focus="userNameRemoteFocus"
            placeholder="可以试试搜索"
            @change="userNameChange"
          >
            <el-option
              v-for="item in userNameList"
              :key="item.id"
              :label="item.userName"
              :value="item.loginName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择项目：" required>
          <el-select
            v-model="projectListId"
            filterable
            remote
            :loading="loadingProject"
            :remote-method="remoteMethodProject"
            @focus="crossProjectIdFocus"
            placeholder="请选择项目名称"
            :disabled="projectDisabled"
            @change="projectChagne"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.projectName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择队列：" required>
          <el-select
            v-model="peojectSipTeamId"
            clearable
            placeholder="请选择队列名称"
            @focus="peojectSipTeamIdFocus"
            :disabled="sipTeamDisabled"
          >
            <el-option
              v-for="item in teamNameList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择线路：" required>
          <el-select
            v-model="projectSipRouteId"
            clearable
            placeholder="请选择线路名称"
            @focus="projectSipRouteIdFocus"
            :disabled="sipDisabled"
          >
            <el-option
              v-for="item in lineList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回拨线路：">
          <el-select
            v-model="callbackLine"
            clearable
            placeholder="请选择回拨线路"
            @focus="projectSipRouteIdFocus"
            :disabled="sipDisabled"
          >
            <el-option
              v-for="item in lineList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务类型：" style="width: 720px">
          <el-select
            v-model="form.taskType"
            placeholder="请选择任务类型"
            @change="taskTypeChagne"
            style="width: 160px; margin: 0"
          >
            <el-option
              v-for="item in taskTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <div style="display: inline-block">
            <el-button type="text" @click="templateSelection('template')"
              >模板选择</el-button
            >
            <span>{{ template_name_label }}</span>
          </div>
        </el-form-item>
        <el-form-item label="隐藏号码：">
          <template slot="label">
            隐藏号码
            <el-tooltip class="item" placement="top-start">
              <div slot="content">开启后，坐席页面将隐藏手机号码</div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <div class="switch_style_m">
            <el-switch
              v-model="mobileVisible"
              active-color="#13ce66"
              inactive-color="#C0CCDA"
            ></el-switch>
          </div>
        </el-form-item>
        <el-form-item label="乱序：" label-width="70px">
          <div class="switch_style_m">
            <el-switch
              v-model="addDisorder"
              active-color="#13ce66"
              inactive-color="#C0CCDA"
              :disabled="
                form.taskType === 'PROLOGUE_SMS' ||
                form.taskType === 'PROLOGUE_WECHAT' ||
                form.taskType === 'AI_ROBOT'
              "
            ></el-switch>
          </div>
        </el-form-item>
        <el-form-item
          label="重呼间隔控制："
          style="width: 500px"
          label-width="140px"
        >
          <template slot="label">
            重呼间隔控制
            <el-tooltip class="item" placement="top-start">
              <div slot="content">开启后，自动重呼</div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <div class="switch_style_m_w">
            <el-button
              v-if="redialEnable"
              type="text"
              @click="redialShowClick()"
              >已开启</el-button
            >
            <el-button
              v-if="!redialEnable"
              type="text"
              class="clear"
              @click="redialShowClick()"
              >已关闭</el-button
            >
            <div class="addWechat_info" v-if="redialEnable">
              <span
                >第一遍间隔：{{ oldRedialDate.first }}
                <i
                  v-if="redialCheck.firstCheck"
                  class="el-icon-refresh-right"
                ></i
              ></span>
              <el-divider direction="vertical"></el-divider>
              <span
                >第二遍间隔：{{ oldRedialDate.second }}
                <i
                  v-if="redialCheck.secondCheck"
                  class="el-icon-refresh-right"
                ></i
              ></span>
              <el-divider direction="vertical"></el-divider>
              <span
                >第三遍间隔：{{ oldRedialDate.third }}
                <i
                  v-if="redialCheck.thirdCheck"
                  class="el-icon-refresh-right"
                ></i
              ></span>
              <br />
              <!-- <el-divider direction="vertical"></el-divider> -->
              <span
                >第四遍间隔：{{ oldRedialDate.four }}
                <i
                  v-if="redialCheck.fourCheck"
                  class="el-icon-refresh-right"
                ></i
              ></span>
              <el-divider direction="vertical"></el-divider>
              <span
                >第五遍间隔：{{ oldRedialDate.five }}
                <i
                  v-if="redialCheck.fiveCheck"
                  class="el-icon-refresh-right"
                ></i
              ></span>
              <el-divider direction="vertical"></el-divider>
              <span
                >第六遍间隔：{{ oldRedialDate.six }}
                <i v-if="redialCheck.sixCheck" class="el-icon-refresh-right"></i
              ></span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="分布式任务：" style="width: 500px">
          <div class="switch_style_m_w">
            <el-button
              v-if="distributeTask"
              type="text"
              @click="distributeTaskClick()"
              :disabled="distributeTaskDisabled"
              >已开启</el-button
            >
            <el-button
              v-if="!distributeTask"
              type="text"
              class="clear"
              @click="distributeTaskClick()"
              :disabled="distributeTaskDisabled"
              >已关闭</el-button
            >
            <div class="addWechat_info" v-if="distributeTask">
              {{ distributeTaskName }}
            </div>
          </div>
        </el-form-item>
        <el-form-item label="数据上传：" label-width="110px">
          <template slot="label">
            数据上传
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                可以上传手机号或客户编号<br />
                手动上传单次最多1000条数据
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-tabs v-model="uploadMethod" @tab-click="UploadMethodClick">
            <el-tab-pane label="文件上传" name="first">
              <el-upload
                class="upload-demo"
                drag
                :data="uploadData"
                :action="url"
                :on-success="successUpload"
                :on-error="errorUpload"
                :on-change="changeUpload"
                ref="upload"
                :auto-upload="false"
                :file-list="fileList"
                :with-credentials="cookie"
                :limit="1"
                :on-exceed="handleExceed"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或
                  <em>点击上传</em>
                </div>
              </el-upload>
            </el-tab-pane>
            <el-tab-pane label="手动上传" name="second">
              <el-input
                type="textarea"
                :rows="8"
                placeholder="请输入客户编号或号码"
                v-model="uploadNumber"
              >
              </el-input>
            </el-tab-pane>
            <el-tab-pane label="跨任务复用" name="crossTask">
              <el-form
                :model="createForm"
                :label-position="labelLeft"
                :inline="true"
                label-width="105px"
              >
                <el-form-item label="选择任务：">
                  <el-select
                    v-model="form.crossTaskNameList"
                    filterable
                    remote
                    :loading="loading"
                    :remote-method="crossTaskRemoteMethod"
                    @focus="crossTaskFocus"
                    clearable
                    placeholder="可以试试搜索"
                    multiple
                    collapse-tags
                  >
                    <el-option
                      v-for="item in crossTaskNameList"
                      :key="item.id"
                      :value="item.batchNumber"
                      :label="item.taskName"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="运营商：">
                  <template slot="label"
                    >运营商
                    <el-tooltip class="item" placement="top-start">
                      <div slot="content">不选择表示选择全部运营商</div>
                      <i class="el-icon-question icon-color"></i> </el-tooltip
                    >：
                  </template>
                  <el-select
                    v-model="form.operator"
                    placeholder="请选择运营商"
                    multiple
                    collapse-tags
                  >
                    <el-option
                      v-for="item in operatorsArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="城市：">
                  <el-cascader
                    v-model="form.cityList"
                    placeholder="可以试试搜索"
                    :options="options"
                    :props="props"
                    collapse-tags
                    filterable
                    :show-all-levels="false"
                    clearable
                    style="width: 240px"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="标记已复用：">
                  <div class="switch_style_m" style="width: 250px">
                    <el-switch
                      v-model="markReused"
                      active-color="#13ce66"
                    ></el-switch>
                  </div>
                </el-form-item>
              </el-form>
              <span style="color: #e6a23c">复用后原任务将被关闭</span>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="addProject_submit()"
          :disabled="isAddProject_submit"
          >提 交</el-button
        >
        <el-button @click="addProject_cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 策略管理 -->
    <strategy-manage
      ref="strategyManage"
      :title="strategyName"
      :type="template_select_type"
      :taskServer="taskServer"
      :templateId="templateId"
      :taskId="taskId"
      :userLoginName="userLoginName"
      :isAddtemplate="isAddtemplate"
      :strategyForm="strategy"
      :textareaSMS="SMStextarea"
      :longUrlValue="longUrlValue"
      :smsDataLabelValue="smsDataLabelValue"
      :smsSign="smsSign"
      :prefixValue="prefixValue"
      :clickCallValue="clickCallValue"
      :isHangupsms="strategy_from_hangupsms"
      :platformArr="platformArr"
      :options="options"
      :roleMenu="roleMenu"
      @submit="changStrategy_submit"
    ></strategy-manage>
    <!-- 修改线路 -->
    <el-dialog
      title="修改线路"
      :visible.sync="changeLineShow"
      width="380px"
      class="el-changeLine"
      @close="closeLine"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">修改线路</span>
      </template>
      <el-form
        :model="form"
        label-position="left"
        label-width="100px"
        class="el-form"
      >
        <el-form-item label="选择线路：" v-if="!isCallbackLine" required>
          <el-select v-model="lineId" placeholder="请选择线路">
            <el-option
              v-for="item in lineList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回拨线路：" v-if="isCallbackLine" required>
          <el-select v-model="callbackLineId" placeholder="请选择回拨线路">
            <el-option
              v-for="item in lineList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          v-if="!isBatchRouteNameCall"
          @click="changeLine_submit"
          :disabled="isChangeLineSubmit"
          >确定</el-button
        >
        <el-button
          type="primary"
          v-if="isBatchRouteNameCall"
          @click="changeBatchLine_submit"
          >确定</el-button
        >
        <el-button @click="changeLine_cencel">取消</el-button>
      </div>
    </el-dialog>
    <!-- 重新创建 -->
    <el-dialog
      title="重新创建"
      :visible.sync="createDialogShow"
      width="450px"
      class="el-changeLine"
      @close="closeCreateDialog"
    >
      <template slot="title">
        <i
          class="el-icon-circle-plus-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title"
          >重新创建
          <el-tooltip class="item" placement="top-start">
            <div slot="content">
              重新创建会把任务未接通号码找到并生成一个新的重播任务
            </div>
            <i class="el-icon-question icon-color"></i>
          </el-tooltip>
        </span>
      </template>
      <el-form :model="createForm" :label-position="labelLeft">
        <el-form-item label="任务名称：" :label-width="formWidth">
          <el-input
            v-model="createForm.taskName"
            placeholder="请输入任务名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="乱序：" :label-width="formWidth">
          <el-switch
            v-model="createDisorder"
            active-color="#13ce66"
          ></el-switch>
          <div style="color: #e6a23c">
            <span>
              <i class="el-icon-warning" v-if="isCategoryJiaoyuShow"></i>
              {{ isCategoryJiaoyuShow }}
            </span>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="create_submit()"
          :disabled="iscreateSubmit"
          >创建</el-button
        >
        <el-button @click="create_cancel">取消</el-button>
      </div>
    </el-dialog>
    <!-- 补充号码 -->
    <el-dialog
      title="补充号码"
      :visible.sync="numberDialogShow"
      width="500px"
      class="el-changeLine"
      @close="closeNumberDialog"
    >
      <template slot="title">
        <i
          class="el-icon-circle-plus-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">补充号码</span>
      </template>
      <el-form :model="numberForm" :label-position="labelLeft">
        <el-form-item label="上传文件：" :label-width="formWidth">
          <el-upload
            class="upload-demo"
            drag
            :data="numberUploadData"
            :action="numberUrl"
            :on-success="successNumberUpload"
            :on-error="errorNumberUpload"
            ref="numberUpload"
            :auto-upload="false"
            :file-list="fileList"
            :with-credentials="cookie"
            :limit="1"
            :on-exceed="handleExceed"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="乱序：" :label-width="formWidth">
          <el-radio-group v-model="numberDisorder">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div style="color: #e6a23c">只能上传customerCode</div>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="number_submit()"
          :disabled="isNumberSubmit"
          >提 交</el-button
        >
        <el-button @click="number_cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 队列名称 -->
    <el-dialog
      title="队列名称"
      :visible.sync="changeTeamNameShow"
      width="420px"
      class="el-changeLine"
      @close="changeTeamClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">队列名称</span>
      </template>
      <el-form
        :model="form"
        label-position="left"
        label-width="90px"
        class="el-form"
      >
        <el-form-item label="选择队列：">
          <el-select
            v-model="teamNameId"
            placeholder="请选择队列"
            @change="teamNameChange"
            style="width: 250px"
          >
            <el-option
              v-for="item in teamNameList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            >
            </el-option>
          </el-select>
          <el-button
            class="teamDetails-class"
            type="text"
            @click="tableShow = true"
            >详情</el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="changeTeamName_submit"
          :disabled="isChangeTeamName_submit"
          >确定</el-button
        >
        <el-button @click="changeTeamName_cencel">取消</el-button>
      </div>
    </el-dialog>
    <!-- 确认拨打 -->
    <el-dialog
      title="确认拨打"
      :visible.sync="callStartShow"
      width="650px"
      class="el-changeLine"
      @close="callStartClose"
    >
      <template slot="title">
        <i
          class="el-icon-warning-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">确认拨打</span>
      </template>
      <el-form label-position="left" label-width="90px" class="el-form">
        <el-form-item label="项目名称：">
          <span>{{ projectName }}</span>
        </el-form-item>
        <el-form-item label="任务名称：">
          <span>{{ taskName }} (拨打总数：{{ totalCallCount }})</span>
        </el-form-item>
        <el-form-item label="队列名称：">
          <span
            >{{ teamName }} (在线：{{ onlineCount }},等待任务：{{
              waitingTaskCount
            }})</span
          >
        </el-form-item>
        <el-form-item label="线路名称：">
          <span>{{ routeName }}</span>
        </el-form-item>
        <div class="textPrompt" v-if="isSeat">*没有坐席等待任务,无法拨打</div>
        <div class="textPrompt" v-if="isNotWorkingTime">
          <div v-for="(item, i) in workingTimeStr" :key="i">*{{ item }}</div>
          <div>
            *当前为非工作时间，开启后任务将进入等待状态(在下个工作时间自动开启)
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="pauseCall_submit"
          :disabled="isSubmit"
          v-if="pauseCallDisabled"
        >
          <span v-if="isNotWorkingTime">排队</span>
          <span v-else>确定</span>
        </el-button>
        <el-button type="primary" disabled v-if="!pauseCallDisabled"
          >确定</el-button
        >
        <el-button @click="pauseCall_cencel">取消</el-button>
      </div>
    </el-dialog>
    <!-- 查询表格 -->
    <el-dialog
      title="详情"
      :visible.sync="connectedTableShow"
      width="1200px"
      class="connectedTable"
      @close="connectedTableClose"
    >
      <template slot="title">
        <i
          class="el-icon-data-analysis dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">详情</span>
      </template>
      <el-table
        :data="connectedTableData"
        size="mini"
        stripe
        style="width: 100%"
        border
        v-loading="loadingTab"
      >
        <el-table-column
          type="index"
          prop
          label="编号"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="customerCode"
          label="客户编号"
          min-width="100"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="clientNumber"
          label="坐席编号"
          min-width="100"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="ringSeconds"
          label="响铃时长"
          width
          align="center"
        ></el-table-column>
        <el-table-column
          prop="callSeconds"
          label="通话时长"
          width
          align="center"
        ></el-table-column>
        <el-table-column
          prop="distributeSeconds"
          label="转接时长"
          width
          align="center"
        ></el-table-column>
        <el-table-column
          prop="connected"
          label="是否接通"
          width="70"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="distributed"
          label="是否转接"
          width="70"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="fee"
          label="费用"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="callEndAt"
          label="完成时间"
          width="170"
          align="center"
        ></el-table-column>
        <el-table-column prop label="通话状态" width="95" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.callStatus }}</span>
            <i
              v-show="scope.row.callStatus == '通话中'"
              class="el-icon-loading"
            ></i>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="detailsCurrentPage_data"
        :current-page.sync="detailsCurrentPage"
        :page-size="10"
        layout="total,prev, pager, next,jumper"
        :total="detailsTotal"
        :pager-count="5"
      ></el-pagination>
    </el-dialog>
    <!-- 坐席详情 -->
    <el-drawer
      title="坐席详情"
      :visible.sync="tableShow"
      direction="rtl"
      class="tableDrawerClass"
      size="30%"
    >
      <template slot="title">
        <div>
          <i
            class="el-icon-data-analysis dialog-title-my"
            style="color: #1296db"
          ></i>
          <span class="dialog-title-my my-title">坐席详情</span>
        </div>
      </template>
      <el-table :data="teamData" stripe border>
        <el-table-column
          type="index"
          label="编号"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column property="name" label="坐席名称"></el-table-column>
        <el-table-column property="number" label="坐席编号"></el-table-column>
      </el-table>
      <el-pagination
        @current-change="teamCurrentPage_data"
        :current-page.sync="teamCurrentPage"
        :page-size="20"
        layout=" total, prev, pager, next,jumper"
        :total="teamtotal"
        :pager-count="5"
      ></el-pagination>
    </el-drawer>
    <!-- 编号导出 -->
    <el-dialog
      title="编号导出"
      :visible.sync="batchNumberExportShow"
      width="550px"
      class="connectedTable"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">编号导出</span>
      </template>
      <el-form label-position="left" label-width="110px" class="el-form">
        <el-form-item label="过滤接通：">
          <el-switch
            v-model="skipNormalClearing"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="关闭任务：">
          <el-switch
            v-model="isCloseTask"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="批次编号：">
          <template slot="label">
            批次编号
            <el-tooltip class="item" placement="top-start">
              <div slot="content">一行一个批次编号</div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-input
            type="textarea"
            :rows="18"
            placeholder="请输入批次编号"
            v-model="batchNumberTextarea"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="batchNumberExport_submit"
          >导出</el-button
        >
        <el-button @click="batchNumberExportShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 多批次混合 -->
    <el-dialog
      title="多批次混合"
      :visible.sync="mergeTaskShow"
      width="550px"
      class="connectedTable"
    >
      <el-form label-position="left" label-width="130px" class="el-form">
        <el-form-item required v-if="!batchMergeTaskShow">
          <template slot="label">
            自动拆分
            <el-tooltip class="item" placement="top-start">
              <div slot="content">批次拨打完成后,自动同步状态到子状态</div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >:
          </template>
          <el-switch
            v-model="autoSplit"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
        </el-form-item>
        <el-form-item required label="任务名称">
          <el-input placeholder="请输入批次编号" v-model="taskName"></el-input>
        </el-form-item>
        <el-form-item required v-if="!batchMergeTaskShow">
          <template slot="label">
            子批次列表
            <el-tooltip class="item" placement="top-start">
              <div slot="content">一行一个批次编号</div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >:
          </template>
          <el-input
            type="textarea"
            :rows="18"
            placeholder="请输入批次编号"
            v-model="childBatchNumber"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button @click="mergeTaskShow = false">取消</el-button>
        <el-button type="primary" @click="mergeTask_submit">确定</el-button>
      </div>
    </el-dialog>
    <!-- 服务器分组 -->
    <el-dialog
      title="服务器分组"
      :visible.sync="distributeTaskShow"
      width="400px"
      class="connectedTable"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">服务器分组</span>
      </template>
      <el-form label-width="100px">
        <el-form-item label="服务器分组：">
          <el-select
            v-model="serverGroupId"
            placeholder="请选择服务器分组"
            style="width: 95%"
            :disabled="!distributeTask"
            @change="serverGroupIdFocus"
            clearable
          >
            <el-option
              v-for="item in serverGroupIdList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="启用：">
          <el-switch
            v-model="distributeTask"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          v-if="!distributeTask_btn_show"
          type="primary"
          @click="distributeTaskShow = false"
          >确定</el-button
        >
        <el-button
          v-if="distributeTask_btn_show"
          type="primary"
          @click="distributeTask_submit"
          >确定</el-button
        >
        <el-button
          v-if="distributeTask_btn_show"
          @click="distributeTaskShow = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
    <!-- 自动重呼管理 -->
    <el-dialog
      title="自动重呼管理"
      :visible.sync="autoRecallShow"
      width="400px"
      class="connectedTable"
      @close="autoRecallClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">自动重呼管理</span>
      </template>
      <el-form label-width="120px">
        <el-form-item label="已重呼次数：">
          <span>{{ autoRecallCount }}</span>
        </el-form-item>
        <el-form-item label="启用：">
          <el-switch
            v-model="autoRecall"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="下次重呼时间：">
          <el-date-picker
            v-model="nextAutoRecallTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
            :disabled="!autoRecall"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="重拨间隔控制：" v-if="autoRecall">
          <el-button type="text" @click="redialShowClick('modity')"
            >设置</el-button
          >
          <div class="addWechat_info">
            <div>
              第一遍间隔：{{ oldRedialDate.first }}
              <i
                v-if="redialCheck.firstCheck"
                class="el-icon-refresh-right"
              ></i>
            </div>
            <div>
              第二遍间隔：{{ oldRedialDate.second }}
              <i
                v-if="redialCheck.secondCheck"
                class="el-icon-refresh-right"
              ></i>
            </div>
            <div>
              第三遍间隔：{{ oldRedialDate.third }}
              <i
                v-if="redialCheck.thirdCheck"
                class="el-icon-refresh-right"
              ></i>
            </div>
            <div>
              第四遍间隔：{{ oldRedialDate.four }}
              <i v-if="redialCheck.fourCheck" class="el-icon-refresh-right"></i>
            </div>
            <div>
              第五遍间隔：{{ oldRedialDate.five }}
              <i v-if="redialCheck.fiveCheck" class="el-icon-refresh-right"></i>
            </div>
            <div>
              第六遍间隔：{{ oldRedialDate.six }}
              <i v-if="redialCheck.sixCheck" class="el-icon-refresh-right"></i>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="autoRecall_submit">更新</el-button>
        <el-button @click="autoRecallShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 重拨控制 -->
    <el-dialog :visible.sync="redialShow" width="450px" @close="redialClose">
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">重拨控制</span>
      </template>
      <el-form label-position="left" label-width="115px" class="el-form">
        <el-form-item>
          <template slot="label">
            第一遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第一遍拨打后间隔 {{ oldRedialDate.first }} 可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="redialForm.intervalSecondsForFirst"
            placeholder="请选择第一遍间隔"
            @change="redialSecondsForFirstChange"
            :disabled="firstRedialDisabled"
            style="width: 150px; margin-right: 20px"
          >
            <el-option
              v-for="item in redialList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
          <el-checkbox
            v-model="redialCheck.firstCheck"
            :disabled="firstRedialDisabled"
            >重置拨打数据</el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <template slot="label">
            第二遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第二遍拨打后间隔 {{ oldRedialDate.second }} 可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="redialForm.intervalSecondsForSecond"
            placeholder="请选择第二遍间隔"
            @change="redialSecondsForSecondChange"
            :disabled="secondRedialDisabled"
            style="width: 150px; margin-right: 20px"
          >
            <el-option
              v-for="item in redialList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
          <el-checkbox
            v-model="redialCheck.secondCheck"
            :disabled="secondRedialDisabled"
            >重置拨打数据</el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <template slot="label">
            第三遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第三遍拨打后间隔 {{ oldRedialDate.third }} 可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="redialForm.intervalSecondsForThird"
            placeholder="请选择第三遍间隔"
            @change="redialSecondsForThirdChange"
            :disabled="thirdRedialDisabled"
            style="width: 150px; margin-right: 20px"
          >
            <el-option
              v-for="item in redialList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
          <el-checkbox
            v-model="redialCheck.thirdCheck"
            :disabled="thirdRedialDisabled"
            >重置拨打数据</el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <template slot="label">
            第四遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第四遍拨打后间隔 {{ oldRedialDate.four }} 可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="redialForm.intervalSecondsForFour"
            placeholder="请选择第四遍间隔"
            @change="redialSecondsForFourChange"
            :disabled="fourRedialDisabled"
            style="width: 150px; margin-right: 20px"
          >
            <el-option
              v-for="item in redialList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
          <el-checkbox
            v-model="redialCheck.fourCheck"
            :disabled="fourRedialDisabled"
            >重置拨打数据</el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <template slot="label">
            第五遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第五遍拨打后间隔 {{ oldRedialDate.five }} 可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="redialForm.intervalSecondsForFive"
            placeholder="请选择第五遍间隔"
            @change="redialSecondsForFiveChange"
            :disabled="fiveRedialDisabled"
            style="width: 150px; margin-right: 20px"
          >
            <el-option
              v-for="item in redialList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
          <el-checkbox
            v-model="redialCheck.fiveCheck"
            :disabled="fiveRedialDisabled"
            >重置拨打数据</el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <template slot="label">
            第六遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第六遍拨打后间隔 {{ oldRedialDate.six }} 可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="redialForm.intervalSecondsForSix"
            placeholder="请选择第六遍间隔"
            @change="redialSecondsForSixChange"
            :disabled="sixRedialDisabled"
            style="width: 150px; margin-right: 20px"
          >
            <el-option
              v-for="item in redialList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
          <el-checkbox
            v-model="redialCheck.sixCheck"
            :disabled="sixRedialDisabled"
            >重置拨打数据</el-checkbox
          >
        </el-form-item>
        <el-form-item label="启用：" v-if="!isRedialModity">
          <el-switch
            v-model="redialEnable"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          v-if="!isRedialModity"
          type="primary"
          @click="redialShow = false"
          >确定</el-button
        >
        <el-button v-if="isRedialModity" type="primary" @click="redial_submit"
          >更新</el-button
        >
        <el-button @click="redialShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 下载提示 -->
    <el-dialog
      :visible.sync="downloadTips"
      width="300px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeDownload"
      class="download-tips"
    >
      <div class="download-div">
        <i class="el-icon-loading loadingClass"></i>
        <span class="download-text">文件正在下载中...</span>
      </div>
    </el-dialog>
    <!-- 批量操作 -->
    <el-dialog
      :visible.sync="batchOperationShow"
      width="400px"
      @close="batchOperationShowClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">批量操作</span>
      </template>
      <div style="max-height: 550px; overflow: auto">
        <task-steps
          :active="batchTaskIndex"
          :stepsList="batchStepsList"
        ></task-steps>
      </div>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button v-if="batchOperationBtnShow" @click="batchOperationSubmit"
          >确认</el-button
        >
      </div>
    </el-dialog>
    <!-- 任务排序 -->
    <el-dialog :visible.sync="taskSortShow" width="500px">
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">任务排序</span>
      </template>
      <el-tree
        :data="taskSortList"
        node-key="id"
        ref="menuTree"
        draggable
        :allow-drop="allowDrop"
        @node-drop="handleDrop"
      >
      </el-tree>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="taskSort_submit">确定</el-button>
        <el-button @click="taskSortShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 批量重新创建 -->
    <el-dialog :visible.sync="batchRecreateTaskShow" width="600px">
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title"
          >批量重新创建
          <el-tooltip class="item" placement="top-start">
            <div slot="content">
              重新创建会把任务未接通号码找到并生成一个新的重播任务
            </div>
            <i class="el-icon-question icon-color"></i>
          </el-tooltip>
        </span>
      </template>
      <el-table
        :data="batchRecreateTaskData"
        stripe
        style="width: 100%"
        border
        size="mini"
      >
        <el-table-column
          prop="batchNumber"
          label="批次编号"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column prop="taskName" label="任务名称" align="center">
          <template slot-scope="scope">
            <el-input
              size="mini"
              placeholder="请输入任务名称"
              v-model="scope.row.taskName"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="taskName"
          label="乱序"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-switch v-model="scope.row.shuffle" active-color="#13ce66">
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="batchRecreateTask_submit"
          >确定</el-button
        >
        <el-button @click="batchRecreateTaskShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 修改任务状态 -->
    <el-dialog :visible.sync="modfityTaskStatusShow" width="400px">
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">修改任务状态</span>
      </template>
      <el-form label-position="left" label-width="90px" class="el-form">
        <el-form-item label="选择状态：">
          <el-select v-model="modfityTaskStatus" placeholder="请选择状态">
            <el-option
              v-for="item in projectStatusList"
              :key="item.status"
              :value="item.status"
              :label="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="modfityTaskStatus_submit"
          >确定</el-button
        >
        <el-button @click="modfityTaskStatusShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 修改任务名称 -->
    <el-dialog :visible.sync="modifyTaskNameShow" width="400px">
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">修改任务名称</span>
      </template>
      <el-form label-position="left" label-width="90px" class="el-form">
        <el-form-item label="任务名称：">
          <el-input
            v-model="newTaskName"
            placeholder="请输入任务名称"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="modifyTaskName_submit"
          >确定</el-button
        >
        <el-button @click="modifyTaskNameShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 数据迁移 -->
    <el-dialog
      :visible.sync="dataMigrationShow"
      width="400px"
      @close="dataMigrationClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">数据迁移</span>
      </template>
      <el-form label-position="left" label-width="100px" class="el-form">
        <el-form-item label="用户名称：" required>
          <el-select
            v-model="migrationUserLoginName"
            filterable
            remote
            :loading="loadingLoginName"
            :remote-method="remoteMethodLoginName"
            @focus="NameRemoteFocus"
            placeholder="请选择用户名称"
            @change="migrationLoginNameChange"
            clearable
          >
            <el-option
              v-for="item in loginNameList"
              :key="item.id"
              :label="item.label"
              :value="item.loginName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目名称：" required>
          <el-select
            v-model="migrationProjectId"
            filterable
            remote
            :loading="loading"
            :remote-method="migrationRemoteMethodProject"
            @focus="migrationProjectFocus"
            placeholder="请选择项目名称"
            clearable
            :disabled="migrationProjectDisabled"
          >
            <el-option
              v-for="item in projectNameList"
              :key="item.id"
              :label="item.projectName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="dataMigration_submit">确定</el-button>
        <el-button @click="dataMigrationShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 数据迁移进度 -->
    <el-dialog
      :visible.sync="dataProgressShow"
      width="400px"
      :title="'数据迁移中 ( ' + batchNumberMigration + ' )'"
      @close="dataProgressClose"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <el-progress
          :text-inside="true"
          :stroke-width="24"
          :percentage="progressNum"
          status="success"
        ></el-progress>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        align="center"
        v-if="isDataProgressShow"
      >
        <el-button @click="dataProgressShow = false">迁移完成</el-button>
      </div>
    </el-dialog>
    <!-- 模板选择 -->
    <taksTypeTemplate
      ref="taksTypeTemplate"
      :taskType="form.taskType"
      @selectTemplate="selectTemplate"
    ></taksTypeTemplate>
  </div>
</template>

<script>
import taskSteps from "../pages/taskSteps.vue";
import distributedTask from "./distributedTask.vue";
import strategyManage from "../pages/strategyManage.vue";
import taksTypeTemplate from "../pages/taksTypeTemplate.vue";
export default {
  name: "project",
  components: {
    strategyManage,
    taskSteps,
    distributedTask,
    taksTypeTemplate,
  },
  data() {
    return {
      modifyTaskNameShow: false,
      newTaskName: "",
      isCategoryJiaoyu: false,
      batchNumberMigration: "",
      isDataProgressShow: false,
      dataMigrationTimer: null,
      progressNum: 0,
      dataProgressShow: false,
      migrationUserLoginName: "",
      dataMigrationShow: false,
      migrationProjectDisabled: true,
      migrationProjectId: "",
      filterClosed: true,
      isBatchRouteNameCall: false,
      modfityTaskNumber: "",
      modfityTaskStatus: "",
      modfityTaskStatusShow: false,
      distributeTaskDisabled: true,
      serverGroupId: "",
      serverGroupIdList: [],
      distributeTaskShow: false,
      distributeTaskName: "",
      distributeTask: false,
      distributedTaskShow: false,
      activeName: "first",
      projectTypeList: [],
      autoRecallCountList: [
        {
          value: 1,
          label: "一遍",
        },
        {
          value: 2,
          label: "二遍",
        },
        {
          value: 3,
          label: "三遍",
        },
        {
          value: 4,
          label: "四遍",
        },
        {
          value: 5,
          label: "五遍",
        },
        {
          value: 6,
          label: "六遍",
        },
      ],
      batchRecreateTaskData: [],
      batchRecreateTaskShow: false,
      taskSortDataList: [],
      taskPriorityItemList: [],
      taskSortList: [],
      waitingTeamId: "",
      taskSortShow: false,
      batchTaskIndex: 0,
      inspectIndex: 0,
      batchOperationShow: false,
      batchOperationBtnShow: false,
      batchTaskStepsList: [],
      batchStepsList: [],
      recycled: true,
      longUrlValue: "",
      smsDataLabelValue: "",
      smsSign: "",
      prefixValue: "https://",
      clickCallValue: "NONE",
      inquireTime: null,
      inquireBtnDisabled: false,
      batchNumberTextarea: "",
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() < start - 60 * 60 * 24 * 1000;
        },
      },
      pickerOptionsCall: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
      },
      isRedialModity: false,
      redialList: [
        {
          label: "不拨打",
          value: -1,
        },
        {
          label: "半小时",
          value: 1800,
        },
        {
          label: "1小时",
          value: 3600,
        },
        {
          label: "2小时",
          value: 7200,
        },
        {
          label: "3小时",
          value: 10800,
        },
        {
          label: "4小时",
          value: 14400,
        },
        {
          label: "5小时",
          value: 18000,
        },
        {
          label: "6小时",
          value: 21600,
        },
        {
          label: "12小时",
          value: 43200,
        },
        {
          label: "1天",
          value: 86400,
        },
        {
          label: "2天",
          value: 172800,
        },
        {
          label: "3天",
          value: 259200,
        },
      ],
      oldRedialDate: {
        first: "1小时",
        second: "1小时",
        third: "1小时",
        four: "1小时",
        five: "1小时",
        six: "1小时",
      },
      redialForm: {
        intervalSecondsForFirst: 3600,
        intervalSecondsForSecond: 3600,
        intervalSecondsForThird: 3600,
        intervalSecondsForFour: 3600,
        intervalSecondsForFive: 3600,
        intervalSecondsForSix: 3600,
      },
      redialCheck: {
        firstCheck: false,
        secondCheck: false,
        thirdCheck: false,
        fourCheck: false,
        fiveCheck: false,
        sixCheck: false,
      },
      redialEnable: false,
      redialShow: false,
      autoRecallShow: false,
      autoRecallCount: "",
      nextAutoRecallTime: "",
      autoRecall: false,
      operatorsArr: [
        {
          label: "中国移动",
          value: "中国移动",
        },
        {
          label: "中国联通",
          value: "中国联通",
        },
        {
          label: "中国电信",
          value: "中国电信",
        },
        {
          label: "其它",
          value: "其它",
        },
      ],
      taskTypeList: [
        {
          label: "普通",
          value: "NORMAL",
        },
        {
          label: "开场白+人工",
          value: "PROLOGUE",
        },
        {
          label: "开场白+短信",
          value: "PROLOGUE_SMS",
        },
        {
          label: "开场白+微信",
          value: "PROLOGUE_WECHAT",
        },
        {
          label: "AI机器人",
          value: "AI_ROBOT",
        },
      ],
      isAddPlatform: true,
      options: [],
      platformArr: [],
      waitingAutoStart: false,
      strategy_from_hangupsms: "",
      SMS: {
        totalNum: 0,
        signLength: 0,
        contentNum: 0,
        num: 1,
      },
      isEndNumDis: false,
      isCityDis: false,
      SMStextarea: "",
      uploadMethod: "first",
      uploadNumber: "",
      customerCodesList: [],
      isPrologueDisabled: false,
      callbackLineId: "",
      isCallbackLine: false,
      callbackLine: "",
      teamCurrentPage: 1,
      teamtotal: null,
      tableShow: false,
      teamData: [],
      originateTimeoutList: [
        {
          value: "30",
          label: "30",
        },
        {
          value: "40",
          label: "40",
        },
      ],
      loadingTable: false,
      loadingTab: false,
      lineUpForm: [],
      downloadTips: false,
      batchNumber: "",
      inquireTaskName: "",
      userLoginName: "",
      loadingLoginName: false,
      loginName: "",
      loginNameList: [],
      userNameId: "",
      userNameList: [],
      loading: false,
      loadingProject: false,
      loadingUserName: false,
      callStatusList: "",
      projectId: "",
      detailsCurrentPage: 1,
      detailsTotal: null,
      connectedTableShow: false,
      connectedTableData: null,
      isSubmit: false,
      isSeat: false,
      isNotWorkingTime: false,
      resumeTask: null,
      projectName: "",
      taskName: "",
      teamName: "",
      routeName: "",
      workingTimeStr: [],
      onlineCount: "",
      waitingTaskCount: "",
      callStartShow: false,
      sipDisabled: true,
      peojectSipTeamId: "",
      projectSipRouteId: "",
      teamNameId: "",
      teamNameList: [],
      sipTeamId: "",
      sipRouteId: "",
      numberUploadData: {},
      numberUrl: "",
      numberForm: {
        number: "",
        taskId: "",
      },
      numberDialogShow: false,
      // callStatus:"NOT_RECEIVED",
      createForm: {
        taskName: "",
        taskId: "",
      },
      props: {
        multiple: true,
        emitPath: false,
        value: "code",
        label: "name",
        checkStrictly: true,
      },
      crossTaskNameList: [],
      createDialogShow: false,
      lineId: "",
      lineList: [],
      changeLineShow: false,
      changeTeamNameShow: false,
      strategy: {},
      taskId: "",
      id: "",
      projectListId: "",
      projectList: [],
      packRecordShow: false,
      pauseCallShow: false,
      tableData: [],
      currentPage: null,
      total: null,
      addProject: false,
      labelLeft: "left",
      form: {
        id: "",
        projectName: "",
        cityList: [],
        operator: [],
        crossTaskNameList: [],
        taskType: "NORMAL",
      },
      formWidth: "100px",
      uploadData: {},
      url: "",
      fileList: [],
      cookie: true,
      condition: {
        projectId: "",
        projectStatus: "",
        newStopCallOnFull: null,
        autoRecallCount: "",
        categoryId: "",
        firstCallAtStart: "",
        firstCallAtEnd: "",
      },
      projectNameList: [],
      strategyList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      projectStatusList: [
        {
          status: "CREATED",
          label: "新建状态",
        },
        {
          status: "QUEUING",
          label: "排队中",
        },
        {
          status: "WAITING_CALL",
          label: "等待拨打",
        },
        {
          status: "CALLING",
          label: "拨打中",
        },
        {
          status: "CREATING",
          label: "创建中",
        },
        {
          status: "ORDERING",
          label: "排序中",
        },
        {
          status: "TRANSFERING",
          label: "迁移中",
        },
        {
          status: "PAUSING",
          label: "暂停中",
        },
        {
          status: "PAUSED",
          label: "已暂停",
        },
        {
          status: "EXCEPTION",
          label: "异常",
        },
        {
          status: "FINISHED",
          label: "已完成",
        },
        {
          status: "WAIT_AUTO_RECALL",
          label: "等待自动重拨",
        },
        {
          status: "CLEANING",
          label: "清理中",
        },
        {
          status: "MERGING",
          label: "合并中",
        },
        {
          status: "MERGED",
          label: "已合并",
        },
        {
          status: "FINISHING",
          label: "结束中",
        },
        {
          status: "UN_MERGING",
          label: "拆分中",
        },
        {
          status: "DELETING",
          label: "删除中",
        },
      ],
      mobileVisible: true,
      timer: {},
      timerPaus: {},
      timerCreat: {},
      timerClean: {},
      timerOrdering: {},
      timerQueue: {},
      status: "",
      totalCallCount: "",
      pauseCallDisabled: true,
      addDisorder: false,
      numberDisorder: false,
      createDisorder: false,
      markReused: true,
      isNumberSubmit: false,
      iscreateSubmit: false,
      isChangeLineSubmit: false,
      isAddProject_submit: false,
      isChangeTeamName_submit: false,
      pageSize: 10,
      downloadTimer: null,
      downloadId: null,
      teamLoginName: "",
      taskServer: "",
      refreshData: {},
      projectDisabled: true,
      taskBatchNumber: "",
      template_select_type: "",
      isAddtemplate: false,
      strategyName: "",
      templateId: "",
      template_name_label: "",
      hangupSmsStrategyTemplateId: "",
      batchNumberExportShow: false,
      isCloseTask: false,
      skipNormalClearing: false,
      unifiedLoading: null,
      multipleSelection: [],
      distributeTask_btn_show: false,
      autoSplit: true,
      childBatchNumber: "",
      mergeTaskShow: false,
      batchMergeTaskShow: false,
      statusTimerList: [],
      filterMerged: false,
    };
  },
  props: ["roleMenu", "powers"],
  mounted() {
    document.title = "惠销平台管理系统-任务列表";
    this.inquire(1);
    this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true });
    this.getProvinceAndCity();
    this.getProjectTypeList();
  },
  computed: {
    isCategoryJiaoyuShow() {
      let title = "";
      if (this.isCategoryJiaoyu) {
        if (!this.createDisorder) {
          title = "有人工坐席项目推荐开启乱序";
        }
        return title;
      } else {
        if (this.createDisorder) {
          title = "无人工坐席项目推荐关闭乱序";
        }
        return title;
      }
    },
    firstRedialDisabled() {
      // 重拨次数大于0禁用
      if (this.autoRecallCount >= 1) {
        return true;
      }
      return false;
    },
    secondRedialDisabled() {
      // 重拨次数大于1禁用
      if (this.autoRecallCount >= 2) {
        return true;
      }
      if (this.redialForm.intervalSecondsForFirst === -1) {
        this.redialForm.intervalSecondsForSecond = -1;
        this.redialForm.intervalSecondsForThird = -1;
        this.redialForm.intervalSecondsForFour = -1;
        this.redialForm.intervalSecondsForFive = -1;
        this.redialForm.intervalSecondsForSix = -1;
        this.redialSecondsForSecondChange(
          this.redialForm.intervalSecondsForSecond
        );
        this.redialSecondsForThirdChange(
          this.redialForm.intervalSecondsForThird
        );
        this.redialSecondsForFourChange(this.redialForm.intervalSecondsForFour);
        this.redialSecondsForFiveChange(this.redialForm.intervalSecondsForFive);
        this.redialSecondsForSixChange(this.redialForm.intervalSecondsForSix);
        this.redialCheck.secondCheck = false;
        this.redialCheck.thirdCheck = false;
        this.redialCheck.fourCheck = false;
        this.redialCheck.fiveCheck = false;
        this.redialCheck.sixCheck = false;
        return true;
      }
      return false;
    },
    thirdRedialDisabled() {
      // 重拨次数大于2禁用
      if (this.autoRecallCount >= 3) {
        return true;
      }
      if (this.redialForm.intervalSecondsForSecond === -1) {
        this.redialForm.intervalSecondsForThird = -1;
        this.redialForm.intervalSecondsForFour = -1;
        this.redialForm.intervalSecondsForFive = -1;
        this.redialForm.intervalSecondsForSix = -1;
        this.redialSecondsForThirdChange(
          this.redialForm.intervalSecondsForThird
        );
        this.redialSecondsForFourChange(this.redialForm.intervalSecondsForFour);
        this.redialSecondsForFiveChange(this.redialForm.intervalSecondsForFive);
        this.redialSecondsForSixChange(this.redialForm.intervalSecondsForSix);
        this.redialCheck.thirdCheck = false;
        this.redialCheck.fourCheck = false;
        this.redialCheck.fiveCheck = false;
        this.redialCheck.sixCheck = false;
        return true;
      }
      return false;
    },
    fourRedialDisabled() {
      // 重拨次数大于3禁用
      if (this.autoRecallCount >= 4) {
        return true;
      }
      if (this.redialForm.intervalSecondsForThird === -1) {
        this.redialForm.intervalSecondsForFour = -1;
        this.redialForm.intervalSecondsForFive = -1;
        this.redialForm.intervalSecondsForSix = -1;
        this.redialSecondsForFourChange(this.redialForm.intervalSecondsForFour);
        this.redialSecondsForFiveChange(this.redialForm.intervalSecondsForFive);
        this.redialSecondsForSixChange(this.redialForm.intervalSecondsForSix);
        this.redialCheck.fourCheck = false;
        this.redialCheck.fiveCheck = false;
        this.redialCheck.sixCheck = false;
        return true;
      }
      return false;
    },
    fiveRedialDisabled() {
      // 重拨次数大于4禁用
      if (this.autoRecallCount >= 5) {
        return true;
      }
      if (this.redialForm.intervalSecondsForFour === -1) {
        this.redialForm.intervalSecondsForFive = -1;
        this.redialForm.intervalSecondsForSix = -1;
        this.redialSecondsForFiveChange(this.redialForm.intervalSecondsForFive);
        this.redialSecondsForSixChange(this.redialForm.intervalSecondsForSix);
        this.redialCheck.fiveCheck = false;
        this.redialCheck.sixCheck = false;
        return true;
      }
      return false;
    },
    sixRedialDisabled() {
      // 重拨次数大于5禁用
      if (this.autoRecallCount >= 6) {
        return true;
      }
      if (this.redialForm.intervalSecondsForFive === -1) {
        this.redialForm.intervalSecondsForSix = -1;
        this.redialSecondsForSixChange(this.redialForm.intervalSecondsForSix);
        this.redialCheck.sixCheck = false;
        return true;
      }
      return false;
    },
    sipTeamDisabled() {
      // 判断项目是否选择
      if (this.sipDisabled) {
        return true;
      } else {
        return false;
      }
    },
    dropdownDisabled() {
      // 是否禁用批量操作
      if (this.multipleSelection.length > 0) {
        return false;
      }
      return true;
    },
  },
  watch: {
    // 监听新增项目选择
    projectListId(id) {
      if (!id) {
        this.sipDisabled = true;
        return;
      }
      this.sipDisabled = false;
      for (let i = 0; i < this.projectList.length; i++) {
        if (this.projectList[i].id == id) {
          for (let j of this.teamNameList) {
            if (j.id == this.projectList[i].defaultTeamId) {
              this.$nextTick(() => {
                this.peojectSipTeamId = this.projectList[i].defaultTeamId;
              });
            } else {
              this.peojectSipTeamId = "";
            }
          }
          for (let c of this.lineList) {
            if (c.id == this.projectList[i].defaultRouteId) {
              this.$nextTick(() => {
                this.projectSipRouteId = this.projectList[i].defaultRouteId;
              });
            } else {
              this.projectSipRouteId = "";
            }
            if (c.id == this.projectList[i].defaultCallbackRouteId) {
              this.$nextTick(() => {
                this.callbackLine = this.projectList[i].defaultCallbackRouteId;
              });
            } else {
              this.callbackLine = "";
            }
          }
        }
      }
    },
    // 手动上传号码处理
    uploadNumber(val) {
      let codes = this.uploadNumber.replace(/[\r\n]/g, ",");
      let codesList = codes.split(",").filter((item) => item);
      for (let i in codesList) {
        codesList[i] = codesList[i].replace(/\s/g, "");
      }
      this.customerCodesList = codesList;
    },
  },
  methods: {
    // 其它操作显示
    taskOtherOperationShow(key) {
      let powers = this.powers;
      for (let i in powers) {
        let item = powers[i];
        if (item.subMenus) {
          for (let j in item.subMenus) {
            let menu = item.subMenus[j];
            let index = JSON.parse(menu.extraMsg).index;
            if (index === key) {
              return true;
            }
          }
        }
      }
      return false;
    },
    // 点击修改任务名称
    modifyTaskName(val) {
      this.modifyTaskNameShow = true;
      this.newTaskName = val.taskName;
      this.taskBatchNumber = val.batchNumber;
    },
    // 确认修改任务名称
    modifyTaskName_submit() {
      let data = {
        batchNumber: this.taskBatchNumber,
        taskName: this.newTaskName,
      };
      if (!data.taskName) {
        this.$message.warning("任务名称不能为空");
        return;
      }
      this.$service("/sip/task/modifyTaskName", data).then((res) => {
        if (res.result === 200) {
          this.modifyTaskNameShow = false;
          this.$message.success("修改成功");
          this.inquire();
        }
      });
    },
    // 点击模板选择
    templateSelection(type) {
      this.$refs.taksTypeTemplate.init();
    },
    // 点击选择模板选择
    selectTemplate(val) {
      this.template_name_label = val.template_name_label;
      this.hangupSmsStrategyTemplateId = val.hangupSmsStrategyTemplateId;
    },
    // 切换tab
    handleClick() {
      if (this.activeName === "first") {
        this.distributedTaskShow = false;
      }
      if (this.activeName === "second") {
        this.distributedTaskShow = true;
      }
    },
    // 确定修改服务器分组
    distributeTask_submit() {
      let data = {
        batchNumber: this.taskBatchNumber,
        distributeServerGroupId: this.serverGroupId,
        distributeTask: this.distributeTask,
      };
      if (!this.serverGroupId && this.distributeTask) {
        this.$message.warning("请选择服务器分组");
        return;
      }
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.$service("/distribute/updateTaskGroupId", data, "post", path).then(
        (res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.distributeTaskShow = false;
            this.inquire();
          }
        }
      );
    },
    // 点击分布式任务
    distributeTaskClick(val) {
      this.distributeTaskShow = true;
      this.distributeTask_btn_show = false;
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      if (val) {
        data.userLoginName = val.userLoginName;
        this.distributeTask = val.distributeTask;
        this.taskBatchNumber = val.batchNumber;
        if (val.distributeServerGroupId) {
          this.serverGroupId = val.distributeServerGroupId;
        } else {
          this.serverGroupId = "";
        }
        this.distributeTask = val.distributeTask;
        this.taskServer = val.server;
        this.distributeTask_btn_show = true;
      } else {
        data.userLoginName = this.userNameId;
      }
      this.getServerGroupList(data);
    },
    // 查询服务器分组列表
    getServerGroupList(data) {
      this.serverGroupIdList = [];
      this.$http("/serverInfo/serverGroupList", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            let list = res.data.records;
            this.serverGroupIdList = list;
          }
        }
      );
    },
    // 服务器分组选择
    serverGroupIdFocus(val) {
      if (!val) {
        this.distributeTaskName = "";
      }
      this.serverGroupIdList.forEach((item) => {
        if (item.id === val) {
          this.distributeTaskName = item.name;
        }
      });
    },
    // 查询任务类别
    getProjectTypeList() {
      this.$http("/sip/project/projectCategoryList", null, "get").then(
        (res) => {
          if (res.result === 200) {
            let list = res.data;
            this.projectTypeList = list;
          }
        }
      );
    },
    // 点击任务排序
    taskSortClick(val) {
      this.waitingTeamId = val.sipTeamId;
      this.taskServer = val.server;
      this.taskSortList = [];
      this.taskSortDataList = [];
      let data = {
        teamId: val.sipTeamId,
      };
      this.$http("/sip/team/teamQueueInfo", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            let list = res.data;
            list.forEach((item) => {
              if (item.status === "WAITING") {
                item.statusLable = "等待中";
              }
              if (item.status === "CALLING") {
                item.statusLable = "拨打中";
              }
              if (item.status === "WAITING_AUTO_START") {
                item.statusLable = "等待自启动";
              }
              this.taskSortList.push({
                id: item.id,
                label:
                  item.batchNumber +
                  " | " +
                  item.taskName +
                  " | " +
                  item.statusLable,
              });
            });
            this.taskSortDataList = list;
          }
        }
      );
      this.taskSortShow = true;
    },
    allowDrop(draggingNode, dropNode, type) {
      //任务拖拽时判定目标节点能否被放置
      if (type === "inner") {
        return false;
      } else {
        return true;
      }
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      //任务拖拽成功时触发的事件
      this.taskPriorityItemList = [];
      this.taskSortList.forEach((item) => {
        this.taskSortDataList.forEach((menu) => {
          if (item.id === menu.id) {
            let obj = {
              batchNumber: menu.batchNumber,
              priority: menu.priority,
              status: menu.status,
            };
            this.taskPriorityItemList.push(obj);
          }
        });
      });
    },
    // 确定任务排序
    taskSort_submit() {
      let data = {
        teamId: this.waitingTeamId,
        taskPriorityItemList: this.taskPriorityItemList,
      };
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.$service("/sip/team/modifyQueuePriority", data, "post", path).then(
        (res) => {
          if (res.result === 200) {
            this.$message.success("排序成功");
            this.taskSortShow = false;
            this.inquire();
          }
        }
      );
    },
    // 表格多选
    handleSelectionChange(val) {
      this.batchTaskStepsList = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach((item) => {
        let obj = {
          batchNumber: item.batchNumber,
          projectName: item.projectName,
          taskName: item.taskName,
          totalCallCount: item.totalCallCount,
          routeName: item.sipRouteName,
          taskId: item.id,
          teamId: item.sipTeamId,
          loginName: item.userLoginName,
          server: item.server,
          resumeTask: item.resumeTask,
          onlineCount: "",
          waitingTaskCount: "",
          finishStatus: "wait",
        };
        this.batchTaskStepsList.push(obj);
      });
    },
    // 批量操作--开始拨打
    startCall() {
      this.batchTaskIndex = 0;
      this.inspectIndex = 0;
      this.batchStepsList = this.$common.deepClone(this.batchTaskStepsList);
      this.batchTaskStepInspect(this.batchStepsList, this.batchStepsList[0], 0);
    },
    // 批量操作--暂停拨打
    suspendCall() {
      this.batchTaskIndex = 0;
      this.batchOperationShow = true;
      this.batchStepsList = this.$common.deepClone(this.batchTaskStepsList);
      this.batchTaskStepStop(this.batchStepsList, this.batchStepsList[0], 0);
    },
    // 批量操作--修改线路
    routeNameCall() {
      this.isCallbackLine = false;
      this.changeLineShow = true;
      this.isBatchRouteNameCall = true;
      this.batchStepsList = this.$common.deepClone(this.batchTaskStepsList);
      let params = { pageNo: 1, pageSize: 1000, loginName: this.loginName };
      this.$http("/route/customerList", null, "get", null, params).then(
        (res) => {
          if (res.result == 200) {
            this.lineList = res.data.list;
          }
        }
      );
    },
    // 执行批量操作--修改线路
    changeBatchLine_submit() {
      if (!this.lineId) {
        this.$message.warning("请选择线路");
        return;
      }
      this.batchTaskIndex = 0;
      this.batchOperationShow = true;
      this.batchTaskStepRoute(this.batchStepsList, this.batchStepsList[0], 0);
    },
    // 批量任务操作--修改线路
    batchTaskStepRoute(stepsList, data, index) {
      if (index > this.batchStepsList.length - 1) {
        this.batchOperationBtnShow = true;
        this.changeLineShow = false;
        return;
      }
      data.finishStatus = "process";
      let params = {
        taskId: data.taskId,
        loginName: data.loginName,
        routeId: this.lineId,
      };
      let path = "https://" + data.server + this.$constants.basePath;
      this.$http("/sip/task/modifyRoute", params, "post", path, null)
        .then((res) => {
          if (res.result === 200) {
            this.batchTaskIndex++;
            data.finishStatus = "success";
            return this.batchTaskStepRoute(
              this.batchStepsList,
              this.batchStepsList[this.batchTaskIndex],
              this.batchTaskIndex
            );
          } else {
            this.batchTaskIndex++;
            data.finishStatus = "error";
            return this.batchTaskStepRoute(
              this.batchStepsList,
              this.batchStepsList[this.batchTaskIndex],
              this.batchTaskIndex
            );
          }
        })
        .catch((err) => {
          this.batchTaskIndex++;
          data.finishStatus = "error";
          return this.batchTaskStepRoute(
            this.batchStepsList,
            this.batchStepsList[this.batchTaskIndex],
            this.batchTaskIndex
          );
        });
    },
    // 批量操作--重新创建
    recreateCall() {
      this.batchRecreateTaskData = [];
      let selectData = this.$common.deepClone(this.multipleSelection);
      for (let i in selectData) {
        if (!selectData[i].reCreateShow || selectData[i].reCreateDisabled) {
          this.$message.warning(selectData[i].batchNumber + " 不能重新创建");
          return;
        }
        let taskName = selectData[i].taskName;
        if (taskName.indexOf("-重拨") !== -1) {
          let i = taskName.indexOf("-重拨");
          let num = taskName.substring(i + 3);
          num = parseInt(num);
          num++;
          let newTaskName = taskName.substring(0, i + 3);
          taskName = newTaskName + num;
        } else {
          taskName = taskName + "-重拨" + 1;
        }
        let obj = {
          batchNumber: selectData[i].batchNumber,
          taskId: selectData[i].id,
          taskName: taskName,
          // callStatus: "NOT_RECEIVED",
          loginName: selectData[i].userLoginName,
          server: selectData[i].server,
          shuffle: false,
          finishStatus: "wait",
          projectName: selectData[i].projectName,
          totalCallCount: selectData[i].totalCallCount,
          routeName: selectData[i].sipRouteName,
          resumeTask: selectData[i].resumeTask,
        };
        this.batchRecreateTaskData.push(obj);
      }
      this.batchTaskStepsList = this.batchRecreateTaskData;
      this.batchRecreateTaskShow = true;
    },
    // 执行批量操作--重新创建
    batchRecreateTask_submit() {
      this.batchTaskIndex = 0;
      this.batchOperationShow = true;
      this.batchStepsList = this.$common.deepClone(this.batchTaskStepsList);
      this.batchTaskStepRecreate(
        this.batchStepsList,
        this.batchStepsList[0],
        0
      );
      this.batchRecreateTaskShow = false;
    },
    // 批量任务操作--重新创建
    batchTaskStepRecreate(stepsList, data, index) {
      if (index > this.batchStepsList.length - 1) {
        this.batchOperationBtnShow = true;
        return;
      }
      data.finishStatus = "process";
      let params = {
        taskId: data.taskId,
        taskName: data.taskName,
        // callStatus: data.callStatus,
        loginName: data.loginName,
        shuffle: data.shuffle,
      };
      let path = "https://" + data.server + this.$constants.basePath;
      this.$http("/sip/task/recycleCreate", params, "post", path, null)
        .then((res) => {
          if (res.result === 200) {
            this.batchTaskIndex++;
            data.finishStatus = "success";
            return this.batchTaskStepRecreate(
              this.batchStepsList,
              this.batchStepsList[this.batchTaskIndex],
              this.batchTaskIndex
            );
          } else {
            this.batchTaskIndex++;
            data.finishStatus = "error";
            return this.batchTaskStepRecreate(
              this.batchStepsList,
              this.batchStepsList[this.batchTaskIndex],
              this.batchTaskIndex
            );
          }
        })
        .catch((err) => {
          this.batchTaskIndex++;
          data.finishStatus = "error";
          return this.batchTaskStepRecreate(
            this.batchStepsList,
            this.batchStepsList[this.batchTaskIndex],
            this.batchTaskIndex
          );
        });
    },
    // 批量任务操作--检查
    batchTaskStepInspect(stepsList, data, index) {
      if (index > this.batchStepsList.length - 1) {
        this.batchOperationShow = true;
        this.batchTaskStepStart(this.batchStepsList, this.batchStepsList[0], 0);
        return;
      }
      let params = {
        teamId: data.teamId,
        taskId: data.taskId,
        loginName: data.loginName,
      };
      let path = "https://" + data.server + this.$constants.basePath;
      this.$http("/sip/team/status", null, "get", path, params)
        .then((res) => {
          if (res.result === 200) {
            data.onlineCount = res.data.onlineCount;
            data.waitingTaskCount = res.data.waitingTaskCount;
            // 如果等待坐席为0禁止拨打
            if (data.waitingTaskCount == 0) {
              this.$message.warning(
                data.batchNumber + " 不满足拨打条件，不能开启"
              );
              return;
            }
            // 如果不在工作时间且是不能自动拨打的禁止排队拨打
            if (!res.data.isLegalWorkingTime && !res.data.autoStartEnabled) {
              this.$message.warning(
                data.batchNumber + " 不满足拨打条件，不能开启"
              );
              return;
            }
            this.inspectIndex++;
            return this.batchTaskStepInspect(
              this.batchStepsList,
              this.batchStepsList[this.inspectIndex],
              this.inspectIndex
            );
          }
        })
        .catch((err) => {
          this.$message.warning(data.batchNumber + " 不满足拨打条件，不能开启");
        });
    },
    // 批量任务操作--开始
    batchTaskStepStart(stepsList, data, index) {
      if (index > this.batchStepsList.length - 1) {
        this.batchOperationBtnShow = true;
        return;
      }
      data.finishStatus = "process";
      let params = {
        teamId: data.teamId,
        taskId: data.taskId,
        loginName: data.loginName,
      };
      let path = "https://" + data.server + this.$constants.basePath;
      this.$http("/sip/team/status", null, "get", path, params)
        .then((res) => {
          if (res.result === 200) {
            data.onlineCount = res.data.onlineCount;
            data.waitingTaskCount = res.data.waitingTaskCount;
            // 如果等待坐席为0禁止拨打
            if (data.waitingTaskCount == 0) {
              this.batchTaskIndex++;
              data.finishStatus = "error";
              return;
            }
            // 如果不在工作时间且是不能自动拨打的禁止排队拨打
            if (!res.data.isLegalWorkingTime && !res.data.autoStartEnabled) {
              this.batchTaskIndex++;
              data.finishStatus = "error";
              return;
            }
            let resumeData = {
              id: data.taskId,
              loginName: data.loginName,
            };
            if (data.resumeTask) {
              this.$http("/sip/task/resumeTask", null, "get", path, resumeData)
                .then((res) => {
                  if (res.result == 200) {
                    this.batchTaskIndex++;
                    data.finishStatus = "success";
                    return this.batchTaskStepStart(
                      this.batchStepsList,
                      this.batchStepsList[this.batchTaskIndex],
                      this.batchTaskIndex
                    );
                  } else {
                    this.batchTaskIndex++;
                    data.finishStatus = "error";
                    return this.batchTaskStepStart(
                      this.batchStepsList,
                      this.batchStepsList[this.batchTaskIndex],
                      this.batchTaskIndex
                    );
                  }
                })
                .catch((err) => {
                  this.batchTaskIndex++;
                  data.finishStatus = "error";
                  return this.batchTaskStepStart(
                    this.batchStepsList,
                    this.batchStepsList[this.batchTaskIndex],
                    this.batchTaskIndex
                  );
                });
            } else {
              this.$http("/sip/task/startTask", null, "get", path, resumeData)
                .then((res) => {
                  if (res.result == 200) {
                    this.batchTaskIndex++;
                    data.finishStatus = "success";
                    return this.batchTaskStepStart(
                      this.batchStepsList,
                      this.batchStepsList[this.batchTaskIndex],
                      this.batchTaskIndex
                    );
                  } else {
                    this.batchTaskIndex++;
                    data.finishStatus = "error";
                    return this.batchTaskStepStart(
                      this.batchStepsList,
                      this.batchStepsList[this.batchTaskIndex],
                      this.batchTaskIndex
                    );
                  }
                })
                .catch((err) => {
                  this.batchTaskIndex++;
                  data.finishStatus = "error";
                  return this.batchTaskStepStart(
                    this.batchStepsList,
                    this.batchStepsList[this.batchTaskIndex],
                    this.batchTaskIndex
                  );
                });
            }
          }
        })
        .catch((err) => {
          this.batchTaskIndex++;
          data.finishStatus = "error";
          return this.batchTaskStepStart(
            this.batchStepsList,
            this.batchStepsList[this.batchTaskIndex],
            this.batchTaskIndex
          );
        });
    },
    // 批量任务操作--暂停
    batchTaskStepStop(stepsList, data, index) {
      if (index > this.batchStepsList.length - 1) {
        this.batchOperationBtnShow = true;
        return;
      }
      data.finishStatus = "process";
      let params = {
        taskId: data.taskId,
        loginName: data.loginName,
      };
      let path = "https://" + data.server + this.$constants.basePath;
      this.$http("/sip/task/pauseTask", null, "get", path, params)
        .then((res) => {
          if (res.result == 200) {
            this.batchTaskIndex++;
            data.finishStatus = "success";
            return this.batchTaskStepStop(
              this.batchStepsList,
              this.batchStepsList[this.batchTaskIndex],
              this.batchTaskIndex
            );
          } else {
            this.batchTaskIndex++;
            data.finishStatus = "success";
            return this.batchTaskStepStop(
              this.batchStepsList,
              this.batchStepsList[this.batchTaskIndex],
              this.batchTaskIndex
            );
          }
        })
        .catch((err) => {
          this.batchTaskIndex++;
          data.finishStatus = "error";
          return this.batchTaskStepStop(
            this.batchStepsList,
            this.batchStepsList[this.batchTaskIndex],
            this.batchTaskIndex
          );
        });
    },
    // 批量任务--取消排队
    pauseLineupCall() {
      this.batchTaskIndex = 0;
      this.batchOperationShow = true;
      this.batchStepsList = this.$common.deepClone(this.batchTaskStepsList);
      this.batchTaskStepPause(this.batchStepsList, this.batchStepsList[0], 0);
    },
    // 批量任务操作--取消排队
    batchTaskStepPause(stepsList, data, index) {
      if (index > this.batchStepsList.length - 1) {
        this.batchOperationBtnShow = true;
        return;
      }
      data.finishStatus = "process";
      let params = {
        taskId: data.taskId,
        teamId: data.teamId,
      };
      let path = "https://" + data.server + this.$constants.basePath;
      this.$http("/sip/task/cancelQueue", null, "get", path, params)
        .then((res) => {
          if (res.result == 200) {
            this.batchTaskIndex++;
            data.finishStatus = "success";
            return this.batchTaskStepPause(
              this.batchStepsList,
              this.batchStepsList[this.batchTaskIndex],
              this.batchTaskIndex
            );
          } else {
            this.batchTaskIndex++;
            data.finishStatus = "error";
            return this.batchTaskStepPause(
              this.batchStepsList,
              this.batchStepsList[this.batchTaskIndex],
              this.batchTaskIndex
            );
          }
        })
        .catch((err) => {
          this.batchTaskIndex++;
          data.finishStatus = "error";
          return this.batchTaskStepPause(
            this.batchStepsList,
            this.batchStepsList[this.batchTaskIndex],
            this.batchTaskIndex
          );
        });
    },
    // 批量操作--策略管理
    batchSetStrategy() {
      let selectDataType = this.$common.deepClone(this.multipleSelection);
      for (let i = 0; i < selectDataType.length; i++) {
        for (let j = i + 1; j < selectDataType.length; j++) {
          if (selectDataType[i].type === selectDataType[j].type) {
            selectDataType.splice(j, 1);
            j--;
          }
        }
      }
      if (selectDataType.length > 1) {
        this.$message.warning(
          selectDataType[0].batchNumber +
            " 与 " +
            selectDataType[1].batchNumber +
            " 任务类型不一样,不能批量操作"
        );
        return;
      }
      this.strategy = {
        templateType: selectDataType[0].type,
        originateTimeout: "",
        answerInterval: "",
        stopCallOnFull: false,
        pauseSecondsOnFull: "",
        addWechatIfMarkFlag: false,
        flagWechatClientGroupId: "",
        showAddWechatButton: false,
        showSendSmsButton: false,
        smsLongUrlGroup: "",
        extraLongUrlGroup: "",
        clientWechatServer: "",
        clientWechatClientGroupId: "",
        // ----------------
        addWechatIfMarkUnFlag: false,
        // notifyAddFans: false,
        unFlagWechatServer: "",
        unFlagWechatClientGroupId: "",
        addWechatIfMarkUnFlagTime: "",
        // notifyAddFansTimes: "",
        // addWechatOnHangup: false,
        // changeCustomerSourceType: false,
      };
      this.strategyName = "批量设置策略";
      this.template_select_type = "batchSet";
      this.strategy_from_hangupsms = selectDataType[0].type;
      this.isAddtemplate = false;
      this.SMStextarea = undefined;
      this.longUrlValue = undefined;
      this.smsDataLabelValue = undefined;
      this.smsSign = undefined;
      this.prefixValue = "https://";
      this.clickCallValue = "NONE";
      this.$refs.strategyManage.init();
    },
    // 批量任务操作--策略管理
    batchSetSteategyStepPause(stepsList, data, index, steategyData) {
      if (index > this.batchStepsList.length - 1) {
        this.batchOperationBtnShow = true;
        return;
      }
      data.finishStatus = "process";
      let params = this.$common.deepClone(steategyData);
      params.batchNumber = this.batchStepsList[this.batchTaskIndex].batchNumber;
      let path = "https://" + data.server + this.$constants.basePath;
      this.$http("/sip/task/getHangupStrategy", null, "get", path, {
        batchNumber: params.batchNumber,
      }).then((res) => {
        params.id = res.data.id;
        this.$service(
          "/sip/task/updateHangupSmsStrategy",
          params,
          "post",
          path,
          null
        )
          .then((res) => {
            if (res.result == 200) {
              this.batchTaskIndex++;
              data.finishStatus = "success";
              return this.batchSetSteategyStepPause(
                this.batchStepsList,
                this.batchStepsList[this.batchTaskIndex],
                this.batchTaskIndex,
                steategyData
              );
            } else {
              this.batchTaskIndex++;
              data.finishStatus = "error";
              return this.batchSetSteategyStepPause(
                this.batchStepsList,
                this.batchStepsList[this.batchTaskIndex],
                this.batchTaskIndex,
                steategyData
              );
            }
          })
          .catch((err) => {
            this.batchTaskIndex++;
            data.finishStatus = "error";
            return this.batchSetSteategyStepPause(
              this.batchStepsList,
              this.batchStepsList[this.batchTaskIndex],
              this.batchTaskIndex,
              steategyData
            );
          });
      });
    },
    // 确认批量操作
    batchOperationSubmit() {
      this.batchOperationShow = false;
      this.inquire(1);
    },
    // 关闭批量操作
    batchOperationShowClose() {
      this.batchTaskIndex = 0;
      this.batchTaskStepsList = [];
      this.batchStepsList = [];
      this.batchOperationBtnShow = false;
    },
    //关闭下载提示
    closeDownload() {
      if (this.downloadTimer) {
        clearInterval(this.downloadTimer);
        this.downloadTimer = null;
      }
    },
    // 点击显示重拨控制
    redialShowClick(key) {
      if (key) {
        this.isRedialModity = true;
      } else {
        this.isRedialModity = false;
      }
      this.redialShow = true;
    },
    // 更新重拨间隔
    redial_submit() {
      let intervalInfo = "";
      for (let i in this.redialForm) {
        intervalInfo += this.redialForm[i] + ",";
      }
      intervalInfo = intervalInfo.substring(0, intervalInfo.length - 1);
      let resetIntervalInfo = "";
      for (let i in this.redialCheck) {
        if (this.redialCheck[i]) {
          resetIntervalInfo += "1";
        } else {
          resetIntervalInfo += "0";
        }
      }
      let data = {
        projectId: this.projectId,
        userLoginName: this.userLoginName,
        batchNumber: this.taskBatchNumber,
        intervalInfo: intervalInfo,
        resetIntervalInfo: resetIntervalInfo,
      };
      this.$service("/sip/task/updateAutoRecallIntervalInfo", data).then(
        (res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.redialShow = false;
            this.getAutoRecallIntervalInfo();
          }
        }
      );
    },
    // 关闭重拨间隔
    redialClose() {},
    // 新增选择项目---查询重拨控制
    redialClick(val) {
      this.redialEnable = val.enableTaskAutoRecall;
      this.projectId = val.id;
      this.userLoginName = val.userLoginName;
      let data = {
        projectId: val.id,
        userLoginName: val.userLoginName,
      };
      this.$http(
        "/sip/project/taskRecallIntervalInfo",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          if (res.data.intervalInfo) {
            let intervalInfo = res.data.intervalInfo.split(",");
            intervalInfo = intervalInfo.filter((item) => item);
            this.redialForm = {
              intervalSecondsForFirst: Number(intervalInfo[0]),
              intervalSecondsForSecond: Number(intervalInfo[1]),
              intervalSecondsForThird: Number(intervalInfo[2]),
              intervalSecondsForFour: Number(intervalInfo[3]),
              intervalSecondsForFive: Number(intervalInfo[4]),
              intervalSecondsForSix: Number(intervalInfo[5]),
            };
            this.oldRedialDate = {
              first: this.setHoursTooltip(Number(intervalInfo[0])),
              second: this.setHoursTooltip(Number(intervalInfo[1])),
              third: this.setHoursTooltip(Number(intervalInfo[2])),
              four: this.setHoursTooltip(Number(intervalInfo[3])),
              five: this.setHoursTooltip(Number(intervalInfo[4])),
              six: this.setHoursTooltip(Number(intervalInfo[5])),
            };
          } else {
            this.redialForm = {
              intervalSecondsForFirst: 3600,
              intervalSecondsForSecond: 3600,
              intervalSecondsForThird: 3600,
              intervalSecondsForFour: 3600,
              intervalSecondsForFive: 3600,
              intervalSecondsForSix: 3600,
            };
          }
          if (res.data.resetIntervalInfo) {
            let resetIntervalInfo = res.data.resetIntervalInfo.split("");
            this.redialCheck = {
              firstCheck: resetIntervalInfo[0] == "1",
              secondCheck: resetIntervalInfo[1] == "1",
              thirdCheck: resetIntervalInfo[2] == "1",
              fourCheck: resetIntervalInfo[3] == "1",
              fiveCheck: resetIntervalInfo[4] == "1",
              sixCheck: resetIntervalInfo[5] == "1",
            };
          } else {
            this.redialCheck = {
              firstCheck: false,
              secondCheck: false,
              thirdCheck: false,
              fourCheck: false,
              fiveCheck: false,
              sixCheck: false,
            };
          }
        }
      });
    },
    // 选择第一遍重拨间隔
    redialSecondsForFirstChange(val) {
      this.oldRedialDate.first = this.setHoursTooltip(val);
    },
    // 选择第二遍重拨间隔
    redialSecondsForSecondChange(val) {
      this.oldRedialDate.second = this.setHoursTooltip(val);
    },
    // 选择第三遍重拨间隔
    redialSecondsForThirdChange(val) {
      this.oldRedialDate.third = this.setHoursTooltip(val);
    },
    // 选择第四遍重拨间隔
    redialSecondsForFourChange(val) {
      this.oldRedialDate.four = this.setHoursTooltip(val);
    },
    // 选择第五遍重拨间隔
    redialSecondsForFiveChange(val) {
      this.oldRedialDate.five = this.setHoursTooltip(val);
    },
    // 选择第六遍重拨间隔
    redialSecondsForSixChange(val) {
      this.oldRedialDate.six = this.setHoursTooltip(val);
    },
    // 处理间隔提示小时--提示
    setHoursTooltip(val) {
      switch (val) {
        case 1800:
          return "半小时";
          break;
        case 3600:
          return "1小时";
          break;
        case 7200:
          return "2小时";
          break;
        case 10800:
          return "3小时";
          break;
        case 14400:
          return "4小时";
          break;
        case 18000:
          return "5小时";
          break;
        case 21600:
          return "6小时";
          break;
        case 43200:
          return "12小时";
          break;
        case 86400:
          return "1天";
          break;
        case 172800:
          return "2天";
          break;
        case 259200:
          return "3天";
          break;
        case -1:
          return "不拨打";
          break;
      }
    },
    // 查询任务重拨控制
    getAutoRecallIntervalInfo() {
      let data = {
        userLoginName: this.userLoginName,
        projectId: this.projectId,
        batchNumber: this.taskBatchNumber,
      };
      this.$http(
        "/sip/task/autoRecallIntervalInfo",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          if (res.data.intervalInfo) {
            let intervalInfo = res.data.intervalInfo.split(",");
            intervalInfo = intervalInfo.filter((item) => item);
            this.redialForm = {
              intervalSecondsForFirst: Number(intervalInfo[0]),
              intervalSecondsForSecond: Number(intervalInfo[1]),
              intervalSecondsForThird: Number(intervalInfo[2]),
              intervalSecondsForFour: Number(intervalInfo[3]),
              intervalSecondsForFive: Number(intervalInfo[4]),
              intervalSecondsForSix: Number(intervalInfo[5]),
            };
            this.oldRedialDate = {
              first: this.setHoursTooltip(Number(intervalInfo[0])),
              second: this.setHoursTooltip(Number(intervalInfo[1])),
              third: this.setHoursTooltip(Number(intervalInfo[2])),
              four: this.setHoursTooltip(Number(intervalInfo[3])),
              five: this.setHoursTooltip(Number(intervalInfo[4])),
              six: this.setHoursTooltip(Number(intervalInfo[5])),
            };
          } else {
            this.redialForm = {
              intervalSecondsForFirst: 3600,
              intervalSecondsForSecond: 3600,
              intervalSecondsForThird: 3600,
              intervalSecondsForFour: 3600,
              intervalSecondsForFive: 3600,
              intervalSecondsForSix: 3600,
            };
          }
          if (res.data.resetIntervalInfo) {
            let resetIntervalInfo = res.data.resetIntervalInfo.split("");
            this.redialCheck = {
              firstCheck: resetIntervalInfo[0] == "1",
              secondCheck: resetIntervalInfo[1] == "1",
              thirdCheck: resetIntervalInfo[2] == "1",
              fourCheck: resetIntervalInfo[3] == "1",
              fiveCheck: resetIntervalInfo[4] == "1",
              sixCheck: resetIntervalInfo[5] == "1",
            };
          } else {
            this.redialCheck = {
              firstCheck: false,
              secondCheck: false,
              thirdCheck: false,
              fourCheck: false,
              fiveCheck: false,
              sixCheck: false,
            };
          }
        }
      });
    },
    // 点击自动重呼管理
    autoRecallClick(val) {
      this.autoRecallShow = true;
      this.nextAutoRecallTime = this.$common.transformTime(
        val.nextAutoRecallTime
      );
      this.autoRecallCount = val.autoRecallCount;
      this.autoRecall = val.autoRecall;
      this.taskBatchNumber = val.batchNumber;
      this.userLoginName = val.userLoginName;
      this.projectId = val.projectId;
      this.getAutoRecallIntervalInfo();
    },
    // 确定自动重呼管理
    autoRecall_submit() {
      let data = {
        batchNumber: this.taskBatchNumber,
        autoRecall: this.autoRecall,
        nextAutoRecallTime: this.nextAutoRecallTime,
      };
      this.$service("/sip/task/modifyAutoRecallInfo", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("更新成功");
          this.autoRecallShow = false;
          this.inquire();
        }
      });
    },
    // 关闭自动重呼管理
    autoRecallClose() {
      this.autoRecallCount = "";
      this.nextAutoRecallTime = "";
      this.autoRecall = false;
      this.redialEnable = false;
      this.redialForm = {
        intervalSecondsForFirst: 3600,
        intervalSecondsForSecond: 3600,
        intervalSecondsForThird: 3600,
        intervalSecondsForFour: 3600,
        intervalSecondsForFive: 3600,
        intervalSecondsForSix: 3600,
      };
      this.oldRedialDate = {
        first: "1小时",
        second: "1小时",
        third: "1小时",
        four: "1小时",
        five: "1小时",
        six: "1小时",
      };
    },
    // 点击编号导出
    batchNumberExport(val) {
      this.taskBatchNumber = val.batchNumber;
      this.skipNormalClearing = true;
      this.isCloseTask = false;
      this.batchNumberExportShow = true;
      this.batchNumberTextarea = val.batchNumber;
      this.taskServer = val.server;
    },
    // 确定编号导出
    batchNumberExport_submit() {
      let params = {
        skipNormalClearing: this.skipNormalClearing,
        closeTask: this.isCloseTask,
        batchNumberList: this.batchNumberTextarea.split("\n"),
      };
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.$service("/sip/task/exportCustomerInfo", params, "post", path).then(
        (res) => {
          if (res.result === 200) {
            this.downloadTips = true;
            this.downloadId = res.data.id;
            this.batchNumberExportShow = false;
            this.downloadStatus();
          }
        }
      );
    },
    mergeTask(val) {
      this.autoSplit = true;
      this.childBatchNumber = val.batchNumber;
      this.taskName = "混合批次-" + val.batchNumber;
      this.batchMergeTaskShow = false;
      this.mergeTaskShow = true;
    },
    batchMergeTask() {
      this.autoSplit = true;
      this.childBatchNumber = "";
      let selectData = this.$common.deepClone(this.multipleSelection);
      selectData.sort((a, b) => {
        return b.id - a.id;
      });
      selectData.forEach((item) => {
        this.childBatchNumber += item.batchNumber + "\n";
      });
      this.childBatchNumber = this.childBatchNumber.substring(
        0,
        this.childBatchNumber.length - 1
      );
      this.taskName = "混合批次-" + selectData[0].batchNumber;
      this.batchMergeTaskShow = true;
      this.mergeTaskShow = true;
    },
    mergeTask_submit() {
      if (!this.taskName) {
        this.$message.warning("任务名称为必传项");
        return;
      }
      if (!this.childBatchNumber) {
        this.$message.warning("子批次列表为必传项");
        return;
      }
      let data = {
        autoSplit: this.autoSplit,
        taskName: this.taskName,
        childBatchNumbers: this.childBatchNumber.replaceAll("\n", ","),
      };
      this.$service("/sip/task/mergeTask", data, null, null, null, {
        "Content-Type": "application/json;charset=UTF-8",
      }).then((res) => {
        if (res.result == 200) {
          this.$message.success("提交成功");
          this.mergeTaskShow = false;
          this.inquire();
        }
      });
    },
    // 重新创建---跨任务创建--任务类型选择
    taskTypeChagne(val) {
      switch (val) {
        case "NORMAL":
          break;
        case "PROLOGUE":
          break;
        case "PROLOGUE_SMS":
          this.addDisorder = false;
          break;
        case "PROLOGUE_WECHAT":
          this.addDisorder = false;
          break;
        case "AI_ROBOT":
          this.addDisorder = false;
          break;
      }
      this.hangupSmsStrategyTemplateId = "";
      this.template_name_label = "";
    },
    //  重新创建---跨任务创建--选择项目获取焦点
    crossProjectIdFocus() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          loginName: this.userNameId,
        };
        this.getTeamName(params);
      }, 200);
    },
    // 重新创建---跨任务创建--选择任务搜索
    crossTaskRemoteMethod(name) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        let params = {
          pageNo: 1,
          pageSize: 100,
          projectId: this.projectListId,
          loginName: this.userNameId,
          taskName: name,
          statusList: "CLOSED,CREATED,PAUSED,EXCEPTION,FINISHED",
          recycled: false,
        };
        this.getCrossTask(params);
      }, 200);
    },
    // 重新创建---跨任务创建--选择任务获取焦点
    crossTaskFocus() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        let params = {
          pageNo: 1,
          pageSize: 100,
          projectId: this.projectListId,
          loginName: this.userNameId,
          statusList: "CLOSED,CREATED,PAUSED,EXCEPTION,FINISHED",
          recycled: false,
        };
        this.getCrossTask(params);
      }, 200);
    },
    // 跨任务创建--选择任务查询
    getCrossTask(params) {
      this.crossTaskNameList = [];
      this.$http("/sip/task/list", null, "get", null, params).then((res) => {
        if (res.result === 200) {
          let list = res.data.list;
          this.crossTaskNameList = list;
        }
      });
    },
    getProvinceAndCity() {
      //查询区域
      this.$http("/getProvinceAndCity", null).then((res) => {
        if (res.result == 200) {
          this.options = [];
          let data = res.data;
          for (let i in data) {
            let a = {};
            a.name = i;
            a.code = i;
            a.children = [];
            if (data[i] && data[i].length > 0) {
              for (let j of data[i]) {
                let b = {};
                b.name = i + " " + j;
                b.code = i + ":" + j;
                a.children.push(b);
              }
            }
            this.options.push(a);
          }
        }
      });
    },
    // 查询平台
    getPlatformList() {
      this.$http("/sms/smsAccountInfo/list", null, "get").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.platformArr = list;
        }
      });
    },
    // 点击上传方式
    UploadMethodClick(tab, e) {
      // console.log(this.uploadMethod)
    },
    // 新增项目选择
    projectChagne(val) {
      this.form.crossTaskNameList = [];
      let data = {
        projectId: val,
      };
      this.projectList.forEach((item) => {
        if (val === item.id) {
          this.redialClick(item);
        }
      });
      this.$http("/sip/project/projectDetail", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            let obj = res.data;
            this.form.taskType = obj.defaultTaskType;
            this.template_name_label = obj.defaultHangupSmsStrategyTemplateName;
            this.mobileVisible = obj.defaultMobileVisible;
            switch (obj.defaultTaskType) {
              case "NORMAL":
                break;
              case "PROLOGUE":
                break;
              case "PROLOGUE_SMS":
                this.addDisorder = false;
                break;
              case "PROLOGUE_WECHAT":
                this.addDisorder = false;
                break;
              case "AI_ROBOT":
                this.addDisorder = false;
                break;
            }
            this.isPrologueDisabled = false;
            this.hangupSmsStrategyTemplateId =
              obj.defaultHangupSmsStrategyTemplateId;
          }
        }
      );
    },
    // 坐席详情分页
    teamCurrentPage_data(index) {
      let data = {
        pageNo: index,
        pageSize: 20,
        teamId: this.sipTeamId,
        loginName: this.teamLoginName,
      };
      this.getTeamDetails(data);
    },
    // 选择队列名称
    teamNameChange(teamId) {
      this.sipTeamId = teamId;
      let data = {
        pageNo: 1,
        pageSize: 20,
        teamId: teamId,
        loginName: this.teamLoginName,
      };
      this.getTeamDetails(data);
    },
    // 获取队列详情
    getTeamDetails(data) {
      this.$http("/sip/client/list", null, "get", null, data).then((res) => {
        if (res.result === 200) {
          let list = res.data.list;
          this.teamData = list;
          this.teamtotal = res.data.count;
        }
      });
    },
    // 点击排队中信息
    lineupClick(val) {
      // console.log(val)
      this.lineUpForm = [];
      let data = {
        teamId: val.sipTeamId,
        taskId: val.id,
      };
      this.$http("/sip/task/getQueueInfo", null, "get", null, data).then(
        (res) => {
          if (res.result == 200) {
            let list = res.data;
            list.forEach((item) => {
              item.timeLable = this.$common.secondCompute(item.latestSeconds);
              if (item.status === "WAITING") {
                item.statusLable = "等待中";
              }
              if (item.status === "CALLING") {
                item.statusLable = "拨打中";
              }
              if (item.status === "WAITING_AUTO_START") {
                item.statusLable = "等待自启动";
              }
            });
            this.lineUpForm = this.$common.deepClone(list);
            this.lineUpForm.splice(list.length - 1);
            this.waitingAutoStart = false;
            if (list.length === 1) {
              if (val.newStatus === "排队中") {
                if (
                  val.type === "PROLOGUE_SMS" ||
                  val.type === "PROLOGUE_WECHAT"
                ) {
                  this.waitingAutoStart = true;
                }
              }
              if (list[list.length - 1].status === "WAITING_AUTO_START") {
                this.waitingAutoStart = true;
              }
            }
          }
        }
      );
    },
    // 排队中信息隐藏
    lineupKide(val) {
      this.waitingAutoStart = false;
    },
    handleExceed(files, fileList) {
      //限制上传文件数量提示
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    currentPage_data(index) {
      //当前页
      this.inquire();
    },
    sizeChange(val) {
      //显示条数--分页
      this.pageSize = val;
      this.currentPage = 1;
      this.inquire();
    },
    detailsCurrentPage_data() {
      //详情当前页
      let params = {
        pageSize: 10,
        pageNo: this.detailsCurrentPage,
        projectId: this.projectId,
        taskId: this.taskId,
        callStatus: this.callStatusList,
        loginName: this.userLoginName,
      };
      this.loadingTab = true;
      this.inquierCall(params);
    },
    inquire(index) {
      //查询列表
      if (index) {
        this.currentPage = index;
      }
      let params = {
        pageSize: this.pageSize,
        pageNo: index || this.currentPage,
        status: this.condition.projectStatus,
        projectId: this.condition.projectId,
        autoRecallCount: this.condition.autoRecallCount,
        categoryId: this.condition.categoryId,
        taskName: this.inquireTaskName.trim(),
        loginName: this.loginName,
        firstCallAtStart: this.condition.firstCallAtStart,
        firstCallAtEnd: this.condition.firstCallAtEnd,
        filterMerged: this.filterMerged,
        filterClosed: this.filterClosed,
        recycled: !this.recycled,
      };
      if (this.batchNumber) {
        let length = this.batchNumber.trim().length;
        if (length >= 10) {
          params.batchNumbers = this.batchNumber;
        } else {
          params.batchNumber = this.batchNumber;
        }
      }
      this.inquireTask(params);
    },
    // 状态更新后查询列表
    statusInquire() {
      this.inquire(1);
    },
    inquireTask(params) {
      //查询任务列表
      let timerArr = Object.keys(this.timer);
      let creatArr = Object.keys(this.timerCreat);
      let cleanArr = Object.keys(this.timerClean);
      let pausArr = Object.keys(this.timerPaus);
      let queueArr = Object.keys(this.timerQueue);
      let orderingArr = Object.keys(this.timerOrdering);
      // 动态刷新定时器清除
      if (timerArr.length > 0) {
        for (let i in this.timer) {
          clearInterval(this.timer[i]);
          this.timer[i] = null;
        }
        this.timer = {};
      }
      if (pausArr.length > 0) {
        for (let i in this.timerPaus) {
          clearInterval(this.timerPaus[i]);
          this.timerPaus[i] = null;
        }
        this.timerPaus = {};
      }
      if (creatArr.length > 0) {
        for (let i in this.timerCreat) {
          clearInterval(this.timerCreat[i]);
          this.timerCreat[i] = null;
        }
        this.timerCreat = {};
      }
      if (cleanArr.length > 0) {
        for (let i in this.timerClean) {
          clearInterval(this.timerClean[i]);
          this.timerClean[i] = null;
        }
        this.timerClean = {};
      }
      if (orderingArr.length > 0) {
        for (let i in this.timerOrdering) {
          clearInterval(this.timerOrdering[i]);
          this.timerOrdering[i] = null;
        }
        this.timerOrdering = {};
      }
      if (queueArr.length > 0) {
        for (let i in this.timerQueue) {
          clearInterval(this.timerQueue[i]);
          this.timerQueue[i] = null;
        }
        this.timerQueue = {};
      }
      let newTime = new Date().getTime();
      if (this.inquireTime) {
        let diff = newTime - this.inquireTime;
        if (diff < 500) {
          return;
        }
      }
      this.inquireTime = newTime;
      this.inquireBtnDisabled = true;
      this.loadingTable = true;
      this.$http("/sip/task/list", null, "get", null, params)
        .then((res) => {
          setTimeout(() => {
            this.inquireBtnDisabled = false;
            this.loadingTable = false;
          }, 200);
          if (res.result == 200) {
            let list = res.data.list;
            this.total = res.data.count;
            this.refreshData = {};
            list.map((item) => {
              this.refreshData[item.server + ".backend." + item.domain] = {};
              if (!item.sipTeamName) {
                item.sipTeamName = "-";
              }
              if (item.connectedCount == 0) {
                item.orderRate = "-";
                item.intentionRate = "-";
                item.greaterThan60sCountRate = "-";
                item.lessThan10sCountRate = "-";
              } else {
                item.orderRate =
                  (item.successCount / item.connectedCount) * 100;
                item.orderRate = item.orderRate.toFixed(2) + "%";
                item.intentionRate =
                  (
                    ((item.successCount + item.needCallbackCount) /
                      item.connectedCount) *
                    100
                  ).toFixed(2) + "%";
                item.greaterThan60sCountRate =
                  (
                    (item.greaterThan60sCount / item.connectedCount) *
                    100
                  ).toFixed(2) + "%";
                item.lessThan10sCountRate =
                  ((item.lessThan10sCount / item.connectedCount) * 100).toFixed(
                    2
                  ) + "%";
              }
              if (item.calledCount == 0) {
                item.connectedCountRate = "-";
              } else {
                item.connectedCountRate =
                  ((item.connectedCount / item.calledCount) * 100).toFixed(2) +
                  "%";
              }
              item.intentionCount = item.successCount + item.needCallbackCount;
              item.reCreateShow = false; //是否显示重新创建
              item.reCreateDisabled = false; //是否禁用重新创建
              item.distributeServerGroupIdDisabled = true; //是否禁用服务器分组
              item.startCallShow = true; //是否显示开始拨打
              item.teamIdDisabled = false; //是否禁用队列名称
              item.firstCallAt = this.$common.transformTime(item.firstCallAt);
              item.lastCallAt = this.$common.transformTime(item.lastCallAt);
              if (item.firstCallAt == "2000-01-01 00:00:00") {
                item.firstCallAt = "-";
                item.lastCallAt = "-";
              }
              item.progress = `${item.calledCount}/${item.totalCallCount}`;
              item.supplementNumberShow = true; //是否禁用补充号码
              item.shuffleDisabeld = true; //是否禁用乱序
              item.reloadDisabeld = true; //是否禁用任务状态同步
              item.pauseTaskShow = false; //是否显示暂停拨打
              item.pauseLineupShow = false; //是否显示取消排队
              item.showStrategyDisabled = false; //是否禁用策略管理
              item.downRecordDisabled = true; //是否禁用下载录音
              item.closeTaskDisabled = false; //是否禁用关闭任务
              item.resumeTask = false; //判断是否状态暂停
              if (item.status == "EXCEPTION" || item.status == "FINISHED") {
                if (!item.recycled) {
                  item.reCreateShow = true;
                  item.startCallShow = false;
                }
                if (item.recycled) {
                  item.closeTaskDisabled = true;
                }
              }
              item.relationTaskList = {};
              switch (item.status) {
                case "CREATING":
                  item.newStatus = "创建中";
                  item.startCallDisabled = true; //是否禁用开始拨打
                  item.showStrategyDisabled = true;
                  item.downRecordDisabled = true;
                  item.closeTaskDisabled = true;
                  break;
                case "ORDERING":
                  item.newStatus = "排序中";
                  item.startCallDisabled = true; //是否禁用开始拨打
                  item.showStrategyDisabled = true;
                  item.downRecordDisabled = true;
                  item.closeTaskDisabled = true;
                  break;
                case "CREATED":
                  item.newStatus = "新建状态";
                  item.startCallDisabled = false;
                  item.supplementNumberShow = false;
                  item.closeTaskDisabled = true;
                  item.shuffleDisabeld = false;
                  item.reloadDisabeld = false;
                  item.distributeServerGroupIdDisabled = false;
                  break;
                case "WAITING_CALL":
                  item.newStatus = "等待拨打";
                  item.startCallDisabled = true;
                  break;
                case "CALLING":
                  item.newStatus = "拨打中";
                  item.startCallDisabled = true;
                  item.pauseTaskShow = true;
                  item.startCallShow = false;
                  item.closeTaskDisabled = true;
                  item.teamIdDisabled = true;
                  break;
                case "PAUSING":
                  item.newStatus = "暂停中";
                  item.startCallDisabled = true;
                  item.showStrategyDisabled = true;
                  item.teamIdDisabled = true;
                  item.downRecordDisabled = true;
                  item.closeTaskDisabled = true;
                  break;
                case "PAUSED":
                  item.newStatus = "已暂停";
                  item.startCallDisabled = false;
                  item.resumeTask = true;
                  item.shuffleDisabeld = false;
                  item.reloadDisabeld = false;
                  item.distributeServerGroupIdDisabled = false;
                  break;
                case "CLOSED":
                  item.newStatus = "已关闭";
                  item.downRecordDisabled = false;
                  break;
                case "EXCEPTION":
                  item.newStatus = "异常";
                  item.startCallDisabled = true;
                  break;
                case "QUEUING":
                  item.newStatus = "排队中";
                  item.closeTaskDisabled = true;
                  item.teamIdDisabled = true;
                  item.startCallShow = false;
                  item.pauseLineupShow = true;
                  break;
                case "FINISHED":
                  item.newStatus = "已完成";
                  item.startCallDisabled = true;
                  if (item.connectedCount > 0) {
                    item.downRecordDisabled = false;
                  } else {
                    item.downRecordDisabled = true;
                  }
                  break;
                case "CLEANING":
                  item.newStatus = "清理中";
                  item.closeTaskDisabled = true;
                  item.showStrategyDisabled = true;
                  item.startCallDisabled = true; //是否禁用开始拨打
                  item.reCreateDisabled = true;
                  item.teamIdDisabled = true;
                  break;
                case "TRANSFERING":
                  item.newStatus = "迁移中";
                  item.closeTaskDisabled = true;
                  item.showStrategyDisabled = true;
                  item.startCallDisabled = true; //是否禁用开始拨打
                  item.reCreateDisabled = true;
                  item.teamIdDisabled = true;
                  break;
                case "WAIT_AUTO_RECALL":
                  item.newStatus = "等待自动重拨";
                  item.startCallDisabled = true;
                  if (item.connectedCount > 0) {
                    item.downRecordDisabled = false;
                  } else {
                    item.downRecordDisabled = true;
                  }
                  break;
                case "MERGING":
                  item.newStatus = "合并中";
                  item.startCallDisabled = true;
                  item.showStrategyDisabled = true;
                  break;
                case "MERGED":
                  item.newStatus = "已合并";
                  item.startCallDisabled = true;
                  item.showStrategyDisabled = true;
                  this.$http("/sip/task/relationTaskList", null, "get", null, {
                    batchNumber: item.batchNumber,
                  }).then((res) => {
                    if (res.result == 200) {
                      item.relationTaskList = res.data;
                    }
                  });
                  break;
                case "FINISHING":
                  item.newStatus = "结束中";
                  item.startCallDisabled = true;
                  item.showStrategyDisabled = true;
                  break;
                case "UN_MERGING":
                  item.newStatus = "拆分中";
                  item.startCallDisabled = true;
                  item.showStrategyDisabled = true;
                  break;
                case "DELETING":
                  item.newStatus = "删除中";
                  item.startCallDisabled = true;
                  item.showStrategyDisabled = true;
                  break;
              }
              if (item.closed) {
                item.downRecordDisabled = true;
                item.startCallDisabled = true;
                item.showStrategyDisabled = true;
                item.closeTaskDisabled = true;
                item.reCreateDisabled = true;
              }
            });
            this.tableData = list;
            // 状态刷新
            this.tableData.forEach((item) => {
              if (
                item.newStatus == "拨打中" ||
                item.newStatus == "暂停中" ||
                item.newStatus == "创建中" ||
                item.newStatus == "排序中" ||
                item.newStatus == "清理中" ||
                item.newStatus == "迁移中" ||
                item.newStatus == "结束中" ||
                item.newStatus == "拆分中" ||
                item.newStatus == "删除中"
              ) {
                this.statusTimerList[this.statusTimerList.length] = setInterval(
                  () => {
                    this.taskDetail(
                      item.id,
                      item.server + ".backend." + item.domain,
                      item.status
                    );
                  },
                  5000
                );
              }
            });
            // for (let i in this.refreshData) {
            //   //获取不同服务器动态刷新的任务id
            //   this.refreshData[i]["CALLING"] = [];
            //   this.refreshData[i]["PAUSING"] = [];
            //   this.refreshData[i]["QUEUING"] = [];
            //   this.refreshData[i]["CREATING"] = [];
            //   this.refreshData[i]["CLEANING"] = [];
            //   this.refreshData[i]["ORDERING"] = [];
            //   list.map((menu) => {
            //     if (i === menu.server + ".backend." + menu.domain) {
            //       if (menu.status === "CALLING") {
            //         this.refreshData[i]["CALLING"].push(menu.id);
            //       }
            //       if (menu.status === "PAUSING") {
            //         this.refreshData[i]["PAUSING"].push(menu.id);
            //       }
            //       if (menu.status === "QUEUING") {
            //         this.refreshData[i]["QUEUING"].push(menu.id);
            //       }
            //       if (menu.status === "CREATING") {
            //         this.refreshData[i]["CREATING"].push(menu.id);
            //       }
            //       if (menu.status === "CLEANING") {
            //         this.refreshData[i]["CLEANING"].push(menu.id);
            //       }
            //       if (menu.status === "ORDERING") {
            //         this.refreshData[i]["ORDERING"].push(menu.id);
            //       }
            //     }
            //   });
            // }

            // for (let j in this.refreshData) {
            //   // 动态刷新
            //   this.timer[j] = "";
            //   this.timerPaus[j] = "";
            //   this.timerCreat[j] = "";
            //   this.timerClean[j] = "";
            //   this.timerQueue[j] = "";
            //   this.timerOrdering[j] = "";
            //   for (let k in this.refreshData[j]) {
            //     if (k === "CALLING") {
            //       if (this.refreshData[j][k].length > 0) {
            //         this.taskDetail(this.refreshData[j][k].join(","), j); // 立即刷新拨打中数据
            //         this.detailCalling(
            //           this.refreshData[j][k].join(","),
            //           k,
            //           j,
            //           5
            //         );
            //       }
            //     }
            //     if (k === "PAUSING") {
            //       if (this.refreshData[j][k].length > 0) {
            //         this.detailPausing(
            //           this.refreshData[j][k].join(","),
            //           k,
            //           j,
            //           2
            //         );
            //       }
            //     }
            //     if (k === "QUEUING") {
            //       if (this.refreshData[j][k].length > 0) {
            //         this.detailQueuing(
            //           this.refreshData[j][k].join(","),
            //           k,
            //           j,
            //           2
            //         );
            //       }
            //     }
            //     if (k === "CREATING") {
            //       if (this.refreshData[j][k].length > 0) {
            //         this.detailCreating(
            //           this.refreshData[j][k].join(","),
            //           k,
            //           j,
            //           2
            //         );
            //       }
            //     }
            //     if (k === "CLEANING") {
            //       if (this.refreshData[j][k].length > 0) {
            //         this.detailCleaning(
            //           this.refreshData[j][k].join(","),
            //           k,
            //           j,
            //           2
            //         );
            //       }
            //     }
            //     if (k === "ORDERING") {
            //       if (this.refreshData[j][k].length > 0) {
            //         this.detailOrdering(
            //           this.refreshData[j][k].join(","),
            //           k,
            //           j,
            //           2
            //         );
            //       }
            //     }
            //   }
            // }
          }
        })
        .catch((err) => {
          this.inquireBtnDisabled = false;
        });
    },
    remoteMethodProject(projectName) {
      //搜索新增框项目
      this.loadingProject = true;
      setTimeout(() => {
        this.loadingProject = false;
        let params = {
          pageNo: 1,
          pageSize: 20,
          projectName: projectName,
          loginName: this.userNameId,
        };
        this.getTeamName(params);
      }, 200);
    },
    getTeamName(params) {
      //新增框项目名称
      this.$http("/sip/project/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let list = res.data.list;
          if (list.length > 0) {
            this.projectList = list;
          } else {
            this.projectList = [];
          }
        }
      });
    },
    addProjectShow() {
      //点击新增任务
      this.addProject = true;
      this.isPrologueDisabled = true;
      this.SMStextarea = "";
      this.longUrlValue = undefined;
      this.smsDataLabelValue = undefined;
      this.smsSign = undefined;
      this.prefixValue = "https://";
      this.clickCallValue = "NONE";
      this.distributeTask = false;
      this.serverGroupId = "";
      this.inquireUserName({ pageNo: 1, pageSize: 20, enabled: true });
    },
    addProject_submit() {
      //新增提交
      this.isAddProject_submit = true;
      let path = "https://" + this.taskServer + this.$constants.basePath;
      let intervalInfo = "";
      for (let i in this.redialForm) {
        intervalInfo += this.redialForm[i] + ",";
      }
      intervalInfo = intervalInfo.substring(0, intervalInfo.length - 1);
      let resetIntervalInfo = "";
      for (let i in this.redialCheck) {
        if (this.redialCheck[i]) {
          resetIntervalInfo += "1";
        } else {
          resetIntervalInfo += "0";
        }
      }
      if (
        this.projectListId &&
        this.form.projectName &&
        this.peojectSipTeamId &&
        this.projectSipRouteId
      ) {
        if (this.uploadMethod === "first") {
          this.url = path + "/sip/task/add";
          this.uploadData.name = this.form.projectName;
          this.uploadData.projectId = this.projectListId;
          this.uploadData.routeId = this.projectSipRouteId;
          this.uploadData.loginName = this.userNameId;
          this.uploadData.shuffle = this.addDisorder;
          this.uploadData.distributeTask = this.distributeTask;
          this.uploadData.distributeServerGroupId = this.serverGroupId;
          this.uploadData.callbackRouteId = this.callbackLine;
          (this.uploadData.taskType = this.form.taskType),
            (this.uploadData.teamId = this.peojectSipTeamId);
          this.uploadData.hangupSmsStrategyTemplateId =
            this.hangupSmsStrategyTemplateId;
          this.uploadData.autoRecall = this.redialEnable;
          this.uploadData.intervalInfo = intervalInfo;
          this.uploadData.resetIntervalInfo = resetIntervalInfo;
          if (this.mobileVisible) {
            // 隐藏号码传false
            this.uploadData.mobileVisible = false;
          } else {
            this.uploadData.mobileVisible = true;
          }
          if (
            this.form.taskType === "PROLOGUE" ||
            this.form.taskType === "PROLOGUE_SMS"
          ) {
            // 开场白启用上传开场白id
          }
          if (this.form.taskType === "PROLOGUE_SMS") {
            // 是否开启挂机短信
            // this.uploadData.smsContent = this.SMStextarea;
          } else {
            // this.uploadData.teamId  = this.peojectSipTeamId;
          }
          this.$nextTick(() => {
            if (this.$refs.upload.uploadFiles.length > 0) {
              this.unifiedLoading = this.$loading();
              this.$refs.upload.submit();
            } else {
              this.isAddProject_submit = false;
              this.$message({ message: "请上传文件", type: "warning" });
            }
          });
        }
        if (this.uploadMethod === "second") {
          this.unifiedLoading = this.$loading();
          let customerCodes = "";
          if (this.customerCodesList.length > 1000) {
            this.$message.warning("最多输入1000个号码");
            this.isAddProject_submit = false;
            this.unifiedLoading.close();
            return;
          } else {
            customerCodes = this.customerCodesList.join(",");
          }
          let data = {
            name: this.form.projectName,
            projectId: this.projectListId,
            routeId: this.projectSipRouteId,
            loginName: this.userNameId,
            shuffle: this.addDisorder,
            distributeTask: this.distributeTask,
            distributeServerGroupId: this.serverGroupId,
            callbackRouteId: this.callbackLine,
            taskType: this.form.taskType,
            customerCodes: customerCodes,
          };
          data.teamId = this.peojectSipTeamId;
          data.hangupSmsStrategyTemplateId = this.hangupSmsStrategyTemplateId;
          data.autoRecall = this.redialEnable;
          data.intervalInfo = intervalInfo;
          data.resetIntervalInfo = resetIntervalInfo;
          if (this.mobileVisible) {
            // 隐藏号码传false
            data.mobileVisible = false;
          } else {
            data.mobileVisible = true;
          }
          if (
            this.form.taskType === "PROLOGUE" ||
            this.form.taskType === "PROLOGUE_SMS"
          ) {
            // 开场白启用上传开场白id
          }
          if (this.form.taskType === "PROLOGUE_SMS") {
            // 是否开启挂机短信
            // data.smsContent = this.SMStextarea;
          } else {
            // data.teamId  = this.peojectSipTeamId;
          }
          this.$http("/sip/task/add", data, "post", path).then((res) => {
            this.isAddProject_submit = false;
            this.unifiedLoading.close();
            if (res.result === 200) {
              this.inquire(1);
              this.addProject_cancel();
              this.$message({ message: "文件上传成功！", type: "success" });
            }
          });
        }
        if (this.uploadMethod === "crossTask") {
          this.unifiedLoading = this.$loading();
          let data = {
            name: this.form.projectName,
            teamId: this.peojectSipTeamId,
            projectId: this.projectListId,
            routeId: this.projectSipRouteId,
            callbackRouteId: this.callbackLine,
            taskType: this.form.taskType,
            loginName: this.userNameId,
            shuffle: this.addDisorder,
            distributeTask: this.distributeTask,
            distributeServerGroupId: this.serverGroupId,
            markUsed: this.markReused,
            areas: this.form.cityList.join(","),
            operators: this.form.operator.join(","),
            batchNumbers: this.form.crossTaskNameList.join(","),
            hangupSmsStrategyTemplateId: this.hangupSmsStrategyTemplateId,
          };
          if (this.mobileVisible) {
            // 隐藏号码传false
            data.mobileVisible = false;
          } else {
            data.mobileVisible = true;
          }
          data.autoRecall = this.redialEnable;
          data.intervalInfo = intervalInfo;
          data.resetIntervalInfo = resetIntervalInfo;
          this.$http("/sip/task/add", data, "post", path)
            .then((res) => {
              this.unifiedLoading.close();
              this.isAddProject_submit = false;
              if (res.result === 200) {
                this.$message({ message: "新增成功", type: "success" });
                this.inquire(1);
                this.addProject_cancel();
              }
            })
            .catch((err) => {
              this.isAddProject_submit = false;
              this.unifiedLoading.close();
            });
        }
      } else {
        this.isAddProject_submit = false;
        this.$message({ message: "信息不完整", type: "warning" });
      }
    },
    successUpload(res, file, fileList) {
      // 新增上传成功
      this.$refs.upload.clearFiles();
      if (res.result == 200) {
        this.isAddProject_submit = false;
        this.unifiedLoading.close();
        this.inquire(1);
        this.addProject_cancel();
        this.$message({ message: "文件上传成功！", type: "success" });
      }
    },
    errorUpload(err, file, fileList) {
      // 新增上传失败
      this.isAddProject_submit = false;
      this.unifiedLoading.close();
      let message = JSON.parse(err.message);
      this.$message.warning(message.message);
    },
    // 新增上传文件改变时
    changeUpload(file, fileList) {
      if (!this.form.projectName) {
        // 任务名称为空时给任务名称赋值
        let index = file.name.lastIndexOf(".");
        this.form.projectName = file.name.substring(0, index);
      }
    },
    taskDetail(taskIds, task, orgStatus) {
      let data = {
        taskIds: taskIds,
      };
      let path = "https://" + task;
      this.$http("/sip/task/detail", data, "post", path, null)
        .then((res) => {
          if (res.result == 200) {
            let data = res.data;
            for (let i in data) {
              if (data[i].status !== orgStatus) {
                this.statusTimerList.forEach((item) => {
                  clearInterval(item);
                });
                this.statusTimerList.splice(0);
                this.statusInquire();
              }
            }
          }
        })
        .catch((err) => {
          this.statusTimerList.forEach((item) => {
            clearInterval(item);
          });
          this.statusTimerList.splice(0);
          this.statusInquire();
        });
    },
    detailCalling(taskIds, orgStatus, task, milliSecond) {
      //监听拨打中态变化
      if (this.timer[task]) {
        clearInterval(this.timer[task]);
      }
      this.timer[task] = setInterval(() => {
        let data = {
          taskIds: taskIds,
        };
        let path = "https://" + task;
        this.$http("/sip/task/detail", data, "post", path, null)
          .then((res) => {
            if (res.result == 200) {
              let data = res.data;
              for (let i in data) {
                for (let j in this.tableData) {
                  if (i == this.tableData[j].id) {
                    this.tableData[j].progress =
                      data[i].calledCount + "/" + data[i].totalCallCount;
                    this.tableData[j].connectedCount = data[i].connectedCount;
                    this.tableData[j].fee = data[i].fee;
                    this.tableData[j].successCount = data[i].successCount;
                    this.tableData[j].intentionCount =
                      data[i].successCount + data[i].needCallbackCount;
                    // this.tableData[j].noAnswerCount = data[i].noAnswerCount;
                    // this.tableData[j].noUserResponseCount = data[i].noUserResponseCount;
                    // this.tableData[j].exceptionCount = data[i].exceptionCount;
                    // this.tableData[j].userBusyCount = data[i].userBusyCount;
                    if (data[i].connectedCount == 0) {
                      this.tableData[j].orderRate = "-";
                      this.tableData[j].intentionRate = "-";
                      this.tableData[j].greaterThan60sCountRate = "-";
                      this.tableData[j].lessThan10sCountRate = "-";
                    } else {
                      let orderRate =
                        (data[i].successCount / data[i].connectedCount) * 100;
                      this.tableData[j].orderRate = orderRate.toFixed(2) + "%";
                      this.tableData[j].intentionRate =
                        (
                          ((data[i].successCount + data[i].needCallbackCount) /
                            data[i].connectedCount) *
                          100
                        ).toFixed(2) + "%";
                      this.tableData[j].greaterThan60sCountRate =
                        (
                          (data[i].greaterThan60sCount /
                            data[i].connectedCount) *
                          100
                        ).toFixed(2) + "%";
                      this.tableData[j].lessThan10sCountRate =
                        (
                          (data[i].lessThan10sCount / data[i].connectedCount) *
                          100
                        ).toFixed(2) + "%";
                    }
                    if (data[i].calledCount == 0) {
                      this.tableData[j].connectedCountRate = "-";
                    } else {
                      this.tableData[j].connectedCountRate =
                        (
                          (data[i].connectedCount / data[i].calledCount) *
                          100
                        ).toFixed(2) + "%";
                    }
                    this.$set(
                      this.tableData[j],
                      "progress",
                      this.tableData[j].progress
                    );
                    this.$set(
                      this.tableData[j],
                      "connectedCount",
                      this.tableData[j].connectedCount
                    );
                    this.$set(this.tableData[j], "fee", this.tableData[j].fee);
                    this.$set(
                      this.tableData[j],
                      "orderRate",
                      this.tableData[j].orderRate
                    );
                    this.$set(
                      this.tableData[j],
                      "intentionRate",
                      this.tableData[j].intentionRate
                    );
                    this.$set(
                      this.tableData[j],
                      "connectedCountRate",
                      this.tableData[j].connectedCountRate
                    );
                    this.$set(
                      this.tableData[j],
                      "greaterThan60sCountRate",
                      this.tableData[j].greaterThan60sCountRate
                    );
                    this.$set(
                      this.tableData[j],
                      "lessThan10sCountRate",
                      this.tableData[j].lessThan10sCountRate
                    );
                    this.$set(
                      this.tableData[j],
                      "successCount",
                      this.tableData[j].successCount
                    );
                    this.$set(
                      this.tableData[j],
                      "intentionCount",
                      this.tableData[j].intentionCount
                    );
                  }
                }
                if (data[i].status !== orgStatus) {
                  clearInterval(this.timer[task]);
                  this.timer[task] = null;
                  this.statusInquire();
                }
              }
            }
          })
          .catch((err) => {
            clearInterval(this.timer[task]);
          });
      }, milliSecond * 1000);
    },
    detailPausing(taskIds, orgStatus, task, milliSecond) {
      //监听暂停中态变化
      if (this.timerPaus[task]) {
        clearInterval(this.timerPaus[task]);
      }
      this.timerPaus[task] = setInterval(() => {
        let data = {
          taskIds: taskIds,
        };
        let path = "https://" + task;
        this.$http("/sip/task/detail", data, "post", path, null)
          .then((res) => {
            if (res.result == 200) {
              let data = res.data;
              for (let i in data) {
                if (data[i].status !== orgStatus) {
                  clearInterval(this.timerPaus[task]);
                  this.timerPaus[task] = null;
                  this.statusInquire();
                }
              }
            }
          })
          .catch((err) => {
            clearInterval(this.timerPaus[task]);
          });
      }, milliSecond * 1000);
    },
    detailQueuing(taskIds, orgStatus, task, milliSecond) {
      //监听排队中态变化
      if (this.timerQueue[task]) {
        clearInterval(this.timerQueue[task]);
      }
      this.timerQueue[task] = setInterval(() => {
        let data = {
          taskIds: taskIds,
        };
        let path = "https://" + task;
        this.$http("/sip/task/detail", data, "post", path, null)
          .then((res) => {
            if (res.result == 200) {
              let data = res.data;
              for (let i in data) {
                if (data[i].status !== orgStatus) {
                  clearInterval(this.timerQueue[task]);
                  this.timerQueue[task] = null;
                  this.statusInquire();
                }
              }
            }
          })
          .catch((err) => {
            clearInterval(this.timerQueue[task]);
          });
      }, milliSecond * 1000);
    },
    detailCreating(taskIds, orgStatus, task, milliSecond) {
      //监听创建中态变化
      if (this.timerCreat[task]) {
        clearInterval(this.timerCreat[task]);
      }
      this.timerCreat[task] = setInterval(() => {
        let data = {
          taskIds: taskIds,
        };
        let path = "https://" + task;
        this.$http("/sip/task/detail", data, "post", path, null)
          .then((res) => {
            if (res.result == 200) {
              let data = res.data;
              for (let i in data) {
                if (data[i].status !== orgStatus) {
                  clearInterval(this.timerCreat[task]);
                  this.timerCreat[task] = null;
                  this.statusInquire();
                }
              }
            }
          })
          .catch((err) => {
            clearInterval(this.timerCreat[task]);
          });
      }, milliSecond * 1000);
    },
    detailCleaning(taskIds, orgStatus, task, milliSecond) {
      //监听清理中态变化
      if (this.timerClean[task]) {
        clearInterval(this.timerClean[task]);
      }
      this.timerClean[task] = setInterval(() => {
        let data = {
          taskIds: taskIds,
        };
        let path = "https://" + task;
        this.$http("/sip/task/detail", data, "post", path, null)
          .then((res) => {
            if (res.result == 200) {
              let data = res.data;
              for (let i in data) {
                if (data[i].status !== orgStatus) {
                  clearInterval(this.timerClean[task]);
                  this.timerClean[task] = null;
                  this.statusInquire();
                }
              }
            }
          })
          .catch((err) => {
            clearInterval(this.timerClean[task]);
          });
      }, milliSecond * 1000);
    },
    detailOrdering(taskIds, orgStatus, task, milliSecond) {
      //监听排序中态变化
      if (this.timerOrdering[task]) {
        clearInterval(this.timerOrdering[task]);
      }
      this.timerOrdering[task] = setInterval(() => {
        let data = {
          taskIds: taskIds,
        };
        let path = "https://" + task;
        this.$http("/sip/task/detail", data, "post", path, null)
          .then((res) => {
            if (res.result == 200) {
              let data = res.data;
              for (let i in data) {
                if (data[i].status !== orgStatus) {
                  clearInterval(this.timerOrdering[task]);
                  this.timerOrdering[task] = null;
                  this.statusInquire();
                }
              }
            }
          })
          .catch((err) => {
            clearInterval(this.timerOrdering[task]);
          });
      }, milliSecond * 1000);
    },
    closeDialog() {
      //关闭新增窗口
      this.form = {
        id: "",
        projectName: "",
        cityList: [],
        operator: [],
        crossTaskNameList: [],
        taskType: "NORMAL",
      };
      this.redialEnable = false;
      this.redialForm = {
        intervalSecondsForFirst: 3600,
        intervalSecondsForSecond: 3600,
        intervalSecondsForThird: 3600,
        intervalSecondsForFour: 3600,
        intervalSecondsForFive: 3600,
        intervalSecondsForSix: 3600,
      };
      this.markReused = true;
      this.hangupSmsStrategyTemplateId = "";
      this.template_name_label = "";
      this.$refs.upload.clearFiles();
      this.id = "";
      this.projectListId = "";
      this.peojectSipTeamId = "";
      this.projectSipRouteId = "";
      this.callbackLine = "";
      this.userNameId = "";
      this.sipDisabled = true;
      this.projectDisabled = true;
      this.addDisorder = false;
      this.distributeTask = false;
      this.distributeTaskDisabled = true;
      this.distributeTaskName = "";
      this.isAddProject_submit = false;
      this.serverGroupId = "";
      if (this.unifiedLoading) {
        this.unifiedLoading.close();
      }
      this.mobileVisible = true;
      this.isPrologueDisabled = true;
      this.uploadNumber = "";
      this.uploadMethod = "first";
      this.SMStextarea = "";
      this.longUrlValue = "";
      this.smsDataLabelValue = "";
      this.smsSign = "";
      this.prefixValue = "https://";
      this.clickCallValue = "NONE";
      this.taskServer = "";
      this.projectId = "";
      this.userLoginName = "";
    },
    addProject_cancel() {
      //取消新增
      this.$refs.upload.clearFiles();
      this.projectListId = "";
      this.form.projectName = "";
      this.addProject = false;
    },
    //刷新下载状态
    downloadStatus() {
      this.downloadTimer = setInterval(() => {
        this.$http("sip/downloadFile/checkDownloadStatus", null, "get", null, {
          id: this.downloadId,
        }).then((res) => {
          if (res.result == 200) {
            let status = res.data;
            if (status === "DOWNLOADABLE") {
              clearInterval(this.downloadTimer);
              this.downloadTimer = null;
              this.downloadTips = false;
              let path =
                "https://" + this.taskServer + this.$constants.basePath;
              let href =
                path + "/sip/downloadFile/download?id=" + this.downloadId;
              window.location.href = href;
            } else if (status === "EXCEPTION") {
              clearInterval(this.downloadTimer);
              this.downloadTimer = null;
              this.downloadTips = false;
              this.$message.error("下载失败");
            }
          } else {
            this.downloadTips = false;
            clearInterval(this.downloadTimer);
            this.downloadTimer = null;
          }
        });
      }, 2000);
    },
    pauseCall(val) {
      //点击开始拨打
      this.id = val.id;
      this.totalCallCount = val.totalCallCount;
      this.isSubmit = false;
      this.isSeat = false;
      this.isNotWorkingTime = false;
      this.resumeTask = val.resumeTask;
      this.projectName = val.projectName;
      this.taskName = val.taskName;
      this.teamName = val.sipTeamName;
      this.routeName = val.sipRouteName;
      this.userLoginName = val.userLoginName;
      this.taskServer = val.server;
      this.callStartShow = true;
      let params = {
        teamId: val.sipTeamId,
        taskId: val.id,
        loginName: val.userLoginName,
      };
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.$http("/sip/team/status", null, "get", path, params).then((res) => {
        if (res.result == 200) {
          this.onlineCount = res.data.onlineCount;
          this.waitingTaskCount = res.data.waitingTaskCount;
          let workingTimeStr = res.data.workingTimeStr.split("#");
          this.workingTimeStr = workingTimeStr;
          // 如果等待坐席为0禁止拨打
          if (this.waitingTaskCount == 0) {
            this.isSubmit = true;
            this.isSeat = true;
            return;
          }
          // 如果不在工作时间且是不能自动拨打的禁止排队拨打
          if (!res.data.isLegalWorkingTime && !res.data.autoStartEnabled) {
            this.isSubmit = true;
          }
          this.isNotWorkingTime = !res.data.isLegalWorkingTime;
        }
      });
    },
    pauseCall_submit() {
      //确定开始拨打
      this.pauseCallDisabled = false;
      let params = {
        id: this.id,
        loginName: this.userLoginName,
      };
      let path = "https://" + this.taskServer + this.$constants.basePath;
      if (this.resumeTask) {
        this.$http("/sip/task/resumeTask", null, "get", path, params).then(
          (res) => {
            this.pauseCallDisabled = true;
            if (res.result == 200) {
              this.$message({ message: "拨打成功", type: "success" });
              this.statusInquire();
              this.pauseCall_cencel();
            }
          }
        );
      } else {
        this.$http("/sip/task/startTask", null, "get", path, params).then(
          (res) => {
            this.pauseCallDisabled = true;
            if (res.result == 200) {
              this.$message({ message: "拨打成功", type: "success" });
              this.statusInquire();
              this.pauseCall_cencel();
            }
          }
        );
      }
    },
    callStartClose() {
      //关闭开始拨打
      this.pauseCallDisabled = true;
      this.workingTimeStr = [];
    },
    pauseCall_cencel() {
      //取消开始拨打
      this.callStartShow = false;
    },
    pauseTask(val) {
      //点击暂停拨打
      this.id = val.id;
      this.taskServer = val.server;
      this.$confirm("暂停拨打, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let params = {
            taskId: this.id,
            loginName: val.userLoginName,
          };
          let path = "https://" + this.taskServer + this.$constants.basePath;
          this.$http("/sip/task/pauseTask", null, "get", path, params).then(
            (res) => {
              if (res.result == 200) {
                this.statusInquire();
                this.$message({ message: "暂停拨打成功", type: "success" });
              }
            }
          );
        })
        .catch(() => {});
    },
    // 点击取消排队
    pauseLineup(val) {
      this.id = val.id;
      this.taskServer = val.server;
      this.$confirm("取消排队, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let params = {
            taskId: this.id,
            teamId: val.sipTeamId,
          };
          let path = "https://" + this.taskServer + this.$constants.basePath;
          this.$http("/sip/task/cancelQueue", null, "get", path, params).then(
            (res) => {
              if (res.result == 200) {
                this.statusInquire();
                this.$message({ message: "取消排队成功", type: "success" });
              }
            }
          );
        })
        .catch(() => {});
    },
    showStrategy(val) {
      //点击管理策略
      this.taskId = val.id;
      this.taskServer = val.server;
      this.userLoginName = val.userLoginName;
      this.strategyName = "外呼策略【" + val.batchNumber + "】";
      this.template_select_type = "";
      this.isAddtemplate = false;
      this.SMStextarea = undefined;
      this.longUrlValue = undefined;
      this.smsDataLabelValue = undefined;
      this.smsSign = undefined;
      this.prefixValue = "https://";
      this.clickCallValue = "NONE";
      this.strategy = {};
      this.$refs.strategyManage.init();
      this.getPlatformList();
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.strategy_from_hangupsms = val.type;
      this.getHangupStrategy(val.batchNumber, path);
    },
    getHangupStrategy(batchNumber, path, id) {
      // 启用挂机短信时点击策略管理
      let params = {
        batchNumber: batchNumber,
        id: id,
      };
      this.$http("/sip/task/getHangupStrategy", null, "get", path, params).then(
        (res) => {
          if (res.result == 200) {
            this.setStrategyData(res);
            this.$nextTick(() => {
              this.isAddPlatform = false;
            });
          }
        }
      );
    },
    // 策略数据转换
    setStrategyData(res) {
      this.SMStextarea = res.data.smsContent;
      this.prefixValue = res.data.prefix;
      this.clickCallValue = res.data.smsClickCallbackType;
      this.longUrlValue = res.data.longUrl;
      this.smsDataLabelValue = res.data.sign;
      this.smsSign = res.data.smsSign;
      this.templateId = res.data.id;
      let hangupSmsRouteInfoList = res.data.hangupSmsRouteInfoList;
      if (hangupSmsRouteInfoList) {
        hangupSmsRouteInfoList.forEach((item) => {
          if (item.customerPhoneOperator) {
            item.customerPhoneOperator = item.customerPhoneOperator.split(",");
          } else {
            item.customerPhoneOperator = [];
          }
          if (item.forbiddenArea) {
            item.forbiddenArea = item.forbiddenArea.split(",");
          } else {
            item.forbiddenArea = [];
          }
          item.platformLabel = item.platform + "_" + item.accessKey;
        });
      }
      this.strategy = res.data;
      console.log("task", res.data);
      this.strategy.showSendSmsButton = false;
      this.strategy.smsLongUrlGroup = "";
      this.strategy.extraLongUrlGroup = "";
      this.strategy.originateTimeout = "";
      this.strategy.answerInterval = "";
      this.strategy.stopCallOnFull = false;
      this.strategy.pauseSecondsOnFull = "";
      this.strategy.addWechatIfMarkFlag = false;
      this.strategy.flagWechatClientGroupId = "";
      this.strategy.showAddWechatButton = false;
      this.strategy.clientWechatServer = "";
      this.strategy.clientWechatClientGroupId = "";
      // --------
      this.strategy.unFlagWechatServer = "";
      this.strategy.addWechatIfMarkUnFlag = false;
      this.strategy.notifyAddFans = false;
      // this.strategy.addWechatOnHangup = false;
      // this.strategy.changeCustomerSourceType = false;
      this.strategy.unFlagWechatClientGroupId = "";
      this.strategy.addWechatIfMarkUnFlagTime = "";
      this.strategy.notifyAddFansTimes = "";
      if (this.strategy.routeMultiple) {
        this.strategy.routeMultiple = this.strategy.routeMultiple / 100;
      }
      if (res.data.strategyTemplateClientTalking) {
        this.strategy.originateTimeout =
          res.data.strategyTemplateClientTalking.originateTimeout;
        this.strategy.answerInterval =
          res.data.strategyTemplateClientTalking.answerInterval;
        this.strategy.stopCallOnFull =
          res.data.strategyTemplateClientTalking.stopCallOnFull;
        this.strategy.pauseSecondsOnFull =
          res.data.strategyTemplateClientTalking.pauseSecondsOnFull;
        this.strategy.addWechatIfMarkFlag =
          res.data.strategyTemplateClientTalking.addWechatIfMarkFlag;
        this.strategy.addWechatIfMarkUnFlag =
          res.data.strategyTemplateClientTalking.addWechatIfMarkUnFlag;
        // this.strategy.notifyAddFans =
        // res.data.strategyTemplateClientTalking.notifyAddFans;
        this.strategy.unFlagWechatServer =
          res.data.strategyTemplateClientTalking.unFlagWechatServer;
        this.strategy.unFlagWechatClientGroupId =
          res.data.strategyTemplateClientTalking.unFlagWechatClientGroupId;
        this.strategy.addWechatIfMarkUnFlagTime =
          res.data.strategyTemplateClientTalking.addWechatIfMarkUnFlagTime /
          1000;
        // this.strategy.notifyAddFansTimes =
        // res.data.strategyTemplateClientTalking.notifyAddFansTimes/1000;
        this.strategy.flagWechatServer =
          res.data.strategyTemplateClientTalking.flagWechatServer;
        this.strategy.flagWechatClientGroupId =
          res.data.strategyTemplateClientTalking.flagWechatClientGroupId;
        this.strategy.showAddWechatButton =
          res.data.strategyTemplateClientTalking.showAddWechatButton;
        this.strategy.showSendSmsButton =
          res.data.strategyTemplateClientTalking.showSendSmsButton;
        this.strategy.clientWechatServer =
          res.data.strategyTemplateClientTalking.clientWechatServer;
        this.strategy.clientWechatClientGroupId =
          res.data.strategyTemplateClientTalking.clientWechatClientGroupId;
      }
    },
    changStrategy_submit(type, data) {
      console.log(type);
      // 确定修改策略
      if (type === "batchSet") {
        this.batchTaskIndex = 0;
        this.batchOperationShow = true;
        this.batchStepsList = this.$common.deepClone(this.batchTaskStepsList);
        this.batchSetSteategyStepPause(
          this.batchStepsList,
          this.batchStepsList[0],
          0,
          data
        );
      }
      this.strategy_from_hangupsms = "";
    },
    changeTeamName(val) {
      //点击队列名称
      this.changeTeamNameShow = true;
      this.taskId = val.id;
      this.sipTeamId = val.sipTeamId;
      this.userLoginName = val.userLoginName;
      this.teamLoginName = val.userLoginName;
      let data = {
        pageNo: 1,
        pageSize: 20,
        teamId: val.sipTeamId,
        loginName: val.userLoginName,
      };
      let params = {
        pageNo: 1,
        pageSize: 1000,
        loginName: val.userLoginName,
        type: "",
      };
      if (
        val.type === "PROLOGUE_SMS" ||
        val.type === "PROLOGUE_WECHAT" ||
        val.type === "AI_ROBOT"
      ) {
        params.type = "VIRTUAL";
      } else {
        params.type = "NORMAL";
      }
      this.inquireTeamName(params);
      this.getTeamDetails(data);
    },
    changeTeamName_submit() {
      //确定队列名称
      let data = {
        taskId: this.taskId,
        teamId: this.teamNameId,
        loginName: this.userLoginName,
      };
      this.isChangeTeamName_submit = true;
      this.$http("/sip/task/modifyTeam", data).then((res) => {
        this.isChangeTeamName_submit = false;
        if (res.result == 200) {
          this.$message({ message: "修改成功", type: "success" });
          this.inquire();
          this.changeTeamName_cencel();
        }
      });
    },
    changeTeamName_cencel() {
      //取消队列名称
      this.changeTeamNameShow = false;
    },
    changeTeamClose() {
      //关闭队列名称
      this.isChangeTeamName_submit = false;
      this.teamNameId = "";
    },
    changeLine(val, type) {
      //点击修改线路
      if (type === "line") {
        this.isCallbackLine = false;
      }
      if (type === "callbackLine") {
        this.isCallbackLine = true;
      }
      this.isBatchRouteNameCall = false;
      this.changeLineShow = true;
      this.inquireRoute({
        pageNo: 1,
        pageSize: 1000,
        loginName: val.userLoginName,
      });
      this.taskId = val.id;
      this.sipRouteId = val.sipRouteId;
      this.callbackLineId = val.sipCallbackRouteId;
      this.userLoginName = val.userLoginName;
      this.taskServer = val.server;
    },
    changeLine_submit() {
      //确定修改线路
      let data = {
        taskId: this.taskId,
        loginName: this.userLoginName,
      };
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.isChangeLineSubmit = true;
      if (!this.isCallbackLine) {
        data.routeId = this.lineId;
        this.$http("/sip/task/modifyRoute", data, "post", path, null).then(
          (res) => {
            this.isChangeLineSubmit = false;
            if (res.result == 200) {
              this.$message({ message: "修改成功", type: "success" });
              this.inquire();
              this.changeLine_cencel();
            }
          }
        );
      } else {
        data.routeId = this.callbackLineId;
        this.$service(
          "/sip/task/modifyCallbackRoute",
          data,
          "post",
          path,
          null
        ).then((res) => {
          this.isChangeLineSubmit = false;
          if (res.result == 200) {
            this.$message({ message: "修改成功", type: "success" });
            this.inquire();
            this.changeLine_cencel();
          }
        });
      }
    },
    changeLine_cencel() {
      //取消修改线路
      this.changeLineShow = false;
    },
    inquireRoute(params) {
      //查询线路
      this.$http("/route/customerList", null, "get", null, params).then(
        (res) => {
          if (res.result == 200) {
            this.lineList = res.data.list;
            if (res.data.list && res.data.list.length > 0) {
              this.lineId = this.lineList[0].id;
              this.lineList.map((item) => {
                if (this.sipRouteId == item.id) {
                  this.lineId = this.sipRouteId;
                }
              });
            }
          }
        }
      );
    },
    inquireTeamName(params) {
      //查询队列
      this.teamNameList = [];
      this.$http("/sip/team/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          this.teamNameList = list;
          this.teamNameList.map((item) => {
            if (this.sipTeamId !== -1) {
              if (item.id === this.sipTeamId) {
                this.teamNameId = this.sipTeamId;
              }
            }
          });
        }
      });
    },
    closeLine() {
      //关闭线路弹框
      this.lineList = [];
      this.lineId = "";
      this.isChangeLineSubmit = false;
    },
    remoteMethod(projectName) {
      //搜索项目名称
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          projectName: projectName,
          loginName: this.loginName,
        };
        this.inquireProject(params);
      }, 200);
    },
    // 项目名称获取焦点
    projectIdFocus() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          loginName: this.loginName,
        };
        this.inquireProject(params);
      }, 200);
    },
    inquireProject(params) {
      //查询项目名称
      this.projectNameList = [];
      this.$http("/sip/project/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newProjectNameList = res.data.list;
          newProjectNameList.map((item) => {
            this.projectNameList.push(item);
          });
        }
      });
    },
    loginNameChange(userName) {
      //改变用户名称
      this.condition.projectId = "";
    },
    migrationLoginNameChange(userName) {
      //改变数据迁移用户名称
      this.migrationProjectId = "";
      if (!userName) {
        this.migrationProjectDisabled = true;
      } else {
        this.migrationProjectDisabled = false;
      }
    },
    // 数据迁移项目名称获取焦点
    migrationProjectFocus() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          loginName: this.migrationUserLoginName,
        };
        this.inquireProject(params);
      }, 200);
    },
    migrationRemoteMethodProject(projectName) {
      //数据迁移搜索项目名称
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          projectName: projectName,
          loginName: this.migrationUserLoginName,
        };
        this.inquireProject(params);
      }, 200);
    },
    crossTaskLoginNameChange(userName) {
      //跨任务复用改变用户名称
      this.createForm.crossProjectName = "";
      this.createForm.crossTaskNameList = [];
    },
    // 用户名称获取焦点
    NameRemoteFocus() {
      this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true });
    },
    remoteMethodLoginName(userName) {
      //搜索用户名称
      this.loadingLoginName = true;
      setTimeout(() => {
        this.loadingLoginName = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          userName: userName,
          enabled: true,
        };
        this.inquireLoginName(params);
      }, 200);
    },
    inquireLoginName(params) {
      //查询用户名称
      this.loginNameList = [];
      this.$http("/user/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newuserNameList = res.data.list;
          newuserNameList.map((item) => {
            item.label = item.userName;
            this.loginNameList.push(item);
          });
        }
      });
    },
    userNameChange(userName) {
      //改变新增用户名称
      this.projectListId = "";
      this.peojectSipTeamId = "";
      this.projectSipRouteId = "";
      this.callbackLine = "";
      this.sipDisabled = true;
      this.projectDisabled = false;
      this.isPrologueDisabled = true;
      this.form.taskType = "NORMAL";
      this.form.crossTaskNameList = [];
      // this.getTeamName({"pageNo":1,"pageSize":20,"loginName":this.userNameId});
      this.inquireRoute({
        pageNo: 1,
        pageSize: 1000,
        loginName: this.userNameId,
      });
      this.inquireTeamName({
        pageNo: 1,
        pageSize: 1000,
        loginName: this.userNameId,
      });
      this.userNameList.forEach((item) => {
        if (userName === item.loginName) {
          this.taskServer = item.server;
        }
      });
      if (userName) {
        this.distributeTaskDisabled = false;
      } else {
        this.distributeTaskDisabled = true;
      }
    },
    // 新增队列选择获取焦点
    peojectSipTeamIdFocus() {
      this.inquireTeamName({
        pageNo: 1,
        pageSize: 1000,
        loginName: this.userNameId,
      });
    },
    // 新增线路选择获取焦点
    projectSipRouteIdFocus() {
      this.inquireRoute({
        pageNo: 1,
        pageSize: 1000,
        loginName: this.userNameId,
      });
    },
    // 新增用户名称获取焦点
    userNameRemoteFocus() {
      this.inquireUserName({ pageNo: 1, pageSize: 20, enabled: true });
    },
    remoteMethodUserName(userName) {
      //搜索新增用户名称
      this.loadingUserName = true;
      setTimeout(() => {
        this.loadingUserName = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          userName: userName,
          enabled: true,
        };
        this.inquireUserName(params);
      }, 200);
    },
    inquireUserName(params) {
      //查询新增用户名称
      this.userNameList = [];
      this.$http("/user/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newuserNameList = res.data.list;
          newuserNameList.map((item) => {
            this.userNameList.push(item);
          });
        }
      });
    },
    reCreate(val) {
      //点击重新创建
      let taskName = val.taskName;
      this.userLoginName = val.userLoginName;
      this.taskServer = val.server;
      this.createDialogShow = true;
      this.createForm.taskId = val.id;
      this.createForm.taskName = val.taskName + "-重拨" + 1;
      if (taskName.indexOf("-重拨") !== -1) {
        let i = taskName.indexOf("-重拨");
        let num = val.taskName.substring(i + 3);
        num = parseInt(num);
        num++;
        let newTaskName = val.taskName.substring(0, i + 3);
        this.createForm.taskName = newTaskName + num;
      }
      let data = {
        projectId: val.projectId,
      };
      this.getProjectDetail(data);
    },
    // 查询任务所属项目详情
    getProjectDetail(data) {
      this.isCategoryJiaoyu = false;
      this.$http("/sip/project/projectDetail", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            if (res.data) {
              this.projectTypeList.forEach((item) => {
                if (item.id === res.data.categoryId) {
                  if (item.category === "jiaoyu") {
                    this.isCategoryJiaoyu = true;
                    this.createDisorder = true;
                  }
                }
              });
            }
          }
        }
      );
    },
    closeCreateDialog() {
      //关闭重新创建弹窗
      // this.callStatus = "NOT_RECEIVED";
      this.iscreateSubmit = false;
      this.createDisorder = false;
      this.isCategoryJiaoyu = false;
      this.createForm = {
        taskName: "",
        taskId: "",
      };
    },
    create_submit() {
      //确定创建
      let data = {
        taskId: this.createForm.taskId,
        taskName: this.createForm.taskName,
        // callStatus:this.callStatus,
        loginName: this.userLoginName,
        shuffle: this.createDisorder,
      };
      this.iscreateSubmit = true;
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.$http("/sip/task/recycleCreate", data, "post", path, null).then(
        (res) => {
          this.iscreateSubmit = false;
          if (res.result == 200) {
            this.$message({ message: "创建成功", type: "success" });
            this.inquire(1);
            this.createDialogShow = false;
          }
        }
      );
    },
    create_cancel() {
      //取消创建
      this.createDialogShow = false;
    },
    supplementNumber(val) {
      //点击补充号码
      this.numberDialogShow = true;
      this.numberForm.taskId = val.id;
      this.userLoginName = val.userLoginName;
      this.taskServer = val.server;
    },
    number_submit() {
      //补充号码提交
      this.isNumberSubmit = true;
      this.numberUrl =
        "https://" +
        this.taskServer +
        this.$constants.basePath +
        "/sip/task/append";
      this.numberUploadData.taskId = this.numberForm.taskId;
      this.numberUploadData.loginName = this.userLoginName;
      this.numberUploadData.shuffle = this.numberDisorder;
      this.$nextTick(() => {
        this.$refs.numberUpload.submit();
      });
    },
    number_cancel() {
      //取消补充号码
      this.numberDialogShow = false;
    },
    closeNumberDialog() {
      //关闭补充号码弹窗
      this.numberForm.taskId = "";
      this.numberDisorder = false;
      this.isNumberSubmit = false;
      this.$refs.numberUpload.clearFiles();
    },
    successNumberUpload(res, file, fileList) {
      //补充号码上传成功
      this.$refs.numberUpload.clearFiles();
      if (res.result == 200) {
        this.isNumberSubmit = false;
        this.$message({ message: "文件上传成功！", type: "success" });
        this.number_cancel();
        this.inquire();
      }
    },
    errorNumberUpload(err, file, fileList) {
      //补充号码上传失败
      this.isNumberSubmit = false;
      let message = JSON.parse(err.message);
      this.$message.warning(message.message);
    },
    viewConnected(val) {
      //查看接通数
      this.connectedTableShow = true;
      this.callStatusList = "CONNECTED";
      this.taskId = val.id;
      this.userLoginName = val.userLoginName;
      this.projectId = val.projectId;
      this.taskServer = val.server;
      let params = {
        pageSize: 10,
        pageNo: this.detailsCurrentPage,
        projectId: this.projectId,
        taskId: this.taskId,
        callStatus: this.callStatusList,
        loginName: val.userLoginName,
      };
      this.loadingTab = true;
      this.inquierCall(params);
    },
    connectedTableClose() {
      //关闭查询通话记录
      this.connectedTableData = [];
    },
    inquierCall(params) {
      //查询通话记录
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.$http("/sip/call/list", null, "get", path, params).then((res) => {
        setTimeout(() => {
          this.loadingTab = false;
        }, 200);
        if (res.result == 200) {
          let list = res.data.list;
          this.detailsTotal = res.data.count;
          list.map((item) => {
            //数据转换
            item.callEndAt = this.$common.transformTime(item.callEndAt);
            if (item.callSeconds == -1) {
              item.callSeconds = "-";
            } else {
              item.callSeconds = `${item.callSeconds} 秒`;
            }
            if (item.ringSeconds == -1) {
              item.ringSeconds = "-";
            } else {
              item.ringSeconds = `${item.ringSeconds} 秒`;
            }
            if (item.distributeSeconds == -1) {
              item.distributeSeconds = "-";
            } else {
              item.distributeSeconds = `${item.distributeSeconds} 秒`;
            }
            switch (item.callStatus) {
              case "CONNECTED":
                item.callStatus = "接通";
                item.playVoiceDisabled = false;
                break;
              case "NO_ANSWER":
                item.callStatus = "无人接听";
                break;
              case "USER_BUSY":
                item.callStatus = "占线";
                break;
              case "EXCEPTION":
                item.callStatus = "无法接通";
                break;
            }
            switch (item.distributed) {
              case true:
                item.distributed = "是";
                break;
              case false:
                item.distributed = "否";
                break;
            }
            switch (item.connected) {
              case true:
                item.connected = "是";
                break;
              case false:
                item.connected = "否";
                break;
            }
          });
          this.connectedTableData = list;
        }
      });
    },
    shuffleClick(val) {
      //点击乱序
      let data = {
        batchNumber: val.batchNumber,
        totalCallCount: val.totalCallCount,
      };
      this.$confirm("确认乱序, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let path = "https://" + val.server + this.$constants.basePath;
          this.$service("/sip/task/shuffleOrder", data, "post", path).then(
            (res) => {
              if (res.result == 200) {
                this.inquire();
                this.$message({ message: "操作成功！", type: "success" });
              }
            }
          );
        })
        .catch(() => {});
    },
    reloadClick(val) {
      //任务状态同步
      let data = {
        batchNumber: val.batchNumber,
      };
      this.$confirm(
        "操作会重新加载sipCall的未呼叫数据到sipCallPool, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning",
        }
      )
        .then(() => {
          let path = "https://" + val.server + this.$constants.basePath;
          this.$http(
            "/distribute/reloadSipCallToPool",
            null,
            "get",
            path,
            data
          ).then((res) => {
            if (res.result == 200) {
              this.inquire();
              this.$message({ message: "任务状态同步成功！", type: "success" });
            }
          });
        })
        .catch(() => {});
    },
    reStatisticsClick(val) {
      //异常状态同步
      let data = {
        batchNumber: val.batchNumber,
      };
      this.$confirm(
        "将已执行未同步到master的拨打信息重新同步一次, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning",
        }
      )
        .then(() => {
          let path = "https://" + val.server + this.$constants.basePath;
          this.$http(
            "/distribute/reStatisticsExceptionInfo",
            null,
            "get",
            path,
            data
          ).then((res) => {
            if (res.result == 200) {
              this.inquire();
              this.$message({ message: "异常状态同步成功！", type: "success" });
            }
          });
        })
        .catch(() => {});
    },
    modfityTaskStatusClick(val) {
      //修改任务状态
      this.modfityTaskStatusShow = true;
      this.modfityTaskStatus = val.status;
      this.modfityTaskNumber = val.batchNumber;
      this.taskServer = val.server;
    },
    // 确定修改任务状态
    modfityTaskStatus_submit() {
      let data = {
        batchNumber: this.modfityTaskNumber,
        status: this.modfityTaskStatus,
      };
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.$service("/sip/task/modifyTaskStatus", data, "post", path).then(
        (res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.modfityTaskStatusShow = false;
            this.inquire();
          }
        }
      );
    },
    closeTask(val) {
      //点击关闭任务
      let data = {
        batchNumber: val.batchNumber,
        // status:'CLOSED',
        userLoginName: val.userLoginName,
      };
      this.$confirm("关闭任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          this.$service("/sip/task/closeTask", data).then((res) => {
            if (res.result == 200) {
              this.inquire();
              this.$message({ message: "关闭成功！", type: "success" });
            }
          });
        })
        .catch(() => {});
    },
    deleteTask(val) {
      //点击删除任务
      let data = {
        batchNumber: val.batchNumber,
        // status:'DELETED',
        userLoginName: val.userLoginName,
      };
      this.$confirm("删除【" + val.taskName + "】任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          this.$service("/sip/task/deleteTask", data).then((res) => {
            if (res.result == 200) {
              this.inquire();
              this.$message({ message: "删除成功！", type: "success" });
            }
          });
        })
        .catch(() => {});
    },
    // 点击数据迁移
    dataMigration(val) {
      this.dataMigrationShow = true;
      this.batchNumberMigration = val.batchNumber;
      this.taskServer = val.server;
    },
    // 确定数据迁移
    dataMigration_submit() {
      let data = {
        batchNumber: this.batchNumberMigration,
        userLoginName: this.migrationUserLoginName,
        projectId: this.migrationProjectId,
      };
      if (!data.userLoginName) {
        this.$message.warning("请选择用户");
        return;
      }
      if (!data.projectId) {
        this.$message.warning("请选择项目");
        return;
      }
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.$http("/sip/task/transSipCallTask", null, "get", path, data).then(
        (res) => {
          if (res.result === 200) {
            this.dataMigrationShow = false;
            this.dataMigrationProgress(data.batchNumber);
          }
        }
      );
    },
    // 数据迁移进度
    dataMigrationProgress(batchNumber) {
      this.dataProgressShow = true;
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.dataMigrationTimer = setInterval(() => {
        this.$http("/sip/task/sipCallTaskTransferInfo", null, "get", path, {
          batchNumber: batchNumber,
        }).then((res) => {
          if (res.result === 200) {
            this.progressBar(res.data);
          }
        });
      }, 1000);
    },
    // 获取百分比（此处保留了一位小数）
    progressBar(progressEvent) {
      let percentage = Number(
        ((progressEvent.current / progressEvent.total) * 100).toFixed(1)
      );
      this.progressNum = percentage;
      if (percentage === 100) {
        clearInterval(this.dataMigrationTimer);
        this.dataMigrationTimer = null;
        this.isDataProgressShow = true;
      }
    },
    // 关闭数据迁移进度
    dataProgressClose() {
      this.isDataProgressShow = false;
      this.progressNum = 0;
      this.inquire();
    },
    // 关闭数据迁移
    dataMigrationClose() {
      this.migrationProjectDisabled = true;
      this.migrationUserLoginName = "";
      this.migrationProjectId = "";
      this.batchNumberMigration = "";
    },
  },
  beforeDestroy() {
    //销毁组件关闭定时器
    let timerArr = Object.keys(this.timer);
    let creatArr = Object.keys(this.timerCreat);
    let cleanArr = Object.keys(this.timerClean);
    let pausArr = Object.keys(this.timerPaus);
    let queueArr = Object.keys(this.timerQueue);
    let orderingArr = Object.keys(this.timerOrdering);
    if (timerArr.length > 0) {
      for (let i in this.timer) {
        clearInterval(this.timer[i]);
        this.timer[i] = null;
      }
      this.timer = {};
    }
    if (pausArr.length > 0) {
      for (let i in this.timerPaus) {
        clearInterval(this.timerPaus[i]);
        this.timerPaus[i] = null;
      }
      this.timerPaus = {};
    }
    if (creatArr.length > 0) {
      for (let i in this.timerCreat) {
        clearInterval(this.timerCreat[i]);
        this.timerCreat[i] = null;
      }
      this.timerCreat = {};
    }
    if (cleanArr.length > 0) {
      for (let i in this.timerClean) {
        clearInterval(this.timerClean[i]);
        this.timerClean[i] = null;
      }
      this.timerClean = {};
    }
    if (orderingArr.length > 0) {
      for (let i in this.timerOrdering) {
        clearInterval(this.timerOrdering[i]);
        this.timerOrdering[i] = null;
      }
      this.timerOrdering = {};
    }
    if (queueArr.length > 0) {
      for (let i in this.timerQueue) {
        clearInterval(this.timerQueue[i]);
        this.timerQueue[i] = null;
      }
      this.timerQueue = {};
    }
  },
};
</script>

<style scoped>
.project >>> .el-tabs__content {
  overflow: auto;
}
.smsTemplate >>> .el-table__fixed-right {
  height: 100% !important;
}
.clear {
  margin-left: 10px;
  color: rgb(179, 174, 174);
}
.taskName_td {
  display: flex;
  justify-content: space-between;
}
.teamDetails-class {
  display: inline-block;
}
.task-table >>> .user-class {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-pai {
  font-weight: bold;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-dropdown {
  font-size: 12px;
  margin-left: 10px;
}
.el-dropdown-menu .el-button {
  display: block;
  width: 95px;
  border-radius: 0px;
}
.el-dropdown-menu .el-button + .el-button {
  margin: 0;
}
.el-dropdown-menu .el-button:hover {
  background: #ecf5ff;
}
.customDialog >>> .el-dialog {
  border-radius: 50px;
  background: transparent;
}
.customDialog >>> .el-dialog__header {
  padding: 0px;
}
.customDialog >>> .el-dialog__body {
  padding: 0px;
  font-size: 0px;
}
.connectedTable >>> .el-table__body .cell {
  font-size: 14px;
}
.addProjectClass >>> .el-dialog__body {
  padding: 30px 20px 0 20px;
}
.addProjectClass >>> .el-dialog__footer {
  padding: 0px 20px 20px 20px;
}
.addProjectClass .el-form-item .el-input,
.addProjectClass .el-form-item .el-select {
  width: 240px;
}
.addProjectClass .el-form-item .switch_style_m {
  width: 252px;
}
.addProjectClass .el-form-item .switch_style_m_w {
  position: relative;
}
.addProjectClass .el-form-item .switch_style_m_w .addWechat_info {
  position: absolute;
  top: 0px;
  left: 50px;
  width: 600px;
}
.addProjectClass .el-form-item .switch_style_m_i {
  width: 270px;
}
.addProjectClass .el-form-item .el-tabs {
  width: 600px;
}
.connectedTable >>> .el-dialog__body::after {
  display: block;
  content: "";
  clear: both;
}
.connectedTable >>> .el-dialog__body {
  padding: 10px 20px;
}
.connectedTable >>> .el-table {
  margin: 0 0 20px 0;
}
.textPrompt {
  font-size: 12px;
  color: #f00;
  /* display: inline-block; */
  margin-bottom: 10px;
}
.intervalClass {
  display: inline-block;
  width: 1px;
  margin-right: 48px;
}
.el-changeLine >>> .el-dialog__body {
  padding-bottom: 0;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.el-changeLine .el-form-item .el-select {
  width: 95%;
  margin: 0;
}
.el-changeLine .el-form-item .el-input {
  width: 95%;
  margin: 0;
}
.addProjectClass .upload-demo >>> .el-upload-dragger {
  width: 600px !important;
}
/* .el-input {
  width: 95% !important;
} */
/* .el-addProject .el-form {
  margin-right: 38px;
} */
.projectStatus {
  width: 400px;
  display: inline-block;
}
.projectStatus .el-select {
  margin-left: 20px;
  width: 260px;
}
.nav {
  position: relative;
  width: 800px;
}
.projectChoice {
  margin-bottom: 20px;
  width: 400px;
}
.projectChoice .el-select {
  width: 260px;
}
.projectName-class {
  margin-bottom: 20px;
  width: 400px;
  /* display: inline-block; */
  /* position: absolute; */
  top: 0px;
  left: 400px;
}
.taskName-class {
  margin-bottom: 20px;
  width: 400px;
  /* display: inline-block; */
  /* position: absolute; */
  top: 60px;
  left: 400px;
}
.taskName-class .el-input {
  margin-left: 20px;
  width: 260px !important;
}
.callTimeClass {
  width: 600px;
  position: absolute;
  top: 120px;
  left: 400px;
}
.callTimeClass .startTime {
  width: 200px !important;
  margin-right: 6px;
  margin-left: 20px;
}
.callTimeClass .endTime {
  width: 200px !important;
  margin-left: 7px;
}
.callTime-class {
  width: 600px;
  display: inline-block;
}
.callTime-class .startTime {
  width: 200px !important;
  margin-right: 6px;
  margin-left: 20px;
}
.callTime-class .endTime {
  width: 200px !important;
  margin-left: 7px;
}
.recycled-class {
  margin-bottom: 20px;
  width: 400px;
  display: inline-block;
  position: absolute;
  top: 60px;
  left: 800px;
}
.recycled-class .el-select {
  width: 260px;
}
.batchNumber-class {
  margin-bottom: 20px;
  width: 400px;
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 800px;
}
.batchNumber-class .el-input {
  margin-left: 20px;
  width: 260px !important;
}
.projectName-class .el-select {
  margin-left: 20px;
  width: 260px;
}
.connectedCountTime .call-startTime {
  width: 70px !important;
  margin: 8px 8px 8px 24px;
}
.connectedCountTime .call-endTime {
  width: 70px !important;
  margin: 8px;
}
.gmtCreatedTime .startTime {
  width: 200px !important;
  margin: 8px;
}
.gmtCreatedTime .endTime {
  width: 200px !important;
  margin: 8px;
}
.form-left {
  text-align: center;
}
.form-left >>> .el-form-item__content {
  margin: 0 !important;
}
.button-mar {
  margin-top: 10px;
}
.relationTaskList >>> .el-form-item {
  margin: 0 !important;
}
.relationTaskList >>> .el-form-item__label {
  line-height: 20px !important;
}
.relationTaskList >>> .el-form-item__content {
  line-height: 20px !important;
}
</style>

<style>
.tableDrawerClass .el-drawer__body {
  padding: 20px;
  overflow: auto;
}
.btn-custom-cancel {
  float: right;
  margin-left: 10px !important;
}
</style>
