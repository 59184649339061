<template>
  <div class="smsContent">
    <el-input
      v-model="SMStextarea"
      type="textarea"
      placeholder="请输入短信内容"
      :rows="6"
      @input="inputChange"
      :disabled="isDisabled"
      id="shortContent"
      style="width: 74%"
    ></el-input>
    <el-input
      v-model="SMStextareaPreview"
      type="textarea"
      placeholder="短信预览"
      :rows="6"
      disabled
      style="width: 25%"
    ></el-input>
    <span class="word">
      已输入
      <i>{{ SMS.totalNum }}</i
      >个字： 签名 <i>({{ SMS.signLength }})</i>+ 内容(
      <i>{{ SMS.contentNum }}</i
      >) ，共消耗 <i>{{ SMS.num }}</i
      >条短信
      <span class="template-sign">选择标签：</span>
      <span v-if="isDisabled">
        <el-button type="text" disabled class="template2">#尾号#</el-button>
        <el-button type="text" disabled class="template3">#短链#</el-button>
      </span>
      <span v-if="!isDisabled">
        <el-button
          type="text"
          :disabled="isEndNumDis"
          @click="endNumberChain"
          class="template2"
          >#尾号#</el-button
        >
        <el-button
          type="text"
          :disabled="isCityDis"
          @click="cityChain"
          class="template3"
          >#短链#</el-button
        >
      </span>
    </span>
    <div>
      数据标记
      <el-tooltip
        content="数据拨打时会对拨打记录(sip_call)进行标记, 方便后续统计"
        placement="top-start"
        effect="light"
      >
        <i class="el-icon-question" style="color: #909399"></i> </el-tooltip
      >：
      <el-input
        v-model="smsDataLabel"
        placeholder="请输入数据标记"
        @input="smsDataLabelChange"
        style="width: 300px; margin: 0 0 5px 0"
        :disabled="isDisabled || isAddPlatform"
      ></el-input>
    </div>
    <div>
      <span v-if="isCityDis"
        >点击回调
        <el-tooltip
          content="用户点击短信链接会触发点击回调"
          placement="top-start"
          effect="light"
        >
          <i class="el-icon-question" style="color: #909399"></i> </el-tooltip
        >：
        <el-radio
          v-model="clickToCall"
          label="NONE"
          :disabled="isDisabled || isAddPlatform"
          >无</el-radio
        >
        <el-radio
          v-model="clickToCall"
          label="ADD_WECHAT"
          :disabled="isDisabled || isAddPlatform"
          >添加微信</el-radio
        >
        <el-select
          v-if="clickToCall === 'ADD_WECHAT'"
          v-model="wechatServer"
          placeholder="请选择服务器"
          @change="wechatClientGroupFocus(wechatServer, '1')"
          :disabled="isDisabled || isAddPlatform"
        >
          <el-option
            v-for="item in wechatServerList"
            :key="item.server"
            :label="item.server"
            :value="item.server"
          >
          </el-option>
        </el-select>
        <el-select
          v-if="clickToCall === 'ADD_WECHAT'"
          v-model="wechatClientGroupId"
          @change="wechatClientGroupIdChange"
          placeholder="请选择机器分组"
          :disabled="isDisabled || isAddPlatform"
        >
          <el-option
            v-for="item in wechatClientGroupList"
            :key="item.id"
            :label="item.groupName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </span>
    </div>
    <div>
      <span v-if="isCityDis"
        >前缀：
        <el-radio v-model="prefix" label="https://" :disabled="isDisabled"
          >https://</el-radio
        >
        <el-radio v-model="prefix" label="none" :disabled="isDisabled"
          >无</el-radio
        >
      </span>
    </div>
    <div>
      <span v-if="isCityDis"
        >长链：
        <el-input
          v-model="longUrl"
          placeholder="请输入长链"
          @input="longUrlChange"
          @blur="compareToLongUrl(longUrl)"
          style="width: 300px"
          :disabled="isDisabled"
        ></el-input>
        <span
          ><el-button type="text" @click="getParseUrlTitle">解析标题</el-button
          >：{{ urlTitle }}</span
        >
        <div
          v-if="urlFormatDisabel"
          class="dropbox-ts"
          style="
            color: red;
            font-size: 12px;
            margin-left: 48px;
            line-height: 20px;
          "
        >
          长网址链接格式不正确
        </div>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "smsInput",
  props: [
    "textareaSMS",
    "isDisabled",
    "longUrlValue",
    "prefixValue",
    "urlFormat",
    "callValue",
    "serverWechat",
    "clientGroupId",
    "smsDataLabelValue",
    "parseUrlTitle",
    "smsSign",
    "isAddPlatform",
  ],
  data() {
    return {
      urlTitle: "",
      urlFormatDisabel: false,
      SMStextarea: "",
      SMStextareaPreview: "",
      isEndNumDis: false,
      isCityDis: false,
      prefix: "https://",
      clickToCall: "NONE",
      longUrl: "",
      smsDataLabel: "",
      SMS: {
        totalNum: 0,
        signLength: 0,
        contentNum: 0,
        num: 0,
      },
      wechatServer: "t9.innerchic.cn",
      wechatServerList: [],
      wechatClientGroupId: "",
      wechatClientGroupList: [],
      wechatServerList: [
        {
          server: "t1.innerchic.cn",
        },
        {
          server: "t9.innerchic.cn",
        },
      ],
    };
  },
  watch: {
    smsSign(val) {
      if (val !== null) {
        this.smsTotal();
      }
    },
    SMStextarea(val) {
      if (val !== null) {
        this.smsTotal();
      }
    },
    textareaSMS(val) {
      // 短信内容
      if (val === undefined) {
        this.SMStextarea = "";
      } else {
        this.SMStextarea = val;
      }
      this.$emit("change", val);
    },
    // 监听前缀
    prefix(val) {
      if (val !== null) {
        this.smsTotal();
      }
      this.$emit("prefixChange", val);
    },
    // 监听点击调用
    clickToCall(val) {
      if (val === "ADD_WECHAT") {
        this.wechatClientGroupFocus(this.serverWechat);
      }
      this.$emit("callValueChange", val);
    },
    prefixValue(val) {
      if (val) {
        this.prefix = val;
      } else {
        this.prefix = "none";
      }
    },
    callValue(val) {
      if (val) {
        this.clickToCall = val;
      } else {
        this.clickToCall = "NONE";
      }
      this.$emit("callValueChange", this.clickToCall);
    },
    serverWechat(val) {
      this.wechatServer = val;
    },
    clientGroupId(val) {
      if (val) {
        this.wechatClientGroupId = val;
      } else {
        this.wechatClientGroupId = "";
      }
    },
    parseUrlTitle(val) {
      if (val === undefined) {
        this.urlTitle = "";
      } else {
        this.urlTitle = val;
      }
    },
    longUrlValue(val) {
      if (val === undefined) {
        this.longUrl = "";
      } else {
        this.longUrl = val;
      }
      this.$emit("longUrlChange", val);
    },
    smsDataLabelValue(val) {
      if (val === undefined) {
        this.smsDataLabel = "";
      } else {
        this.smsDataLabel = val;
      }
      this.$emit("smsDataLabelChange", val);
    },
    urlFormat(val) {
      this.urlFormatDisabel = val;
    },
  },
  methods: {
    // 点击解析标题
    getParseUrlTitle() {
      if (this.longUrl) {
        let httpIndex = this.longUrl.indexOf("http://");
        let httpsIndex = this.longUrl.indexOf("https://");
        if (httpIndex === 0 || httpsIndex === 0) {
          this.apiParseUrlTitle(this.longUrl);
        }
      }
    },
    // 解析url标题
    apiParseUrlTitle(url) {
      this.$http("/parseUrlTitle", null, "get", null, { url: url }).then(
        (res) => {
          if (res.result === 200) {
            this.$message.success("解析成功");
            let title = res.data.urlTitle;
            this.urlTitle = title;
          }
        }
      );
    },
    // 服务器获取
    wechatClientGroupFocus(val, key) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        server: val,
      };
      if (key) {
        this.wechatClientGroupId = "";
        this.$emit("wechatGroupIdChange", this.wechatClientGroupId);
      }
      this.getWechatClientGroupList(data);
      this.$emit("wechatServerChange", val);
    },
    // 机器分组获取
    wechatClientGroupIdChange(val) {
      this.$emit("wechatGroupIdChange", val);
      this.$forceUpdate();
    },
    // 查询机器分组
    getWechatClientGroupList(data) {
      this.$service(
        "/wechat/wechatClientGroupList",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.wechatClientGroupList = list;
        }
      });
    },
    // 短信输入
    inputChange(val) {
      this.$emit("change", val);
    },
    // 长链输入
    longUrlChange(val) {
      this.$emit("longUrlChange", val);
    },
    // 数据标记输入
    smsDataLabelChange(val) {
      this.$emit("smsDataLabelChange", val);
    },
    // 提示长链接格式是否正确
    compareToLongUrl(value) {
      if (value) {
        let httpIndex = value.indexOf("http://");
        let httpsIndex = value.indexOf("https://");
        if (httpIndex === 0 || httpsIndex === 0) {
          this.$emit("urlFormatChange", false);
          return;
        }
        this.$emit("urlFormatChange", true);
      }
    },
    // 插入尾号 点击尾号---挂机短信设置
    endNumberChain() {
      this.SMStextarea = this.$common.insertInputTxt(
        "#尾号#",
        "shortContent",
        this.SMStextarea
      );
      this.$emit("change", this.SMStextarea);
    },
    // 插入城市 点击城市---挂机短信设置
    cityChain() {
      this.SMStextarea = this.$common.insertInputTxt(
        "#短链#",
        "shortContent",
        this.SMStextarea
      );
      this.$emit("change", this.SMStextarea);
    },
    // 计算短信字数
    smsTotal() {
      //文本域
      let SMStextarea = this.SMStextarea;
      if (SMStextarea === null) {
        return;
      }
      let index = SMStextarea.indexOf("#短链#");
      let endNum = SMStextarea.indexOf("#尾号#");
      let cityIndex = SMStextarea.indexOf("#城市#");
      this.SMS.signLength = 0;
      if (this.smsSign) {
        this.SMS.signLength = this.smsSign.length; // 标签长度计算
      }
      if (index !== -1) {
        //有短信内容 #短链#
        if (this.prefix === "https://") {
          this.SMS.contentNum = SMStextarea.length + 21 - 4; // 前缀https:// 21
        } else {
          this.SMS.contentNum = SMStextarea.length + 13 - 4; // 前缀无 21
        }
        this.SMS.totalNum = this.SMS.contentNum + this.SMS.signLength;
        this.isCityDis = true;
      } else {
        // this.SMStextarea = this.SMStextarea.trim();
        this.SMS.contentNum = SMStextarea.length;
        this.SMS.totalNum = this.SMS.contentNum + this.SMS.signLength;
        this.isCityDis = false;
      }
      if (endNum !== -1) {
        this.isEndNumDis = true;
      } else {
        this.isEndNumDis = false;
      }
      // if (cityIndex !== -1) {
      //   this.SMS.contentNum = SMStextarea.length - 2; // 城市算2个字符
      //   this.SMS.totalNum = this.SMS.contentNum + this.SMS.signLength;
      //   this.isCityDis = true;
      // } else {
      //   this.isCityDis = false;
      // }
      if (this.SMS.totalNum < 71) {
        this.SMS.num = 1;
      } else {
        this.SMS.num = Math.ceil((this.SMS.totalNum - 70) / 67) + 1;
      }
      this.SMStextareaPreview = this.smsSign + SMStextarea;
      this.$forceUpdate();
      this.$emit("smsNum", this.SMS.num);
    },
  },
};
</script>
<style scoped>
.smsContent {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}
.smsContent .template {
  float: right;
  font-size: 14px;
  margin-bottom: 5px;
  color: #3a8ee6;
}
.smsContent .editor {
  position: absolute;
  top: -6px;
  left: 70px;
  color: #3a8ee6;
  font-size: 14px;
}
.word {
  display: block;
  width: 100%;
  line-height: 40px;
  text-align: right;
  font-style: normal;
  font-size: 12px;
  position: relative;
}
.word .template-sign {
  position: absolute;
  bottom: -4px;
  left: 0px;
  font-size: 13px;
  margin-bottom: 5px;
  margin-right: 10px;
}
.word .template2 {
  position: absolute;
  bottom: -4px;
  left: 70px;
  font-size: 13px;
  margin-bottom: 5px;
  /* margin-right: 10px; */
  color: #1890ff;
}
.word .template3 {
  position: absolute;
  bottom: -4px;
  left: 120px;
  font-size: 13px;
  margin-bottom: 5px;
  /* margin-right: 10px; */
  margin-left: 0px;
  color: #1890ff;
}
.word i {
  color: red;
  font-style: normal;
}
</style>
