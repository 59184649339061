<template>
  <el-dialog
    :visible.sync="visible"
    :width="
      roleMenu == 'SUPER_MANAGER' || roleMenu === 'LIULIANG_MANAGER'
        ? '950px'
        : '500px'
    "
    class="el-strategy"
    @close="strategyClose"
    top="8vh"
  >
    <template slot="title">
      <i
        class="el-icon-edit-outline dialog-title-my"
        style="color: #1296db"
      ></i>
      <span class="dialog-title-my my-title">{{ title }}</span>
    </template>
    <!-- 启用挂机短信 -->
    <el-form
      ref="form"
      :inline="true"
      :model="strategy"
      label-width="150px"
      label-position="left"
      class="hangup_form"
    >
      <el-form-item label="模板名称：" v-if="type === 'template'">
        <div style="width: 300px">
          <el-input v-model="strategy.name" style="width: 202px"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="并发率：" v-if="isMultipleShow">
        <template slot="label">
          并发率
          <el-tooltip
            content="坐席数*并发率=最大并发数"
            placement="top-start"
            effect="light"
          >
            <i class="el-icon-question" style="color: #909399"></i> </el-tooltip
          >：
        </template>
        <el-input v-model="strategy.routeMultiple"></el-input>
      </el-form-item>
      <el-form-item label="最大并发数：" v-if="!isMultipleShow">
        <el-input v-model="strategy.maxRouteMultiple"></el-input>
      </el-form-item>

      <el-tabs
        v-model="activeNameTemplate"
        @tab-click="handleClick"
        v-if="roleMenu == 'SUPER_MANAGER' || roleMenu === 'LIULIANG_MANAGER'"
      >
        <el-tab-pane label="人工坐席" name="normal" :disabled="!isNormal">
          <el-form>
            <el-form-item style="width: 800px">
              <template slot="label">
                <span
                  >人工坐席内容：
                  <el-radio-group v-model="normalType">
                    <el-radio label="custom">自定义</el-radio>
                    <el-radio label="template">选择模板</el-radio>
                  </el-radio-group>
                  <el-select
                    v-if="normalType === 'template'"
                    v-model="smsTemplateId"
                    placeholder="请选择模板名称"
                    @change="smsTemplateIdChange(smsTemplateId, 'change')"
                    style="margin: 0 0 0 10px"
                  >
                    <el-option
                      v-for="item in smsTemplateIdList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-if="normalType === 'template'"
                    :disabled="smsTemplateIdDisabled"
                    v-model="smsTemplateChildrenId"
                    placeholder="请选择模板子名称"
                    @change="smsTemplateChildrenIdChange"
                  >
                    <el-option
                      v-for="item in smsTemplateChildrenIdList"
                      :key="item.id"
                      :label="item.subName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </span>
              </template>
            </el-form-item>
            <el-form-item label="呼叫超时(秒)：" required class="seats_item">
              <el-select
                v-model="strategy.originateTimeout"
                placeholder="请选择超时时间"
                :disabled="isDisabled"
                @change="originateTimeoutChange"
              >
                <el-option
                  v-for="item in originateTimeoutList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="坐席整理时间(秒)："
              required
              class="seats_item"
            >
              <el-input
                v-model="strategy.answerInterval"
                placeholder="请输入整理时间"
                :disabled="isDisabled"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="坐席全满暂停外呼："
              required
              class="seats_item"
            >
              <el-select
                v-model="strategy.stopCallOnFull"
                placeholder="请选择状态"
                @change="strategyChange"
                :disabled="isDisabled"
              >
                <el-option
                  v-for="item in strategyList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="坐席全满停止外呼(秒)："
              required
              class="seats_item"
            >
              <el-input
                v-model="strategy.pauseSecondsOnFull"
                :disabled="pauseSeconds_disabled || isDisabled"
                placeholder="请输入停止外呼秒数"
              ></el-input>
            </el-form-item>
            <el-form-item label="微信加粉：" required style="width: 700px">
              <template slot="label">
                微信加粉
                <el-tooltip
                  content="开启后，坐席页面将看到加微信按钮"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <el-switch
                v-model="strategy.showAddWechatButton"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="isDisabled"
              ></el-switch>
              <el-button
                type="text"
                v-if="strategy.showAddWechatButton"
                @click="groupSettings(strategy.clientWechatServer, 'wechat')"
                :disabled="isDisabled"
              >
                分组设置 {{ wechatGroupIdName }} ({{
                  strategy.clientWechatServer
                }})
              </el-button>
            </el-form-item>
            <el-form-item label="自动加粉：" required style="width: 700px">
              <template slot="label">
                自动加粉
                <el-tooltip
                  content="坐席标记为成单后， 自动进行加粉操作"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <el-switch
                v-model="strategy.addWechatIfMarkFlag"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="isDisabled"
              ></el-switch>
              <el-button
                type="text"
                v-if="strategy.addWechatIfMarkFlag"
                :disabled="isDisabled"
                @click="groupSettings(strategy.flagWechatServer, 'automatic')"
              >
                分组设置 {{ automaticGroupIdName }} ({{
                  strategy.flagWechatServer
                }})
              </el-button>
            </el-form-item>
            <el-form-item label="未成单加粉：" required style="width: 700px">
              <template slot="label">
                未成单加粉
                <el-tooltip
                  content="坐席标记为非成单并且通话话时长大于n秒后， 自动进行加粉操作"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>

              <el-switch
                v-model="strategy.addWechatIfMarkUnFlag"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="isDisabled"
              ></el-switch>
              <el-button
                type="text"
                v-if="strategy.addWechatIfMarkUnFlag"
                :disabled="isDisabled"
                @click="
                  groupSettings(strategy.unFlagWechatServer, 'unAutomatic')
                "
              >
                分组设置 {{ unFlagWechatClientGroupIdName }} ({{
                  strategy.unFlagWechatServer
                }})
              </el-button>
            </el-form-item>
            <!-- <el-form-item label="二次加粉:" required style="width: 700px">
              <template slot="label">
                二次加粉
                <el-tooltip
                  content="提醒客户通过好友申请， 同时更改好友申请类型为 二次加粉"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <el-switch
                v-model="strategy.notifyAddFans"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="isDisabled"
              ></el-switch>
              <span v-if="strategy.notifyAddFans">
                通话时长：<el-input
                  v-model="strategy.notifyAddFansTimes"
                  placeholder="请输入通话时长"
                  :disabled="isDisabled"
                ></el-input>
              </span>
            </el-form-item> -->
            <el-form-item label="启用短信：" required style="width: 700px">
              <el-switch
                v-model="strategy.showSendSmsButton"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="isDisabled"
              ></el-switch>
            </el-form-item>
            <el-form-item
              label="默认短信长链分组："
              required
              v-show="strategy.showSendSmsButton"
            >
              <el-select
                v-model="strategy.smsLongUrlGroup"
                placeholder="请选择短信长链分组"
                :disabled="isDisabled"
              >
                <el-option
                  v-for="item in smsLongUrlGroupList"
                  :key="item.id"
                  :label="item.groupName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="附加短信长链分组："
              v-show="strategy.showSendSmsButton"
            >
              <el-select
                v-model="strategy.extraLongUrlGroup"
                placeholder="请选择短信长链分组"
                :disabled="isDisabled"
                multiple
              >
                <el-option
                  v-for="item in smsLongUrlGroupList"
                  :key="item.id"
                  :label="item.groupName"
                  :value="item.id"
                  :disabled="item.id === strategy.smsLongUrlGroup"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="人工开场白" name="prologue" :disabled="!isPrologue">
          <el-form>
            <el-form-item style="width: 800px">
              <template slot="label">
                <span
                  >人工坐席内容：
                  <el-radio-group v-model="prologueType">
                    <el-radio label="custom">自定义</el-radio>
                    <el-radio label="template">选择模板</el-radio>
                  </el-radio-group>
                  <el-select
                    v-if="prologueType === 'template'"
                    v-model="smsTemplateId"
                    placeholder="请选择模板名称"
                    @change="smsTemplateIdChange(smsTemplateId, 'change')"
                    style="margin: 0 0 0 10px"
                  >
                    <el-option
                      v-for="item in smsTemplateIdList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-if="prologueType === 'template'"
                    :disabled="smsTemplateIdDisabled"
                    v-model="smsTemplateChildrenId"
                    placeholder="请选择模板子名称"
                    @change="smsTemplateChildrenIdChange"
                  >
                    <el-option
                      v-for="item in smsTemplateChildrenIdList"
                      :key="item.id"
                      :label="item.subName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </span>
              </template>
            </el-form-item>
            <el-form-item label="呼叫超时(秒)：" required class="seats_item">
              <el-select
                v-model="strategy.originateTimeout"
                placeholder="请选择超时时间"
                :disabled="isDisabled"
                @change="originateTimeoutChange"
              >
                <el-option
                  v-for="item in originateTimeoutList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="坐席整理时间(秒)："
              required
              class="seats_item"
            >
              <el-input
                v-model="strategy.answerInterval"
                placeholder="请输入整理时间"
                :disabled="isDisabled"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="坐席全满暂停外呼："
              required
              class="seats_item"
            >
              <el-select
                v-model="strategy.stopCallOnFull"
                placeholder="请选择状态"
                @change="strategyChange"
                :disabled="isDisabled"
              >
                <el-option
                  v-for="item in strategyList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="坐席全满停止外呼(秒)："
              required
              class="seats_item"
            >
              <el-input
                v-model="strategy.pauseSecondsOnFull"
                :disabled="pauseSeconds_disabled || isDisabled"
                placeholder="请输入停止外呼秒数"
              ></el-input>
            </el-form-item>
            <el-form-item label="微信加粉：" required style="width: 700px">
              <template slot="label">
                微信加粉
                <el-tooltip
                  content="开启后，坐席页面将看到加微信按钮"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <el-switch
                v-model="strategy.showAddWechatButton"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="isDisabled"
              ></el-switch>
              <el-button
                type="text"
                v-if="strategy.showAddWechatButton"
                @click="groupSettings(strategy.clientWechatServer, 'wechat')"
                :disabled="isDisabled"
              >
                分组设置 {{ wechatGroupIdName }} ({{
                  strategy.clientWechatServer
                }})
              </el-button>
            </el-form-item>
            <el-form-item label="自动加粉：" required style="width: 700px">
              <template slot="label">
                自动加粉
                <el-tooltip
                  content="坐席标记为成单后， 自动进行加粉操作"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>

              <el-switch
                v-model="strategy.addWechatIfMarkFlag"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="isDisabled"
              ></el-switch>
              <el-button
                type="text"
                v-if="strategy.addWechatIfMarkFlag"
                :disabled="isDisabled"
                @click="groupSettings(strategy.flagWechatServer, 'automatic')"
              >
                分组设置 {{ automaticGroupIdName }} ({{
                  strategy.flagWechatServer
                }})
              </el-button>
            </el-form-item>
            <el-form-item label="未成单加粉：" required style="width: 700px">
              <template slot="label">
                未成单加粉
                <el-tooltip
                  content="坐席标记为非成单并且通话话时长大于n秒后， 自动进行加粉操作"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>

              <el-switch
                v-model="strategy.addWechatIfMarkUnFlag"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="isDisabled"
              ></el-switch>
              <el-button
                type="text"
                v-if="strategy.addWechatIfMarkUnFlag"
                :disabled="isDisabled"
                @click="
                  groupSettings(strategy.unFlagWechatServer, 'unAutomatic')
                "
              >
                分组设置 {{ unFlagWechatClientGroupIdName }} ({{
                  strategy.unFlagWechatServer
                }})
              </el-button>
            </el-form-item>
            <!-- <el-form-item label="二次加粉:" required style="width: 700px">
              <template slot="label">
                二次加粉
                <el-tooltip
                  content="提醒客户通过好友申请， 同时更改好友申请类型为 二次加粉"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <el-switch
                v-model="strategy.notifyAddFans"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="isDisabled"
              ></el-switch>
              <span v-if="strategy.notifyAddFans">
                通话时长：<el-input
                  v-model="strategy.notifyAddFansTimes"
                  placeholder="请输入通话时长"
                  :disabled="isDisabled"
                ></el-input>
              </span>
            </el-form-item> -->
            <el-form-item label="开场白启用：" required style="width: 700px">
              <el-switch
                v-model="strategy.playPrologue"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="isDisabled"
              ></el-switch>
              <el-button
                type="text"
                v-if="strategy.playPrologue"
                :disabled="isDisabled"
                @click="prologueSettings(strategy.prologueId)"
              >
                开场白设置 {{ strategy.prologueTitle }}
              </el-button>
            </el-form-item>
            <el-form-item label="启用短信：" required style="width: 700px">
              <el-switch
                v-model="strategy.showSendSmsButton"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="isDisabled"
              ></el-switch>
            </el-form-item>
            <el-form-item
              label="短信长链分组："
              required
              v-show="strategy.showSendSmsButton"
            >
              <el-select
                v-model="strategy.smsLongUrlGroup"
                placeholder="请选择短信长链分组"
                :disabled="isDisabled"
              >
                <el-option
                  v-for="item in smsLongUrlGroupList"
                  :key="item.id"
                  :label="item.groupName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="附加短信长链分组："
              v-show="strategy.showSendSmsButton"
            >
              <el-select
                v-model="strategy.extraLongUrlGroup"
                placeholder="请选择短信长链分组"
                :disabled="isDisabled"
                multiple
              >
                <el-option
                  v-for="item in smsLongUrlGroupList"
                  :key="item.id"
                  :label="item.groupName"
                  :value="item.id"
                  :disabled="item.id === strategy.smsLongUrlGroup"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="挂机短信" name="shortMessage" :disabled="!isSMS">
          <el-form>
            <el-form-item>
              <template slot="label">
                <span
                  >挂机短信内容：
                  <el-radio-group v-model="smsType">
                    <el-radio label="custom">自定义</el-radio>
                    <el-radio label="template">选择模板</el-radio>
                  </el-radio-group>
                  <el-select
                    v-if="smsType === 'template'"
                    v-model="smsTemplateId"
                    placeholder="请选择模板名称"
                    @change="smsTemplateIdChange(smsTemplateId, 'change')"
                    style="margin: 0 0 0 10px"
                  >
                    <el-option
                      v-for="item in smsTemplateIdList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-if="smsType === 'template'"
                    :disabled="smsTemplateIdDisabled"
                    v-model="smsTemplateChildrenId"
                    placeholder="请选择模板子名称"
                    @change="smsTemplateChildrenIdChange"
                  >
                    <el-option
                      v-for="item in smsTemplateChildrenIdList"
                      :key="item.id"
                      :label="item.subName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </span>
              </template>
            </el-form-item>
            <el-form-item label="触发动作：" required style="width: 700px">
              <el-radio-group
                v-model="strategy.triggerAction"
                @change="radioTriggerChange"
                :disabled="isDisabled"
              >
                <!-- <el-radio label="FLAG_CHANGE">标记成单
                  <el-tooltip content="通话结束后如果通话被标记为成单则执行" placement="top-start" effect="light">
                    <i class="el-icon-question" style="color: #909399"></i>
                  </el-tooltip>
                </el-radio> -->
                <el-radio label="CALL_MILLIS_LIMIT">通话时长达标(秒)</el-radio>
              </el-radio-group>
              <el-input
                v-model="callDuration"
                placeholder="请输入通话达标时长"
                style="width: 200px; margin: 0 0 0 10px"
                clearable
                v-if="callDurationShow"
                :disabled="isDisabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="短信平台路由：" required>
              <div style="width: 900px">
                <el-table
                  :data="platformTableData"
                  stripe
                  style="width: 100%"
                  border=""
                  class="m_table"
                >
                  <el-table-column prop label="禁用区域" align="center">
                    <template slot-scope="scope">
                      <el-cascader
                        v-model="scope.row.forbiddenArea"
                        placeholder="可以试试搜索"
                        :options="options"
                        :props="props"
                        collapse-tags
                        filterable
                        :show-all-levels="false"
                        size="small"
                        clearable
                        :disabled="isAddWechat"
                      ></el-cascader>
                    </template>
                  </el-table-column>
                  <el-table-column prop label="运营商" align="center">
                    <template slot-scope="scope">
                      <el-select
                        v-model="scope.row.customerPhoneOperator"
                        placeholder="请选择运营商"
                        size="small"
                        multiple
                        collapse-tags
                        :disabled="isAddWechat"
                      >
                        <el-option
                          v-for="item in operatorsArr"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column prop label="平台名称" align="center">
                    <template slot-scope="scope">
                      <el-select
                        v-model="scope.row.platformLabel"
                        placeholder="请选择平台名称"
                        size="small"
                        @change="platformChagne($event, scope.$index)"
                        :disabled="isAddWechat"
                      >
                        <el-option
                          v-for="item in platformArr"
                          :key="item.id"
                          :label="item.name"
                          :value="item.code + '_' + item.accessKey"
                        ></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column prop label="顺序" width="100" align="center">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.order"
                        size="small"
                        :disabled="isAddWechat"
                        style="width: 100%"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop label="操作" width="60" align="center">
                    <template slot-scope="scope">
                      <el-button
                        type="text"
                        size="small"
                        @click.native.prevent="
                          platformDelete(
                            scope.$index,
                            platformTableData,
                            scope.row.id
                          )
                        "
                        style="color: #ff0000b0"
                        :disabled="isAddWechat"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <el-button
                  type="primary"
                  size="small"
                  @click="addPlatform()"
                  :disabled="isAddPlatform"
                  v-if="addBtnShow"
                  >新增</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  disabled
                  v-if="!addBtnShow"
                  >新增</el-button
                >
              </div>
            </el-form-item>
            <el-form-item label="短信签名：" required>
              <el-select
                v-model="smsSignValue"
                placeholder="请选择短信签名"
                @change="smsSignChange"
                :disabled="isDisabled"
              >
                <el-option
                  v-for="item in smsSignList"
                  :key="item.signId"
                  :label="item.label"
                  :value="item.sign"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="短信内容：" required>
              <template slot="label">
                短信内容
                <el-tooltip
                  content="短信内容不需要手动输入签名"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <sms-input
                :textareaSMS="SMStextarea"
                @change="textarea_sms"
                @longUrlChange="longUrlChange"
                @smsDataLabelChange="smsDataLabelChange"
                @prefixChange="prefixChange"
                @smsNum="getSmsNum"
                @callValueChange="callValueChange"
                @wechatServerChange="wechatServerChange"
                @wechatGroupIdChange="wechatGroupIdChange"
                style="width: 750px"
                :isDisabled="isDisabled"
                :longUrlValue="longUrl"
                :smsDataLabelValue="smsDataLabel"
                :prefixValue="prefix"
                :callValue="callValue"
                :serverWechat="serverWechat"
                :clientGroupId="clientGroupId"
                @urlFormatChange="urlFormatChange"
                :urlFormat="urlFormat"
                :parseUrlTitle="parseUrlTitle"
                :smsSign="smsSignValue"
              ></sms-input>
            </el-form-item>
            <el-form-item label="开场白启用：" required style="width: 700px">
              <el-switch
                v-model="strategy.playPrologue"
                active-color="#13ce66"
                inactive-color="#ff4949"
                disabled
              ></el-switch>
              <el-button
                type="text"
                v-if="strategy.playPrologue"
                :disabled="isDisabled"
                @click="prologueSettings(strategy.prologueId)"
              >
                开场白设置 {{ strategy.prologueTitle }}
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="挂机微信" name="wechat" :disabled="!isWechat">
          <el-form>
            <el-form-item>
              <template slot="label">
                <span
                  >挂机微信内容：
                  <el-radio-group v-model="smsWechatType">
                    <el-radio label="custom">自定义</el-radio>
                    <el-radio label="template">选择模板</el-radio>
                  </el-radio-group>
                  <el-select
                    v-if="smsWechatType === 'template'"
                    v-model="smsTemplateId"
                    placeholder="请选择模板名称"
                    @change="smsTemplateIdChange(smsTemplateId, 'change')"
                    style="margin: 0 0 0 10px"
                  >
                    <el-option
                      v-for="item in smsTemplateIdList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-if="smsWechatType === 'template'"
                    :disabled="smsTemplateIdDisabled"
                    v-model="smsTemplateChildrenId"
                    placeholder="请选择模板子名称"
                    @change="smsTemplateChildrenIdChange"
                  >
                    <el-option
                      v-for="item in smsTemplateChildrenIdList"
                      :key="item.id"
                      :label="item.subName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </span>
              </template>
            </el-form-item>
            <el-form-item label="触发动作：" required style="width: 700px">
              <el-radio-group
                v-model="strategy.triggerAction"
                @change="radioTriggerChange"
                :disabled="isDisabled"
              >
                <!-- <el-radio label="FLAG_CHANGE">标记成单
                  <el-tooltip content="通话结束后如果通话被标记为成单则执行" placement="top-start" effect="light">
                    <i class="el-icon-question" style="color: #909399"></i>
                  </el-tooltip>
                </el-radio> -->
                <el-radio label="CALL_MILLIS_LIMIT">通话时长达标(秒)</el-radio>
              </el-radio-group>
              <el-input
                v-model="callDuration"
                placeholder="请输入通话达标时长"
                style="width: 200px; margin: 0 0 0 10px"
                clearable
                v-if="callDurationShow"
                :disabled="isDisabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="微信加粉:" required style="width: 700px">
              <el-switch
                :disabled="isDisabled"
                v-model="strategy.addWechatOnHangup"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </el-form-item>
            <el-form-item
              label="服务器："
              required
              style="width: 700px"
              v-if="strategy.addWechatOnHangup"
            >
              <div style="width: 500px; margin-left: 15px">
                <el-select
                  v-model="strategy.triggerWechatServer"
                  placeholder="请选择服务器"
                  @change="
                    wechatClientGroupFocus(strategy.triggerWechatServer, '3')
                  "
                  :disabled="isDisabled"
                >
                  <el-option
                    v-for="item in wechatServerList"
                    :key="item.server"
                    :label="item.server"
                    :value="item.server"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item
              label="机器分组："
              required
              v-if="strategy.addWechatOnHangup"
            >
              <div style="width: 500px">
                <el-select
                  v-model="strategy.triggerWechatClientGroupId"
                  @change="wechatClientGroupIdChange"
                  placeholder="请选择机器分组"
                  :disabled="isDisabled"
                >
                  <el-option
                    v-for="item in wechatClientGroupList"
                    :key="item.id"
                    :label="item.groupName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="二次加粉:" required style="width: 700px">
              <template slot="label">
                二次加粉
                <el-tooltip
                  content="提醒客户通过好友申请， 同时更改好友申请类型为 二次加粉"
                  placement="top-start"
                  effect="light"
                >
                  <i
                    class="el-icon-question"
                    style="color: #909399"
                  ></i> </el-tooltip
                >：
              </template>
              <el-switch
                :disabled="isDisabled"
                v-model="strategy.changeCustomerSourceType"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </el-form-item>
            <el-form-item label="开场白启用：" required style="width: 700px">
              <el-switch
                v-model="strategy.playPrologue"
                active-color="#13ce66"
                inactive-color="#ff4949"
                disabled
              ></el-switch>
              <el-button
                type="text"
                v-if="strategy.playPrologue"
                :disabled="isDisabled"
                @click="prologueSettings(strategy.prologueId)"
              >
                开场白设置 {{ strategy.prologueTitle }}
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="AI机器人" name="robot" :disabled="!isRobot">
          <el-form label-position="left">
            <el-form-item>
              <template slot="label">
                <span
                  >话术内容：
                  <el-radio-group v-model="robotType">
                    <el-radio label="custom">自定义</el-radio>
                    <el-radio label="template">选择模板</el-radio>
                  </el-radio-group>
                  <el-select
                    v-if="robotType === 'template'"
                    v-model="smsTemplateId"
                    placeholder="请选择模板名称"
                    @change="smsTemplateIdChange(smsTemplateId, 'change')"
                    style="margin: 0 0 0 10px"
                  >
                    <el-option
                      v-for="item in smsTemplateIdList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-if="robotType === 'template'"
                    :disabled="smsTemplateIdDisabled"
                    v-model="smsTemplateChildrenId"
                    placeholder="请选择模板子名称"
                    @change="smsTemplateChildrenIdChange"
                  >
                    <el-option
                      v-for="item in smsTemplateChildrenIdList"
                      :key="item.id"
                      :label="item.subName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </span>
              </template>
            </el-form-item>
            <el-form-item label="话术名称：" required>
              <div style="width: 700px">
                <el-select
                  v-model="strategy.dialogueId"
                  placeholder="请选择话术"
                  filterable
                  :disabled="isDisabled"
                >
                  <el-option
                    v-for="item in dialogueList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <div slot="footer" class="dialog-footer" align="center">
      <el-button
        type="primary"
        @click="changStrategy_submit"
        :disabled="isChangStrategy_submit"
        >确定</el-button
      >
      <el-button @click="close">取消</el-button>
    </div>
    <!-- 分组设置 -->
    <el-dialog
      width="450px"
      :title="innerTitle"
      :visible.sync="innerVisible"
      append-to-body
    >
      <el-form>
        <el-form-item
          label="分组类型:"
          v-if="innerTitle === '微信加粉分组设置'"
        >
          <el-radio-group v-model="value2" @change="switchChange">
            <el-radio label="false">固定分组</el-radio>
            <el-radio label="true">动态分组</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="分组设置："
          required
          v-if="innerTitle === '微信加粉分组设置' && value2 === 'false'"
        >
          <div style="width: 500px; margin-bottom: 10px">
            <el-select
              v-model="strategy.clientWechatServer"
              placeholder="请选择服务器"
              @change="wechatClientGroupFocus(strategy.clientWechatServer, '1')"
              :disabled="isDisabled"
            >
              <el-option
                v-for="item in wechatServerList"
                :key="item.server"
                :label="item.server"
                :value="item.server"
              >
              </el-option>
            </el-select>
          </div>
          <div style="width: 500px; padding-left: 92px">
            <el-select
              v-model="strategy.clientWechatClientGroupId"
              placeholder="请选择机器分组"
              @change="clientWechatClientGroupIdChange($event, 'wechat')"
              :disabled="isDisabled"
            >
              <el-option
                v-for="item in wechatClientGroupList"
                :key="item.id"
                :label="item.groupName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item
          label="分组设置："
          required
          v-if="innerTitle === '自动加粉分组设置'"
        >
          <div style="width: 500px; margin-bottom: 10px">
            <el-select
              v-model="strategy.flagWechatServer"
              placeholder="请选择服务器"
              @change="wechatClientGroupFocus(strategy.flagWechatServer, '2')"
              :disabled="isDisabled"
            >
              <el-option
                v-for="item in wechatServerList"
                :key="item.server"
                :label="item.server"
                :value="item.server"
              >
              </el-option>
            </el-select>
          </div>
          <div style="width: 500px; padding-left: 92px">
            <el-select
              v-model="strategy.flagWechatClientGroupId"
              @change="clientWechatClientGroupIdChange($event, 'automatic')"
              placeholder="请选择机器分组"
              :disabled="isDisabled"
            >
              <el-option
                v-for="item in wechatClientGroupList"
                :key="item.id"
                :label="item.groupName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <!-- 未成单加粉 -->
        <el-form-item
          label="分组类型:"
          v-if="innerTitle === '未成单加粉分组设置'"
        >
          <el-radio-group v-model="value3" @change="switchChange3">
            <el-radio label="false">固定分组</el-radio>
            <el-radio label="true">动态分组</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="分组设置："
          required
          v-if="innerTitle === '未成单加粉分组设置' && value3 === 'false'"
        >
          <div style="width: 500px; margin-bottom: 10px">
            <el-select
              v-model="strategy.unFlagWechatServer"
              placeholder="请选择服务器"
              @change="wechatClientGroupFocus(strategy.unFlagWechatServer, '4')"
            >
              <el-option
                v-for="item in wechatServerList"
                :key="item.server"
                :label="item.server"
                :value="item.server"
              >
              </el-option>
            </el-select>
          </div>
          <div style="width: 500px; padding-left: 92px">
            <el-select
              v-model="strategy.unFlagWechatClientGroupId"
              @change="clientWechatClientGroupIdChange($event, 'unAutomatic')"
              placeholder="请选择机器分组"
            >
              <el-option
                v-for="item in wechatClientGroupList"
                :key="item.id"
                :label="item.groupName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item
          label="通话时长(秒):"
          v-if="innerTitle === '未成单加粉分组设置'"
        >
          <div style="width: 500px; padding-left: 92px">
            <el-input
              class="input1"
              v-model="strategy.addWechatIfMarkUnFlagTime"
              placeholder="请输入时长(秒)"
            ></el-input>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="innerVisible = false">确定</el-button>
        <el-button @click="innerVisible = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 开场白模板 -->
    <prologueTemplate
      ref="prologueTemplate"
      :appendToBody="true"
      @select="selectionClick"
    ></prologueTemplate>
  </el-dialog>
</template>
<script>
import smsInput from "../pages/smsInput.vue";
import prologueTemplate from "../pages/prologueTemplate.vue";
export default {
  name: "strategyManage",
  props: {
    loading: {
      // 确定按钮loading
      type: Boolean,
      default: false,
    },
    isAddtemplate: {
      // 判读是否新增模板
      type: Boolean,
      default: false,
    },
    roleMenu: {
      // 管理权限
      type: String,
      default: "",
    },
    isHangupsms: {
      // 模板类型
      type: String,
      default: "NORMAL",
    },
    platformArr: {
      // 平台数据
      type: Array,
      default: function () {
        return [];
      },
    },
    options: {
      // 区域数据
      type: Array,
      default: function () {
        return [];
      },
    },
    strategyForm: {
      // 表单数据
      type: Object,
      default: function () {
        return {};
      },
    },
    textareaSMS: String, // 短信内容
    longUrlValue: String, // 长链
    smsDataLabelValue: String, // 数据标记
    prefixValue: String, // 前缀
    clickCallValue: String, // 前缀
    smsSign: String, // 短信签名
    type: {
      // 组件类型
      type: String,
      default: "",
    },
    // 模板id
    templateId: [String, Number],
    // 项目id
    taskId: [String, Number],
    userLoginName: {
      // 登录名称
      type: String,
      default: "",
    },
    taskServer: {
      // 服务器名称
      type: String,
      default: "",
    },
    title: {
      // 名称
      type: String,
      default: "",
    },
  },
  components: {
    smsInput,
    prologueTemplate,
  },
  data() {
    return {
      serverSelect: false,
      // addWechatIfMarkUnFlagTime: "",
      // addWechatIfMarkUnFlag:false,
      // autoAddFansByTimeFlag: "false",
      value2: "false",
      switchStatus: "NORMAL",
      value3: "false",
      switchStatus3: "NORMAL",
      // -------------------------
      dialogueList: [],
      smsLongUrlGroupList: [],
      wechatGroupIdName: "",
      automaticGroupIdName: "",
      unFlagWechatClientGroupIdName: "",
      innerVisible: false,
      innerTitle: "",
      callDurationShow: false,
      callDuration: "",
      addBtnShow: true,
      smsTemplateId: "",
      smsTemplateChildrenId: "",
      smsTemplateIdList: [],
      smsTemplateChildrenIdList: [],
      smsType: "custom",
      smsWechatType: "custom",
      normalType: "custom",
      prologueType: "custom",
      robotType: "custom",
      urlFormat: false,
      longUrl: "",
      smsSignValue: "",
      smsSignLabel: "",
      smsDataLabel: "",
      smsSignList: [],
      parseUrlTitle: "",
      prefix: "https://",
      callValue: "NONE",
      serverWechat: "t9.innerchic.cn",
      clientGroupId: "",
      wechatServerList: [
        {
          server: "t1.innerchic.cn",
        },
        {
          server: "t9.innerchic.cn",
        },
        {
          server: "gateway.bilinl.com",
        },
      ],
      isChangStrategy_submit: false,
      wechatClientGroupList: [],
      activeNameTemplate: "normal",
      visible: false,
      isAddWechat: false,
      strategy: {},
      platformTableData: [],
      SMStextarea: "",
      isAddPlatform: false,
      isDisabled: false,
      operatorsArr: [
        {
          label: "中国移动",
          value: "中国移动",
        },
        {
          label: "中国联通",
          value: "中国联通",
        },
        {
          label: "中国电信",
          value: "中国电信",
        },
        {
          label: "其它",
          value: "其它",
        },
      ],
      strategyList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      originateTimeoutList: [
        {
          value: "30",
          label: "30",
        },
        {
          value: "40",
          label: "40",
        },
      ],
      pauseSeconds_disabled: false,
      props: {
        multiple: true,
        emitPath: false,
        value: "code",
        label: "name",
        checkStrictly: true,
      },
      isEndNumDis: false,
      isCityDis: false,
      smsNum: 1,
    };
  },
  watch: {
    strategyForm(val) {
      // 表单数据
      this.strategy = this.$common.deepClone(val);
      // console.log(this.strategy)
      if (this.strategy.stopCallOnFull == true) {
        this.pauseSeconds_disabled = false;
      } else {
        this.pauseSeconds_disabled = true;
      }
      if (this.strategy.hangupSmsRouteInfoList) {
        this.platformTableData = this.strategy.hangupSmsRouteInfoList;
        if (this.platformTableData.length > 0) {
          let signData = {
            pageNo: 1,
            pageSize: 100,
            accessKey: this.platformTableData[0].accessKey,
          };
          this.getSignList(signData);
        }
      }
      if (this.strategy.wechatClientGroupId === 0) {
        this.strategy.wechatClientGroupId = "";
      }
      if (this.strategy.triggerAction === "CALL_MILLIS_LIMIT") {
        this.callDuration = this.strategy.triggerValue;
        this.callDurationShow = true;
      }
      // if (this.strategy.triggerAction === "FLAG_CHANGE") {
      //   this.callDuration = "";
      //   this.callDurationShow = false;
      // }
      this.clientGroupId = val.wechatClientGroupId;
      this.serverWechat = val.wechatServer;
      this.isDisabled = true;
      // 人工坐席
      if (this.strategy.templateType === "NORMAL") {
        this.getGroupItemList();
        this.strategy.strategyTemplateClientTalkingId =
          val.strategyTemplateClientTalking.id;
        this.strategy.showSendSmsButton =
          val.strategyTemplateClientTalking.showSendSmsButton;
        this.strategy.smsLongUrlGroup = "";
        if (val.strategyTemplateClientTalking.longUrlGroupId) {
          this.strategy.smsLongUrlGroup =
            val.strategyTemplateClientTalking.longUrlGroupId;
        }
        this.strategy.extraLongUrlGroup = [];
        if (val.strategyTemplateClientTalking.extraLongUrlGroupId) {
          let extraLongUrlGroup =
            val.strategyTemplateClientTalking.extraLongUrlGroupId.split(",");
          this.strategy.extraLongUrlGroup = extraLongUrlGroup.map(Number);
        }
        if (this.strategy.msgTemplateId > 0) {
          this.normalType = "template";
          this.smsTemplateChildrenId = this.strategy.msgTemplateId;
        } else {
          this.normalType = "custom";
          this.smsTemplateChildrenId = "";
          this.isDisabled = false;
        }
        this.activeNameTemplate = "normal";
        this.isAddWechat = true;
        this.isAddPlatform = true;
        this.wechatClientGroupFocus(undefined);
        // // yc新增
        // if (val.strategyTemplateClientTalking.addFansGroupType === "CLIENT") {
        //   this.switchStatus =
        //     val.strategyTemplateClientTalking.addFansGroupType;
        //   this.value2 = true;
        //   this.strategy.clientWechatClientGroupId = "";
        // } else if (
        //   val.strategyTemplateClientTalking.addFansGroupType === "NORMAL"
        // ) {
        //   this.switchStatus =
        //     val.strategyTemplateClientTalking.addFansGroupType;
        //   this.value2 = false;
        // }
        // // yc新增end
      }
      // 人工开场白
      if (this.strategy.templateType === "PROLOGUE") {
        this.getGroupItemList();
        this.strategy.strategyTemplateClientTalkingId =
          val.strategyTemplateClientTalking.id;
        this.strategy.showSendSmsButton =
          val.strategyTemplateClientTalking.showSendSmsButton;
        this.strategy.smsLongUrlGroup = "";
        if (val.strategyTemplateClientTalking.longUrlGroupId) {
          this.strategy.smsLongUrlGroup =
            val.strategyTemplateClientTalking.longUrlGroupId;
        }
        this.strategy.extraLongUrlGroup = [];
        if (val.strategyTemplateClientTalking.extraLongUrlGroupId) {
          let extraLongUrlGroup =
            val.strategyTemplateClientTalking.extraLongUrlGroupId.split(",");
          this.strategy.extraLongUrlGroup = extraLongUrlGroup.map(Number);
        }
        if (this.strategy.msgTemplateId > 0) {
          this.prologueType = "template";
          this.smsTemplateChildrenId = this.strategy.msgTemplateId;
        } else {
          this.prologueType = "custom";
          this.smsTemplateChildrenId = "";
          this.isDisabled = false;
        }
        this.activeNameTemplate = "prologue";
        this.isAddWechat = true;
        this.isAddPlatform = true;
        this.wechatClientGroupFocus(undefined);
        // // yc新增
        // if (val.strategyTemplateClientTalking.addFansGroupType === "CLIENT") {
        //   this.switchStatus =
        //     val.strategyTemplateClientTalking.addFansGroupType;
        //   this.value2 = true;
        //   this.strategy.clientWechatClientGroupId = "";
        // } else if (
        //   val.strategyTemplateClientTalking.addFansGroupType === "NORMAL"
        // ) {
        //   this.switchStatus =
        //     val.strategyTemplateClientTalking.addFansGroupType;
        //   this.value2 = false;
        // }
        // // yc新增end
      }
      // 挂机短信
      if (this.strategy.templateType === "PROLOGUE_SMS") {
        this.$nextTick(() => {
          if (this.strategy.msgTemplateId > 0) {
            this.smsType = "template";
            this.smsTemplateChildrenId = this.strategy.msgTemplateId;
          } else {
            this.smsType = "custom";
            this.isDisabled = false;
            this.smsTemplateChildrenId = "";
            if (this.strategy.longUrl) {
              let httpIndex = this.strategy.longUrl.indexOf("http://");
              let httpsIndex = this.strategy.longUrl.indexOf("https://");
              if (httpIndex === 0 || httpsIndex === 0) {
                this.getParseUrlTitle(this.strategy.longUrl);
              }
            }
          }
          this.isAddPlatform = false;
          this.activeNameTemplate = "shortMessage";
        });
      }
      // 挂机微信
      if (this.strategy.templateType === "PROLOGUE_WECHAT") {
        if (this.strategy.msgTemplateId > 0) {
          this.smsWechatType = "template";
          this.smsTemplateChildrenId = this.strategy.msgTemplateId;
        } else {
          this.smsWechatType = "custom";
          this.smsTemplateChildrenId = "";
          this.isDisabled = false;
        }
        this.isAddWechat = true;
        this.isAddPlatform = true;
        this.activeNameTemplate = "wechat";
        this.wechatClientGroupFocus(this.strategy.triggerWechatServer);
      }
      if (this.strategy.templateType === "AI_ROBOT") {
        this.activeNameTemplate = "robot";
        if (this.strategy.msgTemplateId > 0) {
          this.robotType = "template";
          this.smsTemplateChildrenId = this.strategy.msgTemplateId;
        } else {
          this.robotType = "custom";
          this.smsTemplateChildrenId = "";
          this.isDisabled = false;
        }
        this.getDialogueList();
      }
    },
    smsType(val) {
      if (val === "custom") {
        this.isDisabled = false;
        this.isAddWechat = false;
        if (this.platformTableData.length >= 1) {
          this.addBtnShow = false;
        } else {
          this.addBtnShow = true;
        }
      }
      if (val === "template") {
        this.isDisabled = true;
        this.isAddWechat = true;
        this.addBtnShow = false;
        this.smsTemplateList();
      }
    },
    smsWechatType(val) {
      if (val === "custom") {
        this.isDisabled = false;
      }
      if (val === "template") {
        this.isDisabled = true;
        this.smsTemplateList();
      }
    },
    prologueType(val) {
      if (val === "custom") {
        this.isDisabled = false;
      }
      if (val === "template") {
        this.isDisabled = true;
        this.smsTemplateList();
      }
    },
    robotType(val) {
      if (val === "custom") {
        this.isDisabled = false;
      }
      if (val === "template") {
        this.isDisabled = true;
        this.smsTemplateList();
      }
    },
    normalType(val) {
      if (val === "custom") {
        this.isDisabled = false;
      }
      if (val === "template") {
        this.isDisabled = true;
        this.smsTemplateList();
      }
    },
    textareaSMS(val) {
      this.SMStextarea = this.textareaSMS;
      if (val) {
        let signFirstIndex = this.textareaSMS.indexOf("【");
        let signLastIndex = this.textareaSMS.indexOf("】");
        if (signFirstIndex !== -1 && signLastIndex !== -1) {
          let startText = this.textareaSMS.substring(0, signFirstIndex);
          let endText = this.textareaSMS.substring(signLastIndex + 1);
          this.SMStextarea = startText + endText;
        }
      }
    },
    longUrlValue(val) {
      this.longUrl = this.longUrlValue;
    },
    smsDataLabelValue(val) {
      this.smsDataLabel = this.smsDataLabelValue;
    },
    smsSign(val) {
      if (val) {
        this.smsSignValue = "【" + this.smsSign + "】";
        this.smsSignLabel = this.smsSign;
      } else {
        this.smsSignValue = "";
        this.smsSignLabel = "";
      }
    },
    prefixValue(val) {
      this.prefix = this.prefixValue;
    },
    clickCallValue(val) {
      this.callValue = this.clickCallValue;
    },
    platformTableData(val) {
      if (this.smsType === "custom") {
        if (this.platformTableData.length >= 1) {
          this.addBtnShow = false;
        } else {
          this.addBtnShow = true;
        }
      }
      if (this.smsType === "template") {
        this.addBtnShow = false;
      }
    },
  },
  computed: {
    smsTemplateIdDisabled() {
      if (this.smsTemplateId) {
        return false;
      }
      return true;
    },
    isNormal() {
      if (this.isHangupsms === "NORMAL") {
        return true;
      }
      return false;
    },
    isPrologue() {
      if (this.isHangupsms === "PROLOGUE") {
        return true;
      }
      return false;
    },
    isSMS() {
      if (this.isHangupsms === "PROLOGUE_SMS") {
        return true;
      }
      return false;
    },
    isWechat() {
      if (this.isHangupsms === "PROLOGUE_WECHAT") {
        return true;
      }
      return false;
    },
    isRobot() {
      if (this.isHangupsms === "AI_ROBOT") {
        return true;
      }
      return false;
    },
    isMultipleShow() {
      if (
        this.activeNameTemplate === "normal" ||
        this.activeNameTemplate === "prologue"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    switchChange() {
      console.log(this.value2);
      if (this.value2 == "true") {
        this.switchStatus = "CLIENT";
        this.strategy.clientWechatServer = "";
        this.strategy.clientWechatClientGroupId = 0;
        this.wechatGroupIdName = "";
      } else {
        this.switchStatus = "NORMAL";
      }
    },
    switchChange3() {
      if (this.value3 == "true") {
        this.switchStatus3 = "CLIENT";
        this.strategy.unFlagWechatServer = "";
        this.strategy.unFlagWechatClientGroupId = 0;
        this.unFlagWechatClientGroupIdName = "";
      } else {
        this.switchStatus3 = "NORMAL";
      }
    },
    // ycEnd----------------------------------------------------------------
    // 查询话术列表
    getDialogueList() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      this.dialogueList = [];
      this.$http("/dialogue/list", null, "get", null, data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.dialogueList = list;
        }
      });
    },
    // 查询坐席长链
    getGroupItemList() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      this.$http("/longUrlGroup/groupList", null, "get", null, data)
        .then((res) => {
          if (res.result === 200) {
            let list = res.data.records;
            this.smsLongUrlGroupList = list;
          }
        })
        .catch((err) => {});
    },
    // 开场白设置
    prologueSettings(val) {
      this.$refs.prologueTemplate.init();
    },
    // 选中开场白
    selectionClick(val) {
      this.strategy.prologueId = val.id;
      this.strategy.prologueTitle = val.title;
      this.$forceUpdate();
    },
    // 点击分组设置
    groupSettings(val, key) {
      // console.log(val);
      this.innerVisible = true;
      if (key === "wechat") {
        this.innerTitle = "微信加粉分组设置";
      }
      if (key === "automatic") {
        this.innerTitle = "自动加粉分组设置";
      }
      if (key === "unAutomatic") {
        this.innerTitle = "未成单加粉分组设置";
      }
      this.wechatClientGroupFocus(val);
    },
    // 请求短信编辑详情
    getMsgTemplateDetail(id) {
      this.$http("/msgTemplate/detail", null, "get", null, { id: id }).then(
        (res) => {
          if (res.result === 200) {
            let val = res.data;
            if (val) {
              this.strategy.triggerAction = val.triggerAction;
              this.strategy.triggerValue = val.triggerValue;
              this.strategy.playPrologue = val.playPrologue;
              this.strategy.prologueTitle = val.prologueTitle;
              this.strategy.prologueId = val.prologueId;
              if (this.strategy.triggerAction === "CALL_MILLIS_LIMIT") {
                this.callDuration = val.triggerValue;
                this.callDurationShow = true;
              } else {
                this.callDuration = "";
                this.callDurationShow = false;
              }
              // 人工坐席
              if (val.templateType === "NORMAL") {
                this.strategy.strategyTemplateClientTalkingId =
                  val.strategyTemplateClientTalking.id;
                this.strategy.showSendSmsButton =
                  val.strategyTemplateClientTalking.showSendSmsButton;
                this.strategy.smsLongUrlGroup =
                  val.strategyTemplateClientTalking.longUrlGroupId;
                this.strategy.extraLongUrlGroup = [];
                if (val.strategyTemplateClientTalking.extraLongUrlGroupId) {
                  let extraLongUrlGroup =
                    val.strategyTemplateClientTalking.extraLongUrlGroupId.split(
                      ","
                    );
                  this.strategy.extraLongUrlGroup =
                    extraLongUrlGroup.map(Number);
                }
                this.strategy.originateTimeout =
                  val.strategyTemplateClientTalking.originateTimeout;
                this.strategy.stopCallOnFull =
                  val.strategyTemplateClientTalking.stopCallOnFull;
                this.strategy.pauseSecondsOnFull =
                  val.strategyTemplateClientTalking.pauseSecondsOnFull;
                this.strategy.answerInterval =
                  val.strategyTemplateClientTalking.answerInterval;
                this.strategy.addWechatIfMarkFlag =
                  val.strategyTemplateClientTalking.addWechatIfMarkFlag;
                this.strategy.addWechatIfMarkUnFlag =
                  val.strategyTemplateClientTalking.addWechatIfMarkUnFlag;
                // this.strategy.notifyAddFans =
                //   val.strategyTemplateClientTalking.notifyAddFans;
                this.strategy.flagWechatServer =
                  val.strategyTemplateClientTalking.flagWechatServer;
                this.strategy.unFlagWechatServer =
                  val.strategyTemplateClientTalking.unFlagWechatServer;
                this.strategy.flagWechatClientGroupId =
                  val.strategyTemplateClientTalking.flagWechatClientGroupId;
                this.strategy.unFlagWechatClientGroupId =
                  val.strategyTemplateClientTalking.unFlagWechatClientGroupId;
                this.strategy.showAddWechatButton =
                  val.strategyTemplateClientTalking.showAddWechatButton;
                this.strategy.clientWechatServer =
                  val.strategyTemplateClientTalking.clientWechatServer;
                this.strategy.clientWechatClientGroupId =
                  val.strategyTemplateClientTalking.clientWechatClientGroupId;
                this.$nextTick(() => {
                  this.callValue = val.smsClickCallbackType;
                  this.serverWechat = val.wechatServer;
                  this.clientGroupId = val.wechatClientGroupId;
                  this.smsSignLabel = val.strategyTemplateClientTalking.smsSign;
                });
                // yc新增
                this.autoAddFansByTimeFlag =
                  val.strategyTemplateClientTalking.autoAddFansByTimeFlag;
                this.strategy.addWechatIfMarkUnFlagTime =
                  val.strategyTemplateClientTalking.addWechatIfMarkUnFlagTime /
                  1000;
                // this.strategy.notifyAddFansTimes =
                //   val.strategyTemplateClientTalking.notifyAddFansTimes / 1000;
                if (
                  val.strategyTemplateClientTalking.addFansGroupType ===
                  "CLIENT"
                ) {
                  this.switchStatus =
                    val.strategyTemplateClientTalking.addFansGroupType;
                  this.value2 = "true";
                  this.strategy.clientWechatClientGroupId = 0;
                } else if (
                  val.strategyTemplateClientTalking.addFansGroupType ===
                  "NORMAL"
                ) {
                  this.switchStatus =
                    val.strategyTemplateClientTalking.addFansGroupType;
                  this.value2 = "false";
                }
                //
                if (
                  val.strategyTemplateClientTalking.unFlagAddFansGroupType ===
                  "CLIENT"
                ) {
                  this.switchStatus3 =
                    val.strategyTemplateClientTalking.unFlagAddFansGroupType;
                  this.value3 = "true";
                  this.strategy.unFlagWechatClientGroupId = 0;
                } else if (
                  val.strategyTemplateClientTalking.unFlagAddFansGroupType ===
                  "NORMAL"
                ) {
                  this.switchStatus3 =
                    val.strategyTemplateClientTalking.unFlagAddFansGroupType;
                  this.value3 = "false";
                }
                // yc新增end
              }
              // 人工开场白
              if (val.templateType === "PROLOGUE") {
                this.strategy.strategyTemplateClientTalkingId =
                  val.strategyTemplateClientTalking.id;
                this.strategy.showSendSmsButton =
                  val.strategyTemplateClientTalking.showSendSmsButton;
                this.strategy.smsLongUrlGroup =
                  val.strategyTemplateClientTalking.longUrlGroupId;
                this.strategy.extraLongUrlGroup = [];
                if (val.strategyTemplateClientTalking.extraLongUrlGroupId) {
                  let extraLongUrlGroup =
                    val.strategyTemplateClientTalking.extraLongUrlGroupId.split(
                      ","
                    );
                  this.strategy.extraLongUrlGroup =
                    extraLongUrlGroup.map(Number);
                }
                this.strategy.originateTimeout =
                  val.strategyTemplateClientTalking.originateTimeout;
                this.strategy.stopCallOnFull =
                  val.strategyTemplateClientTalking.stopCallOnFull;
                this.strategy.pauseSecondsOnFull =
                  val.strategyTemplateClientTalking.pauseSecondsOnFull;
                this.strategy.answerInterval =
                  val.strategyTemplateClientTalking.answerInterval;
                this.strategy.addWechatIfMarkFlag =
                  val.strategyTemplateClientTalking.addWechatIfMarkFlag;
                this.strategy.addWechatIfMarkUnFlag =
                  val.strategyTemplateClientTalking.addWechatIfMarkUnFlag;
                // this.strategy.notifyAddFans =
                //   val.strategyTemplateClientTalking.notifyAddFans;
                this.strategy.flagWechatServer =
                  val.strategyTemplateClientTalking.flagWechatServer;
                this.strategy.unFlagWechatServer =
                  val.strategyTemplateClientTalking.unFlagWechatServer;
                this.strategy.flagWechatClientGroupId =
                  val.strategyTemplateClientTalking.flagWechatClientGroupId;
                this.strategy.unFlagWechatClientGroupId =
                  val.strategyTemplateClientTalking.unFlagWechatClientGroupId;
                this.strategy.showAddWechatButton =
                  val.strategyTemplateClientTalking.showAddWechatButton;
                this.strategy.clientWechatServer =
                  val.strategyTemplateClientTalking.clientWechatServer;
                this.strategy.clientWechatClientGroupId =
                  val.strategyTemplateClientTalking.clientWechatClientGroupId;
                this.wechatClientGroupFocus(undefined);
                this.$nextTick(() => {
                  this.callValue = val.smsClickCallbackType;
                  this.serverWechat = val.wechatServer;
                  this.clientGroupId = val.wechatClientGroupId;
                  this.smsSignLabel = val.strategyTemplateClientTalking.smsSign;
                });
                // yc新增
                this.autoAddFansByTimeFlag =
                  val.strategyTemplateClientTalking.autoAddFansByTimeFlag;
                this.strategy.addWechatIfMarkUnFlagTime =
                  val.strategyTemplateClientTalking.addWechatIfMarkUnFlagTime /
                  1000;
                // this.strategy.notifyAddFansTimes =
                //   val.strategyTemplateClientTalking.notifyAddFansTimes / 1000;
                if (
                  val.strategyTemplateClientTalking.addFansGroupType ===
                  "CLIENT"
                ) {
                  this.switchStatus =
                    val.strategyTemplateClientTalking.addFansGroupType;
                  this.value2 = "true";
                  this.strategy.clientWechatClientGroupId = 0;
                } else if (
                  val.strategyTemplateClientTalking.addFansGroupType ===
                  "NORMAL"
                ) {
                  this.switchStatus =
                    val.strategyTemplateClientTalking.addFansGroupType;
                  this.value2 = "false";
                }
                // ---
                if (
                  val.strategyTemplateClientTalking.unFlagAddFansGroupType ===
                  "CLIENT"
                ) {
                  this.switchStatus3 =
                    val.strategyTemplateClientTalking.unFlagAddFansGroupType;
                  this.value3 = "true";
                  this.strategy.unFlagWechatClientGroupId = 0;
                } else if (
                  val.strategyTemplateClientTalking.unFlagAddFansGroupType ===
                  "NORMAL"
                ) {
                  this.switchStatus3 =
                    val.strategyTemplateClientTalking.unFlagAddFansGroupType;
                  this.value3 = "false";
                }
                // yc新增end
              }
              // 挂机短信
              if (val.templateType === "PROLOGUE_SMS") {
                this.strategy.playPrologue = true;
                this.$nextTick(() => {
                  this.longUrl = val.longUrl;
                  let SMStextarea = val.content;
                  if (!SMStextarea) {
                    SMStextarea = "";
                  }
                  let signFirstIndex = SMStextarea.indexOf("【");
                  let signLastIndex = SMStextarea.indexOf("】");
                  if (signFirstIndex !== -1 && signLastIndex !== -1) {
                    let startText = SMStextarea.substring(0, signFirstIndex);
                    let endText = SMStextarea.substring(signLastIndex + 1);
                    SMStextarea = startText + endText;
                  }
                  this.SMStextarea = SMStextarea;
                  this.prefix = val.prefix;
                  this.callValue = val.smsClickCallbackType;
                  this.serverWechat = val.wechatServer;
                  this.clientGroupId = val.wechatClientGroupId;
                  if (val.smsSign) {
                    this.smsSignValue = "【" + val.smsSign + "】";
                  } else {
                    this.smsSignValue = "";
                  }
                  this.smsSignLabel = val.smsSign;
                  this.smsDataLabel = val.sign;
                  let hangupSmsRouteInfoList = val.hangupSmsRouteInfoList;
                  if (hangupSmsRouteInfoList) {
                    hangupSmsRouteInfoList.forEach((item) => {
                      if (item.customerPhoneOperator) {
                        item.customerPhoneOperator =
                          item.customerPhoneOperator.split(",");
                      } else {
                        item.customerPhoneOperator = [];
                      }
                      if (item.forbiddenArea) {
                        item.forbiddenArea = item.forbiddenArea.split(",");
                      } else {
                        item.forbiddenArea = [];
                      }
                      item.platformLabel = item.platform + "_" + item.accessKey;
                    });
                    if (hangupSmsRouteInfoList.length > 0) {
                      let signData = {
                        pageNo: 1,
                        pageSize: 100,
                        accessKey: hangupSmsRouteInfoList[0].accessKey,
                      };
                      this.getSignList(signData);
                    }
                  }
                  this.platformTableData = hangupSmsRouteInfoList;
                });
                if (val.longUrl) {
                  let httpIndex = val.longUrl.indexOf("http://");
                  let httpsIndex = val.longUrl.indexOf("https://");
                  if (httpIndex === 0 || httpsIndex === 0) {
                    this.getParseUrlTitle(val.longUrl);
                  }
                }
              }
              // 挂机微信
              if (val.templateType === "PROLOGUE_WECHAT") {
                this.strategy.playPrologue = true;
                this.strategy.changeCustomerSourceType =
                  val.changeCustomerSourceType;
                this.strategy.addWechatOnHangup = val.addWechatOnHangup;
                this.strategy.triggerWechatServer = val.triggerWechatServer;
                this.wechatClientGroupFocus(
                  this.strategy.triggerWechatServer,
                  "3"
                );
                this.strategy.triggerWechatClientGroupId =
                  val.triggerWechatClientGroupId;
              }
              // ai机器人
              if (val.templateType === "AI_ROBOT") {
                this.strategy.dialogueId = val.dialogueId;
              }
              this.$forceUpdate();
            }
          }
        }
      );
    },
    // 解析url标题
    getParseUrlTitle(url) {
      this.$http("/parseUrlTitle", null, "get", null, { url: url }).then(
        (res) => {
          if (res.result === 200) {
            let title = res.data.urlTitle;
            this.parseUrlTitle = title;
          }
        }
      );
    },
    // 选择短信签名
    smsSignChange(val) {
      this.smsSignList.forEach((item) => {
        if (val === item.sign) {
          this.smsSignLabel = item.label;
          this.smsDataLabel = item.label;
        }
      });
    },
    // 查询短信模板
    smsTemplateList() {
      let data = {};
      if (this.activeNameTemplate === "wechat") {
        data.templateType = "PROLOGUE_WECHAT";
      }
      if (this.activeNameTemplate === "shortMessage") {
        data.templateType = "PROLOGUE_SMS";
      }
      if (this.activeNameTemplate === "normal") {
        data.templateType = "NORMAL";
      }
      if (this.activeNameTemplate === "prologue") {
        data.templateType = "PROLOGUE";
      }
      if (this.activeNameTemplate === "robot") {
        data.templateType = "AI_ROBOT";
      }
      this.$http("/msgTemplate/groupList", null, "get", null, data).then(
        (res) => {
          this.smsTemplateIdList = [];
          let index = 0;
          for (let i in res) {
            index++;
            let obj = {
              id: index,
              name: i,
              children: res[i],
            };
            this.smsTemplateIdList.push(obj);
            this.smsTemplateIdList.forEach((item) => {
              item.children.forEach((menu) => {
                if (menu.id === this.smsTemplateChildrenId) {
                  this.smsTemplateId = menu.name;
                }
              });
            });
          }
          this.smsTemplateIdChange(this.smsTemplateId);
        }
      );
    },
    // 选择短信模板
    smsTemplateIdChange(name, key) {
      this.smsTemplateChildrenIdList = [];
      this.smsTemplateIdList.forEach((item) => {
        if (name === item.name) {
          this.smsTemplateChildrenIdList = item.children;
        }
      });
      if (key === "change") {
        this.smsTemplateChildrenId = "";
      }
      if (this.smsTemplateChildrenId > 0) {
        this.smsTemplateChildrenIdChange(this.smsTemplateChildrenId);
      }
    },
    // 选择短信子模板
    smsTemplateChildrenIdChange(id) {
      this.getMsgTemplateDetail(id);
    },
    // 服务器获取
    wechatClientGroupFocus(val, key) {
      console.log(val, this.value2);
      if (val === "gateway.bilinl.com") {
        this.serverSelect = true;
      } else {
        this.serverSelect = false;
      }
      let data = {
        pageNo: 1,
        pageSize: 100,
        server: val,
      };
      if (key === "1") {
        this.strategy.clientWechatClientGroupId = 0;
        this.wechatGroupIdName = "";
      }
      if (key === "2") {
        this.strategy.flagWechatClientGroupId = "";
        this.automaticGroupIdName = "";
      }
      if (key === "3") {
        this.strategy.triggerWechatClientGroupId = "";
      }
      if (key === "4") {
        this.strategy.unFlagWechatClientGroupId = 0;
        this.unFlagWechatClientGroupIdName = "";
      }
      this.getWechatClientGroupList(data);
    },
    // 机器分组获取--挂机微信
    wechatClientGroupIdChange(val) {
      this.$forceUpdate();
    },
    // 呼叫超时
    originateTimeoutChange(val) {
      this.$forceUpdate();
    },
    // 机器分组获取--人工坐席
    clientWechatClientGroupIdChange(val, key) {
      this.wechatClientGroupList.forEach((item) => {
        if (val === item.id) {
          if (key === "wechat") {
            this.wechatGroupIdName = item.groupName;
          }
          if (key === "automatic") {
            this.automaticGroupIdName = item.groupName;
          }
          if (key === "unAutomatic") {
            this.unFlagWechatClientGroupIdName = item.groupName;
          }
        }
      });
      this.$forceUpdate();
    },
    // 查询机器分组
    getWechatClientGroupList(data) {
      this.$service(
        "/wechat/wechatClientGroupList",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          // if (res.data.records.length == 0) {
          //   this.strategy.clientWechatClientGroupId = 0;
          //   // this.strategy.clientWechatServer = "";
          //   this.wechatGroupIdName = "";
          //   // this.value2 = "true";
          // }
          this.wechatClientGroupList = list;
          this.wechatClientGroupList.forEach((item) => {
            if (this.strategy.clientWechatClientGroupId === item.id) {
              this.wechatGroupIdName = item.groupName;
            }
            if (this.strategy.flagWechatClientGroupId === item.id) {
              this.automaticGroupIdName = item.groupName;
            }
            if (this.strategy.unFlagWechatClientGroupId === item.id) {
              this.unFlagWechatClientGroupIdName = item.groupName;
            }
          });
        }
      });
    },
    // 触发动作选择
    radioTriggerChange(val) {
      // if (val === "FLAG_CHANGE") {
      //   this.callDurationShow = false;
      // }
      if (val === "CALL_MILLIS_LIMIT") {
        this.callDurationShow = true;
      }
      this.$forceUpdate();
    },
    // 标签tab选择
    handleClick(tab, event) {
      if (tab.name === "shortMessage") {
        this.isAddWechat = false;
        this.isAddPlatform = false;
        if (this.smsType === "custom") {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      }
      if (tab.name === "wechat") {
        this.isAddWechat = true;
        this.isAddPlatform = true;
        if (this.smsWechatType === "custom") {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      }
      if (tab.name === "normal") {
        this.isAddWechat = true;
        this.isAddPlatform = true;
        if (this.normalType === "custom") {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      }
      if (tab.name === "prologue") {
        this.isAddWechat = true;
        this.isAddPlatform = true;
        if (this.prologueType === "custom") {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      }
      if (tab.name === "robot") {
        this.isAddWechat = true;
        this.isAddPlatform = true;
        if (this.robotType === "custom") {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      }
    },
    // 平台选择
    platformChagne(e, index) {
      let platforms = e.split("_");
      this.$set(this.platformTableData[index], "platformLabel", e);
      this.$set(this.platformTableData[index], "platform", platforms[0]);
      this.$set(this.platformTableData[index], "accessKey", platforms[1]);
      this.smsSignLabel = "";
      this.smsSignValue = "";
      let signData = {
        pageNo: 1,
        pageSize: 100,
        accessKey: platforms[1],
      };
      this.getSignList(signData);
      this.$forceUpdate();
    },
    // 获取短信签名
    getSignList(data) {
      this.$http("/sms/signList", null, "get", null, data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          let signList = [];
          list.forEach((item) => {
            signList.push({
              sign: "【" + item.sign.trim() + "】",
              label: item.sign.trim(),
              signId: item.id,
            });
          });
          this.smsSignList = signList;
        }
      });
    },
    // 打开弹框
    init() {
      this.visible = true;
      this.isAddPlatform = true;
      this.isDisabled = true;
      this.isAddWechat = false;
    },
    // 点击新增
    addPlatform() {
      let index = this.platformTableData.length + 1;
      let obj = {
        forbiddenArea: [],
        customerPhoneOperator: [],
        platform: "",
        order: index,
        accessKey: "",
        platformLabel: "",
        id: null,
      };
      this.platformTableData.push(obj);
    },
    // 点击删除
    platformDelete(index, rows, id) {
      rows.splice(index, 1);
    },
    //坐席全满暂停外呼改变
    strategyChange(val) {
      if (val == true) {
        this.pauseSeconds_disabled = false;
      } else {
        this.pauseSeconds_disabled = true;
      }
      this.$forceUpdate();
    },
    // 获取短信内容--挂机
    textarea_sms(val) {
      this.SMStextarea = val;
    },
    // 获取长链内容
    longUrlChange(val) {
      this.longUrl = val;
    },
    // 获取数据标记内容
    smsDataLabelChange(val) {
      this.smsDataLabel = val;
    },
    // 获取前缀
    prefixChange(val) {
      this.prefix = val;
    },
    // 获取点击调用
    callValueChange(val) {
      this.callValue = val;
    },
    // 获取微信服务器
    wechatServerChange(val) {
      this.serverWechat = val;
    },
    // 获取微信机器
    wechatGroupIdChange(val) {
      this.clientGroupId = val;
    },
    // 获取短信条数
    getSmsNum(num) {
      this.smsNum = num;
    },
    // 判断长链格式
    urlFormatChange(val) {
      this.urlFormat = val;
    },
    // 点击确定
    changStrategy_submit() {
      let isOrder = false; // 是否填写顺序
      let isPlatform = false; // 是否填写平台
      let data = {
        triggerAction: this.strategy.triggerAction,
        smsContent: this.smsSignValue + this.SMStextarea,
        maxRouteMultiple: this.strategy.maxRouteMultiple,
        routeMultiple: this.strategy.routeMultiple,
        originateTimeout: this.strategy.originateTimeout,
        stopCallOnFull: this.strategy.stopCallOnFull,
        pauseSecondsOnFull: this.strategy.pauseSecondsOnFull,
        answerInterval: this.strategy.answerInterval,
        addWechatIfMarkFlag: this.strategy.addWechatIfMarkFlag,
        addWechatIfMarkUnFlag: this.strategy.addWechatIfMarkUnFlag,
        flagWechatClientGroupId: this.strategy.flagWechatClientGroupId,
        unFlagWechatClientGroupId: this.strategy.unFlagWechatClientGroupId,
        unFlagWechatServer: this.strategy.unFlagWechatServer,
        addWechatIfMarkUnFlagTime: this.strategy.addWechatIfMarkUnFlagTime,
        showAddWechatButton: this.strategy.showAddWechatButton,
        clientWechatServer: this.strategy.clientWechatServer,
        clientWechatClientGroupId: this.strategy.clientWechatClientGroupId,
        name: this.strategy.name,
        batchNumber: this.strategy.batchNumber,
        wechatClientGroupId: this.strategy.wechatClientGroupId,
        wechatServer: this.strategy.wechatServer,
        triggerWechatServer: this.strategy.triggerWechatServer,
        triggerWechatClientGroupId: this.strategy.triggerWechatClientGroupId,
        smsSign: this.smsSignLabel,
        strategyTemplateClientTalkingId:
          this.strategy.strategyTemplateClientTalkingId,
        playPrologue: this.strategy.playPrologue,
        changeCustomerSourceType: this.strategy.changeCustomerSourceType,
        addWechatOnHangup: this.strategy.addWechatOnHangup,
        prologueId: this.strategy.prologueId,
        showSendSmsButton: this.strategy.showSendSmsButton,
        smsLongUrlGroup: this.strategy.smsLongUrlGroup,
        dialogueId: this.strategy.dialogueId,
      };
      if (this.strategy.extraLongUrlGroup) {
        data.extraLongUrlGroupId = this.strategy.extraLongUrlGroup.join(",");
      }

      if (data.routeMultiple) {
        data.routeMultiple = data.routeMultiple * 100;
      } else {
        data.routeMultiple = 0;
      }
      if (this.activeNameTemplate === "wechat") {
        data.templateType = "PROLOGUE_WECHAT";
        if (this.smsWechatType === "custom") {
          data.msgTemplateId = 0;
        }
        if (this.smsWechatType === "template") {
          data.msgTemplateId = this.smsTemplateChildrenId;
          if (!data.msgTemplateId) {
            this.$message.warning("请选择模板");
            return;
          }
        }
      }
      if (this.activeNameTemplate === "shortMessage") {
        data.templateType = "PROLOGUE_SMS";
        if (this.smsType === "custom") {
          data.msgTemplateId = 0;
        }
        if (this.smsType === "template") {
          data.msgTemplateId = this.smsTemplateChildrenId;
          if (!data.msgTemplateId) {
            this.$message.warning("请选择模板");
            return;
          }
        }
      }
      if (this.activeNameTemplate === "normal") {
        data.templateType = "NORMAL";
        if (this.normalType === "custom") {
          data.msgTemplateId = 0;
        }
        if (this.normalType === "template") {
          data.msgTemplateId = this.smsTemplateChildrenId;
          if (!data.msgTemplateId) {
            this.$message.warning("请选择模板");
            return;
          }
        }
      }
      if (this.activeNameTemplate === "prologue") {
        data.templateType = "PROLOGUE";
        if (this.prologueType === "custom") {
          data.msgTemplateId = 0;
        }
        if (this.prologueType === "template") {
          data.msgTemplateId = this.smsTemplateChildrenId;
          if (!data.msgTemplateId) {
            this.$message.warning("请选择模板");
            return;
          }
        }
      }
      if (this.activeNameTemplate === "robot") {
        data.templateType = "AI_ROBOT";
        if (this.robotType === "custom") {
          data.msgTemplateId = 0;
          if (!this.strategy.dialogueId) {
            this.$message.warning("请选择话术");
            return;
          }
        }
        if (this.robotType === "template") {
          data.msgTemplateId = this.smsTemplateChildrenId;
          if (!data.msgTemplateId) {
            this.$message.warning("请选择模板");
            return;
          }
        }
      }
      if (!data.triggerAction) {
        if (
          this.activeNameTemplate === "wechat" ||
          this.activeNameTemplate === "shortMessage"
        ) {
          this.$message({ message: "请选择触发动作", type: "warning" });
          return;
        }
      }
      if (data.triggerAction === "CALL_MILLIS_LIMIT") {
        data.triggerValue = this.callDuration;
        if (!data.triggerValue) {
          this.$message({ message: "请输入通话达标时长", type: "warning" });
          return;
        }
      }
      if (!this.SMStextarea) {
        this.SMStextarea = "";
      }
      let containShortUrl = this.SMStextarea.indexOf("#短链#") !== -1;
      if (containShortUrl) {
        data.prefix = this.prefix;
        data.smsClickCallbackType = this.callValue;
        data.wechatServer = this.serverWechat;
        data.wechatClientGroupId = this.clientGroupId;
        data.longUrl = this.longUrl;
        data.smsDataLabel = this.smsDataLabel;
        if (this.urlFormat) {
          // 长链格式不正确
          return;
        }
      }
      let manShortUrl = this.SMStextarea.indexOf("#坐席短链#") !== -1;
      if (manShortUrl) {
        data.smsClickCallbackType = this.callValue;
        data.wechatServer = this.serverWechat;
        data.wechatClientGroupId = this.clientGroupId;
      }
      if (
        this.platformTableData.length < 1 &&
        this.activeNameTemplate === "shortMessage"
      ) {
        this.$message({ message: "请新增短信路由平台", type: "warning" });
        return;
      }
      this.platformTableData.forEach((item) => {
        let platforms = item.platformLabel.split("_");
        item.platform = platforms[0];
        item.accessKey = platforms[1];
        if (!item.order) {
          isOrder = true;
        }
        if (!item.platform) {
          isPlatform = true;
        }
      });
      if (isPlatform) {
        this.$message({ message: "平台不能为空", type: "warning" });
        return;
      }
      if (isOrder) {
        this.$message({ message: "顺序不能为空", type: "warning" });
        return;
      }
      if (!data.smsSign && this.activeNameTemplate === "shortMessage") {
        this.$message({ message: "请选择短信签名", type: "warning" });
        return;
      }
      if (this.activeNameTemplate === "shortMessage") {
        if (!this.SMStextarea) {
          this.$message({ message: "短信内容不能为空", type: "warning" });
          return;
        }
      }
      if (
        containShortUrl &&
        !data.longUrl &&
        this.activeNameTemplate === "shortMessage"
      ) {
        this.$message({ message: "长链不能为空", type: "warning" });
        return;
      }
      data.platformTableData = this.platformTableData;
      let path = "https://" + this.taskServer + this.$constants.basePath;
      if (this.activeNameTemplate === "shortMessage" && this.smsNum > 1) {
        this.$confirm("确认发送 " + this.smsNum + "条短信？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.strategyFromHangupsmsChange(data, path);
          })
          .catch(() => {});
      } else {
        this.strategyFromHangupsmsChange(data, path);
      }
    },
    // 启用挂机短信--修改策略
    strategyFromHangupsmsChange(obj, path) {
      let data = {
        id: this.templateId,
        templateType: obj.templateType,
        triggerAction: obj.triggerAction,
        triggerValue: obj.triggerValue,
        batchNumber: obj.batchNumber,
        msgTemplateId: obj.msgTemplateId,
      };
      if (this.activeNameTemplate === "wechat") {
        data.triggerWechatServer = obj.triggerWechatServer;
        data.maxRouteMultiple = obj.maxRouteMultiple;
        data.triggerWechatClientGroupId = obj.triggerWechatClientGroupId;
        data.playPrologue = obj.playPrologue;
        data.changeCustomerSourceType = obj.changeCustomerSourceType;
        data.addWechatOnHangup = obj.addWechatOnHangup;
        data.prologueId = obj.prologueId;
        if (data.addWechatOnHangup) {
          if (!data.triggerWechatServer) {
            this.$message.warning("请选择服务器 ");
            return;
          }
          if (this.serverSelect) {
            data.triggerWechatClientGroupId = -2;
          }
          if (!data.triggerWechatClientGroupId) {
            this.$message.warning("请选择机器分组 ");
            return;
          }
        }

        if (!data.prologueId && data.playPrologue) {
          this.$message.warning("请选择开场白");
          return;
        }
      }
      if (this.activeNameTemplate === "shortMessage") {
        data.smsContent = obj.smsContent;
        data.hangupSmsRouteInfoItemList = [];
        data.maxRouteMultiple = obj.maxRouteMultiple;
        data.wechatClientGroupId = obj.wechatClientGroupId;
        data.wechatServer = obj.wechatServer;
        data.longUrl = obj.longUrl;
        data.sign = obj.smsDataLabel;
        data.smsSign = obj.smsSign;
        data.prefix = obj.prefix;
        data.smsClickCallbackType = obj.smsClickCallbackType;
        data.playPrologue = obj.playPrologue;
        data.prologueId = obj.prologueId;
        obj.platformTableData.forEach((item) => {
          let obj = {
            forbiddenArea: item.forbiddenArea.join(","),
            customerPhoneOperator: item.customerPhoneOperator.join(","),
            order: item.order,
            platform: item.platform,
            accessKey: item.accessKey,
            id: item.id,
          };
          data.hangupSmsRouteInfoItemList.push(obj);
        });
        if (!data.prologueId && data.playPrologue) {
          this.$message.warning("请选择开场白");
          return;
        }
      }
      if (this.activeNameTemplate === "normal") {
        data.triggerAction = "NONE";
        data.triggerValue = "";
        data.routeMultiple = obj.routeMultiple;
        data.strategyTemplateClientTalking = {
          originateTimeout: obj.originateTimeout,
          stopCallOnFull: obj.stopCallOnFull,
          pauseSecondsOnFull: obj.pauseSecondsOnFull,
          answerInterval: obj.answerInterval,
          addWechatIfMarkFlag: obj.addWechatIfMarkFlag,
          addWechatIfMarkUnFlag: obj.addWechatIfMarkUnFlag,
          // notifyAddFans: obj.notifyAddFans,
          flagWechatClientGroupId: obj.flagWechatClientGroupId,
          unFlagWechatClientGroupId: obj.unFlagWechatClientGroupId,
          unFlagWechatServer: obj.unFlagWechatServer,
          showAddWechatButton: obj.showAddWechatButton,
          clientWechatServer: obj.clientWechatServer,
          clientWechatClientGroupId: obj.clientWechatClientGroupId,
          id: obj.strategyTemplateClientTalkingId,
          longUrlGroupId: obj.smsLongUrlGroup,
          extraLongUrlGroupId: obj.extraLongUrlGroupId,
          showSendSmsButton: obj.showSendSmsButton,
          // yc新增
          addFansGroupType: this.switchStatus,
          unFlagAddFansGroupType: this.switchStatus3,
          autoAddFansByTimeFlag: this.autoAddFansByTimeFlag,
          addWechatIfMarkUnFlagTime: obj.addWechatIfMarkUnFlagTime * 1000,
          // notifyAddFansTimes: obj.notifyAddFansTimes * 1000,
        };
        data.smsClickCallbackType = obj.smsClickCallbackType;
        data.wechatServer = obj.wechatServer;
        data.wechatClientGroupId = obj.wechatClientGroupId;
        if (data.strategyTemplateClientTalking.addWechatIfMarkFlag) {
          if (!data.strategyTemplateClientTalking.flagWechatClientGroupId) {
            this.$message.warning("请选择自动加粉机器分组");
            return;
          }
        }
        // ------------------
        if (data.strategyTemplateClientTalking.addWechatIfMarkUnFlag) {
          if (this.value3 === "false") {
            if (this.serverSelect) {
              data.strategyTemplateClientTalking.unFlagWechatClientGroupId = -2;
            }
            if (!data.strategyTemplateClientTalking.unFlagWechatClientGroupId) {
              this.$message.warning("请选择未成单加粉机器分组");
              return;
            }
          }
        }
        // ------------------
        if (data.strategyTemplateClientTalking.showAddWechatButton) {
          if (this.value2 === "false") {
            //yc新增
            if (this.serverSelect) {
              data.strategyTemplateClientTalking.clientWechatClientGroupId = -2;
            }
            if (!data.strategyTemplateClientTalking.clientWechatClientGroupId) {
              this.$message.warning("请选择微信加粉机器分组");
              return;
            }
          }
        }
        if (data.showSendSmsButton) {
          if (!data.strategyTemplateClientTalking.longUrlGroupId) {
            this.$message.warning("请选择短信长链分组");
            return;
          }
        }
      }
      if (this.activeNameTemplate === "prologue") {
        data.triggerAction = "NONE";
        data.triggerValue = "";
        data.routeMultiple = obj.routeMultiple;
        data.strategyTemplateClientTalking = {
          originateTimeout: obj.originateTimeout,
          stopCallOnFull: obj.stopCallOnFull,
          pauseSecondsOnFull: obj.pauseSecondsOnFull,
          answerInterval: obj.answerInterval,
          addWechatIfMarkFlag: obj.addWechatIfMarkFlag,
          addWechatIfMarkUnFlag: obj.addWechatIfMarkUnFlag,
          // notifyAddFans: obj.notifyAddFans,
          flagWechatClientGroupId: obj.flagWechatClientGroupId,
          unFlagWechatClientGroupId: obj.unFlagWechatClientGroupId,
          unFlagWechatServer: obj.unFlagWechatServer,
          showAddWechatButton: obj.showAddWechatButton,
          clientWechatServer: obj.clientWechatServer,
          clientWechatClientGroupId: obj.clientWechatClientGroupId,
          id: obj.strategyTemplateClientTalkingId,
          longUrlGroupId: obj.smsLongUrlGroup,
          extraLongUrlGroupId: obj.extraLongUrlGroupId,
          showSendSmsButton: obj.showSendSmsButton,
          // yc新增
          addFansGroupType: this.switchStatus,
          unFlagAddFansGroupType: this.switchStatus3,
          autoAddFansByTimeFlag: this.autoAddFansByTimeFlag,
          addWechatIfMarkUnFlagTime: obj.addWechatIfMarkUnFlagTime * 1000,
          // notifyAddFansTimes: obj.notifyAddFansTimes * 1000,
        };
        data.smsClickCallbackType = obj.smsClickCallbackType;
        data.wechatServer = obj.wechatServer;
        data.wechatClientGroupId = obj.wechatClientGroupId;
        data.playPrologue = obj.playPrologue;
        data.prologueId = obj.prologueId;
        if (data.strategyTemplateClientTalking.addWechatIfMarkFlag) {
          if (this.serverSelect) {
            data.strategyTemplateClientTalking.flagWechatClientGroupId = -2;
          }
          if (!data.strategyTemplateClientTalking.flagWechatClientGroupId) {
            this.$message.warning("请选择自动加粉机器分组");
            return;
          }
        }
        // ------------------
        if (data.strategyTemplateClientTalking.addWechatIfMarkUnFlag) {
          if (this.value3 === "false") {
            if (this.serverSelect) {
              data.strategyTemplateClientTalking.unFlagWechatClientGroupId = -2;
            }
            if (!data.strategyTemplateClientTalking.unFlagWechatClientGroupId) {
              this.$message.warning("请选择未成单加粉机器分组");
              return;
            }
          }
        }
        // ------------------
        if (data.strategyTemplateClientTalking.showAddWechatButton) {
          if (this.value2 === "false") {
            //yc新增
            if (this.serverSelect) {
              data.strategyTemplateClientTalking.clientWechatClientGroupId = -2;
            }
            if (!data.strategyTemplateClientTalking.clientWechatClientGroupId) {
              this.$message.warning("请选择微信加粉机器分组");
              return;
            }
          }
        }
        if (!data.prologueId && data.playPrologue) {
          this.$message.warning("请选择开场白");
          return;
        }
        if (data.showSendSmsButton) {
          if (!data.strategyTemplateClientTalking.longUrlGroupId) {
            this.$message.warning("请选择短信长链分组");
            return;
          }
        }
      }
      if (this.activeNameTemplate === "robot") {
        data.triggerAction = "NONE";
        data.triggerValue = "";
        data.maxRouteMultiple = obj.maxRouteMultiple;
        data.dialogueId = obj.dialogueId;
      }
      if (this.type === "batchSet") {
        this.close();
        this.$emit("submit", "batchSet", data);
        return;
      }
      this.isChangStrategy_submit = true;
      this.$service(
        "/sip/task/updateHangupSmsStrategy",
        data,
        "post",
        path,
        null
      )
        .then((res) => {
          this.isChangStrategy_submit = false;
          if (res.result == 200) {
            this.$message({ message: "修改成功", type: "success" });
            this.close();
            this.$emit("submit", "hangupModifySuccess");
          }
        })
        .catch((err) => {
          this.isChangStrategy_submit = false;
        });
    },
    // 点击取消
    close() {
      this.visible = false;
    },
    // 关闭弹框
    strategyClose() {
      this.strategy = {};
      this.SMStextarea = "";
      this.longUrl = "";
      this.smsDataLabel = "";
      this.prefix = "https://";
      this.callValue = "NONE";
      this.urlFormat = false;
      this.platformTableData = [];
      this.isAddPlatform = false;
      this.isDisabled = false;
      this.isAddWechat = false;
      this.visible = false;
      this.smsType = "custom";
      this.smsWechatType = "custom";
      this.normalType = "custom";
      this.prologueType = "custom";
      this.robotType = "custom";
      this.smsTemplateId = "";
      this.smsTemplateChildrenId = "";
      this.smsSignValue = "";
      this.smsSignLabel = "";
      this.parseUrlTitle = "";
      this.smsSignList = [];
      this.callDuration = "";
      this.wechatGroupIdName = "";
      this.automaticGroupIdName = "";
      this.smsLongUrlGroupList = [];
      // ----
      this.strategy.clientWechatClientGroupId = 0;
    },
  },
};
</script>
<style scoped>
.input1 {
  width: 200px;
}
.m_table {
  margin: 20px 0 5px !important;
}
.m_table >>> .has-gutter {
  line-height: normal;
}
.hangup_form .el-form-item >>> .el-input {
  width: 202px;
}
.seats_item {
  width: 400px;
  position: relative;
}
.seats_item >>> .el-form-item__content {
  position: absolute;
  right: 30px;
}
</style>
